export default {
  setBusinessUnits(state, { data }) {
    data.sort((a, b) => a.name.localeCompare(b.name));
    state.list = data;
  },
  setBusinessUnit(state, { businessUnit }) {
    if (state.list.find((el) => el.id === businessUnit.id)) {
      state.list = state.list.filter((el) => el.id !== businessUnit.id);
    } else {
      state.list.push(businessUnit);
    }
  }
};
