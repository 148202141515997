export default {
  setHolidays(state, { data }) {
    state.list = data;
  },
  setHoliday(state, { data }) {
    const holidays = [...state.list];
    const { id } = data;
    const index = holidays.findIndex((holiday) => holiday.id === id);
    if (index >= 0) {
      holidays[index] = data;
    } else {
      holidays.push(data);
    }
    state.list = holidays;
  }
};
