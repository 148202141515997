const routes = [
  {
    path: "/notificaciones",
    name: "notifications",
    component: () => import(
      /* webpackChunkName: "notifications" */
      "@/views/Notifications"
    ),
    meta: {
      module: "Notificaciones",
      publicName: "Notificaciones"
    }
  },
  {
    path: "/configuracion/notificaciones",
    name: "notification-settings",
    component: () => import(
      /* webpackChunkName: "notifications" */
      "@/views/settings/settingsNotifications"
    ),
    meta: {
      requireAuth: true,
      module: "Notificaciones",
      publicName: "Configuraci\xF3n de notificaciones",
      notValidateModule: true
    }
  }
];
export default routes;
