var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import store from "@/store";
import { api } from "@/api";
const routes = {
  path: "/outsourcer/:id",
  name: "osr-profile",
  component: () => import(
    /* webpackChunkName: "outsourcer" */
    "@/views/osrProfile/OsrProfileLayout"
  ),
  beforeEnter: (to, from, next) => __async(void 0, null, function* () {
    let profileId = "";
    if (to.params.id) {
      localStorage.setItem("profile_id", to.params.id);
      localStorage.setItem("profile_url", to.params.url);
      profileId = to.params.id;
    } else if (to.params.url === from.params.url && to.params.url === localStorage.getItem("profile_url")) {
      profileId = localStorage.getItem("profile_id");
    } else {
      const data = yield api.profile.getIdFromUrl(to.params.url);
      profileId = data;
      localStorage.setItem("profile_id", data);
      localStorage.setItem("profile_url", to.params.url);
    }
    if (store.state.me.user.role !== "OSR" || profileId !== store.state.me.user.id) {
      next({ name: "not-found" });
    } else
      next();
  }),
  redirect: { name: "osr-profile-personal-info" },
  children: [
    {
      path: "personal",
      name: "osr-profile-personal-info",
      component: () => import(
        /* webpackChunkName: "outsourcer" */
        "@/views/osrProfile/OsrProfilePersonal"
      ),
      meta: {
        requireAuth: true,
        module: "OSR",
        publicName: "Perfil de colaborador",
        notValidateModule: true
      }
    },
    {
      path: "razonesSociales",
      name: "osr-profile-legal-names",
      component: () => import(
        /* webpackChunkName: "outsourcer" */
        "@/views/osrProfile/OsrProfileLegalNames"
      ),
      meta: {
        requireAuth: true,
        module: "OSR",
        publicName: "Perfil de colaborador",
        notValidateModule: true
      }
    }
  ]
};
export default routes;
