var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("transition", { attrs: { "name": "modal" } }, [_c("div", { staticClass: "modal" }, [_c("div", { staticClass: "modal__dialog", class: {
    "modal__dialog--responsive": _vm.width
  } }, [_vm.loading ? _c("AppLoader", { attrs: { "inner-loader": "" } }) : _vm._e(), _c("div", { staticClass: "modal__content", style: __spreadValues({}, _vm.width ? { width: _vm.width } : {}) }, [_vm.closeable || _vm.title ? _c("div", { staticClass: "modal__header" }, [_c("p", { staticClass: "modal__title" }, [_vm._t("modal-title", function() {
    return [_vm._v(" " + _vm._s(_vm.title) + " ")];
  })], 2), _vm.closeable ? _c("div", { staticClass: "modal__close", attrs: { "role": "button" }, on: { "click": _vm.closeModal } }, [_c("Icon", { attrs: { "icon": "close" } })], 1) : _vm._e()]) : _vm._e(), _c("div", { staticClass: "modal__body" }, [_vm._t("default"), !_vm.$slots.default ? _vm._t("modal-body", function() {
    return [_vm._v(" Not contain ")];
  }) : _vm._e()], 2), _c("div", { staticClass: "modal__footer" }, [_vm._t("modal-buttons"), _vm._t("modal-footer"), _vm.config ? _c("div", { staticClass: "flex flex-row justify-end" }, [_vm.config.buttonCancelActive ? _c("div", { staticClass: "mx-8 flex justify-end", on: { "click": _vm.closeModal } }, [_vm.config.firstButton ? _c("Button", { attrs: { "theme": "secondary", "label": _vm.config.firstButton.label || "Cancelar", "size": "small" } }) : _c("Button", { attrs: { "theme": "secondary", "label": "Cancelar", "size": "small" } })], 1) : _vm._e(), _vm.config.secondButton.active ? _c("div", { staticClass: "flex justify-end" }, [_c("Button", { attrs: { "disabled": _vm.config.secondButton.disabled || _vm.loadingButton || false, "theme": _vm.config.secondButton.theme || "primary", "label": _vm.config.secondButton.label || "Label", "size": _vm.config.secondButton.size || "medium", "loading": _vm.loadingButton }, on: { "click": _vm.successClick } })], 1) : _vm._e()]) : _vm._e()], 2)])], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
