export default {
  setTimeOffPolicies(state, policies) {
    state.timeOffPolicies = policies.map((policy) => {
      const sortedPeriodsPolicy = policy;
      const sortedPeriods = policy.periods.sort((a, b) => a.start_year > b.start_year ? 1 : -1);
      sortedPeriodsPolicy.periods = sortedPeriods;
      return sortedPeriodsPolicy;
    });
  },
  setNewTimeOffPolicy(state, policy) {
    state.timeOffPolicies.push(policy);
  },
  setUpdatedTimeOffPolicy(state, policy) {
    const findPolicy = state.timeOffPolicies.findIndex((pol) => pol.id === policy.id);
    if (findPolicy) {
      state.timeOffPolicies.splice(findPolicy, 1, policy);
    }
  },
  removeTimeOffPolicy(state, policy) {
    const findPolicy = state.timeOffPolicies.findIndex((pol) => pol.id === policy.id);
    if (findPolicy) {
      state.timeOffPolicies.splice(findPolicy, 1);
    }
  },
  setCompanyData(state, payload) {
    state.companyData = payload;
  },
  setDownloadTimeClockTemplate(state, payload) {
    state.downloadTimeClockTemplate = payload;
  },
  setProfileSetting(state, payload) {
    state.publicProfile = payload;
  }
};
