import axios from "axios";
import { capitalize } from "@/models/utils";
export default class Model {
  /**
   * @param {Object} data - Data used to initialize the object.
   * @param {Object} options - Controls the object instantiation.
   * @param {string[]} options.fields - Adds these fields to the fields defined
   * in the class.
   * @param {string} [options.idField="id"] - Set the field used to identify the
   * object.
   * @param {{name: string, value: any}[]} options.defaults - Default values to
   * use when initializing the object.
   */
  constructor(data = {}, options = {}) {
    Object.defineProperty(this, "objectFields", {
      value: [],
      writable: true,
      enumerable: false,
      configurable: true
    });
    Object.defineProperty(this, "valid", {
      value: true,
      writable: true,
      enumerable: false,
      configurable: true
    });
    Object.defineProperty(this, "errors", {
      value: [],
      writable: true,
      enumerable: false,
      configurable: true
    });
    Object.defineProperty(this, "idField", {
      value: "id",
      writable: true,
      enumerable: false,
      configurable: true
    });
    const instantiationFields = options && options.fields || [];
    const classFields = this.constructor.fields();
    const objectFields = [...instantiationFields, ...classFields];
    if (options && options.idField) {
      this.idField = options.idField;
    } else if (options && options.idField === null) {
      this.idField = null;
    }
    if (this.idField && !objectFields.includes(this.idField)) {
      objectFields.unshift(this.idField);
    }
    this.objectFields = objectFields;
    this.setFields(data, options.defaults);
    this.validate();
  }
  /**
   * Returns the fields used on the object.
   * @return {string[]} - Fields of the model
   * @abstract
   * @private
   */
  static fields() {
    return [];
  }
  /**
   * Global default value used.
   */
  static default() {
    return null;
  }
  static defaults() {
    return {};
  }
  static apiNamespace() {
    return "api";
  }
  static resourceNamespace() {
    return "";
  }
  static apiPath() {
    const resourceNamespace = this.resourceNamespace();
    const resource = resourceNamespace ? `/${resourceNamespace}` : "";
    return `/${this.apiNamespace()}${resource}`;
  }
  resourcePath() {
    return `${this.constructor.apiPath()}/${this._id}`;
  }
  reportsResourcePath() {
    return `${this.constructor.apiPath()}/reports/${this._id}`;
  }
  get _id() {
    return this.idField && this[this.idField];
  }
  setFields(data, defaults) {
    this.objectFields.forEach((field) => {
      const objectDefaults = this.constructor.defaults();
      const defaultValue = this.constructor.default();
      const customDefaultValue = defaults && field in defaults ? defaults[field] : objectDefaults[field];
      if (data[field] !== void 0) {
        this[field] = data[field];
      } else if (this[field] === void 0) {
        this[field] = customDefaultValue !== void 0 ? customDefaultValue : defaultValue;
      }
    });
    return this;
  }
  /**
   * Validates object fields using the custom class validations
   * @return {Boolean} Value of valid field
   */
  validate() {
    this.errors = [];
    if (this.validations) {
      const conditions = Object.keys(this.validations);
      this.valid = conditions.reduce((valid, condition) => {
        if (!this.validations[condition]) {
          this.errors.push(condition);
        }
        return !!this.validations[condition] && valid;
      }, true);
    }
    return this.valid;
  }
  camelCaseFields() {
    this.objectFields.forEach((field) => {
      const words = field.split("_");
      if (words.length > 1) {
        const firstWord = words.shift();
        const nextWords = [];
        words.forEach((word) => {
          nextWords.push(capitalize(word));
        });
        const newField = [firstWord.toLowerCase(), ...nextWords].join("");
        this[newField] = this[field];
      }
    });
  }
  static get(url, config = {}) {
    return axios.get(url, config).then((response) => response.data);
  }
  static post(url, data, config = {}) {
    return axios.post(url, data, config).then((response) => response.data);
  }
  static put(url, data, config = {}) {
    return axios.put(url, data, config).then((response) => response.data);
  }
  static patch(url, data, config = {}) {
    return axios.patch(url, data, config).then((response) => response.data);
  }
  static delete(url, config = {}) {
    return axios.delete(url, config).then((response) => response.data);
  }
  static list() {
    return this.get(`${this.apiPath()}/`).then((data) => data.map((objectData) => new this(objectData)));
  }
  static retrive(id) {
    return this.get(`${this.apiPath()}/${id}/`).then((data) => new this(data));
  }
  refresh() {
    return this.constructor.get(`${this.resourcePath()}/`).then((data) => this.setFields(data));
  }
  create() {
    return this.constructor.post(`${this.constructor.apiPath()}/`, this).then((data) => this.setFields(data));
  }
  update() {
    return this.constructor.put(`${this.resourcePath()}/`, this).then((data) => this.setFields(data));
  }
  updatePatch(updatedData) {
    return this.constructor.patch(`${this.resourcePath()}/`, updatedData).then((data) => this.setFields(data));
  }
  delete() {
    return this.constructor.delete(`${this.resourcePath()}/`);
  }
}
