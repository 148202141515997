var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "toggle", class: { "toggle--disabled": _vm.disabled } }, [_vm.type === "simple" ? _c("div", { staticClass: "toggle__simple", class: {
    "toggle__simple--off": !_vm.value,
    "toggle__simple--on": _vm.value
  } }, [_c("div", { staticClass: "toggle__bar w-12 h-6 rounded-xl relative", on: { "click": function($event) {
    $event.stopPropagation();
    return _vm.toggle.apply(null, arguments);
  } } }, [_c("div", { staticClass: "toggle__circle w-5 h-5 bg-white rounded-full absolute" })])]) : _c("div", { staticClass: "toggle__labeled inline-block" }, [_c("div", { staticClass: "flex border border-grey2 rounded bg-white" }, [_c("div", { staticClass: "w-1/2" }, [_c("div", { staticClass: "text-fs-14 whitespace-nowrap toggle__box", class: { "bg-[var(--brand-primary)]": _vm.value, "text-white": _vm.value }, on: { "click": function($event) {
    $event.stopPropagation();
    return (() => _vm.disabled ? null : _vm.$emit("input", true)).apply(null, arguments);
  } } }, [_vm._v(" S\xED ")])]), _c("div", { staticClass: "w-1/2" }, [_c("div", { staticClass: "text-fs-14 whitespace-nowrap toggle__box", class: { "bg-[var(--brand-primary)]": !_vm.value, "text-white": !_vm.value }, on: { "click": function($event) {
    $event.stopPropagation();
    return (() => _vm.disabled ? null : _vm.$emit("input", false)).apply(null, arguments);
  } } }, [_vm._v(" No ")])])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
