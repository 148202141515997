var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "select", class: {
    "select--is-focus": _vm.isFocus,
    "select--is-disabled": _vm.disabled,
    "select--has-error": _vm.hasError,
    [`select--${_vm.size}`]: _vm.size !== "normal",
    [`select--direction-${_vm.openDirection}`]: true,
    "select--multiple-selected": _vm.multiple && !_vm.showCounterSelect && _vm.selectedItems.length > 0
  } }, [_c("div", { staticClass: "select__control", on: { "click": () => !_vm.showCounterSelect ? _vm.onFocus() : null } }, [_c("div", { staticClass: "select__input", class: {
    "flex": _vm.startIcon
  } }, [_vm._t("start-icon"), _c("div", { staticClass: "select__input" }, [!_vm.filterText && (!_vm.multiple || _vm.multiple && _vm.showCounterSelect) ? _c("div", { directives: [{ name: "tooltip", rawName: "v-tooltip", value: _vm.selectedText, expression: "selectedText" }], staticClass: "select__text", class: { "select__text--show-options": _vm.showOptions }, on: { "click": () => !_vm.disabled && _vm.$refs.input.focus() } }, [_vm._t("selectedText", function() {
    return [_vm._v(" " + _vm._s(_vm.selectedText) + " ")];
  }, { "selected": _vm.selectedItem, "selectedText": _vm.selectedText })], 2) : _vm._e(), !_vm.showCounterSelect ? _c("div", { staticClass: "select__items-selected" }, _vm._l(_vm.selectedItems, function(itemSelected) {
    return _c("div", { directives: [{ name: "tooltip", rawName: "v-tooltip", value: _vm.getSelectedItemText(itemSelected), expression: "getSelectedItemText(itemSelected)" }], key: _vm.onlyKeySelect ? itemSelected : itemSelected[_vm.keyField], staticClass: "select__item-selected", on: { "click": () => _vm.onSelectItem(itemSelected) } }, [!_vm.hasCustomInfoSlot ? _c("div", { staticClass: "select__item-selected-text" }, [_vm._v(" " + _vm._s(_vm.getSelectedItemText(itemSelected)) + " ")]) : [_vm._t("customInfo", null, { "option": itemSelected })], _c("div", { staticClass: "select__item-clear" }, [_c("Icon", { attrs: { "icon": "close" } })], 1)], 2);
  }), 0) : _vm._e(), _c("input", { directives: [{ name: "show", rawName: "v-show", value: _vm.searchable || !_vm.searchable && _vm.showCounterSelect, expression: "searchable || (!searchable && showCounterSelect)" }, { name: "model", rawName: "v-model", value: _vm.filterText, expression: "filterText" }], ref: "input", class: {
    "hide_input": !_vm.showOptions && (_vm.multiple && _vm.selectedItems.length > 0) && !_vm.showCounterSelect
  }, attrs: { "placeholder": _vm.selectedText || _vm.multiple && _vm.selectedItems.length > 0 ? "" : _vm.placeholder, "disabled": _vm.disabled, "readonly": !_vm.searchable }, domProps: { "value": _vm.filterText }, on: { "focus": _vm.onFocus, "keydown": [function($event) {
    if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "down", 40, $event.key, ["Down", "ArrowDown"]))
      return null;
    return _vm.nextElementHighligth.apply(null, arguments);
  }, function($event) {
    if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"]))
      return null;
    return _vm.prevElementHighligth.apply(null, arguments);
  }, function($event) {
    if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "tab", 9, $event.key, "Tab"))
      return null;
    return _vm.onTab.apply(null, arguments);
  }], "keypress": [function($event) {
    if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter"))
      return null;
    return _vm.onEnter.apply(null, arguments);
  }, function($event) {
    if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"]))
      return null;
    return _vm.onSpace.apply(null, arguments);
  }], "click": () => _vm.showOptions = true, "input": [function($event) {
    if ($event.target.composing)
      return;
    _vm.filterText = $event.target.value;
  }, _vm.onInput] } }), !_vm.searchable && !_vm.showCounterSelect && _vm.showPlaceholder ? _c("div", { class: { "opacity-50": !_vm.selectedText } }, [_vm._v(" " + _vm._s(_vm.selectedText || _vm.placeholder) + " ")]) : _vm._e(), (_vm.selectedKey || _vm.selectedItems.length > 0) && _vm.showClearButton && !_vm.disabled ? _c("div", { staticClass: "select__clear", on: { "click": _vm.onClear } }, [_c("Icon", { attrs: { "icon": "close" } })], 1) : _vm._e(), _c("div", { staticClass: "select__icon", on: { "click": _vm.setFocus } }, [_c("Icon", { attrs: { "icon": _vm.showOptions ? "chevron-up" : "chevron-down" } })], 1)])], 2)]), _vm.showOptions || _vm.alwaysOpen ? _c("div", { ref: "box", staticClass: "select__box", class: {
    "select__box--always-open": _vm.alwaysOpen,
    "select__box--in-the-box": _vm.isInTheBox,
    "h-[100px]": _vm.loading
  }, style: _vm.isInTheBox ? __spreadValues({
    top: `${_vm.positionTop}px`,
    left: `${_vm.positionLeft}px`,
    width: `${_vm.controlBoxWidth}px`
  }, _vm.hasTransform ? {
    transform: `translateY(-100%)`
  } : {}) : {}, on: { "scroll": _vm.onScrollBox } }, [_vm.loading ? _c("AppLoader", { attrs: { "inner-loader": "" } }) : [_vm.allSelectedOption && _vm.filteredItems.length > 0 ? _c("div", [_c("div", { staticClass: "select__box-item", class: {
    "select__box-item--is-selected": _vm.allSelected,
    "select__box-item--is-highligth": _vm.highligthIndex === "all"
  }, on: { "click": () => _vm.onAllSelect(), "mouseover": () => _vm.setHighligth("all") } }, [_c("Checkbox", { attrs: { "value": _vm.allSelected, "no-model": true } }, [_vm._v(" " + _vm._s(_vm.allSelectedText) + " ")])], 1)]) : _vm._e(), _vm._l(_vm.filteredItems.filter((option) => !_vm.elementInValue(option) || !_vm.hideSelecteds), function(option, index) {
    return _c("div", { key: option[_vm.keyField] }, [_c("div", { staticClass: "select__box-item", class: {
      "select__box-item--is-highligth": _vm.highligthIndex === index,
      "select__box-item--is-selected": _vm.isSelected(option),
      "select__box-item--is-disabled": option.disabled,
      "select__box-item--has-all-option": _vm.allSelectedOption && _vm.filteredItems.length > 0,
      "flex items-baseline": _vm.hasOptionSlot && _vm.multiple
    }, attrs: { "data-id": index }, on: { "click": function($event) {
      $event.stopPropagation();
      return (() => _vm.onSelectItem(option)).apply(null, arguments);
    }, "mouseover": () => _vm.setHighligth(index) } }, [_vm.multiple ? [_c("Checkbox", { attrs: { "value": _vm.elementInValue(option) || _vm.allChildrenSelected(option), "indeterminate": _vm.childrenInValue(option) && !_vm.allChildrenSelected(option), "disabled": option.disabled || false, "no-model": true } })] : _vm._e(), _vm._t("option", function() {
      return [_vm._v(" " + _vm._s(option[_vm.textField]) + " "), _c("span", { staticClass: "text-xs text-yellow", attrs: { "name": "help-text" } }, [_vm._v(" " + _vm._s(option.helptext) + " ")])];
    }, { "option": option })], 2), _vm._l(option.children || [], function(children, childrenIndex) {
      return _c("div", { key: `${index}-${childrenIndex}-${children[_vm.keyField]}`, staticClass: "select__box-item select__box-item--is-children", on: { "click": function($event) {
        $event.stopPropagation();
        return (() => _vm.onSelectItem(children)).apply(null, arguments);
      } } }, [_vm.multiple ? [_c("Checkbox", { attrs: { "value": _vm.elementInValue(children), "disabled": children.disabled || false, "no-model": true } }, [_vm._v(" " + _vm._s(children[_vm.textField]) + " ")])] : [_vm._t("option", function() {
        return [_vm._v(" " + _vm._s(children[_vm.textField]) + " ")];
      }, { "option": children })]], 2);
    })], 2);
  }), _vm.filteredItems.length === 0 || _vm.filteredItems.filter((option) => !_vm.elementInValue(option)).length === 0 && _vm.hideSelecteds ? _c("div", { staticClass: "select__box-item" }, [_c("p", [_vm._v(" No hay resultados ")])]) : _vm._e()]], 2) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
