const routes = {
  path: "/recibos-nomina",
  name: "payroll-receipts",
  component: () => import(
    /* webpackChunkName: "payroll-receipts" */
    "@/views/payroll-receipts/PayrollReceiptLayout"
  ),
  redirect: {
    name: "payroll-receipts-upload"
  },
  children: [
    {
      path: "empresa",
      name: "payroll-receipts-company",
      component: {
        render: (h) => h("router-view")
      },
      redirect: {
        name: "payroll-receipts-upload"
      },
      children: [
        {
          path: "asignados",
          name: "payroll-receipts-upload",
          component: () => import(
            /* webpackChunkName: "payroll-receipts" */
            "@/views/payroll-receipts/PayrollReceiptCompanyUpload"
          ),
          meta: {
            requireAuth: true,
            module: "payroll_receipts",
            publicName: "Recibos de n\xF3mina"
          }
        },
        {
          path: "sin-asignar",
          name: "payroll-receipts-no-assigned",
          component: () => import(
            /* webpackChunkName: "payroll-receipts" */
            "@/views/payroll-receipts/PayrollReceiptNoAssigned"
          ),
          meta: {
            requireAuth: true,
            module: "payroll_receipts",
            publicName: "Recibos de n\xF3mina sin asignar"
          }
        }
      ]
    },
    {
      path: "empleado",
      name: "payroll-receipts-employee",
      component: () => import(
        /* webpackChunkName: "payroll-receipts" */
        "@/views/payroll-receipts/PayrollReceiptEmployee"
      ),
      meta: {
        requireAuth: true,
        module: "payroll_receipts",
        publicName: "Mis recibos de n\xF3mina",
        onlyMyCompany: true
      }
    },
    {
      path: "empleado/:id",
      name: "payroll-receipts-employee-detail",
      redirect: {
        name: "payroll-receipts-employee"
      }
    },
    {
      path: "configuracion",
      name: "payroll-receipts-configuration",
      component: () => import(
        /* webpackChunkName: "payroll-receipts" */
        "@/views/payroll-receipts/PayrollReceiptConfiguration"
      ),
      meta: {
        requireAuth: true,
        module: "payroll_receipts",
        publicName: "Configuraci\xF3n de recibos de n\xF3mina",
        backTo: "back"
      }
    }
  ]
};
export default routes;
