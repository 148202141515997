var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "tabstrip", class: [`tabstrip--${_vm.mode}`] }, [_c("div", { staticClass: "tabstrip__head" }, _vm._l(_vm.childrenTabs, function(tab, tabIndex) {
    return _c("div", { key: tab.value, staticClass: "tabstrip__head-title", class: {
      "tabstrip__head-title--active": _vm.tabSelected === tab.value
    }, attrs: { "index": tabIndex }, on: { "click": () => _vm.setTabActive(tab.value) } }, [_c("span", [_vm._v(" " + _vm._s(tab.title) + " ")])]);
  }), 0), _c("div", { staticClass: "tabstrip__body", class: {
    "tabstrip__body--first-selected": _vm.isFirstSelected
  } }, [_vm._t("default")], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
