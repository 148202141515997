import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import areas from "./areas";
import employees from "./employees";
import holidays from "./holidays";
import levels from "./levels";
import locations from "./locations";
import vacations from "./vacations";
import razonesSociales from "./razonesSociales";
import timeOff from "./timeOff";
import timeClock from "./timeClock";
import policies from "./policies";
import vacancies from "./vacancies";
import calendar from "./calendar";
import surveys from "./surveys";
import reports from "./reports";
import benefits from "./benefits";
import customDocuments from "./customDocuments";
import payrollReceipts from "./payrollReceipts";
import notifications from "./notifications";
import incidences from "./incidences";
import schedulesV1 from "./v1/company/schedules";
import jobProfiles from "./jobProfiles";
import businessUnits from "./businessUnits";
import trainings from "./trainings";
import permissions from "./permissions";
const state = {
  companyData: null,
  timeOffPolicies: [],
  checklistComplete: false,
  downloadTemplate: true,
  errorEmployees: {},
  downloadTimeClockTemplate: true,
  publicProfile: true
};
const modules = {
  areas,
  employees,
  holidays,
  locations,
  vacations,
  razonesSociales,
  timeOff,
  timeClock,
  policies,
  vacancies,
  calendar,
  surveys,
  reports,
  benefits,
  customDocuments,
  payrollReceipts,
  notifications,
  schedulesV1,
  levels,
  incidences,
  jobProfiles,
  businessUnits,
  trainings,
  permissions
};
export default {
  state,
  getters,
  mutations,
  actions,
  modules,
  namespaced: true
};
