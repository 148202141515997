var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
export default function convertNumberFormat(number = 0, format = "n") {
  const formatSplited = format.split("");
  const decimals = formatSplited.length === 1 ? 2 : Number(formatSplited[1]);
  const options = __spreadValues(__spreadValues(__spreadValues({
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals
  }, format.includes("c") ? { style: "currency", currency: "MXN" } : {}), format.includes("n") ? { style: "decimal" } : {}), format.includes("p") ? { style: "percent" } : {});
  const numberSanitize = isNaN(number) ? 0 : Number(number);
  return new Intl.NumberFormat("es-MX", options).format(numberSanitize);
}
