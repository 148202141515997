export function checkRole(store) {
  return function check(role) {
    const lowercaseRole = role.toLowerCase();
    if (lowercaseRole === "wa") {
      return false;
    }
    if (lowercaseRole === "hra") {
      return store.state.me.user.role === "HRA";
    }
    if (lowercaseRole === "boss") {
      return store.state.me.user.is_boss;
    }
    if (lowercaseRole === "recruiter") {
      return store.state.me.user.is_recruiter;
    }
    if (lowercaseRole === "recruitmentvisitor") {
      return store.state.me.user.is_recruitment_visitor;
    }
    if (lowercaseRole === "em") {
      return store.state.me.user.role === "EM";
    }
    if (lowercaseRole === "osr") {
      return store.state.me.user.role === "OSR";
    }
    if (lowercaseRole === "superhra") {
      return store.state.me.user.is_superhra === true;
    }
    if (lowercaseRole === "org_user") {
      return store.state.me.user.is_organization_user === true;
    }
    if (lowercaseRole === "mycompany") {
      if (!store.state.me.user.is_organization_user) {
        return true;
      }
      return store.state.me.user.is_in_base_company;
    }
    if (lowercaseRole === "prepayroll") {
      return store.state.me.user.is_prepayroll_admin;
    }
    if (lowercaseRole === "self_onboarded") {
      return store.state.me.user.company.is_self_onboarded;
    }
    if (lowercaseRole === "monetary_excel") {
      return store.state.me.user.monetary_excel;
    }
    if (lowercaseRole === "process_reviewer") {
      return store.state.me.user.is_internal_process_reviewer;
    }
    return false;
  };
}
function checkPermission(store) {
  return function check(...permissions) {
    return store.state.me.user.access_permissions.map((permission) => permission.field_or_action).some((permission) => permissions.includes(permission));
  };
}
export default function plugin(Vue, store) {
  if (plugin.installed) {
    return;
  }
  plugin.installed = true;
  Vue.prototype.$is = checkRole(store);
  Vue.prototype.$permission = checkPermission(store);
}
