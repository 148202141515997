import Model from "@/models/v1/model";
export default class CustomRequest extends Model {
  static get modelName() {
    return "Request";
  }
  static apiNamespace() {
    return "api/v2";
  }
  static resourceNamespace() {
    return "requests";
  }
  static fields() {
    return [
      "id",
      "reviewer",
      "owner",
      "created_by",
      "edited_by",
      "start_date",
      "end_date",
      "status",
      "reviewed_at",
      "incidence_type",
      "detail",
      "comment",
      "days_off",
      "total_days",
      "with_salary"
    ];
  }
  static defaults() {
    return {
      reviewer: {},
      owner: {},
      edited_by: {},
      status: "",
      with_salary: true
    };
  }
  create(request) {
    return this.constructor.post(`api/v2/requests/`, request);
  }
  update(request) {
    return this.constructor.put(`/api/v2/requests/${request.id}`, request);
  }
  remind(requestId) {
    return this.constructor.post(`${this.resourcePath()}/${requestId}/remind/`);
  }
  addFile(data) {
    const formData = new FormData();
    formData.append("file", data.file);
    return this.constructor.post(`${this.resourcePath()}/files/`, formData, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  }
  getMyUpcomingRequests(date) {
    return this.constructor.get(`api/v2/incidences/?start_date_gte=${date}`);
  }
  getMyPreviousRequests(date) {
    return this.constructor.get(`api/v2/incidences/?start_date_lt=${date}`);
  }
  deleteFile(idFile) {
    return this.constructor.delete(
      `${this.resourcePath()}/files/${idFile}/`
    );
  }
}
