export default [
  {
    path: "/zkteco/incidencias",
    name: "ZKTecoIncidences",
    component: () => import(
      /* webpackChunkName: "timeclock" */
      "@/views/zkteco/ZKTecoView"
    ),
    meta: {
      requireAuth: true,
      module: "zkteco",
      publicName: "Incidencias de ZKTeco"
    }
  }
];
