import { api } from "@/api";
export default {
  createRequest({ commit, rootState }, request) {
    const newRequest = Object.assign(
      {},
      request,
      {
        status: "PEN",
        employee: rootState.me.user.employee.id
      }
    );
    return api.incidencias.create(newRequest).then((response) => {
      commit("company/incidences/setIncidencia", { incidencia: response.data }, { root: true });
      return response.data;
    });
  },
  getVacations({ commit }) {
    return api.vacations.getList().then((response) => {
      const { data } = response;
      const vacations = data.filter((item) => item.type === "VA" || item.type === "PTO");
      commit("company/incidences/setIncidencias", { data: vacations }, { root: true });
      return vacations;
    });
  },
  approveVacation({ commit }, id) {
    return api.vacations.approve(id).then((response) => {
      commit("company/incidences/setIncidencia", { incidencia: response.data }, { root: true });
      return response.data;
    });
  },
  rejectVacation({ commit }, data) {
    return api.vacations.reject(data).then((response) => {
      commit("company/incidences/setIncidencia", { incidencia: response.data }, { root: true });
      return response.data;
    });
  },
  deleteVacation({ commit }, id) {
    return api.incidencias.deleteObject(id).then((response) => {
      commit("company/incidences/setIncidencia", { incidencia: response.data }, { root: true });
      return response.data;
    });
  }
};
