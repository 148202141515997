var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { api } from "@/api";
import { DataError } from "@/exceptions";
export default {
  getBusinessUnits(_0) {
    return __async(this, arguments, function* ({ commit }) {
      const { data } = yield api.businessUnits.getBusinessUnits();
      commit("setBusinessUnits", { data });
      return data;
    });
  },
  createBusinessUnit(_0) {
    return __async(this, arguments, function* ({ commit }, { businessUnit } = {}) {
      if (!businessUnit) {
        throw new DataError("The business unit data is not defined");
      }
      const { data } = yield api.businessUnits.createNewBusinessUnit(businessUnit);
      commit("setBusinessUnit", { businessUnit: data });
      return data;
    });
  },
  updateBusinessUnit(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, { businessUnit }) {
      const updatedBusinessUnit = yield api.businessUnits.editBusinessUnit(businessUnit.id, businessUnit);
      commit("setBusinessUnit", { businessUnit: updatedBusinessUnit });
      return updatedBusinessUnit;
    });
  },
  deleteBusinessUnit(_0, _1) {
    return __async(this, arguments, function* ({ commit, dispatch, state }, { id }) {
      yield api.businessUnits.deleteBusinessUnit(id);
      commit("setBusinessUnit", { businessUnit: { id } });
    });
  }
};
