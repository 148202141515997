var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { ref: "parentMenu", class: _vm.parentPadding, on: { "mouseover": function($event) {
    _vm.showHoverMenu = true;
  }, "mouseleave": function($event) {
    _vm.showHoverMenu = false;
  } } }, [_c("RouterLink", { staticClass: "module-link", class: _vm.computedClasses, attrs: { "to": _vm.submodules.length > 0 ? "" : { name: _vm.routeName, params: _vm.params || {} } } }, [_c("div", { staticClass: "module flex items-center gap-4", class: _vm.active ? "white font-bold" : _vm.color, attrs: { "title": _vm.title } }, [_c("Icon", { staticClass: "!h-[1em] text-fs-16 flex-shrink-0", attrs: { "type": _vm.active ? "fas" : "fal", "icon": _vm.icon } }), _c("transition", { attrs: { "name": "module" } }, [_vm.isOpenMenu ? _c("span", { key: _vm.title, staticClass: "whitespace-nowrap" }, [_vm._v(_vm._s(_vm.title))]) : _vm._e()])], 1), _c("transition", { attrs: { "name": "module" } }, [_vm.isOpenMenu && _vm.submodules.length > 0 ? _c("Icon", { staticClass: "module-arrow !h-[1em] !w-auto text-fs-12", class: _vm.active ? "white" : _vm.color, attrs: { "type": "fal", "icon": "chevron-right" } }) : _vm._e()], 1)], 1), _c("transition", { attrs: { "name": "submenu" } }, [_c("div", { directives: [{ name: "show", rawName: "v-show", value: _vm.showHoverMenu && _vm.isOpenMenu, expression: "showHoverMenu && isOpenMenu" }], ref: "submenu", staticClass: "fixed z-999 w-fit rounded-r-md overflow-hidden bg-main-950" }, _vm._l(_vm.submodules, function(route) {
    return _c("RouterLink", { key: route.title, staticClass: "w-full", attrs: { "to": { name: route.routeName, params: route.params || {} } }, nativeOn: { "click": function($event) {
      return _vm.handleClickSubmenu(route);
    } } }, [_c("div", { staticClass: "h-[52px] px-6 flex items-center text-white text-fs-14 hover:bg-main-900 whitespace-nowrap", class: {
      "bg-main-950": !route.active,
      "bg-main-400": route.active
    }, attrs: { "title": route.title } }, [_vm._v(" " + _vm._s(route.title) + " ")])]);
  }), 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
