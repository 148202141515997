export default {
  areas: (state) => state.areas.list,
  companyData: (state) => state.companyData,
  employees: (state) => state.employees.list,
  locations: (state) => state.locations.list,
  razonesSociales: (state) => state.razonesSociales.list,
  timeOffPolicies: (state) => state.timeOffPolicies,
  vacationsPolicies: (state) => state.timeOffPolicies.filter((policy) => policy.policy_kind === "VAC"),
  personalDaysPolicies: (state) => state.timeOffPolicies.filter((policy) => policy.policy_kind === "PTO"),
  downloadTimeClockTemplate: (state) => state.downloadTimeClockTemplate
};
