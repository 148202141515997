import { Interval, DateTime } from "luxon";
function workingDays(start, end, holidays = [], employee) {
  const localStart = start.includes("sep") ? "en-US" : "es-Mx";
  const localEnd = end.includes("sep") ? "en-US" : "es-Mx";
  const startDate = DateTime.fromFormat(start.replace(".", ""), "dd MMM yyyy", { locale: localStart });
  const endDate = DateTime.fromFormat(end.replace(".", ""), "dd MMM yyyy", { locale: localEnd });
  if (endDate < startDate) {
    return 0;
  }
  const interval = Interval.fromDateTimes(startDate, endDate);
  let duration = interval.count("days");
  holidays.forEach((holiday) => {
    var _a;
    const date = DateTime.fromISO(holiday.date);
    if (interval.contains(date) || ((_a = interval.end) == null ? void 0 : _a.equals(date))) {
      const dayLong = date.setLocale("en-GB").weekdayLong.toLowerCase();
      Object.keys(employee.days_off).forEach((dayOff) => {
        if (dayOff === dayLong) {
          if (!employee.days_off[dayOff]) {
            duration -= 1;
          }
        }
      });
    }
  });
  if (employee.days_off) {
    let day = startDate;
    while (day <= endDate) {
      if (employee.days_off.monday && day.weekday === 1) {
        duration -= 1;
      }
      if (employee.days_off.tuesday && day.weekday === 2) {
        duration -= 1;
      }
      if (employee.days_off.wednesday && day.weekday === 3) {
        duration -= 1;
      }
      if (employee.days_off.thursday && day.weekday === 4) {
        duration -= 1;
      }
      if (employee.days_off.friday && day.weekday === 5) {
        duration -= 1;
      }
      if (employee.days_off.saturday && day.weekday === 6) {
        duration -= 1;
      }
      if (employee.days_off.sunday && day.weekday === 7) {
        duration -= 1;
      }
      day = day.plus({ days: 1 });
    }
  }
  return duration;
}
function countAllDays(start, end) {
  const startDate = DateTime.fromFormat(start.replace(".", ""), "dd MMM yyyy", { locale: "es-MX" });
  const endDate = DateTime.fromFormat(end.replace(".", ""), "dd MMM yyyy", { locale: "es-MX" });
  if (endDate < startDate) {
    return 0;
  }
  const interval = Interval.fromDateTimes(startDate, endDate);
  const duration = interval.count("days");
  return duration;
}
function countValidIncidenceDays(start, end, holidays, employee, incidenceType) {
  const startDate = DateTime.fromFormat(start.replace("sep", "sept").replace(".", ""), "dd MMM yyyy", { locale: "es-MX" });
  const endDate = DateTime.fromFormat(end.replace("sep", "sept").replace(".", ""), "dd MMM yyyy", { locale: "es-MX" });
  const cantBeInDaysOff = ["VA", "PTO"];
  if (endDate < startDate) {
    return 0;
  }
  const interval = Interval.fromDateTimes(startDate, endDate);
  let duration = interval.count("days");
  if (cantBeInDaysOff.includes(incidenceType)) {
    holidays.forEach((holiday) => {
      var _a;
      const date = DateTime.fromISO(holiday.date);
      if (interval.contains(date) || ((_a = interval.end) == null ? void 0 : _a.equals(date))) {
        const dayLong = date.setLocale("en-GB").weekdayLong.toLowerCase();
        Object.keys(employee.days_off).forEach((dayOff) => {
          if (dayOff === dayLong) {
            if (!employee.days_off[dayOff]) {
              duration -= 1;
            }
          }
        });
      }
    });
  }
  if (employee.days_off && cantBeInDaysOff.includes(incidenceType)) {
    let day = startDate;
    while (day <= endDate) {
      if (employee.days_off.monday && day.weekday === 1) {
        duration -= 1;
      }
      if (employee.days_off.tuesday && day.weekday === 2) {
        duration -= 1;
      }
      if (employee.days_off.wednesday && day.weekday === 3) {
        duration -= 1;
      }
      if (employee.days_off.thursday && day.weekday === 4) {
        duration -= 1;
      }
      if (employee.days_off.friday && day.weekday === 5) {
        duration -= 1;
      }
      if (employee.days_off.saturday && day.weekday === 6) {
        duration -= 1;
      }
      if (employee.days_off.sunday && day.weekday === 7) {
        duration -= 1;
      }
      day = day.plus({ days: 1 });
    }
  }
  return duration;
}
function countIncidenceInDaysOff(start, end, holidays, employee, incidenceType) {
  const startDate = DateTime.fromFormat(start.replace("sep", "sept").replace(".", ""), "dd MMM yyyy", { locale: "es-MX" });
  const endDate = DateTime.fromFormat(end.replace("sep", "sept").replace(".", ""), "dd MMM yyyy", { locale: "es-MX" });
  const interval = Interval.fromDateTimes(startDate, endDate);
  let duration = 0;
  holidays.forEach((holiday) => {
    var _a;
    const date = DateTime.fromISO(holiday.date);
    if (interval.contains(date) || ((_a = interval.end) == null ? void 0 : _a.equals(date))) {
      const dayLong = date.setLocale("en-GB").weekdayLong.toLowerCase();
      Object.keys(employee.days_off).forEach((dayOff) => {
        if (dayOff === dayLong) {
          if (!employee.days_off[dayOff]) {
            duration += 1;
          }
        }
      });
    }
  });
  if (employee.days_off) {
    let day = startDate;
    while (day <= endDate) {
      if (employee.days_off.monday && day.weekday === 1) {
        duration += 1;
      }
      if (employee.days_off.tuesday && day.weekday === 2) {
        duration += 1;
      }
      if (employee.days_off.wednesday && day.weekday === 3) {
        duration += 1;
      }
      if (employee.days_off.thursday && day.weekday === 4) {
        duration += 1;
      }
      if (employee.days_off.friday && day.weekday === 5) {
        duration += 1;
      }
      if (employee.days_off.saturday && day.weekday === 6) {
        duration += 1;
      }
      if (employee.days_off.sunday && day.weekday === 7) {
        duration += 1;
      }
      day = day.plus({ days: 1 });
    }
  }
  return duration;
}
function countAllDaysFromISO(start, end) {
  const startDate = DateTime.fromISO(start);
  const endDate = DateTime.fromISO(end);
  if (endDate < startDate) {
    return 0;
  }
  const interval = Interval.fromISO(`${startDate}/${endDate}`);
  const duration = interval.count("days");
  return duration;
}
function countValidIncidenceDaysFromISO(start, end, holidays, employee, incidenceType) {
  const startDate = DateTime.fromISO(start);
  const endDate = DateTime.fromISO(end);
  const cantBeInDaysOff = ["VA", "PTO"];
  if (endDate < startDate) {
    return 0;
  }
  const interval = Interval.fromISO(`${startDate}/${endDate}`);
  let duration = interval.count("days");
  if (cantBeInDaysOff.includes(incidenceType)) {
    holidays.forEach((holiday) => {
      var _a;
      const date = DateTime.fromISO(holiday.date);
      if (interval.contains(date) || ((_a = interval.end) == null ? void 0 : _a.equals(date))) {
        const dayLong = date.setLocale("en-GB").weekdayLong.toLowerCase();
        Object.keys(employee.days_off).forEach((dayOff) => {
          if (dayOff === dayLong) {
            if (!employee.days_off[dayOff]) {
              duration -= 1;
            }
          }
        });
      }
    });
  }
  if (employee.days_off && cantBeInDaysOff.includes(incidenceType)) {
    let day = startDate;
    while (day <= endDate) {
      if (employee.days_off.monday && day.weekday === 1) {
        duration -= 1;
      }
      if (employee.days_off.tuesday && day.weekday === 2) {
        duration -= 1;
      }
      if (employee.days_off.wednesday && day.weekday === 3) {
        duration -= 1;
      }
      if (employee.days_off.thursday && day.weekday === 4) {
        duration -= 1;
      }
      if (employee.days_off.friday && day.weekday === 5) {
        duration -= 1;
      }
      if (employee.days_off.saturday && day.weekday === 6) {
        duration -= 1;
      }
      if (employee.days_off.sunday && day.weekday === 7) {
        duration -= 1;
      }
      day = day.plus({ days: 1 });
    }
  }
  return duration;
}
function workingDaysFromISO(start, end, holidays = [], employee) {
  const startDate = DateTime.fromISO(start);
  const endDate = DateTime.fromISO(end);
  if (endDate < startDate) {
    return 0;
  }
  const interval = Interval.fromISO(`${startDate}/${endDate}`);
  let duration = interval.count("days");
  holidays.forEach((holiday) => {
    var _a;
    const date = DateTime.fromISO(holiday.date);
    if (interval.contains(date) || ((_a = interval.end) == null ? void 0 : _a.equals(date))) {
      const dayLong = date.setLocale("en-GB").weekdayLong.toLowerCase();
      Object.keys(employee.days_off).forEach((dayOff) => {
        if (dayOff === dayLong) {
          if (!employee.days_off[dayOff]) {
            duration -= 1;
          }
        }
      });
    }
  });
  if (employee.days_off) {
    let day = startDate;
    while (day <= endDate) {
      if (employee.days_off.monday && day.weekday === 1) {
        duration -= 1;
      }
      if (employee.days_off.tuesday && day.weekday === 2) {
        duration -= 1;
      }
      if (employee.days_off.wednesday && day.weekday === 3) {
        duration -= 1;
      }
      if (employee.days_off.thursday && day.weekday === 4) {
        duration -= 1;
      }
      if (employee.days_off.friday && day.weekday === 5) {
        duration -= 1;
      }
      if (employee.days_off.saturday && day.weekday === 6) {
        duration -= 1;
      }
      if (employee.days_off.sunday && day.weekday === 7) {
        duration -= 1;
      }
      day = day.plus({ days: 1 });
    }
  }
  return duration;
}
function countIncidenceInDaysOffFromISO(start, end, holidays, employee, incidenceType) {
  const startDate = DateTime.fromISO(start);
  const endDate = DateTime.fromISO(end);
  const interval = Interval.fromISO(`${startDate}/${endDate}`);
  let duration = 0;
  holidays.forEach((holiday) => {
    var _a;
    const date = DateTime.fromISO(holiday.date);
    if (interval.contains(date) || ((_a = interval.end) == null ? void 0 : _a.equals(date))) {
      const dayLong = date.setLocale("en-GB").weekdayLong.toLowerCase();
      Object.keys(employee.days_off).forEach((dayOff) => {
        if (dayOff === dayLong) {
          if (!employee.days_off[dayOff]) {
            duration += 1;
          }
        }
      });
    }
  });
  if (employee.days_off) {
    let day = startDate;
    while (day <= endDate) {
      if (employee.days_off.monday && day.weekday === 1) {
        duration += 1;
      }
      if (employee.days_off.tuesday && day.weekday === 2) {
        duration += 1;
      }
      if (employee.days_off.wednesday && day.weekday === 3) {
        duration += 1;
      }
      if (employee.days_off.thursday && day.weekday === 4) {
        duration += 1;
      }
      if (employee.days_off.friday && day.weekday === 5) {
        duration += 1;
      }
      if (employee.days_off.saturday && day.weekday === 6) {
        duration += 1;
      }
      if (employee.days_off.sunday && day.weekday === 7) {
        duration += 1;
      }
      day = day.plus({ days: 1 });
    }
  }
  return duration;
}
export {
  workingDays,
  countAllDays,
  countValidIncidenceDays,
  countIncidenceInDaysOff,
  workingDaysFromISO,
  countAllDaysFromISO,
  countValidIncidenceDaysFromISO,
  countIncidenceInDaysOffFromISO
};
