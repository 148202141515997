export default {
  path: "/reportes-rotacion",
  name: "turnover-reports",
  component: () => import(
    /* webpackChunkName: "common" */
    "@/views/TurnoverReportsLayout"
  ),
  meta: {
    requireAuth: true,
    publicName: "Reportes Rotaci\xF3n",
    module: "reports"
  }
};
