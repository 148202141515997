import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;
export const permissions = {
  // status, status_in, search, fields
  assignEmployees(id, employees, force) {
    if (force) {
      return axios.post(`/api/v1/permissions/groups/${id}/assign_employees/?force_assign=true`, employees);
    }
    return axios.post(`/api/v1/permissions/groups/${id}/assign_employees/`, employees);
  },
  getGroups(status) {
    return axios.get(`/api/v1/permissions/groups/?status=${status}`);
  },
  getFieldsForModule(module) {
    return axios.get(`/api/v1/permissions/items/?module=${module}&status=1`);
  },
  getGroup(groupId) {
    return axios.get(`/api/v1/permissions/groups/${groupId}/`);
  },
  getGroupEmployees(groupId) {
    return axios.get(`/api/v1/permissions/groups/${groupId}/employees/`);
  },
  activateGroup(groupId) {
    return axios.post(`/api/v1/permissions/groups/${groupId}/activate/`);
  },
  deactivateGroup(groupId) {
    return axios.post(`/api/v1/permissions/groups/${groupId}/deactivate/`);
  },
  postGroup(group) {
    return axios.post(`/api/v1/permissions/groups/`, group);
  },
  removeEmployee(groupId, payload) {
    return axios.post(`/api/v1/permissions/groups/${groupId}/revoke_employees/`, payload);
  },
  updateGroup(groupId, payload) {
    return axios.put(`/api/v1/permissions/groups/${groupId}/`, payload);
  },
  getAllUserPermissions() {
    return axios.get(`/api/v1/permissions/`);
  },
  getAllPermissions(group) {
    return axios.get(`/api/v1/permissions/?module=${group}`);
  },
  getPermissions(group) {
    return axios.get(`/api/v1/permissions/?module=${group}`);
  },
  assignPermisionsPrepayroll(payload) {
    return axios.post(`/api/v1/prepayroll/set_employee_permissions/`, payload);
  },
  removePermisionsPrepayroll(payload) {
    return axios.post(`/api/v1/prepayroll/remove_employee_permissions/`, payload);
  }
};
export default permissions;
