import Model from "@/models/model";
export default class TimeOffPeriod extends Model {
  static get modelName() {
    return "TimeOffPeriod";
  }
  static apiNamespace() {
    return "api/v2";
  }
  static resourceNamespace() {
    return "time-off-periods";
  }
  static fields() {
    return [
      "start_year",
      "end_year",
      "days_allowed",
      "policy"
    ];
  }
}
