var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { timeClock } from "@/api";
export default {
  state: {
    clipboardShift: null,
    modalShiftCell: null,
    editMaster: null,
    refreshData: false,
    schedulesShifts: [],
    emptyStateSchedule: true,
    schedulesCount: 0
  },
  mutations: {
    copyShift(state, stats) {
      state.clipboardShift = stats;
    },
    createShiftCell(state, stats) {
      state.modalShiftCell = stats;
    },
    setEditMaster(state, stats) {
      state.editMaster = stats;
    },
    toogleRefreshData(state, stats) {
      state.refreshData = stats;
    },
    setSchedulesShifts(state, stats) {
      state.schedulesShifts = stats;
    },
    setEmptyState(state, stats) {
      state.emptyStateSchedule = stats;
    },
    setSchedulesCount(state, stats) {
      state.schedulesCount = stats;
    }
  },
  getters: {
    schedulesShifts: (state) => state.schedulesShifts,
    schedulesEmptyState: (state) => state.emptyStateSchedule,
    schedulesCount: (state) => state.schedulesCount
  },
  actions: {
    getDataSchedules(_0) {
      return __async(this, arguments, function* ({ commit }) {
        const filters = {
          paginate: false
        };
        const data = yield timeClock.getSchedulesShifts(filters);
        commit("setSchedulesShifts", data);
      });
    },
    getStatusSchedules(_0) {
      return __async(this, arguments, function* ({ commit }) {
        const { shifts_count, schedules_exists } = yield timeClock.getSchedulesDetailStatus();
        commit("setEmptyState", schedules_exists);
        commit("setSchedulesCount", shifts_count);
      });
    }
  },
  namespaced: true
};
