const route = {
  path: "/colaborador/documento/firma",
  name: "publicDigitalSignEmployee",
  component: () => import(
    /* webpackChunkName: "documentsAndDigitalSign" */
    "@/views/v2/documents/publicSign/PublicDigitalSignLayout"
  ),
  meta: {
    requireAuth: false
  },
  children: [
    {
      path: "",
      name: "publicDigitalSignEmployeeIndex",
      component: () => import(
        /* webpackChunkName: "documentsAndDigitalSign" */
        "@/views/v2/documents/publicSign/PublicDigitalSignIndex"
      ),
      meta: {
        requireAuth: false
      }
    },
    {
      path: "digital",
      name: "publicDigitalSignEmployeeDo",
      component: () => import(
        /* webpackChunkName: "documentsAndDigitalSign" */
        "@/views/v2/documents/publicSign/PublicDigitalSignEmployee"
      ),
      meta: {
        requireAuth: false
      }
    }
  ]
};
export default route;
