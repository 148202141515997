export default {
  showSurvey(state) {
    return state.user.survey_responded;
  },
  getEmployee(state) {
    return state.user.employee;
  },
  getCompany(state) {
    return state.user.company;
  },
  getModules(state) {
    return state.user.modules;
  },
  getUser(state) {
    return state.user;
  },
  getLocation({ user }) {
    if (!user || !user.employee) {
      return null;
    }
    return user.employee.location;
  }
};
