var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { api } from "@/api";
import { DateTime, Info } from "luxon";
import { convertTimeFormatToMinutesEquivalent } from "@/utils/time";
const calculateNextCheck = (startTime, startWeekday, location) => {
  let now = DateTime.now().setLocale("en");
  if (location && location.timezone) {
    const { code_timezone } = location.timezone;
    now = now.setZone(code_timezone);
  } else {
    now = now.setZone("America/Mexico_City");
  }
  const weekdays = Info.weekdays("long", { locale: "en" }).map((day) => day.toLowerCase());
  const startWeekdayNumber = weekdays.indexOf(startWeekday.toLowerCase());
  const nowDayNumber = weekdays.indexOf(now.weekdayLong.toLowerCase());
  let totalOfMinutes = 0;
  if (startWeekdayNumber === nowDayNumber) {
    totalOfMinutes = convertTimeFormatToMinutesEquivalent(startTime);
  } else if (startWeekdayNumber > nowDayNumber) {
    totalOfMinutes = convertTimeFormatToMinutesEquivalent(startTime) + (startWeekdayNumber - nowDayNumber) * 24 * 60;
  } else {
    totalOfMinutes = convertTimeFormatToMinutesEquivalent(startTime) + (startWeekdayNumber - nowDayNumber + 7) * 24 * 60;
  }
  let timeNow = now.startOf("day").plus({ minutes: totalOfMinutes });
  if (location && location.timezone) {
    const { code_timezone } = location.timezone;
    timeNow = timeNow.setZone(code_timezone);
  } else {
    timeNow = timeNow.setZone("America/Mexico_City");
  }
  return timeNow;
};
const state = {
  configuration: null,
  hasConfiguration: false,
  now: null,
  nextCheck: null,
  timerId: null
};
const actions = {
  loadConfiguration(_0) {
    return __async(this, arguments, function* ({ commit, dispatch, state: state2 }) {
      if (state2.timerId) {
        clearInterval(state2.timerId);
        commit("setTimerId", null);
      }
      return new Promise((resolve) => __async(this, null, function* () {
        let hasCheckIn = false;
        try {
          const registerKind = "web";
          const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
          const data = yield api.timeClock.myStatus({
            timeZone,
            registerKind
          });
          commit("setConfiguration", data);
          dispatch("calculateNow", data);
          dispatch("setTaskToCheck", data);
          hasCheckIn = true;
        } catch (_) {
        } finally {
          commit("setHasConfiguration", hasCheckIn);
          resolve(hasCheckIn);
        }
      }));
    });
  },
  calculateNow({ commit, dispatch, state: state2 }, configuration) {
    if (configuration.location && configuration.location.timezone) {
      commit("setNow", DateTime.now().setZone(configuration.location.timezone.code_timezone));
    } else {
      commit("setNow", DateTime.now());
    }
    if (!state2.timerId) {
      const timerId = setInterval(() => {
        dispatch("calculateNow", configuration);
      }, 1e3);
      commit("setTimerId", timerId);
    }
    if (state2.nextCheck && (configuration.current_shift && !configuration.current_shift.id)) {
      if (state2.nextCheck <= state2.now) {
        dispatch("loadConfiguration");
      }
    }
  },
  setTaskToCheck({ commit }, configuration) {
    const {
      record_status: recordStatus,
      current_shift: currentShift,
      next_shift: nextShift,
      record_status_lunch: recordStatusLunch,
      location
    } = configuration;
    if (currentShift && currentShift.id) {
      const {
        start_time: startTime,
        end_time: endTime,
        start_weekday: startWeekday,
        end_weekday: endWeekday,
        lunch,
        start_time_lunch: startTimeLunch,
        end_time_lunch: endTimeLunch
      } = currentShift;
      let nextCheck = null;
      if (recordStatus === "no_record") {
        nextCheck = calculateNextCheck(startTime, startWeekday, location);
      } else if (recordStatus === "checkin" && recordStatusLunch === "no_lunch" && lunch) {
        nextCheck = calculateNextCheck(startTimeLunch, startWeekday, location);
      } else if (recordStatus === "checkin" && recordStatusLunch !== "no_lunch" && lunch) {
        nextCheck = calculateNextCheck(endTimeLunch, startWeekday, location);
      } else {
        nextCheck = calculateNextCheck(endTime, endWeekday, location);
      }
      commit("setNextCheck", nextCheck);
    } else if (nextShift && nextShift.id) {
      const { start_time: startTime, start_weekday: startWeekday } = nextShift;
      commit("setNextCheck", calculateNextCheck(startTime, startWeekday, location));
    }
  },
  hideConfiguration({ commit }) {
    commit("hideConfiguration");
  }
};
const mutations = {
  setConfiguration(state2, configuration) {
    state2.configuration = configuration;
  },
  setHasConfiguration(state2, hasConfiguration) {
    state2.hasConfiguration = hasConfiguration;
  },
  setNow(state2, now) {
    state2.now = now;
  },
  setNextCheck(state2, nextCheck) {
    state2.nextCheck = nextCheck;
  },
  setTimerId(state2, timerId) {
    state2.timerId = timerId;
  },
  hideConfiguration(state2) {
    state2.hasConfiguration = false;
  }
};
const getters = {
  nowIsAfterNextCheck(state2) {
    if (state2.now && state2.nextCheck) {
      return state2.now > state2.nextCheck;
    }
    return false;
  },
  isFiveMinutesNearNextCheck(state2) {
    if (state2.now && state2.nextCheck && state2.nextCheck.diff) {
      return state2.nextCheck.diff(state2.now, "minutes").minutes <= 5;
    }
    return false;
  },
  nowAsString: (state2) => {
    var _a;
    return (_a = state2.now) == null ? void 0 : _a.toString();
  },
  nextCheckAsString: (state2) => {
    var _a;
    return (_a = state2.nextCheck) == null ? void 0 : _a.toString();
  }
};
export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
};
