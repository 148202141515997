var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Company from "@/models/v1/company";
import Schedule from "@/models/v1/schedule";
export default {
  getSchedules(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const company = new Company(rootState.company.data);
      const schedules = yield company.workingHours().get();
      commit("setSchedules", { schedules });
      return schedules;
    });
  },
  createSchedule(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { data }) {
      const schedule = new Schedule(data);
      const newSchedule = yield schedule.create();
      commit("setSchedule", { schedule: newSchedule });
      return newSchedule;
    });
  },
  updateSchedule(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, { data }) {
      const oldSchedule = state.list.find((_schedule) => _schedule.id === data.id);
      const scheduleObject = new Schedule(oldSchedule);
      const updatedSchedule = yield scheduleObject.setFields(data).update();
      commit("setSchedule", { schedule: updatedSchedule });
      return updatedSchedule;
    });
  },
  deleteSchedule(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, { id }) {
      const oldSchedule = state.list.find((_schedule) => _schedule.id === id);
      const scheduleObject = new Schedule(oldSchedule);
      yield scheduleObject.delete();
      const schedules = state.list.filter((_schedule) => _schedule.id !== id);
      commit("setSchedules", { schedules });
      return schedules;
    });
  }
};
