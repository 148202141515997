import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;
export const delegatePermissions = {
  getGroups() {
    return axios.get(`/api/v1/permissions/role_groups/`);
  },
  getGroupDetail(id) {
    return axios.get(`/api/v1/permissions/role_groups/${id}`);
  },
  getPermissions() {
    return axios.get(`/api/v1/permissions/access_permissions/`);
  },
  getHistory(idGroup, idEmployee) {
    return axios.get(`/api/v1/permissions/role_groups/${idGroup}/logs/?employee_id=${idEmployee}`);
  },
  getHistoryByEmployee(idEmployee) {
    return axios.get(`/api/v1/permissions/role_groups/employee_logs/?employee_id=${idEmployee}`);
  },
  getHistoryByGroup(idGroup) {
    return axios.get(`/api/v1/permissions/role_groups/${idGroup}/logs/`);
  },
  createGroup(payload) {
    return axios.post(`/api/v1/permissions/role_groups/`, payload);
  },
  assingManagers(payload, id) {
    return axios.post(`/api/v1/permissions/role_groups/${id}/assign_admin_users/`, payload);
  },
  assingManaged(payload, id) {
    return axios.post(`/api/v1/permissions/role_groups/${id}/assign_managed_users/`, payload);
  },
  assingPermissions(payload, id) {
    return axios.post(`/api/v1/permissions/role_groups/${id}/assign_permissions/`, payload);
  },
  toogleGroup(id, status) {
    return axios.post(`/api/v1/permissions/role_groups/${id}/${status}/`);
  },
  removeManager(payload, id) {
    return axios.post(`/api/v1/permissions/role_groups/${id}/remove_admin_users/`, payload);
  },
  removeManaged(payload, id) {
    return axios.post(`/api/v1/permissions/role_groups/${id}/remove_managed_users/`, payload);
  },
  removeGroup(id) {
    return axios.delete(`/api/v1/permissions/role_groups/${id}/`);
  },
  updateGroupName(id, name) {
    return axios.patch(`/api/v1/permissions/role_groups/${id}/`, { name, role: name });
  },
  createGroupWithConfiguration(groupConfiguration) {
    return axios.post("/api/v1/permissions/role_groups/flow/", groupConfiguration).then(({ data }) => data);
  },
  getEmployeePermissions(employeeID) {
    return axios.get(`/api/v1/permissions/role_groups/employee/${employeeID}/`).then(({ data }) => data);
  }
};
export default delegatePermissions;
