var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import api from "@/plugins/api";
import Employee from "@/models/employee";
import Request from "@/models/request";
export default {
  // @deprecated
  getUserData(_0) {
    return __async(this, arguments, function* ({ commit }) {
      try {
        const { data } = yield api.me.get();
        commit("setUserData", data);
        return data;
      } catch (error) {
        throw error;
      }
    });
  },
  getUserEmployee(_0) {
    return __async(this, arguments, function* ({ commit, state, rootState }) {
      const employee = yield Employee.retrive(rootState.me.user.employee.id);
      const isRecruitmentReviewer = rootState.me.user.is_recruitment_visitor;
      Object.assign(
        employee,
        { isRecruitmentReviewer }
      );
      commit("setUserEmployee", { data: employee });
      return new Promise((resolve) => resolve(employee));
    });
  },
  getDataToVerify({ commit }, { key }) {
    return api.verifyAccount(key).getData();
  },
  setPasswordToVerify({ commit }, { key, password, activateAccount }) {
    return api.verifyAccount(key).setPassword({ password, activateAccount });
  },
  setPath({ commit }, path) {
    commit("setPath", path);
  },
  clearPath({ commit }) {
    commit("clearPath");
  },
  request(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, { request }) {
      const employee = new Employee(state.employee);
      const newRequest = new Request(request);
      const response = yield employee.requests().create(newRequest);
      commit("company/timeOff/setRequest", { request: response }, { root: true });
      return response;
    });
  }
};
