var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "file-viewer" }, [!_vm.fileBlobUrl ? _c("AppLoader", { attrs: { "inner-loader": true } }) : _vm._e(), _c("div", { staticClass: "file-viewer__overlay" }), _c("div", { staticClass: "file-viewer__toolbar" }, [_c("div", { staticClass: "flex items-center" }, [_c("div", { staticClass: "w-10/12" }, [_c("span", { staticClass: "font-medium text-white text-fs-20" }, [_vm._v(" " + _vm._s(_vm.file.name) + " ")])]), _c("div", { staticClass: "w-2/12 text-white text-right text-fs-20" }, [_c("Icon", { directives: [{ name: "tooltip", rawName: "v-tooltip", value: "Descargar", expression: "'Descargar'" }], staticClass: "mr-8 cursor-pointer", attrs: { "icon": "download" }, nativeOn: { "click": function($event) {
    return _vm.downloadFile.apply(null, arguments);
  } } }), _c("Icon", { directives: [{ name: "tooltip", rawName: "v-tooltip", value: "Cerrar", expression: "'Cerrar'" }], staticClass: "cursor-pointer", attrs: { "icon": "close" }, nativeOn: { "click": function($event) {
    return (() => _vm.$emit("close")).apply(null, arguments);
  } } })], 1)])]), _c("div", { staticClass: "file-viewer__content" }, [_vm.isImageExtension ? _c("div", { staticClass: "file-viewer__content--img" }, [_c("picture", [_vm.fileBlobUrl ? _c("img", { attrs: { "src": _vm.fileBlobUrl } }) : _vm._e()])]) : _vm._e(), _vm.fileBlobUrl && !_vm.isImageExtension ? _c("div", { staticClass: "file-viewer__content--pdf" }, [_c("VuePdfEmbed", { staticClass: "custom-embed", attrs: { "width": 700, "source": _vm.fileBlobUrl } })], 1) : _vm._e()])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
