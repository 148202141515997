export const getInitialsCapitalized = (text, toDiscard = []) => {
  var _a, _b, _c, _d, _e, _f;
  if (!text) {
    return "";
  }
  return (_f = (_e = (_d = (_c = (_b = (_a = text == null ? void 0 : text.split(" ")) == null ? void 0 : _a.filter((word) => word && !toDiscard.includes(word))) == null ? void 0 : _b.slice(0, 2)) == null ? void 0 : _c.map((x) => x == null ? void 0 : x.at(0))) == null ? void 0 : _d.join("")) == null ? void 0 : _e.toUpperCase()) != null ? _f : "";
};
export const normalizeString = (str) => {
  if (!str) {
    return "";
  }
  return String(str).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();
};
export default {
  getInitialsCapitalized,
  normalizeString
};
