const routes = {
  path: "/reportes",
  name: "reports",
  component: {
    render: (h) => h("router-view")
  },
  redirect: { name: "reports-directory" },
  children: [
    {
      path: "expedientes",
      name: "reports-directory",
      component: () => import(
        /* webpackChunkName: "incidences" */
        "@/views/ReportsLayout"
      ),
      meta: {
        requireAuth: true,
        module: "reports",
        publicName: "Reportes",
        permission: true
      }
    },
    {
      path: "incidencias",
      name: "reports-incidents",
      component: () => import(
        /* webpackChunkName: "incidences" */
        "@/views/ReportsLayout"
      ),
      meta: {
        requireAuth: true,
        module: "reports",
        publicName: "Reportes",
        permission: true
      }
    },
    {
      path: "asistencia",
      name: "reports-attendance",
      component: () => import(
        /* webpackChunkName: "incidences" */
        "@/views/ReportsLayout"
      ),
      meta: {
        requireAuth: true,
        module: "reports",
        publicName: "Reportes",
        permission: true
      }
    },
    {
      path: "nomina",
      name: "reports-payroll",
      component: () => import(
        /* webpackChunkName: "incidences" */
        "@/views/ReportsLayout"
      ),
      meta: {
        requireAuth: true,
        module: "reports",
        publicName: "Reportes"
      }
    },
    {
      path: "climalaboral",
      name: "reports-working-environment",
      component: () => import(
        /* webpackChunkName: "incidences" */
        "@/views/ReportsLayout"
      ),
      meta: {
        requireAuth: true,
        module: "reports",
        publicName: "Reportes",
        permission: true
      }
    }
  ]
};
export default routes;
