var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", [_c("WTable", _vm._g(_vm._b({ attrs: { "columns": _vm.internalColumns, "items": _vm.items, "loading": _vm.internalLoading, "filters": _vm.internalFilters, "is-async": true, "total": _vm.totalOfItems, "page-size": _vm.internalPageSize, "current-page": _vm.internalPage, "sort-by": _vm.internalSortBy, "clickable": _vm.clickable, "paginated": _vm.paginated }, on: { "update:filters": function($event) {
    _vm.internalFilters = $event;
  }, "update:pageSize": function($event) {
    _vm.internalPageSize = $event;
  }, "update:page-size": function($event) {
    _vm.internalPageSize = $event;
  }, "update:currentPage": function($event) {
    _vm.internalPage = $event;
  }, "update:current-page": function($event) {
    _vm.internalPage = $event;
  }, "update:sortBy": function($event) {
    _vm.internalSortBy = $event;
  }, "update:sort-by": function($event) {
    _vm.internalSortBy = $event;
  } }, scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function(_, slot) {
    return { key: slot, fn: function(scope) {
      return [_vm._t(slot, null, null, scope)];
    } };
  })], null, true) }, "WTable", _vm.$attrs, false), _vm.$listeners))], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
