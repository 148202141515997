var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { benefits } from "@/api";
const state = {
  myBenefits: [],
  loading: false
};
const actions = {
  getMyBenefits(_0) {
    return __async(this, arguments, function* ({ commit }) {
      try {
        const list = yield benefits.myBenefits();
        commit("SET_LIST", list);
      } catch (_) {
        commit("SET_LIST", []);
      }
    });
  }
};
const mutations = {
  SET_LIST(state2, list) {
    state2.myBenefits = list;
  }
};
export default {
  state,
  actions,
  mutations,
  namespaced: true
};
