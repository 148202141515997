var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "dropdown", class: {
    "dropdown--open-on-click": _vm.openOnClick,
    "dropdown--box--opened": _vm.openOnClick && _vm.isOpen,
    "dropdown--is-in-the-box": _vm.isInTheBox
  } }, [_c("div", { staticClass: "dropdown__trigger", on: { "click": _vm.toggleOpened } }, [_vm._t("trigger", function() {
    return [_c("Button", [_vm._v(" " + _vm._s(_vm.text) + " "), _c("Icon", { staticClass: "ml-4", attrs: { "icon": "chevron-down" } })], 1)];
  }, { "isOpen": _vm.isOpen })], 2), _c("div", { staticClass: "dropdown__box", class: {
    [`dropdown__box--${_vm.position}`]: true
  }, style: __spreadValues({}, _vm.isInTheBox ? {
    top: `${_vm.positionTop}px !important`,
    left: `${_vm.positionLeft}px !important`
  } : {}) }, [_c("div", { class: { "hidden": _vm.showSubMenu } }, _vm._l(_vm.filteredItems, function(item, index) {
    return _c("DropdownItem", { key: index, attrs: { "text": item.name, "is-html": item.isHtml, "is-separator": item.isSeparator, "non-selectable": item.nonSelectable || Array.isArray(item) }, on: { "click": (e) => _vm.onClick(item, e) } }, [item.children ? _c("Dropdown", { attrs: { "items": item.children, "open-on-click": true }, scopedSlots: _vm._u([{ key: "trigger", fn: function() {
      return [_c("div", [item.children ? _c("Icon", { attrs: { "icon": "chevron-left" } }) : _vm._e(), _vm._v(" " + _vm._s(item.name) + " ")], 1)];
    }, proxy: true }], null, true) }) : _vm._e(), item.length > 0 ? _c("div", { staticClass: "dropdown-container" }, _vm._l(item, function(groupItem, groupIndex) {
      return _c("DropdownItem", { key: `dropdown-organization-${groupIndex}`, attrs: { "text": groupItem.name, "is-html": groupItem.isHtml, "is-separator": groupItem.isSeparator, "non-selectable": groupItem.nonSelectable || Array.isArray(groupItem) }, on: { "click": (e) => _vm.onClick(groupItem, e) } }, [groupItem.children ? _c("Dropdown", { attrs: { "organizations": groupItem.children, "open-on-click": true }, scopedSlots: _vm._u([{ key: "trigger", fn: function() {
        return [_c("div", [groupItem.children ? _c("Icon", { attrs: { "icon": "chevron-left" } }) : _vm._e(), _vm._v(" " + _vm._s(groupItem.name) + " ")], 1)];
      }, proxy: true }], null, true) }) : _vm._e()], 1);
    }), 1) : [_vm._v(" " + _vm._s(item.name) + " ")]], 2);
  }), 1), _vm.filteredItemsSub ? _c("div", { staticClass: "hidden", class: { "!block": _vm.showSubMenu } }, _vm._l(_vm.filteredItemsSub, function(item, index) {
    return _c("DropdownItem", { key: index, attrs: { "text": item.name, "is-html": item.isHtml, "is-separator": item.isSeparator, "non-selectable": item.nonSelectable }, on: { "click": (e) => _vm.onClick(item, e) } }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
