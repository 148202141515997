import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
const state = {
  list: [],
  listWithPolicies: [],
  former: [],
  profile: null,
  gettingState: "OK"
};
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
