export default {
  setAreas(state, { areas }) {
    areas.sort((a, b) => a.name.localeCompare(b.name));
    state.list = areas;
  },
  setArea(state, { area }) {
    const { id } = area;
    const newList = [...state.list];
    const index = newList.findIndex((_area) => _area.id === id);
    if (index >= 0) {
      newList[index] = area;
    } else {
      newList.push(area);
    }
    state.list = newList;
  }
};
