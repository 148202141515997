import Model from "@/models/model";
export default class RazonSocial extends Model {
  static get modelName() {
    return "RazonSocial";
  }
  static apiNamespace() {
    return "api/v2";
  }
  static resourceNamespace() {
    return "razones_sociales";
  }
  static default() {
    return "";
  }
  static fields() {
    return [
      "id",
      "rfc",
      "kind",
      "legal_name"
    ];
  }
  get() {
    return this.constructor.get(`${this.resourcePath()}`);
  }
  create(razonSocial) {
    return this.constructor.post(`${this.resourcePath()}`, razonSocial);
  }
  update(razonSocial) {
    return this.constructor.put(`${this.resourcePath()}/`, razonSocial);
  }
  delete(idRazonSocial) {
    return this.constructor.delete(`${this.resourcePath()}${idRazonSocial}`);
  }
}
