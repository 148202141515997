var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { api } from "@/api";
export default {
  assignJobProfile(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, { employees, id }) {
      try {
        const { data } = yield api.company(id).jobProfile.assign(id, { employees });
        commit("setAssignedJobProfile", data);
        return data;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "No se pudieron asignar las descripciones de puesto"
        });
        return null;
      }
    });
  },
  getJobProfiles(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, { filters, id }) {
      try {
        const { data } = yield api.company(id).jobProfile.getList(filters);
        return data;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "No se pudieron cargar las descripciones de puesto"
        });
        return null;
      }
    });
  },
  getJobProfile(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, id) {
      try {
        const { data } = yield api.company(id).jobProfile.get(id);
        return data;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "No se pudieron cargar las descripciones de puesto"
        });
        return null;
      }
    });
  },
  getEmployeeJobProfile(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, id) {
      try {
        const { data } = yield api.company(id).jobProfile.employeeJobDetail(id);
        return data;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "No se pudo cargar la descripci\xF3n de puesto"
        });
        return null;
      }
    });
  },
  createJobProfile({ commit, rootState }, { data }) {
    return api.company(rootState.me.user.company.id).jobProfile.create(data).then((response) => {
      commit("setJobProfile", { data: response.data });
      return response.data;
    });
  },
  removeAssignedProfile(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, { employees, id }) {
      try {
        const { data } = yield api.company(id).jobProfile.unAssign(id, { employees });
        return data;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "No se pudieron asignar las descripciones de puesto"
        });
        return null;
      }
    });
  },
  updateJobProfile(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, jobProfile) {
      try {
        const { data } = yield api.company(jobProfile.id).jobProfile.update(jobProfile);
        return data;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "No se pudeo actualizar la descripci\xF3n de puesto"
        });
        return null;
      }
    });
  },
  editEmployeeJobProfile(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, jobProfile) {
      try {
        const { data } = yield api.company(rootState.me.user.company.id).jobProfile.editEmployeeDetail(jobProfile);
        return data;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "No se pudo cargar la descripci\xF3n de puesto"
        });
        return null;
      }
    });
  }
};
