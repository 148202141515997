const routes = [
  {
    path: "/preonboarding/:id",
    name: "preonboarding-candidate",
    component: () => import(
      /* webpackChunkName: "preonboarding-candidate" */
      "@/views/preonboarding/PreonboardingInvited"
    ),
    meta: {
      publicName: "Invitado",
      module: "Preonboarding"
    },
    props: true
  },
  {
    path: "/gracias",
    name: "preonboarding-thanks",
    component: () => import(
      /* webpackChunkName: "preonboarding-candidate" */
      "@/views/preonboarding/ThankYouPreonboarding"
    ),
    props: true
  }
];
export default routes;
