export function buildURLParams(params) {
  const urlParams = new URLSearchParams();
  if (params) {
    if (typeof params !== "object" || Array.isArray(params)) {
      throw new Error("Params should be an object");
    }
    Object.entries(params).forEach(([key, value]) => {
      urlParams.append(key, value);
    });
  }
  return urlParams;
}
export default buildURLParams;
