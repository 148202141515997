import "@/styles/css/skeleton.css";
const skeleton = {
  bind(el, bindings, vnode) {
    const { value } = bindings;
    if (value) {
      el.classList.add("w-skeleton");
    }
  },
  update(el, bindings, vnode) {
    const { value } = bindings;
    if (value) {
      el.classList.add("w-skeleton");
    } else {
      el.classList.remove("w-skeleton");
    }
  }
};
const install = (Vue, options) => {
  Vue.directive("skeleton", skeleton);
};
export default install;
