var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { api } from "@/api";
const state = {
  dashboard: {
    time_off_policies: {},
    recruitments: [],
    birthdates: [],
    anniversaries: [],
    holidays: [],
    directory: {},
    vacations: {},
    calendar: [],
    incidences: [],
    web_check_in: {},
    subscription: {},
    web_check_inV3: {}
  }
};
const mutations = {
  setTimeOffPolicies(state2, payload) {
    state2.dashboard.time_off_policies = payload;
  },
  setIncidences(state2, payload) {
    state2.dashboard.incidences = payload;
  },
  setRecruitments(state2, recruitments) {
    state2.dashboard.recruitments = recruitments;
  },
  setBirthdates(state2, birthdates) {
    state2.dashboard.birthdates = birthdates;
  },
  setAnniversaries(state2, anniversaries) {
    state2.dashboard.anniversaries = anniversaries;
  },
  setHolidays(state2, holidays) {
    state2.dashboard.holidays = holidays;
  },
  setDirectory(state2, directory) {
    state2.dashboard.directory = directory;
  },
  setVacations(state2, vacations) {
    state2.dashboard.vacations = vacations;
  },
  setCalendar(state2, calendar) {
    state2.dashboard.calendar = calendar;
  },
  setWebCheckIn(state2, payload) {
    state2.dashboard.web_check_in = payload;
  },
  setSubscriptionInfo(state2, payload) {
    state2.dashboard.subscription = payload;
  },
  setWebCheckInV3(state2, payload) {
    state2.dashboard.web_check_inV3 = payload;
  }
};
const actions = {
  loadTimeOffPolicies(_0) {
    return __async(this, arguments, function* ({ commit }) {
      try {
        const { data } = yield api.dashboard.getTimeOffPolicies();
        commit("setTimeOffPolicies", data);
      } catch (_) {
        this._vm.$notify({
          type: "error",
          text: `\xA1Oh, oh!, algo sali\xF3 mal al obtener las politicas de vacaciones.`
        });
      }
    });
  },
  loadRecruitments(_0) {
    return __async(this, arguments, function* ({ commit }) {
      try {
        const { data } = yield api.dashboard.getRecruitments();
        commit("setRecruitments", data);
      } catch (_) {
        this._vm.$notify({
          type: "error",
          text: `\xA1Oh, oh!, algo sali\xF3 mal al cargar la informaci\xF3n de reclutamiento.`
        });
      }
    });
  },
  loadBirthdates(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      try {
        commit("setBirthdates", null);
        const { data } = yield api.dashboard.getBirthdates(rootState.me.user.company.id);
        commit("setBirthdates", data);
      } catch (_) {
        this._vm.$notify({
          type: "error",
          text: `\xA1Oh, oh!, algo sali\xF3 mal al cargar la informaci\xF3n de cumplea\xF1os de la empresa.`
        });
      }
    });
  },
  loadAnniversaries(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      try {
        commit("setAnniversaries", null);
        const { data } = yield api.dashboard.getAnniversaries(rootState.me.user.company.id);
        commit("setAnniversaries", data);
      } catch (_) {
        this._vm.$notify({
          type: "error",
          text: `\xA1Oh, oh!, algo sali\xF3 mal al cargar los aniversarios de la empresa.`
        });
      }
    });
  },
  loadIncidences(_0) {
    return __async(this, arguments, function* ({ commit }) {
      try {
        const { data } = yield api.dashboard.getIncidences();
        commit("setIncidences", data);
      } catch (_) {
        this._vm.$notify({
          type: "error",
          text: `\xA1Oh, oh!, algo sali\xF3 mal al cargar las incidencias de la empresa.`
        });
      }
    });
  },
  loadHolidays(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      try {
        const { data } = yield api.dashboard.getHolidays(rootState.me.user.company.id);
        commit("setHolidays", data);
      } catch (_) {
        this._vm.$notify({
          type: "error",
          text: `\xA1Oh, oh!, algo sali\xF3 mal al cargar los d\xEDas feriados.`
        });
      }
    });
  },
  loadDirectory(_0) {
    return __async(this, arguments, function* ({ commit }) {
      try {
        commit("setDirectory", []);
        const { data } = yield api.dashboard.getDirectory();
        commit("setDirectory", data);
      } catch (_) {
        this._vm.$notify({
          type: "error",
          text: `\xA1Oh, oh!, algo sali\xF3 mal al cargar directorio.`
        });
      }
    });
  },
  loadVacations(_0) {
    return __async(this, arguments, function* ({ commit }) {
      try {
        const { data } = yield api.dashboard.getVacations();
        commit("setVacations", data);
      } catch (_) {
        this._vm.$notify({
          type: "error",
          text: `\xA1Oh, oh!, algo sali\xF3 mal al cargar vacaciones.`
        });
      }
    });
  },
  loadCalendar(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      try {
        const { data } = yield api.dashboard.getCalendar(rootState.me.user.company.id);
        commit("setCalendar", data);
      } catch (_) {
        this._vm.$notify({
          type: "error",
          text: `\xA1Oh, oh!, algo sali\xF3 mal al cargar calendario.`
        });
      }
    });
  },
  loadWebCheckIn(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      return new Promise((resolve) => __async(this, null, function* () {
        try {
          const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
          const { data } = yield api.timeClock.info(rootState.me.user.employee.id, {
            timeZone
          });
          commit("setWebCheckIn", data);
        } finally {
          resolve();
        }
      }));
    });
  },
  loadWebCheckInV3(_0) {
    return __async(this, arguments, function* ({ commit }) {
      return new Promise((resolve) => __async(this, null, function* () {
        let hasCheckIn = false;
        try {
          const registerKind = "web";
          const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
          const data = yield api.timeClock.myStatus({
            timeZone,
            registerKind
          });
          commit("setWebCheckInV3", data);
          hasCheckIn = true;
        } finally {
          resolve(hasCheckIn);
        }
      }));
    });
  },
  loadSubscription(_0) {
    return __async(this, arguments, function* ({ commit }) {
      try {
        const { data } = yield api.plans.getSubscriptionInfo();
        commit("setSubscriptionInfo", data);
      } catch (_) {
        this._vm.$notify({
          type: "error",
          text: `\xA1Oh, oh!, algo sali\xF3 mal.`
        });
      }
    });
  }
};
const getters = {
  dashboardData: (state2) => state2.dashboard,
  monthBirthdays: (state2) => state2.dashboard.birthdates,
  monthAnniversaries: (state2) => state2.dashboard.anniversaries,
  webCheckinInfo: (state2) => state2.dashboard.web_check_in,
  subscriptionInfo: (state2) => state2.dashboard.subscription,
  webCheckinInfoV3: (state2) => state2.dashboard.web_check_inV3
};
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
};
