import Model from "@/models/model";
export default class Location extends Model {
  static get modelName() {
    return "Location";
  }
  static apiNamespace() {
    return "api/v2";
  }
  static resourceNamespace() {
    return "locations";
  }
  static default() {
    return "";
  }
  static fields() {
    return [
      "id",
      "name",
      "phone",
      "address",
      "company",
      "description",
      "timezone",
      "geolocation_active",
      "distance_threshold",
      "latitude",
      "longitude",
      "assigned_employees",
      "assigned_employees_geolocation",
      "detail_address"
    ];
  }
}
