import Model from "@/models/model";
export default class CustomIncidenceTemplate extends Model {
  static get modelName() {
    return "CustomIncidenceTemplate";
  }
  static apiNamespace() {
    return "api/v2";
  }
  static resourceNamespace() {
    return "custom_incidences_template";
  }
  static fields() {
    return [
      "id",
      "active",
      "company",
      "name",
      "date_range",
      "temporality",
      "quantity",
      "amount",
      "requestable",
      "identifier",
      "duration",
      "apply_to",
      "areas",
      "locations",
      "use_vacation_balance"
    ];
  }
  static default() {
    return "";
  }
  get() {
    return this.constructor.get(`${this.resourcePath()}`);
  }
  retrieveCustomIncidence(idCustomIncidence) {
    return this.constructor.get(`${this.resourcePath()}${idCustomIncidence}`);
  }
  delete(idCustomIncidence) {
    return this.constructor.delete(`${this.resourcePath()}${idCustomIncidence}`);
  }
}
