var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Benefit from "@/models/benefit";
import Company from "@/models/company";
import { api } from "@/api";
export default {
  getBenefits(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const company = new Company(rootState.me.user.company);
      const { data } = yield api.dashboard.getCompanyBenefits(company.id);
      const filterData = data.filter((ben) => !ben.name.includes("undefined"));
      commit("setBenefits", filterData);
    });
  },
  createBenefit(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, { data }) {
      const company = new Company(rootState.me.user.company);
      const benefit = yield company.benefits().create(data);
      commit("setBenefit", { benefit });
      return benefit;
    });
  },
  updateBenefit(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, { data }) {
      const oldBenefit = state.list.find((_benefit) => _benefit.id === data.id);
      const benefit = new Benefit(oldBenefit);
      const updatedBenefit = yield benefit.setFields(data).update();
      commit("setBenefit", { benefit: updatedBenefit });
      return benefit;
    });
  },
  deleteBenefit(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, { id }) {
      const oldBenefit = state.list.find((_benefit) => _benefit.id === id);
      const benefit = new Benefit(oldBenefit);
      yield benefit.delete();
      commit("unsetBenefit", benefit);
    });
  }
};
