import { DateTime } from "luxon";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
const state = {
  myIncidences: [],
  calendarIncidences: [],
  startRange: DateTime.local(),
  endRange: DateTime.local()
};
export default {
  getters,
  state,
  mutations,
  actions,
  namespaced: true
};
