const routes = {
  path: "/onboarding",
  component: {
    render: (h) => h("router-view")
  },
  children: [
    {
      path: "empleado",
      component: {
        render: (h) => h("router-view")
      },
      redirect: { name: "welcome-employee" },
      children: [
        {
          path: "inicio",
          name: "welcome-employee",
          component: () => import(
            /* webpackChunkName: "onboarding" */
            "@/views/v2/onboardingEmployee/OnboardingWelcome"
          ),
          meta: {
            module: "Onboarding",
            requireAuth: true,
            notValidateModule: true
          }
        }
      ]
    },
    {
      path: "empresa",
      component: {
        render: (h) => h("router-view")
      },
      redirect: { name: "welcome-hra" },
      children: [
        {
          path: "inicio",
          name: "welcome-hra",
          component: () => import(
            /* webpackChunkName: "onboarding" */
            "@/views/v2/onboardingHra/OnboardingWelcomeHra"
          ),
          meta: {
            module: "Onboarding",
            requireAuth: true
          }
        }
      ]
    }
  ]
};
export default routes;
