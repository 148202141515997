export default {
  required: (fieldName) => `${fieldName} es requerido`,
  minLength: (fieldName, { min }) => `${fieldName} debe tener al menos ${min} caracteres`,
  maxLength: (fieldName, { max }) => `${fieldName} debe tener como m\xE1ximo ${max} caracteres`,
  numeric: (fieldName) => `${fieldName} debe ser num\xE9rico`,
  requiredIf: (fieldName, { value }) => `${fieldName} es requerido`,
  email: () => `Escribe un correo electr\xF3nico v\xE1lido`,
  mustBeSame: (fieldName) => `${fieldName} debe ser el mismo`,
  mobileLengthValidation: () => "Debes ingresar 10 caracteres sin contar la lada.",
  usernameInvalid: (fieldName) => `${fieldName} inv\xE1lido, intente con otro.`,
  minValue: (fieldName, { min }) => `${fieldName} debe ser mayor o igual a ${min}`,
  custom: (fieldValue) => `${fieldValue} es inv\xE1lido`,
  alpha: (fieldName) => `${fieldName} debe ser alfab\xE9tico`,
  decimal: (fieldName) => `${fieldName} debe ser decimal`,
  equalName: () => `El nombre ya existe, intenta con uno distinto`,
  positiveDecimal: (fieldName) => `${fieldName} debe ser un n\xFAmero positivo, no se admiten ( , )`,
  time: (fieldName) => `${fieldName} inv\xE1lido, tiempo v\xE1lido p. ej. 00:30, 02:45`,
  idFormat: (fieldName) => `${fieldName} inv\xE1lido, solo se admiten letras y n\xFAmeros`,
  formatValid: (fieldName) => `${fieldName} tiene un formato inv\xE1lido.`,
  isBeforeJoinDate: () => "El \xFAltimo dia calculado debe ser mayor o igual a la fecha de ingreso"
};
