import axios from "axios";
import { buildURLParams } from "@/utils/params";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;
export const bulk = {
  getStep(companyId) {
    return axios.get(`/api/v1/massive_create/process/${companyId}/`);
  },
  updateStep(companyId, payload) {
    return axios.put(`/api/v1/massive_create/process/${companyId}/`, payload);
  },
  leaveProcess(companyId) {
    return axios.put(`/api/v1/massive_create/process/${companyId}/?exit_process=true`);
  },
  getFieldsConfig() {
    return axios.get(`/api/v2/fields_configuration/`);
  },
  getFieldsByFilter(params) {
    const urlParams = buildURLParams(params);
    return axios.get(`/api/v2/fields_configuration/`, {
      params: urlParams
    });
  },
  updateField(id, payload) {
    return axios.put(`/api/v2/fields_configuration/${id}/`, payload);
  },
  updateFields(payload) {
    return axios.put(`/api/v2/fields_configuration/bulk_update/`, payload);
  },
  massiveCreate(employees) {
    return axios.post("/api/v1/massive_create/", employees);
  },
  massiveUpdate(employees) {
    return axios.put("/api/v1/massive_create/bulk_update/", employees);
  },
  getEstates() {
    return axios.get(`/api/v2/catalogs/estates/`);
  },
  getGenders() {
    return axios.get(`/api/v2/catalogs/genders/`);
  },
  getBank() {
    return axios.get(`/api/v2/catalogs/banks/`);
  },
  getCivilStatus() {
    return axios.get(`/api/v2/catalogs/civil_statuses/`);
  },
  getContracts() {
    return axios.get(`/api/v2/catalogs/contract_types/`);
  },
  getPayrolls() {
    return axios.get(`/api/v2/catalogs/payroll_schemes/`);
  },
  getMassiveEmployees() {
    return axios.get(`/api/v1/massive/employees/`);
  },
  getMassiveEmployeesFilter(filter) {
    return axios.get(`/api/v1/massive/employees/?status=${filter}`);
  },
  massiveActivate(payload) {
    return axios.post("/api/v1/massive_create/process/invite_employees/", payload);
  },
  getMassiveEmployeesByIds(ids) {
    return axios.post("/api/v1/massive/employees/dynamic/", ids);
  },
  autoSave(companyId, draft) {
    return axios.put(`/api/v1/massive_create/process/${companyId}/`, draft);
  }
};
export default bulk;
