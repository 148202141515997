export default {
  setRequests(state, { data }) {
    state.list = data;
  },
  setRequest(state, { request }) {
    const { id } = request;
    const newList = [...state.list];
    const index = newList.findIndex((_request) => _request === id);
    if (index >= 0) {
      newList[index] = request;
    } else {
      newList.push(request);
    }
    state.list = newList;
  }
};
