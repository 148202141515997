import { DateTime } from "luxon";
export default {
  setIncidencesList(state, payload) {
    state.incidencesList = payload.sort((a, b) => {
      const startDateA = new Date(DateTime.fromISO(a.start_date));
      const startDateB = new Date(DateTime.fromISO(b.start_date));
      return startDateA > startDateB ? 1 : -1;
    });
  },
  setIncidencesByEmployee(state, payload) {
    state.incidencesByEmployee = payload.sort((a, b) => {
      const startDateA = new Date(DateTime.fromISO(a.start_date));
      const startDateB = new Date(DateTime.fromISO(b.start_date));
      return startDateA > startDateB ? 1 : -1;
    });
  },
  setIncidencesCount(state, payload) {
    state.incidencesCount = payload;
  },
  setIncidencesByEmployeeCount(state, payload) {
    state.incidencesByEmployeeCount = payload;
  },
  setLegacyIncidencesSettings(state, payload) {
    state.legacyIncidencesSettings = payload;
  },
  setCustomIncidencesSettings(state, payload) {
    state.customIncidencesSettings = payload;
  },
  setCustomIncidencesCategory(state, payload) {
    state.customIncidencesCategory = payload;
  },
  setCustomIncidencesTypes(state, payload) {
    state.customIncidencesTypes = payload;
  },
  setMyRequestsRequestable(state, payload) {
    state.myRequestsRequestable = payload;
    state.customApprovalFlows = payload.review_policies;
    if (this._vm.$feature("incidences.complete_view.new_version")) {
      state.legacyIncidencesSettings = payload.Legacy;
      state.customListRequestable = payload.CUST;
    } else {
      state.customListRequestable = payload.requestable.CUST;
    }
  },
  setMyUpcomingRequestsList(state, payload) {
    state.myUpcomingRequestsList = payload.sort((a, b) => {
      const startDateA = new Date(DateTime.fromISO(a.start_date));
      const startDateB = new Date(DateTime.fromISO(b.start_date));
      return startDateA > startDateB ? 1 : -1;
    });
  },
  setMyPreviousRequestsList(state, payload) {
    state.myPreviousRequestsList = payload.sort((a, b) => {
      const startDateA = new Date(DateTime.fromISO(a.start_date));
      const startDateB = new Date(DateTime.fromISO(b.start_date));
      return startDateA < startDateB ? 1 : -1;
    });
  },
  setPendingRequestsList(state, payload) {
    state.pendingRequestsList = payload;
  },
  setAreasInfo(state, payload) {
    state.areasInfo = payload;
  },
  setFilterByType(state, payload) {
    state.filterByType = payload;
  },
  setFilterByStatus(state, payload) {
    state.filterByStatus = payload;
  },
  setFilterByDate(state, payload) {
    state.filterByDate = payload;
  },
  clearFilterByType(state) {
    state.filterByType = [];
  },
  clearFilterByStatus(state) {
    state.filterByStatus = [];
  },
  clearFilterByDate(state) {
    state.filterByDate = {};
  },
  setOnboarded(state, { data }) {
    state.onboarded = data;
  },
  setIncidencias(state, { data }) {
    state.list = data;
  },
  setIncidencia(state, { incidencia }) {
    const { id } = incidencia;
    const list = [...state.list];
    const index = list.findIndex((_incidencia) => _incidencia.id === id);
    if (index >= 0) {
      list[index] = incidencia;
    } else {
      list.push(incidencia);
    }
    state.list = list;
  },
  removeIncidence(state, { id }) {
    state.list = state.list.filter((incidence) => incidence.id !== id);
  },
  setGettingStats(state, status) {
    state.gettingStats = status;
  }
};
