export default {
  setSchedules(state, { schedules }) {
    state.list = schedules;
  },
  setSchedule(state, { schedule }) {
    const { id } = schedule;
    const newList = [...state.list];
    const index = newList.findIndex((_schedule) => _schedule.id === id);
    if (index >= 0) {
      newList[index] = schedule;
    } else {
      newList.push(schedule);
    }
    state.list = newList;
  }
};
