export default {
  getCompany(state) {
    return (id) => state.companies.find((company) => company.id === id);
  },
  companies(state) {
    return state.companies;
  },
  users(state) {
    return state.users;
  },
  employees(state) {
    return state.employees;
  },
  companyUsers(state, getters, rootState) {
    return function users(companyId) {
      return getters.users.filter((user) => user.company === companyId).map((user) => Object.assign({}, user, {
        company: rootState.find((company) => company.id === companyId)
      }));
    };
  },
  companyEmployees(state, getters, rootState) {
    return function employees(companyId) {
      return getters.employees.filter((employee) => employee.company === companyId);
    };
  },
  activeCompanyEmployees(state, getters) {
    return function activeEmployees(companyId) {
      return getters.companyEmployees(companyId).filter((emp) => emp.active);
    };
  },
  inactiveCompanyEmployees(state, getters) {
    return function inactiveEmployees(companyId) {
      return getters.companyEmployees(companyId).filter((emp) => !emp.active);
    };
  }
};
