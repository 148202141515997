import Model from "@/models/model";
export default class Template extends Model {
  static get modelName() {
    return "Candidate";
  }
  static apiNamespace() {
    return "api/v2";
  }
  static resourceNamespace() {
    return "candidates";
  }
  static fields() {
    return [
      "id",
      "comments",
      "name",
      "email",
      "phone",
      "web",
      "order",
      "hired",
      "rejected",
      "cv",
      "vacancy",
      "round"
    ];
  }
  static defaults() {
    return {
      comments: []
    };
  }
  notifyCandidate(data) {
    return this.constructor.post(`${this.resourcePath()}/notification/`, { data });
  }
}
