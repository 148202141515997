import Model from "@/models/v1/model";
export default class Company extends Model {
  static get modelName() {
    return "Company";
  }
  static apiNamespace() {
    return "api/v2";
  }
  static resourceNamespace() {
    return "companies";
  }
  static default() {
    return "";
  }
  static defaults() {
    return {
      vacations_onboarded: false,
      company_onboarded: false,
      incidencias_onboarded: false,
      checklist: false
    };
  }
  static fields() {
    return [
      "id",
      "name",
      "description",
      "cover",
      "logo",
      "active",
      "checklist",
      "company_onboarded",
      "incidencias_onboarded",
      "vacations_onboarded",
      "admin_name",
      "admin_email",
      "admin_phone",
      "social_facebook",
      "social_twitter",
      "social_linkedin",
      "social_instagram",
      "webpage",
      "outsourcer_company",
      "outsourcer_name",
      "outsourcer_email",
      "outsourcer_phone",
      "accountant_name",
      "accountant_email",
      "accountant_phone",
      "active_employees",
      "count_employees"
    ];
  }
  workingHours() {
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/working_hours/`)
    };
  }
}
