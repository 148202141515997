var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "chips-container p-default" }, [_vm._l(_vm.chipsList, function(item, index) {
    return _c("div", { key: index, staticClass: "chip bg-[var(--filter-tag)] text-white rounded-16px py-1 m-1" }, [_c("span", { staticClass: "px-3 overflow-hidden" }, [_vm._v(" " + _vm._s(item) + " ")]), _c("button", { staticClass: "remove", on: { "click": function($event) {
      return _vm.removeChip(index);
    } } }, [_c("CloseSmall")], 1)]);
  }), _c("input", { directives: [{ name: "model", rawName: "v-model", value: _vm.input, expression: "input" }], staticClass: "outline-none w-full p-default", attrs: { "type": "text", "placeholder": "Nombre de la etiqueta" }, domProps: { "value": _vm.input }, on: { "keyup": function($event) {
    if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter"))
      return null;
    return _vm.onEnter.apply(null, arguments);
  }, "input": function($event) {
    if ($event.target.composing)
      return;
    _vm.input = $event.target.value;
  } } })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };
