var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { delegatePermissions } from "@/api";
const state = {
  permissions: [],
  employeeId: null
};
const actions = {
  configureEmployeeId({ commit }, employeeId) {
    commit("setEmployeeId", employeeId);
  },
  getPermissions(_0) {
    return __async(this, arguments, function* ({ commit, state: state2 }) {
      const permissions = yield delegatePermissions.getEmployeePermissions(state2.employeeId);
      commit("setPermissions", permissions);
      return permissions;
    });
  }
};
const mutations = {
  setPermissions(state2, permissions) {
    state2.permissions = permissions;
  },
  setEmployeeId(state2, employeeId) {
    state2.employeeId = employeeId;
  }
};
export default {
  state,
  actions,
  mutations,
  namespaced: true
};
