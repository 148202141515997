import Model from "@/models/v1/model";
export default class Schedule extends Model {
  static get modelName() {
    return "Schedule";
  }
  static apiNamespace() {
    return "api/v2";
  }
  static resourceNamespace() {
    return "working_hours";
  }
  static fields() {
    return [
      "company",
      "tolerance",
      "name",
      "entry_time",
      "working_hours",
      "lunch_hours",
      "areas",
      "offices"
    ];
  }
  static defaults() {
    return {
      areas: [],
      offices: []
    };
  }
}
