var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "loader", class: [
    _vm.styleLoader,
    `loader--${_vm.innerLoader ? "inner" : "outer"}`,
    {
      "fixed top-0 left-0 w-screen h-screen": !_vm.innerLoader,
      "w-full h-full absolute inset-0": _vm.innerLoader
    }
  ] }, [_c("SvgAnimator", { attrs: { "options": _vm.defaultOptions, "height": 60, "width": 60 }, on: { "animCreated": _vm.handleAnimation } }), _vm.loadingText ? _c("p", [_vm._v(" " + _vm._s(_vm.loadingText) + " ")]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
