var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Notification from "@/models/notification";
export default {
  getNotifications(_0) {
    return __async(this, arguments, function* ({ commit }) {
      const notification = new Notification();
      const notifications = yield notification.notifications().get();
      return notifications;
    });
  },
  getNotificationsCount(_0) {
    return __async(this, arguments, function* ({ commit }) {
      const notification = new Notification();
      const notifications = yield notification.notifications().getCount();
      return notifications;
    });
  },
  getNotificationsCreated(_0) {
    return __async(this, arguments, function* ({ commit }) {
      const notification = new Notification();
      const notifications = yield notification.notifications().create();
      return notifications;
    });
  },
  updateNotificationUnread(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { idNotification }) {
      const notification = new Notification();
      const notifications = yield notification.notifications().update({ id: idNotification });
      return notifications;
    });
  }
};
