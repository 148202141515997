var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "colorpicker", class: _vm.styleClasses }, [_c("input", { directives: [{ name: "model", rawName: "v-model", value: _vm.internalValueComputed, expression: "internalValueComputed" }], staticClass: "colorpicker__control", attrs: { "type": "text", "placeholder": _vm.placeholder, "disabled": _vm.disabled }, domProps: { "value": _vm.internalValueComputed }, on: { "focus": _vm.onFocus, "blur": _vm.onBlur, "input": function($event) {
    if ($event.target.composing)
      return;
    _vm.internalValueComputed = $event.target.value;
  } } }), _vm.internalValueComputed && !_vm.disabled ? _c("span", { staticClass: "colorpicker__clear", on: { "click": () => _vm.internalValueComputed = "" } }, [_c("Icon", { attrs: { "icon": "close" } })], 1) : _vm._e(), _c("input", { directives: [{ name: "model", rawName: "v-model", value: _vm.internalValueComputed, expression: "internalValueComputed" }], staticClass: "colorpicker__color", attrs: { "disabled": _vm.disabled, "type": "color" }, domProps: { "value": _vm.internalValueComputed }, on: { "input": function($event) {
    if ($event.target.composing)
      return;
    _vm.internalValueComputed = $event.target.value;
  } } })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
