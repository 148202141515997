import axios from "axios";
export const upsell = {
  getUpsellModules() {
    return axios.get("/api/v1/payments/modules_template/modules/");
  },
  getUpsellDetail(id) {
    return axios.get(`/api/v1/payments/modules_template/${id}`);
  },
  getModule(payload) {
    return axios.post("/api/v1/payments/company_additional_modules/", payload);
  },
  getAditionalModules() {
    return axios.get("/api/v1/payments/company_additional_modules/");
  }
};
export default upsell;
