export default {
  setRazonesSociales(state, { razonesSociales }) {
    state.list = razonesSociales;
  },
  setRazonSocial(state, { razonSocial }) {
    const { id } = razonSocial;
    const newList = [...state.list];
    const index = newList.findIndex((_razonSocial) => _razonSocial.id === id);
    if (index >= 0) {
      newList[index] = razonSocial;
    } else {
      newList.push(razonSocial);
    }
    state.list = newList;
  },
  unsetRazonSocial(state, payload) {
    state.list = state.list.filter((item) => item.id !== payload.id);
  }
};
