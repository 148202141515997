import Model from "@/models/model";
export default class Template extends Model {
  static get modelName() {
    return "Template";
  }
  static apiNamespace() {
    return "api/v1/evaluations";
  }
  static resourceNamespace() {
    return "templates";
  }
  static fields() {
    return [
      "id",
      "questionary",
      "title",
      "goal",
      "created",
      "modified",
      "master",
      "company",
      "author",
      "editors"
    ];
  }
  apply(data) {
    return this.constructor.post(`${this.resourcePath()}/apply/`, data);
  }
}
