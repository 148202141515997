import axios from "axios";
import { buildURLParams } from "@/utils/params";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;
export const trainings = {
  // status, status_in, search, fields
  get(params) {
    const urlParams = buildURLParams(params);
    return axios.get("/api/v1/as_hra/trainings/", {
      params: urlParams
    });
  },
  getOne(id, params) {
    const urlParams = buildURLParams(params);
    return axios.get(`/api/v1/as_hra/trainings/${id}/`, {
      params: urlParams
    });
  },
  getEmployeeTrainings(idemployee) {
    return axios.get(`/api/v1/as_hra/trainings/?employee=${idemployee}`);
  },
  getEmployeeTrainings2(idEmployee) {
    return axios.get(`/api/v1/as_hra/guests/detailed_list/?employee=${idEmployee}`);
  },
  getComments(id, params) {
    let queryParamsString = "";
    if (params) {
      queryParamsString = Object.keys(params).map((queryParam) => `${queryParam}=${params[queryParam]}`).join("&");
    }
    return axios.get(`/api/v1/as_hra/trainings/${id}/comments/?${queryParamsString}`);
  },
  create(training) {
    return axios.post("/api/v1/trainings/", training);
  },
  update(id, training) {
    return axios.put(`/api/v1/trainings/${id}/`, training);
  },
  archive(id) {
    return axios.post(`/api/v1/trainings/${id}/archive/`);
  },
  unarchive(id) {
    return axios.post(`/api/v1/trainings/${id}/unarchive/`);
  },
  clone(id) {
    return axios.post(`/api/v1/trainings/${id}/clone/`);
  },
  delete(id) {
    return axios.delete(`/api/v1/trainings/${id}/`);
  },
  invite(id, payload) {
    return axios.post(`/api/v1/trainings/${id}/invite/`, payload);
  },
  uninvite(id, guestsIds) {
    return axios.post(`/api/v1/trainings/${id}/uninvite/`, guestsIds);
  },
  remind(guestsIds) {
    return axios.post(`/api/v1/trainings/remind/`, guestsIds);
  },
  finish(guestsIds) {
    return axios.post(`/api/v1/trainings/finish/`, guestsIds);
  },
  getAvailableEmployees(id, params) {
    const urlParams = buildURLParams(params);
    return axios.get(`api/v1/as_hra/trainings/${id}/available_employees/`, {
      params: urlParams
    });
  },
  detail: {
    get(id, params) {
      const defaultParams = {
        training: id,
        status_in: "0,1",
        order_by: "-individual_limit"
      };
      const paramsObj = Object.assign(defaultParams, params);
      const urlParams = buildURLParams(paramsObj);
      return axios.get("/api/v1/as_hra/guests/", {
        params: urlParams
      });
    },
    getExpanded(id) {
      return axios.get(`/api/v1/as_hra/guests/${id}/expand/`);
    }
  },
  history: {
    get(params) {
      const urlParams = buildURLParams(params);
      return axios.get("/api/v1/as_hra/trainings_history/company/", {
        params: urlParams
      });
    },
    getOne(id, params) {
      const urlParams = buildURLParams(params);
      return axios.get(`/api/v1/as_hra/trainings_history/${id}/employee/`, {
        params: urlParams
      });
    }
  },
  as_boss: {
    get(params) {
      const urlParams = buildURLParams(params);
      return axios.get("/api/v1/as_boss/trainings/", {
        params: urlParams
      });
    },
    getOne(id, params) {
      const urlParams = buildURLParams(params);
      return axios.get(`/api/v1/as_boss/trainings/${id}/`, {
        params: urlParams
      });
    },
    history: {
      get() {
        return axios.get("/api/v1/as_boss/trainings_history/company/");
      },
      getOne(id, params) {
        const urlParams = buildURLParams(params);
        return axios.get(`/api/v1/as_boss/trainings_history/${id}/employee/`, {
          params: urlParams
        });
      }
    },
    detail: {
      get(id, params) {
        const defaultParams = {
          training: id,
          status_in: "0,1",
          order_by: "-individual_limit"
        };
        const paramsObj = Object.assign(defaultParams, params);
        const urlParams = buildURLParams(paramsObj);
        return axios.get("/api/v1/as_boss/guests/", {
          params: urlParams
        });
      }
    }
  },
  employee: {
    get(params) {
      const urlParams = buildURLParams(params);
      return axios.get("/api/v1/as_guest/trainings/", {
        params: urlParams
      });
    },
    getOne(id) {
      return axios.get(`/api/v1/as_guest/trainings/${id}/`);
    },
    markTask(taskId, payload) {
      return axios.put(`/api/v1/guests_tasks_trainings/${taskId}/`, payload);
    },
    finish(id, data) {
      return axios.post(`/api/v1/guests_trainings/${id}/finish/`, data);
    },
    uploadSectionFile(sectionId, fileData) {
      const formData = new FormData();
      if (fileData) {
        formData.append("file", fileData);
      } else {
        formData.append("file", "");
      }
      return axios.patch(`/api/v1/guests_sections_trainings/${sectionId}/`, formData, {
        headers: { "content-Type": "multipart/form-data" }
      });
    }
  }
};
export default trainings;
