var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
import { DateTime } from "luxon";
const URL_ZENTRIC = "/api/v1/integrations/zentric/";
const API_ZENTRIC = axios.create({
  baseURL: process.env.VUE_APP_API_URL_ZENTRIC,
  withCredentials: false
});
const getToken = () => __async(void 0, null, function* () {
  return axios.post(`${URL_ZENTRIC}company_account/z_token/`);
});
const setTimeGenerationToken = () => {
  const timeNow = DateTime.utc().plus({ hours: 8 });
  localStorage.setItem("zTokenExp", timeNow.toString());
};
const checkTokenExpiration = () => {
  const tokenExpiration = localStorage.getItem("zTokenExp");
  const timeNow = DateTime.utc();
  return tokenExpiration && DateTime.fromISO(tokenExpiration) >= timeNow;
};
const setHeaders = () => __async(void 0, null, function* () {
  let token = localStorage.getItem("zToken");
  const cmp = localStorage.getItem("cmp");
  const cmpZ = localStorage.getItem("cmpZ");
  if (!token || cmp !== cmpZ) {
    const tokenData = yield getToken().then(({ data }) => data);
    ({ token } = tokenData);
    localStorage.setItem("zToken", token);
    localStorage.setItem("cmpZ", cmp);
    setTimeGenerationToken();
  } else {
    const tokenTimeIsValid = checkTokenExpiration();
    if (!tokenTimeIsValid) {
      localStorage.removeItem("zToken");
      return setHeaders();
    }
  }
  API_ZENTRIC.defaults.headers.common.Authorization = `JWT ${token}`;
  return token;
});
const apiGet = (...args) => __async(void 0, null, function* () {
  yield setHeaders();
  let getCall = null;
  try {
    getCall = yield API_ZENTRIC.get(...args);
  } catch (error) {
    const { response } = error;
    if (response.status === 401) {
      localStorage.removeItem("zToken");
      return apiGet(...args);
    }
    throw error;
  }
  return getCall;
});
const apiPost = (...args) => __async(void 0, null, function* () {
  yield setHeaders();
  let postCall = null;
  try {
    postCall = yield API_ZENTRIC.post(...args);
  } catch (error) {
    const { response } = error;
    if (response.status === 401) {
      localStorage.removeItem("zToken");
      return apiPost(...args);
    }
    throw error;
  }
  return postCall;
});
const apiPut = (...args) => __async(void 0, null, function* () {
  yield setHeaders();
  let putCall = null;
  try {
    putCall = yield API_ZENTRIC.put(...args);
  } catch (error) {
    const { response } = error;
    if (response.status === 401) {
      localStorage.removeItem("zToken");
      return apiPut(...args);
    }
    throw error;
  }
  return putCall;
});
const apiDelete = (...args) => __async(void 0, null, function* () {
  yield setHeaders();
  let deleteCall = null;
  try {
    deleteCall = yield API_ZENTRIC.delete(...args);
  } catch (error) {
    const { response } = error;
    if (response.status === 401) {
      localStorage.removeItem("zToken");
      return apiDelete(...args);
    }
    throw error;
  }
  return deleteCall;
});
const validateArray = ({ data }) => Array.isArray(data) ? data : [];
export const zentric = {
  // Token
  getToken,
  // Zentric Options
  getCountries() {
    return apiGet(`catalogs/countries/`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getTaxRegimes() {
    return apiGet(`organigrams/tax-regimes/`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getCompanies() {
    return apiGet("organigrams/companies/").then(({ data }) => data.data);
  },
  getEmployerRegistries() {
    return apiGet("/organigrams/employer-registries/").then(({ data }) => data.data);
  },
  getBranches() {
    return apiGet(`/organigrams/branches/`).then(({ data }) => data.data || []);
  },
  getDepartments() {
    return apiGet(`/organigrams/departments/`).then(({ data }) => data.data || []);
  },
  getPositions() {
    return apiGet(`/organigrams/positions/`).then(({ data }) => data.data || []);
  },
  getRegions() {
    return apiGet(`/organigrams/regions/`).then(({ data }) => data.data || []);
  },
  getGroups() {
    return apiGet(`/organigrams/organigrams-groups/`).then(({ data }) => data.data || []);
  },
  getDivisions() {
    return apiGet(`/organigrams/divisions/`).then(({ data }) => data.data || []);
  },
  getZAreas() {
    return apiGet(`/organigrams/areas/`).then(({ data }) => data.data || []);
  },
  getDistricts() {
    return apiGet(`/organigrams/districts/`).then(({ data }) => data.data || []);
  },
  getZEmployees() {
    return apiGet(`/employees/employees/`).then(({ data }) => data.data || []);
  },
  getAddress(postalCode) {
    return apiGet(`catalogs/postal-codes/?key=${postalCode}`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getNeighborhood(idAddress) {
    return apiGet(`catalogs/neighborhoods/?postal_code=${idAddress}`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getIMMSDelegations() {
    return apiGet(`catalogs/imss-delegations/`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getIMMSSubdelegations(idDelegation) {
    return apiGet(`catalogs/imss-subdelegations/?imss_delegation=${idDelegation}`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getRiskFractions() {
    return apiGet(`organigrams/risk-fractions/`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getRiskClasses() {
    return apiGet(`organigrams/risk-classes/`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getPayrollAttributes() {
    return apiGet(`payrolls/payroll-attributes/`).then(({ data }) => {
      if (Array.isArray(data.data)) {
        return data.data;
      }
      return [];
    });
  },
  getPeriodStart(calendarId) {
    return apiGet(`calendars/calendar-details/?calendar=${calendarId}`, {
      headers: { "Content-Type": "application/json" }
    }).then(({ data }) => {
      if (Array.isArray(data.data)) {
        return data.data;
      }
      return [];
    });
  },
  getBanks() {
    return apiGet(`catalogs/banks/`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getTypeOfContracts() {
    return apiGet(`/employees/contract-types/`);
  },
  getWorkingDays() {
    return apiGet(`/employees/workday-types/`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getRegimeTypes() {
    return apiGet(`/employees/contractual-regimes/`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getEmployeeTypes() {
    return apiGet(`/employees/employee-types/`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getSalaryTypes() {
    return apiGet(`/employees/salary-types/`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getWeekTypes() {
    return apiGet(`/employees/week-types/`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getTableBenefits() {
    return apiGet(`/organigrams/benefits-tables/`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getAffiliationTypes() {
    return apiGet(`/affiliationmovements/afil-mov-types/`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getAffiliationReasons() {
    return apiGet(`/affiliationmovements/afil-mov-motives/`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getInfonavitTypes() {
    return apiGet(`/infonavitmovements/infonavit-mov-types/`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getInfonavitReasons() {
    return apiGet(`/infonavitmovements/infonavit-mov-motives/`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getAbsenteeismTypes() {
    return apiGet(`/absenteeism/absenteeism-types/`).then(({ data }) => validateArray(data));
  },
  getConcepts() {
    return apiGet(`/concepts/concepts/`).then(({ data }) => validateArray(data));
  },
  getInabilitiesTypes() {
    return apiGet(`/inabilities/inability-types/`).then(({ data }) => validateArray(data));
  },
  getInabilitiesSubtypes(typeId) {
    return apiGet(`/inabilities/inability-subtypes/?inability_type=${typeId}`).then(({ data }) => validateArray(data));
  },
  getInabilitiesBranches() {
    return apiGet(`/inabilities/inability-branches/`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getInabilitiesSubbranches(branchId) {
    return apiGet(`/inabilities/inability-subbranches/?inability_branch=${branchId}`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getConceptsTypes() {
    return apiGet(`/concepts/concept-sat-types/`, {
      headers: { "Content-Type": "application/json" }
    });
  },
  getEmployeePayrollData(employeeId) {
    return apiGet(`/employees/payroll-data/?employee=${employeeId}`).then(({ data }) => {
      if (Array.isArray(data.data)) {
        return data.data;
      }
      return [];
    });
  },
  getEmployeePayrollMovements(employeeId, payrollId) {
    return apiGet(`/employees/payroll-movements/?employee=${employeeId}&payroll=${payrollId}`).then(({ data }) => validateArray(data));
  },
  getEmployeeIMSSMovements(employeeId, payrollId) {
    return apiGet(`/employees/payroll-imss-data/?employee=${employeeId}&payroll=${payrollId}`).then(({ data }) => validateArray(data));
  },
  getEmployeeAffiliationMovements(employeeId, payrollId) {
    return apiGet(`/affiliationmovements/afil-movs/?employee=${employeeId}&payroll=${payrollId}`).then(({ data }) => validateArray(data));
  },
  createPayrollMovement(payrollMovement) {
    return apiPost(`/employees/payroll-movements/`, [payrollMovement]);
  },
  updatePayrollMovement(payrollMovement) {
    return apiPut(`/employees/payroll-movements/${payrollMovement.id}/`, payrollMovement);
  },
  createIMSSMovement(imssMovement) {
    return apiPost(`/employees/payroll-imss-data/`, [imssMovement]);
  },
  updateIMSSMovement(imssMovement) {
    return apiPut(`/employees/payroll-imss-data/${imssMovement.id}/`, imssMovement);
  },
  createAffiliationMovement(affiliationMovement) {
    return apiPost(`/affiliationmovements/afil-movs/`, [affiliationMovement]);
  },
  updateAffiliationMovement(affiliationMovement) {
    return apiPut(`/affiliationmovements/afil-movs/${affiliationMovement.id}/`, affiliationMovement);
  },
  getZOrganigrams() {
    return apiGet("organigrams/organigrams/").then(({ data }) => {
      if (Array.isArray(data.data)) {
        return data.data;
      }
      return [];
    });
  },
  getZOrganigram(organigramId) {
    return apiGet(`organigrams/organigrams/${organigramId}/`).then(({ data }) => data.data);
  },
  createOrganigram(organigram) {
    return apiPost(`organigrams/organigrams/`, [organigram]).then(({ data }) => data.data);
  },
  updateOrganigram(organigram) {
    return apiPut(`organigrams/organigrams/${organigram.id}/`, organigram).then(({ data }) => data.data);
  },
  getPayrolls(type = null, year = null) {
    const query = [
      type ? `type=${type}` : null,
      year ? `year=${year}` : null
    ].filter((q) => q).join("&");
    return apiGet(`payrolls/payrolls/?${query}`).then(({ data }) => validateArray(data));
  },
  getZPayroll(payrollId) {
    return apiGet(`payrolls/payrolls/?id=${payrollId}`).then(({ data }) => validateArray(data)).then((items) => items.length === 0 ? null : items[0]);
  },
  getPayrollsWithCalendar(calendarId) {
    return apiGet(`payrolls/payrolls/?calendar=${calendarId}`).then(({ data }) => data.data);
  },
  getCalendarDetails(calendarId = null) {
    let query = "";
    if (calendarId) {
      query = `?calendar=${calendarId}`;
    }
    return apiGet(`calendars/calendar-details/${query}`).then(({ data }) => data.data);
  },
  getCalendars(options = {}) {
    return apiGet("calendars/calendars/", { params: options }).then(({ data }) => validateArray(data));
  },
  getCalendar(calendarId) {
    return apiGet(`calendars/calendars/${calendarId}`).then(({ data }) => data.data);
  },
  getDashboard() {
    return apiGet("dashboards/dashboards/").then(({ data }) => data.data);
  },
  deleteCalendar(calendarId) {
    return apiDelete(`calendars/calendars/${calendarId}/`);
  },
  getCalendarYears() {
    return apiGet("calendars/calendar-years").then(({ data }) => validateArray(data));
  },
  getPayrollsInYear(year) {
    return apiGet(`payrolls/payrolls-by-calendar/?attribute=1&year=${year}`).then(({ data }) => validateArray(data));
  },
  executeEngine(payrollInfo) {
    return __async(this, null, function* () {
      let isExecuted = false;
      try {
        yield apiPost("/engines/engines/", payrollInfo);
        isExecuted = true;
      } catch (_) {
        console.error(_);
      }
      return isExecuted;
    });
  },
  getPeriodSummary(_0) {
    return __async(this, arguments, function* ({ payroll, period, year }) {
      let periodSummary = {};
      try {
        const periodSummaries = yield apiGet(`calculations/period-summaries/?payroll=${payroll}&period=${period}&year=${year}`).then(({ data }) => data.data);
        if (Array.isArray(periodSummaries)) {
          [periodSummary] = periodSummaries;
        }
      } catch (_) {
        console.error(_);
      }
      return periodSummary;
    });
  },
  createPayrolls(payrolls) {
    return apiPost(`payrolls/payrolls/`, payrolls);
  },
  createZPayrollData(payrollData) {
    return apiPost(`employees/payroll-data/`, [payrollData]);
  },
  updatePayrollData(payrollData) {
    return apiPut(`employees/payroll-data/${payrollData.id}/`, payrollData);
  },
  getPayrollEmployeeSummaries({ payroll, year, period }) {
    return apiGet(`calculations/employee-summaries/?payroll=${payroll}&period=${period}&year=${year}`).then(({ data }) => validateArray(data));
  },
  getEmployeeCalculations({
    period,
    payroll,
    year,
    employee
  }) {
    return apiGet(`/calculations/calculations/?employee=${employee}&payroll=${payroll}&period=${period}&year=${year}`).then(({ data }) => validateArray(data));
  },
  getPeriodSummaries(options) {
    const query = Object.keys(options).map((option) => `${option}=${options[option]}`).join("&");
    return apiGet(`calculations/period-summaries-detail/?${query}`).then(({ data }) => validateArray(data));
  },
  getPayrollSummary({ payroll, period, year }) {
    return apiGet(`calculations/period-summaries/?payroll=${payroll}&period=${period}&year=${year}`).then(({ data }) => validateArray(data)).then((items) => items.length === 0 ? null : items[0]);
  },
  getHistoryPayrollEmployeeSummaries({ payroll, period, year }) {
    return apiGet(`calculations/employee-historic-summaries/?payroll=${payroll}&period=${period}&year=${year}`).then(({ data }) => validateArray(data));
  },
  getEmployeeCalculationsHistoric({
    period,
    payroll,
    year,
    employee
  }) {
    return apiGet(`calculations/historic/?employee=${employee}&payroll=${payroll}&period=${period}&year=${year}`).then(({ data }) => validateArray(data));
  },
  closePeriod(payroll) {
    return apiPost("calculations/close_period", payroll);
  },
  openPeriod(payroll) {
    return apiPost("calculations/open_period", payroll);
  },
  deleteEmployeePayrollMovement(movement) {
    return apiDelete(`employees/payroll-movements/${movement.id}`);
  },
  deleteEmployeeIMSSMovement(movement) {
    return apiDelete(`/employees/payroll-imss-data/${movement.id}`);
  },
  deleteEmployeeAffiliationMovement(movement) {
    return apiDelete(`/affiliationmovements/afil-movs/${movement.id}`);
  },
  updatePayroll(payroll) {
    return apiPut(`payrolls/payrolls/${payroll.id}/`, payroll);
  },
  deletePayroll(payrollId) {
    return apiDelete(`payrolls/payrolls/${payrollId}/`);
  },
  deleteOrganigram(organigram) {
    return apiDelete(`organigrams/organigrams/${organigram.id}`);
  },
  getPeriodicities() {
    return apiGet("catalogs/periodicities/").then(({ data }) => validateArray(data));
  },
  createPayrollCalendar(payrollCalendar) {
    return apiPost("/calendars/calendars/", [payrollCalendar]).then(({ data }) => validateArray(data)).then((data) => data[0]);
  },
  createPayrollCalendarDetails(details) {
    return apiPost("calendars/calendar-details/", details);
  },
  updatePayrollCalendar(payrollCalendar) {
    return apiPut(`calendars/calendars/${payrollCalendar.id}/`, payrollCalendar);
  },
  updatePayrollCalendarDetail(payrollCalendarDetail) {
    return apiPut(`calendars/calendar-details/${payrollCalendarDetail.id}/`, payrollCalendarDetail);
  },
  // Activation
  getStatus() {
    return axios.get(`${URL_ZENTRIC}company_account/get_company_account/`);
  },
  createAccount(idCompany, payload) {
    return axios.post(`${URL_ZENTRIC}company_account/${idCompany}/create_account/`, payload);
  },
  // Legal name
  createLegalName(payload) {
    return axios.post(`${URL_ZENTRIC}legal_name/`, payload);
  },
  getLegalNames(page) {
    const params = {};
    if (page) {
      params.page = page;
    }
    return axios.get(`${URL_ZENTRIC}legal_name/`, { params });
  },
  integrateLegalName(idLegalName) {
    return axios.post(`${URL_ZENTRIC}legal_name/${idLegalName}/integrate/`);
  },
  removeLegalName(idLegalName) {
    return axios.delete(`${URL_ZENTRIC}legal_name/${idLegalName}/`);
  },
  editLegalName(idLegalName, payload) {
    return axios.patch(`${URL_ZENTRIC}legal_name/${idLegalName}/`, payload);
  },
  // Employer Registrations
  createEmployerRegistration(payload) {
    return axios.post(`${URL_ZENTRIC}employeer_registration/`, payload);
  },
  getEmployerRegistrations(page) {
    const params = {};
    if (page) {
      params.page = page;
    }
    return axios.get(`${URL_ZENTRIC}employeer_registration/`, { params });
  },
  integrateEmployerRegistration(idEmployerRegistration) {
    return axios.post(`${URL_ZENTRIC}employeer_registration/${idEmployerRegistration}/integrate/`);
  },
  removeEmployerRegistration(idEmployerRegistration) {
    return axios.delete(`${URL_ZENTRIC}employeer_registration/${idEmployerRegistration}/`);
  },
  editEmployerRegistration(idEmployerRegistration, payload) {
    return axios.patch(`${URL_ZENTRIC}employeer_registration/${idEmployerRegistration}/`, payload);
  },
  // Offices
  createOffice(payload) {
    return axios.post(`${URL_ZENTRIC}location/`, payload);
  },
  getOffices(isIntegrated, page, search) {
    const params = {};
    params.is_integrated = isIntegrated;
    if (page) {
      params.page = page;
    }
    if (search) {
      params.name = search;
    }
    return axios.get(`${URL_ZENTRIC}location/`, { params });
  },
  integrateOffice(idOffice) {
    return axios.post(`${URL_ZENTRIC}location/${idOffice}/integrate/`);
  },
  editOffice(idOffice, payload) {
    return axios.put(`${URL_ZENTRIC}location/${idOffice}/`, payload);
  },
  // Areas
  createArea(payload) {
    return axios.post(`${URL_ZENTRIC}area/`, payload);
  },
  getAreas(isIntegrated, page, search) {
    const params = {};
    params.is_integrated = isIntegrated;
    if (page) {
      params.page = page;
    }
    if (search) {
      params.name = search;
    }
    return axios.get(`${URL_ZENTRIC}area/`, { params });
  },
  editArea(idArea, payload) {
    return axios.patch(`${URL_ZENTRIC}area/${idArea}/`, payload);
  },
  integrateArea(idArea) {
    return axios.post(`${URL_ZENTRIC}area/${idArea}/integrate/`);
  },
  // Jobs
  createJob(payload) {
    return axios.post(`${URL_ZENTRIC}position/`, payload);
  },
  getJobs(isIntegrated, page, search) {
    const params = {};
    params.is_integrated = isIntegrated;
    if (page) {
      params.page = page;
    }
    if (search) {
      params.name = search;
    }
    return axios.get(`${URL_ZENTRIC}position/`, { params });
  },
  editJob(idJob, payload) {
    return axios.patch(`${URL_ZENTRIC}position/${idJob}/`, payload);
  },
  integrateJob(idJob) {
    return axios.post(`${URL_ZENTRIC}position/${idJob}/integrate/`);
  },
  synchronizeCalendars() {
    return axios.post(`${URL_ZENTRIC}calendar/sync_zentric_information/`);
  },
  // Organigrams
  getOrganigrams(page, search) {
    const params = {};
    if (page) {
      params.page = page;
    }
    if (search) {
      params.name = search;
    }
    return axios.get(`${URL_ZENTRIC}organigram/`, { params });
  },
  synchronizeOrganigrams() {
    return axios.post(`${URL_ZENTRIC}organigram/sync_zentric_information/`);
  },
  // Resume
  getResume() {
    return axios.get(`${URL_ZENTRIC}company_account/catalogs_resume/`);
  },
  // Employees
  getEmployees() {
    return apiGet("employees/employees/").then(({ data }) => validateArray(data));
  },
  createEmployee(payload) {
    return axios.post(`${URL_ZENTRIC}employees/`, payload);
  },
  editEmployee(idEmployee, payload) {
    return axios.patch(`${URL_ZENTRIC}employees/${idEmployee}/`, payload);
  },
  editEmployeeAddress(idEmployee, payload) {
    return axios.patch(`${URL_ZENTRIC}employees/${idEmployee}/update_employee_location/`, payload);
  },
  editEmployeeBank(idEmployee, payload) {
    return axios.patch(`${URL_ZENTRIC}employees/${idEmployee}/update_employee_bank_info/`, payload);
  },
  integrateEmployee(idEmployee) {
    return axios.post(`${URL_ZENTRIC}employees/${idEmployee}/integrate/`);
  },
  getEmployee(idEmployee) {
    return axios.get(`${URL_ZENTRIC}employees/${idEmployee}/`);
  },
  getEmployeeByURL(urlUsername) {
    return axios.post(`${URL_ZENTRIC}employees/${urlUsername}/get_z_employee_by_url_username/`);
  },
  // Payroll
  createPayroll(payload) {
    return axios.post(`${URL_ZENTRIC}payroll/`, payload);
  },
  getPayroll(isIntegrated, page, search) {
    const params = {};
    params.is_integrated = isIntegrated;
    if (page) {
      params.page = page;
    } else {
      params.paginate = false;
    }
    if (search) {
      params.name = search;
    }
    return axios.get(`${URL_ZENTRIC}payroll/`, { params });
  },
  getPayrollById(idPayroll) {
    return axios.get(`${URL_ZENTRIC}payroll/${idPayroll}`);
  },
  editPayroll(idPayroll, payload) {
    return axios.patch(`${URL_ZENTRIC}payroll/${idPayroll}/`, payload);
  },
  integratePayroll(idPayroll) {
    return axios.post(`${URL_ZENTRIC}payroll/${idPayroll}/integrate/`);
  },
  // Employees Payroll
  getPayrollData(idEmployee, page, search) {
    const params = {};
    if (page) {
      params.page = page;
    }
    if (search) {
      params.name = search;
    }
    return axios.get(`${URL_ZENTRIC}employees/${idEmployee}/list_payrolls/`, { params });
  },
  createPayrollData(idEmployee, payload) {
    return axios.post(`api/v1/integrations/zentric/employees/${idEmployee}/new_payroll/`, payload);
  },
  editPayrollData(idPayroll, payload) {
    return axios.patch(`${URL_ZENTRIC}employees_payrolls/${idPayroll}/`, payload);
  },
  getPayrollMovements(idEmployee, idPayroll, page, search) {
    const params = {};
    if (page) {
      params.page = page;
    }
    if (search) {
      params.name = search;
    }
    return axios.get(`${URL_ZENTRIC}employees/${idEmployee}/list_payroll_movements/${idPayroll}/`, { params });
  },
  createPayrollMovements(idEmployee, payload) {
    return axios.post(`${URL_ZENTRIC}employees/${idEmployee}/new_payroll_movements/`, payload);
  },
  editPayrollMovements(idMovement, payload) {
    return axios.patch(`${URL_ZENTRIC}employees_payroll_movements/${idMovement}/`, payload);
  },
  getIMMSPayroll(idEmployee, idPayroll, page, search) {
    const params = {};
    if (page) {
      params.page = page;
    }
    if (search) {
      params.name = search;
    }
    return axios.get(`${URL_ZENTRIC}employees/${idEmployee}/list_payroll_imss/${idPayroll}/`, { params });
  },
  createIMMSPayroll(idEmployee, payload) {
    return axios.post(`${URL_ZENTRIC}employees/${idEmployee}/new_payroll_imss/`, payload);
  },
  editIMMSPayroll(idIMMS, payload) {
    return axios.patch(`${URL_ZENTRIC}employees_payroll_imss/${idIMMS}/`, payload);
  },
  getAffiliationMovements(idEmployee, idPayroll, page, search) {
    const params = {};
    if (page) {
      params.page = page;
    }
    if (search) {
      params.name = search;
    }
    return axios.get(`${URL_ZENTRIC}employees/${idEmployee}/list_afil_movs/${idPayroll}/`, { params });
  },
  createAffiliationMovements(idEmployee, payload) {
    return axios.post(`${URL_ZENTRIC}employees/${idEmployee}/new_afil_movs/`, payload);
  },
  editAffiliationMovements(idAffiliation, payload) {
    return axios.patch(`${URL_ZENTRIC}employees_afil_movements/${idAffiliation}/`, payload);
  },
  getInfonavitMovements(idEmployee, idPayroll, page, search) {
    const params = {};
    if (page) {
      params.page = page;
    }
    if (search) {
      params.name = search;
    }
    return axios.get(`${URL_ZENTRIC}employees/${idEmployee}/list_afil_movs/${idPayroll}/`, { params });
  },
  createInfonavitMovements(idEmployee, payload) {
    return axios.post(`${URL_ZENTRIC}employees/${idEmployee}/new_afil_movs/`, payload);
  },
  editInfonavitMovements(idInfonavit, payload) {
    return axios.patch(`${URL_ZENTRIC}employees_afil_movements/${idInfonavit}/`, payload);
  },
  // Incidents
  getIncidents(idPayroll, isIntegrated, page, search) {
    const params = {};
    if (isIntegrated) {
      params.is_integrated = isIntegrated;
    }
    if (page) {
      params.page = page;
    }
    if (search) {
      params.full_name = search;
    }
    return axios.get(`${URL_ZENTRIC}payroll/${idPayroll}/incidences/`, { params });
  },
  updateAbsenteeism(idIncident, payload) {
    return axios.patch(`${URL_ZENTRIC}incidences/${idIncident}/update_absenteeism/`, payload);
  },
  updateInability(idIncident, payload) {
    return axios.patch(`${URL_ZENTRIC}incidences/${idIncident}/update_inability/`, payload);
  },
  updateIncident(idIncident, payload) {
    return axios.patch(`${URL_ZENTRIC}incidences/${idIncident}/update_incidence/`, payload);
  },
  integrateIncidents(incidencesIds = []) {
    return axios.post(`${URL_ZENTRIC}incidences/integrate/`, {
      ids: incidencesIds
    }).then(({ data }) => data);
  },
  // Settings Incidents
  getIncidentSettings(idPayroll) {
    return axios.get(`${URL_ZENTRIC}payroll/${idPayroll}/incidences_configuration/`).then(({ data }) => data);
  },
  updateIncidentSettings(payload) {
    return axios.post(`${URL_ZENTRIC}payroll/set_incidences_configuration/`, payload);
  },
  // First steps
  getFirstSteps() {
    return axios.get(`${URL_ZENTRIC}first_steps/`).then(({ data }) => data);
  },
  updateFirstSteps(payload) {
    return axios.patch(`${URL_ZENTRIC}first_steps/`, payload);
  },
  // Massive Edition
  getAllCollaboratorsWithPayroll() {
    return axios.get(`${URL_ZENTRIC}employees/with_payrolls/`);
  },
  saveAllCollaboratorsWithPayroll(payload) {
    return axios.post(`${URL_ZENTRIC}employees/massive_payroll_creation/`, payload);
  },
  // Complete Payroll
  getPayrollCompleted() {
    return axios.get(`${URL_ZENTRIC}employees/payroll_completed/`);
  },
  createPayrollInWorky(payroll) {
    return axios.post("/api/v1/integrations/zentric/payroll/", payroll);
  },
  updatePayrollInWorky(payroll) {
    return axios.put(`/api/v1/integrations/zentric/payroll/${payroll.id}/`, payroll);
  },
  getPayrollsInWorky() {
    return axios.get("/api/v1/integrations/zentric/payroll/?paginate=false").then(({ data }) => data);
  },
  getPayrollInWorky(payrollId) {
    return axios.get(`/api/v1/integrations/zentric/payroll/${payrollId}/`).then(({ data }) => data);
  },
  getIncidencesPayrollInWorky(payrollId, isIntegrated = false) {
    return axios.get(`/api/v1/integrations/zentric/payroll/${payrollId}/incidences/?${isIntegrated ? "is_integrated=true" : ""}`).then(({ data }) => data);
  },
  getEmployeesWithPayrolls(options = {}) {
    const keys = Object.keys(options);
    const queryText = keys.map((key) => `${key}=${options[key]}`).join("&");
    const query = keys.length > 0 ? `?${queryText}` : "";
    return axios.get(`/api/v1/integrations/zentric/employees/with_payrolls/${query}`).then(({ data }) => data);
  },
  saveMassivePayrollMovements(movements) {
    return apiPost("employees/payroll-movements/", movements);
  },
  saveMassivePayrollIMSSMovements(movements) {
    return apiPost("employees/payroll-imss-data/", movements);
  },
  saveMassiveAffiliationMovements(movements) {
    return apiPost("affiliationmovements/afil-movs/", movements);
  },
  syncPayrollData() {
    return axios.get("/api/v1/integrations/zentric/employees/sync_zentric/");
  },
  syncPayrolls() {
    return axios.get("api/v1/integrations/zentric/payroll/sync_zentric/");
  },
  getLeavingMotives() {
    return apiGet(`/employees/leaving-motive/`).then(({ data }) => validateArray(data));
  },
  createZPayrollsData(payrollsData) {
    return apiPost(`employees/payroll-data/`, payrollsData);
  },
  getIncidencesInPayrollStats({ payroll, start_date, end_date }) {
    return axios.get(`/api/v1/integrations/zentric/payroll/${payroll}/integrated_incidences/?start_date=${start_date}&end_date=${end_date}`).then(({ data }) => data);
  },
  updatePayrollDataInactive(employeeId) {
    return axios.post(`/api/v1/integrations/zentric/employees/${employeeId}/payroll_inactive/`);
  },
  getAccumulatedReport(filter) {
    return apiPost(`/reports/accumulated/filter/`, filter).then(({ data }) => data);
  },
  cancelIncidences(incidencesIds) {
    return axios.post("/api/v1/integrations/zentric/incidences/cancel/", { ids: incidencesIds }).then(({ data }) => data);
  },
  deleteIncidences(incidencesIds) {
    return axios.post("api/v1/integrations/zentric/incidences/massive_delete/", { ids: incidencesIds }).then(({ data }) => data);
  },
  getSummaryIncidences(options) {
    const query = Object.keys(options).map((option) => `${option}=${options[option]}`).join("&");
    return axios.get(`/api/v1/integrations/zentric/incidences/count_integrated/?${query}`).then(({ data }) => data);
  },
  getSummaryTotalIncidences(dates) {
    const query = {
      end_date_gte: dates[0],
      start_date_lte: dates[1]
    };
    return axios.get("/api/v1/integrations/zentric/incidences/total_pending/", { params: query }).then(({ data }) => data);
  },
  getClockIncidencesPending(query) {
    return axios.get("/api/v1/integrations/zentric/incidences/cloko_pending/", { params: query }).then(({ data }) => data);
  },
  getIncidencesPending(query) {
    return axios.get("/api/v1/integrations/zentric/incidences/sandi_pending/", { params: query }).then(({ data }) => data);
  }
};
export default zentric;
