const accentMap = {
  \u00E0: "a",
  \u00E1: "a",
  \u00E2: "a",
  \u00E3: "a",
  \u00E4: "a",
  \u00E5: "a",
  \u00E7: "c",
  \u00E8: "e",
  \u00E9: "e",
  \u00EA: "e",
  \u00EB: "e",
  \u00EC: "i",
  \u00ED: "i",
  \u00EE: "i",
  \u00EF: "i",
  \u00F1: "n",
  \u00F2: "o",
  \u00F3: "o",
  \u00F4: "o",
  \u00F5: "o",
  \u00F6: "o",
  \u00F8: "o",
  \u00DF: "s",
  \u00F9: "u",
  \u00FA: "u",
  \u00FB: "u",
  \u00FC: "u",
  \u00FF: "y"
};
export default function(string) {
  if (!string) {
    return "";
  }
  let newString = "";
  for (let i = 0; i < string.length; i += 1) {
    const currentLetter = string.charAt(i);
    const upper = currentLetter === currentLetter.toUpperCase();
    const newLetter = accentMap[currentLetter.toLowerCase()] || currentLetter;
    newString += upper ? newLetter.toUpperCase() : newLetter;
  }
  return newString;
}
