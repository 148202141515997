import actions from "./actions";
const state = {
  list: [],
  former: []
};
export default {
  state,
  actions,
  namespaced: true
};
