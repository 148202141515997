export default {
  namespaced: true,
  state: {
    flows: []
  },
  getters: {
    availableFlows: (state, getters, rootState, rootGetters) => {
      const requestableLegacy = rootGetters["company/incidences/myLegacyRequestableRequests"];
      const legacyList = rootGetters["company/incidences/formatedActiveLegacyIncidencesSettings"];
      const legacyTypes = legacyList.reduce((types, li) => {
        const filterFlows = state.flows.filter((flow) => flow.incidence_type === li.type);
        if (filterFlows.length === 2)
          return [...types];
        return [...types, {
          id: null,
          type: li.type,
          identifier: li.type,
          requestable: !!requestableLegacy.find((req) => req.type === li.type),
          name: li.name,
          incidencesFlowSet: !!filterFlows.find((flow) => flow.apply_to === "incidences"),
          requestsFlowSet: !requestableLegacy.find((req) => req.type === li.type) ? false : !!filterFlows.find((fl) => fl.apply_to === "requests")
        }];
      }, []);
      const requestableCust = rootGetters["company/incidences/myCustomRequestableRequests"];
      const customList = rootGetters["company/incidences/formatedCustomSettings"];
      const custFlows = state.flows.filter((fl) => fl.incidence_type === "CUST");
      const customTypes = customList.filter((cust) => cust.active).reduce((custTypes, cust) => {
        const filterCustFlows = custFlows.filter((flow) => flow.custom_incidence_template === cust.id);
        if (filterCustFlows.length === 2)
          return [...custTypes];
        return [...custTypes, {
          type: "CUST",
          template: cust.id,
          identifier: cust.id,
          requestable: !!requestableCust.find((req) => req.id === cust.id),
          id: cust.id,
          name: cust.name,
          incidencesFlowSet: !!filterCustFlows.find((flow) => flow.apply_to === "incidences"),
          requestsFlowSet: requestableCust.find((req) => req.id === cust.template) ? false : !!filterCustFlows.find((fl) => fl.apply_to === "requests")
        }];
      }, []);
      return legacyTypes.concat(customTypes);
    }
  },
  mutations: {
    setFlows(state, payload) {
      state.flows = payload;
    }
  },
  actions: {}
};
