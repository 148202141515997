var render = function render2() {
  var _vm = this, _c = _vm._self._c, _setup = _vm._self._setupProxy;
  return _c("div", { ref: "colorPicker", staticClass: "color-picker", class: {
    "color-picker--is-focus": _setup.isFocus
  } }, [_c("div", { staticClass: "color-picker__control" }, [_c("div", { staticClass: "color-picker__preview", style: {
    backgroundColor: _setup.model
  }, on: { "click": _setup.setFocus } }), _c("input", { directives: [{ name: "model", rawName: "v-model", value: _setup.model, expression: "model" }], ref: "colorPickerInput", domProps: { "value": _setup.model }, on: { "focus": _setup.onFocus, "input": function($event) {
    if ($event.target.composing)
      return;
    _setup.model = $event.target.value;
  } } })]), _setup.isFocus ? _c("div", { staticClass: "color-picker__box" }, [_c(_setup.Chrome, { attrs: { "value": _setup.model, "disable-alpha": _vm.disableAlpha }, on: { "input": _setup.onInput } })], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
