var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { api } from "@/api";
import * as types from "./mutations-types";
export default {
  getTrainings(_0, _1) {
    return __async(this, arguments, function* ({ commit }, params) {
      try {
        commit(types.START_LOADING);
        const { data } = yield api.trainings.get(params);
        commit(types.GET_TRAININGS, data);
      } catch (error) {
        console.log(error);
      } finally {
        commit(types.STOP_LOADING);
      }
    });
  },
  getOneTraining(_0, _1) {
    return __async(this, arguments, function* ({ commit }, id) {
      let response;
      try {
        commit(types.START_LOADING);
        response = yield api.trainings.getOne(id);
      } catch (error) {
        console.log(error);
      } finally {
        commit(types.STOP_LOADING);
      }
      return response.data;
    });
  },
  createTraining(_0, _1) {
    return __async(this, arguments, function* ({ commit }, training) {
      try {
        commit(types.START_LOADING);
        const { data } = yield api.trainings.create(training);
        commit(types.CREATE_TRAINING, data);
      } catch (error) {
        console.log(error);
      } finally {
        commit(types.STOP_LOADING);
      }
    });
  },
  editTraining(_0, _1) {
    return __async(this, arguments, function* ({ commit }, training) {
      try {
        commit(types.START_LOADING);
        const { data } = yield api.trainings.update(training.id, training);
        commit(types.EDIT_TRAINING, data);
      } catch (error) {
        console.log(error);
      } finally {
        commit(types.STOP_LOADING);
      }
    });
  },
  updateTraining(_0, _1) {
    return __async(this, arguments, function* ({ commit }, trainingId) {
      try {
        const { data: training } = yield api.trainings.getOne(trainingId);
        delete training.sections;
        commit(types.EDIT_TRAINING, training);
      } catch (error) {
        console.log(error);
      }
    });
  },
  archiveTraining(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, training) {
      try {
        commit(types.START_LOADING);
        const { data } = yield api.trainings.archive(training.id);
        const itemIndex = state.trainings.results.findIndex((t) => t.id === training.id);
        commit(types.ARCHIVE_TRAINING, { index: itemIndex, training: data });
      } catch (error) {
        console.log(error);
      } finally {
        commit(types.STOP_LOADING);
      }
    });
  },
  unarchiveTraining(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, training) {
      try {
        commit(types.START_LOADING);
        const { data } = yield api.trainings.unarchive(training.id);
        const itemIndex = state.trainings.results.findIndex((t) => t.id === training.id);
        commit(types.UNARCHIVE_TRAINING, { index: itemIndex, training: data });
      } catch (error) {
        console.log(error);
      } finally {
        commit(types.STOP_LOADING);
      }
    });
  },
  cloneTraining(_0, _1) {
    return __async(this, arguments, function* ({ commit }, training) {
      try {
        commit(types.START_LOADING);
        const { data } = yield api.trainings.clone(training.id);
        commit(types.CLONE_TRAINING, data);
      } catch (error) {
        console.log(error);
      } finally {
        commit(types.STOP_LOADING);
      }
    });
  },
  deleteTraining(_0, _1) {
    return __async(this, arguments, function* ({ commit }, training) {
      try {
        commit(types.START_LOADING);
        yield api.trainings.delete(training.id);
        commit(types.DELETE_TRAINING, training);
      } catch (error) {
        console.log(error);
      } finally {
        commit(types.STOP_LOADING);
      }
    });
  },
  inviteToTraining(_0, _1) {
    return __async(this, arguments, function* ({ commit }, payload) {
      try {
        const { data: training } = yield api.trainings.invite(payload.id, {
          guests_ids: payload.guests_ids,
          limit: payload.limit
        });
        commit(types.EDIT_TRAINING, training);
      } catch (error) {
        console.log(error);
        Promise.reject(error);
      }
    });
  },
  getEmployeesInProcess(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { id, params }) {
      try {
        commit(types.START_LOADING);
        const { data } = yield api.trainings.detail.get(id, params);
        commit(types.SET_GUESTS_IN_PROCESS, data);
      } catch (error) {
        console.log(error);
      } finally {
        commit(types.STOP_LOADING);
      }
    });
  },
  getFinishedEmployees(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { id, params }) {
      try {
        commit(types.START_LOADING);
        const { data } = yield api.trainings.detail.get(id, params);
        commit(types.SET_GUESTS_FINISHED, data);
      } catch (error) {
        console.log(error);
      } finally {
        commit(types.STOP_LOADING);
      }
    });
  },
  finishAsHra(_0, _1) {
    return __async(this, arguments, function* ({ commit }, guestsIds) {
      try {
        yield api.trainings.finish({ guests_ids: guestsIds });
        commit(types.REMOVE_GUESTS_IN_PROCESS, guestsIds);
      } catch (error) {
        console.log(error);
      }
    });
  },
  // BOSS
  getTeamTrainings(_0, _1) {
    return __async(this, arguments, function* ({ commit }, params) {
      try {
        commit(types.START_LOADING);
        const { data } = yield api.trainings.as_boss.get(params);
        commit(types.SET_TEAM_TRAININGS, data);
      } catch (error) {
        console.log(error);
      } finally {
        commit(types.STOP_LOADING);
      }
    });
  },
  getTeamEmployeesInProcess(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { id, params }) {
      try {
        commit(types.START_LOADING);
        const { data } = yield api.trainings.as_boss.detail.get(id, params);
        commit(types.SET_GUESTS_IN_PROCESS, data);
      } catch (error) {
        console.log(error);
      } finally {
        commit(types.STOP_LOADING);
      }
    });
  },
  getFinishedTeamEmployees(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { id, params }) {
      try {
        const defaultParams = { status_in: "2,4" };
        const buildParams = Object.assign(defaultParams, params);
        commit(types.START_LOADING);
        const { data } = yield api.trainings.as_boss.detail.get(id, buildParams);
        commit(types.SET_GUESTS_FINISHED, data);
      } catch (error) {
        console.log(error);
      } finally {
        commit(types.STOP_LOADING);
      }
    });
  },
  updateTeamTraining(_0, _1) {
    return __async(this, arguments, function* ({ commit }, trainingId) {
      try {
        const { data: training } = yield api.trainings.as_boss.getOne(trainingId);
        delete training.sections;
        commit(types.EDIT_TEAM_TRAINING, training);
      } catch (error) {
        console.log(error);
      }
    });
  },
  // EMPLOYEE
  getMyTrainings(_0) {
    return __async(this, arguments, function* ({ commit }, params = {
      status_in: "0,1"
    }) {
      try {
        commit(types.START_LOADING);
        const { data } = yield api.trainings.employee.get(params);
        commit(types.SET_MY_TRAININGS, data);
      } catch (error) {
        console.log(error);
      } finally {
        commit(types.STOP_LOADING);
      }
    });
  },
  getMyCompletedTrainings(_0) {
    return __async(this, arguments, function* ({ commit }, params = {
      status_in: "2,3,4"
    }) {
      try {
        commit(types.START_LOADING);
        const { data } = yield api.trainings.employee.get(params);
        commit(types.SET_MY_COMPLETED_TRAININGS, data);
      } catch (error) {
        console.log(error);
      } finally {
        commit(types.STOP_LOADING);
      }
    });
  },
  markTask(_0, _1) {
    return __async(this, arguments, function* ({ commit }, task) {
      let response;
      try {
        const { data: trainingUpdated } = yield api.trainings.employee.markTask(task.id, {
          done: task.done
        });
        response = trainingUpdated;
        commit(types.UPDATE_MY_TRAINING, trainingUpdated);
      } catch (error) {
        console.log(error);
      }
      return response;
    });
  },
  finishTraining(_0, _1) {
    return __async(this, arguments, function* ({ commit, dispatch }, payload) {
      try {
        commit(types.START_LOADING);
        const { data } = yield api.trainings.employee.finish(payload.id, {
          individual_score: payload.individual_score,
          comment: payload.comment
        });
        if (data) {
          dispatch("getMyTrainings");
          dispatch("getMyCompletedTrainings");
        }
      } catch (error) {
        console.log(error);
      } finally {
        commit(types.STOP_LOADING);
      }
    });
  }
};
