import Model from "@/models/model";
export default class Document extends Model {
  static get modelName() {
    return "Document";
  }
  static apiNamespace() {
    return "api/v2";
  }
  static resourceNamespace() {
    return "documents";
  }
  static retrive(id, custom) {
    if (custom) {
      return this.get(`/api/v2/employees/custom_documents/file/${id}/`, { responseType: "arraybuffer" });
    }
    return this.get(`${this.apiPath()}/${id}/`);
  }
}
