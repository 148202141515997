var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "numeric", class: {
    "numeric--is-focus": _vm.hasFocus,
    "numeric--disabled": _vm.disabled,
    [`numeric--${_vm.size}`]: _vm.size !== "normal"
  } }, [_c("input", { directives: [{ name: "model", rawName: "v-model", value: _vm.internalValueComputed, expression: "internalValueComputed" }], staticClass: "numeric__control", attrs: { "disabled": _vm.disabled, "type": "text", "placeholder": _vm.placeholder }, domProps: { "value": _vm.internalValueComputed }, on: { "keypress": _vm.onKeyPress, "focus": _vm.onFocus, "blur": _vm.onBlur, "input": function($event) {
    if ($event.target.composing)
      return;
    _vm.internalValueComputed = $event.target.value;
  } } }), _vm.showSteps ? _c("div", { staticClass: "numeric__steps" }, [_c("Icon", { staticClass: "numeric__step", class: { "numeric__step--disabled": _vm.max && _vm.max === _vm.internalValueComputed }, attrs: { "icon": "chevron-up" }, on: { "click": _vm.stepperPlus } }), _c("Icon", { staticClass: "numeric__step", class: { "numeric__step--disabled": _vm.min && _vm.min === _vm.internalValueComputed }, attrs: { "icon": "chevron-down" }, on: { "click": _vm.stepperMinus } })], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
