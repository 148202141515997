var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("svg", _vm._g(_vm._b({ staticClass: "worky-icon", class: [_vm.classesFromIconProp, { "worky-icon--is-spin": _vm.spin }], attrs: { "name": _vm.icon, "aria-hidden": "true", "focusable": "false", "role": "img", "xmlns": "http://www.w3.org/2000/svg", "viewBox": _vm.viewbox }, domProps: { "innerHTML": _vm._s(_vm.iconoSVG) }, on: { "mouseover": function($event) {
    _vm.over = true;
  }, "mouseleave": function($event) {
    _vm.over = false;
  } } }, "svg", __spreadValues({}, _vm.$attrs), false), _vm.$listeners));
};
var staticRenderFns = [];
export { render, staticRenderFns };
