var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "week-selector" }, _vm._l(_vm.days, function(day) {
    return _c("AppCheckbox", { key: day.name, attrs: { "name": day.name, "label": day.label, "value": _vm.value[day.name], "disabled": _vm.disabled || _vm.disabledDays[day.name], "bubble": "", "negative": _vm.negative }, on: { "change": function($event) {
      return _vm.change(day.name, $event);
    } } });
  }), 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
