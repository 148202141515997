import Model from "@/models/v1/model";
export default class Request extends Model {
  static get modelName() {
    return "Request";
  }
  static apiNamespace() {
    return "api/v1";
  }
  static resourceNamespace() {
    return "requests";
  }
  static fields() {
    return [
      "id",
      "employee",
      "comments",
      "start_date",
      "end_date",
      "type",
      "quantity",
      "with_salary",
      "status",
      "reviewer",
      "reviewer_at",
      "requested",
      "file"
    ];
  }
  static defaults() {
    return {
      note: "",
      file: []
    };
  }
  approve() {
    return this.constructor.post(`${this.resourcePath()}/approve/`);
  }
  reject(params) {
    return this.constructor.post(`${this.resourcePath()}/reject/`, params);
  }
  remind() {
    return this.constructor.post(`${this.resourcePath()}/remind/`);
  }
  update(data) {
    return this.constructor.put(`${this.resourcePath()}/`, data);
  }
  addFile(data) {
    const formData = new FormData();
    formData.append("file", data.file);
    return this.constructor.post(`${this.resourcePath()}/files/`, formData, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  }
  deleteFile(idFile) {
    return this.constructor.delete(
      `${this.resourcePath()}/files/${idFile}/`
    );
  }
}
