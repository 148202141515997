var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "data-table bg-white", class: `data-table--${_vm.theme}`, on: { "scroll": _vm.onScrollTable } }, [_vm.filteredItems.length <= 0 && _vm.searchTerms.length === 0 ? _c("div", { staticClass: "data-table__empty" }, [_c("div", [_c("IncidenceEmptyIcon")], 1), _c("div", { staticClass: "data-table__empty--title" }, [_vm._v(" " + _vm._s(_vm.emptyTitle) + " ")]), _c("div", { staticClass: "data-table__empty--body" }, [_vm._v(" Intenta cambiar los filtros ")])]) : _vm.filteredItems.length <= 0 && _vm.searchTerms.length >= 1 ? _c("div", { staticClass: "data-table__empty" }, [_c("div", [_c("SearchIcon")], 1), _c("div", { staticClass: "data-table__empty--title" }, [_vm._v(" Sin resultados ")]), _c("div", { staticClass: "data-table__empty--body" }, [_vm._v(" No se han encontrado resultados de tu b\xFAsqueda ")])]) : _c("table", { ref: "dataTable" }, [_c("thead", { staticClass: "data-table__head" }, [_c("tr", [_vm.selectable ? _c("td", { staticClass: "data-table__header" }, [_c("span", [_c("input", { attrs: { "type": "checkbox" }, domProps: { "checked": _vm.haveSelectedItems }, on: { "click": _vm.toggleGlobalSelection } })])]) : _vm._e(), _vm._l(_vm.headers, function(header, index) {
    return _c("td", { key: header.field, class: {
      "position-stiky": index === 0,
      "data-table__header": index !== 0
    } }, [_c("span", { staticClass: "data-table__header sortable", class: { "sorted": !header.sorted }, on: { "click": function($event) {
      return _vm.sortBy(header);
    } } }, [typeof header.haserror !== "undefined" && header.haserror ? _c("span", { staticClass: "smallAlert" }, [_c("Alert-Small")], 1) : _vm._e(), _vm._v(" " + _vm._s(header.title) + " "), header.help ? _c("div", { staticClass: "help" }, [_c("BricksIconTooltip", { attrs: { "text": header.help } }, [_c("div", { staticClass: "icon-help", attrs: { "slot": "icon" }, slot: "icon" }, [_c("div", { staticClass: "icon" }, [_c("IconHelp")], 1)])])], 1) : _vm._e(), header.sortable !== false ? _c("DownIcon", { staticClass: "icon-down" }) : _vm._e()], 1)]);
  }), _vm.withActions ? _c("td", { staticClass: "data-table__header data-table__actions" }, [_c("span", [_vm._v(" Acciones ")])]) : _vm._e()], 2)]), _c("tbody", { staticClass: "data-table__body" }, _vm._l(_vm.filteredItems, function(item, index) {
    return _c("tr", { key: item.id && item.id !== "" && item.id || index, staticClass: "data-table__row", class: { "data-table__row--invalid": item.validate && !item.valid } }, [_vm.selectable ? _c("td", { staticClass: "data-table__item" }, [_c("input", { attrs: { "type": "checkbox" }, domProps: { "checked": _vm.selected.find((_item) => _item.id === item.id) }, on: { "click": function($event) {
      return _vm.toggleSelection(item);
    } } })]) : _vm._e(), _vm._l(_vm.data.headers, function(header) {
      return _c("td", { key: header.field, staticClass: "data-table__item" }, [_vm._t("default", function() {
        return [_vm._v(" " + _vm._s(item[header.field]) + " ")];
      }, { "item": item, "field": header.field, "index": index })], 2);
    }), _vm.withActions ? _c("td", { staticClass: "data-table__item data-table__actions" }, [_vm._t("actions", null, { "item": item })], 2) : _vm._e()], 2);
  }), 0)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
