import Model from "@/models/model";
export default class CustomIncidenceToogleTemplate extends Model {
  static get modelName() {
    return "CustomIncidenceToogleTemplate";
  }
  static apiNamespace() {
    return "api/v2";
  }
  static resourceNamespace() {
    return "custom_incidences_template";
  }
  static fields() {
    return [
      "id",
      "active"
    ];
  }
  static default() {
    return "";
  }
  toogle() {
    const path = `${this.constructor.apiNamespace()}/${this.constructor.resourceNamespace()}`;
    return this.constructor.put(`${path}/${this.id}/active/`, this).then((data) => this.setFields(data));
  }
}
