export default {
  setLocations(state, { locations }) {
    state.list = locations;
  },
  setLocation(state, { location }) {
    const { id } = location;
    const newList = [...state.list];
    const index = newList.findIndex((_location) => _location.id === id);
    if (index >= 0) {
      newList[index] = location;
    } else {
      newList.push(location);
    }
    state.list = newList;
  }
};
