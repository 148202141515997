import Model from "@/models/model";
export default class CustomIncidenceNewTemplate extends Model {
  static get modelName() {
    return "CustomIncidenceNewTemplate";
  }
  static apiNamespace() {
    return "api/v2";
  }
  static resourceNamespace() {
    return "custom_incidences_template";
  }
  static fields() {
    return [
      "id",
      "active",
      "company",
      "name",
      "description",
      "legacy_incidence_type",
      "incidence_category",
      "requestable",
      "date_range",
      "temporality",
      "amount",
      "quantity",
      "duration",
      "identifier",
      "incidence_file",
      "is_duplicated",
      "template_base",
      "apply_to",
      "areas",
      "locations",
      "use_vacation_balance"
    ];
  }
  static default() {
    return "";
  }
  get() {
    return this.constructor.get(`${this.resourcePath()}`);
  }
  retrieveCustomIncidence(idCustomIncidence) {
    return this.constructor.get(`${this.resourcePath()}${idCustomIncidence}`);
  }
  delete(idCustomIncidence) {
    return this.constructor.delete(`${this.resourcePath()}${idCustomIncidence}`);
  }
}
