export default {
  setUserData(state, payload) {
    state.data = payload;
  },
  setUserEmployee(state, { data }) {
    state.employee = data;
  },
  setPath(state, path) {
    state.path = path;
  },
  clearPath(state, path) {
    state.path = void 0;
  }
};
