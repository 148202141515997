export default {
  setEmployees(state, { data }) {
    state.list = data;
  },
  setEmployeesWithPolicies(state, { data }) {
    state.listWithPolicies = data;
  },
  setEmployeeWithPolicies(state, { data }) {
    const { id } = data;
    const index = state.listWithPolicies.findIndex((employee) => employee.id === id);
    const newList = [...state.listWithPolicies];
    if (index >= 0) {
      newList[index] = data;
    } else {
      newList.push(data);
    }
    state.listWithPolicies = newList;
  },
  setLaidOffEmployees(state, { data }) {
    state.former = data;
  },
  setEmployee(state, { data }) {
    const { id } = data;
    const index = state.list.findIndex((employee) => employee.id === id);
    const newList = [...state.list];
    if (index >= 0) {
      newList[index] = data;
    } else {
      newList.push(data);
    }
    state.list = newList;
  },
  removeEmployee(state, { id }) {
    state.list = state.list.filter((employee) => employee.id !== id);
  },
  setProfile(state, profile) {
    state.profile = profile;
  },
  setGettingState(state, status) {
    state.gettingState = status;
  }
};
