var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { capitalize, capitalizeFirstLetter } from "@/models/utils";
import Model from "@/models/model";
export default class Employee extends Model {
  constructor() {
    super(...arguments);
    __publicField(this, "onboard", () => this.constructor.post(`${this.constructor.apiNamespace()}/companies/${this.company}/onboarding_list/`, this));
    __publicField(this, "invite", () => this.constructor.post(`${this.resourcePath()}/invite/`));
    __publicField(this, "setLaidOff", () => this.constructor.post(`${this.resourcePath()}/laid_off/`, this));
    __publicField(this, "getVacancies", () => this.constructor.get(`${this.resourcePath()}/vacancies/`));
    __publicField(this, "acceptTerms", () => this.constructor.post(`${this.resourcePath()}/accept_terms/`, { terms_accepted: true }).then((employee) => new Employee(employee)));
  }
  static get modelName() {
    return "Employee";
  }
  static apiNamespace() {
    return "api/v2";
  }
  static resourceNamespace() {
    return "employees";
  }
  static fields() {
    return [
      "first_name",
      "last_name",
      "last_name2",
      "full_name",
      "email",
      "notification_email",
      "bossName",
      "neighborhood",
      "locality",
      "country",
      "entry_time",
      "birthplace",
      "work_puesto",
      "id",
      "nom_employee_id",
      "company",
      "is_boss",
      "days_off",
      "active",
      "invited",
      "laid_off",
      "is_onboarded",
      "is_integrated",
      "level",
      "location",
      "business_unit",
      "street",
      "post_code",
      "state",
      "birthdate",
      "nationality",
      "sex",
      "estado_civil",
      "nivel_de_estudios",
      "personal_email",
      "personal_phone",
      "personal_mobile",
      "contact_name",
      "contact_relationship",
      "contact_email",
      "contact_phone",
      "contact_cellphone",
      "work_phone",
      "work_phone_ext",
      "work_mobile",
      "work_place",
      "work_start_date",
      "work_end_date",
      "work_antiguedad_date",
      "work_employee_number",
      "salary_net",
      "salary_gross",
      "salary_contract_duration",
      "salary_esquema_de_nomina",
      "salary_outsourcing",
      "salary_adjustment_date",
      "salary_bank_name",
      "salary_bank_account",
      "salary_bank_clabe",
      "documents_clave_de_elector",
      "documents_rfc",
      "documents_imss",
      "documents_curp",
      "documents_drivers_licence",
      "documents_passport",
      "documents_pld",
      "other_antiguedad",
      "other_campus",
      "other_rr_number",
      "is_recruiter",
      "is_recruitment_visitor",
      "razon_social",
      "url_username",
      "user",
      "username",
      "role",
      "area",
      "boss",
      "benefits",
      "vacations_policy",
      "pto_policy",
      "boss_email",
      "avatar",
      "laid_off_reason",
      "laid_off_comments",
      "is_analyzer",
      "is_evaluator",
      "time_clock",
      "time_clockV3",
      "terms_accepted",
      "terms_accepted_date",
      "completed_profile",
      "work_temporal_worker",
      "is_documents_info_complete",
      "is_salary_info_complete",
      "is_bank_info_complete",
      "is_personal_info_complete",
      "is_prepayroll_admin",
      "is_work_info_complete",
      "is_profile_complete",
      "missing_data",
      "count_missing_data",
      "fiscal_postal_code",
      "fiscal_postal_code_key",
      "is_organization_user"
    ];
  }
  static default() {
    return "";
  }
  static defaults() {
    return {
      active: false,
      invited: false,
      is_recruiter: false,
      is_recruitment_visitor: false,
      is_onboarded: false,
      days_off: null,
      laid_off: false,
      is_analyzer: false,
      nom_employee_id: null,
      is_integrated: false,
      is_evaluator: false,
      time_clock: null,
      time_clockV3: null,
      work_antiguedad_date: null,
      terms_accepted: false,
      terms_accepted_date: null,
      work_temporal_worker: false,
      salary_net: 0,
      salary_gross: 0,
      areaName: null,
      work_start_date: null
    };
  }
  get validations() {
    return {
      first_name: this.first_name && this.first_name !== "",
      last_name: this.last_name && this.last_name !== "",
      last_name2: this.last_name2 && this.last_name2 !== "",
      username: this.username && this.username !== "",
      work_start_date: this.work_start_date,
      company: this.company
    };
  }
  static capitalizedFields() {
    return [
      "areaName",
      "first_name",
      "last_name",
      "last_name2",
      "neighborhood",
      "locality",
      "country",
      "birthplace",
      "nationality"
    ];
  }
  static capitalizedFirstLetter() {
    return [
      "work_puesto"
    ];
  }
  static startCaseFields() {
    return [
      "bossName",
      "first_name",
      "last_name",
      "last_name2",
      "neighborhood",
      "locality",
      "country",
      "birthplace",
      "nationality"
    ];
  }
  setFields(data, defaults) {
    super.setFields(data, defaults);
    this.capitalizeFields();
    return this;
  }
  capitalizeFields() {
    this.constructor.capitalizedFields().forEach((field) => {
      this[field] = capitalize(this[field]);
    });
    this.constructor.capitalizedFirstLetter().forEach((field) => {
      this[field] = capitalizeFirstLetter(this[field]);
    });
    this.constructor.startCaseFields().forEach((field) => {
      this[field] = this[field] && this[field].split(" ").map((word) => capitalize(word)).join(" ");
    });
  }
  documents() {
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/documents/`),
      upload: (tag, file) => {
        const formData = new FormData();
        formData.append("tag", tag);
        formData.append("file", file, file.name);
        return this.constructor.post(`${this.resourcePath()}/documents/`, formData, {
          headers: {
            "content-type": "multipart/form-data"
          }
        });
      }
    };
  }
  setAvatar(file) {
    if (file) {
      const formData = new FormData();
      formData.append("file", file, file.name);
      return this.constructor.post(`${this.resourcePath()}/upload_avatar/`, formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      });
    }
    return this.constructor.delete(`${this.resourcePath()}/upload_avatar/`);
  }
  setRecruiter(payload) {
    return this.constructor.patch(`api/v2/employees/${this.id}/`, payload);
  }
  customDocuments() {
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/custom_documents/`),
      upload: (idDocument, file) => {
        const formData = new FormData();
        formData.append("company_custom_document", idDocument);
        formData.append("file", file, file.name);
        return this.constructor.post(`${this.resourcePath()}/custom_documents/`, formData, {
          headers: {
            "content-type": "multipart/form-data"
          }
        });
      },
      removeArchive: (fileId) => this.constructor.delete(
        `${this.resourcePath()}/custom_documents/`,
        fileId
      )
    };
  }
  payRollReceipts() {
    return {
      get: () => this.constructor.get(`${this.constructor.apiNamespace()}/payroll_receipts/`)
    };
  }
  getMyRequestableRequests(idEmployee) {
    return this.constructor.get(`/api/v2/employees/${idEmployee}/available_requests/`);
  }
  getMyRequestableRequests_V2(idEmployee, _, options) {
    const query = Object.keys(options).map((key) => `${key}=${options[key]}`).join("&");
    return this.constructor.get(`/api/v2/employees/${idEmployee}/available_request_v2_front/?${query}`);
  }
}
