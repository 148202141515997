var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { api } from "@/api";
export default {
  getPayrollReceipts(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      try {
        const hasReceiver = true;
        const { data } = yield api.payrollReceipts.company.getPayrollReceipts(hasReceiver);
        const payrollReceipts = data.results;
        payrollReceipts.forEach((element) => {
          element.full_name = element.receiver_employee.full_name;
        });
        commit("setPayrollReceipts", { data: payrollReceipts });
        return data;
      } catch (e) {
        commit("setPayrollReceipts", { data: [] });
        throw e;
      }
    });
  },
  getPayrollReceiptsWithPage(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, params) {
      try {
        const has = params.receiver;
        const { data } = yield api.payrollReceipts.company.getPayrollReceiptsByPage(has, params);
        const payrollReceipts = data.results;
        params.receiver ? commit("setPayrollReceipts", { data: payrollReceipts }) : commit("setUnAssignedPayrollReceipts", { data: payrollReceipts });
        return data;
      } catch (e) {
        params.receiver ? commit("setPayrollReceipts", { data: [] }) : commit("setUnAssignedPayrollReceipts", { data: [] });
        throw e;
      }
    });
  },
  getPayrollReceiptsWithPeriod(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, params) {
      try {
        const hasReceiver = params.receiver;
        const { data } = yield api.payrollReceipts.company.getPayrollReceiptsByPeriod(hasReceiver, params);
        const payrollReceipts = data.results;
        params.receiver ? commit("setPayrollReceipts", { data: payrollReceipts }) : commit("setUnAssignedPayrollReceipts", { data: payrollReceipts });
        return data;
      } catch (e) {
        params.receiver ? commit("setPayrollReceipts", { data: [] }) : commit("setUnAssignedPayrollReceipts", { data: [] });
        throw e;
      }
    });
  },
  getUnassignedPayrollReceipts(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      try {
        const hasReceiver = false;
        const { data } = yield api.payrollReceipts.company.getPayrollReceipts(hasReceiver);
        const payrollReceipts = data.results;
        commit("setUnAssignedPayrollReceipts", { data: payrollReceipts });
        return data;
      } catch (e) {
        commit("setUnAssignedPayrollReceipts", { data: [] });
        throw e;
      }
    });
  },
  getEmployeePayrollReceipts(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, { id }) {
      const { data } = yield api.payrollReceipts.employee.getPayrollReceipts();
      return data;
    });
  },
  uploadPayrollReceiptFile(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, { file }) {
      try {
        const response = yield api.payrollReceipts.company.uploadPayrollReceiptFile(file);
        return response;
      } catch (error) {
        throw error;
      }
    });
  }
};
