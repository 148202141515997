import getDeepFieldFromObject from "@/components/v2/utils/getDeepFieldFromObject";
export default {
  inject: {
    $v: {
      default: () => {
      }
    }
  },
  props: {
    name: {
      type: String
    },
    dataGroup: {
      type: String,
      default: ""
    },
    dataVvAs: {
      type: String,
      default: ""
    },
    dataIndex: {
      type: Number,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isControl: true,
      isDirty: false
    };
  },
  computed: {
    hasValidationInjected() {
      return !!this.$v;
    },
    validationNameField() {
      if (!this.hasValidationInjected) {
        return "";
      }
      if (this.dataIndex !== null) {
        return `${this.dataGroup}.$each[${this.dataIndex}].${this.name}`;
      }
      return [this.dataGroup, this.name].filter((item) => item).join(".");
    },
    validationElement() {
      if (!this.hasValidationInjected) {
        return null;
      }
      return getDeepFieldFromObject(this.$v, this.validationNameField);
    },
    hasError() {
      if (!this.validationElement) {
        return false;
      }
      return this.validationElement.$error;
    }
  },
  methods: {
    touchInput() {
      if (this.validationElement) {
        this.validationElement.$touch();
      }
      this.isDirty = true;
    },
    reloadParent() {
      const { $parent } = this;
      if ($parent && $parent.isField) {
        $parent.checkChildrenProperties();
      }
    }
  },
  watch: {
    required() {
      this.reloadParent();
    },
    name() {
      this.reloadParent();
    }
  }
};
