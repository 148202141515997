import { DateTime } from "luxon";
export function convertTimeFormatToMinutesEquivalent(time) {
  if (!time) {
    return 0;
  }
  const timeInMinutes = time.split(":").filter((_, index) => index <= 1).map((timePart) => Number(timePart)).map((timePart, index) => index === 0 ? timePart * 60 : timePart).reduce((prevValue, currentTimePart) => prevValue + currentTimePart, 0);
  return timeInMinutes;
}
export function convertTimeFormatToText(time) {
  const minutes = convertTimeFormatToMinutesEquivalent(time);
  return convertTimeMinutesToText(minutes);
}
export function convertTimeMinutesToText(timeAsMinutes) {
  let timeAsText = "";
  const minutesIntime = Math.abs(timeAsMinutes) % 60;
  const hoursInTime = (Math.abs(timeAsMinutes) - minutesIntime) / 60;
  if (hoursInTime > 0) {
    timeAsText += `${hoursInTime}hrs.`;
  }
  if (minutesIntime > 0 || hoursInTime === 0) {
    timeAsText += hoursInTime > 0 ? ` ${minutesIntime}m` : `${minutesIntime}m`;
  }
  return timeAsText;
}
export function convertTimeMinutesToTextWithSuffix(timeAsMinutes) {
  const timeAsText = convertTimeMinutesToText(timeAsMinutes);
  const prefix = timeAsMinutes < 0 ? "m\xE1s" : "menos";
  return `${timeAsText} ${prefix}`;
}
export function convertTimeMinutesToFormat(timeAsMinutes) {
  const minutes = timeAsMinutes % 60;
  const minutesText = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const hours = (timeAsMinutes - minutes) / 60;
  const hoursText = hours < 10 ? `0${hours}` : `${hours}`;
  return `${hoursText}:${minutesText}:00`;
}
export function reformatTime(timeInFormat, outputFormat = "HH:mm:ss", inputFormat = "hh:mm a") {
  if (!timeInFormat) {
    return null;
  }
  return DateTime.fromFormat(timeInFormat, inputFormat).toFormat(outputFormat);
}
export default {
  convertTimeFormatToMinutesEquivalent,
  convertTimeMinutesToText,
  convertTimeMinutesToTextWithSuffix,
  convertTimeMinutesToFormat,
  reformatTime
};
