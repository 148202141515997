var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "textbox", class: _vm.styleClasses }, [_vm.icon ? _c("Icon", { staticClass: "textbox__icon", attrs: { "icon": _vm.icon } }) : _vm._e(), (_vm.type === "password" ? _vm.internalType : _vm.type) === "checkbox" ? _c("input", { directives: [{ name: "model", rawName: "v-model", value: _vm.internalValueComputed, expression: "internalValueComputed" }], ref: "input", staticClass: "textbox__control", attrs: { "placeholder": _vm.placeholder, "disabled": _vm.disabled, "name": _vm.name, "required": _vm.required, "autofocus": _vm.autofocus, "maxlength": _vm.maxLength, "autocomplete": false, "type": "checkbox" }, domProps: { "checked": Array.isArray(_vm.internalValueComputed) ? _vm._i(_vm.internalValueComputed, null) > -1 : _vm.internalValueComputed }, on: { "keypress": (event) => _vm.$emit("keypress", event), "focus": _vm.onFocus, "blur": _vm.onBlur, "change": function($event) {
    var $$a = _vm.internalValueComputed, $$el = $event.target, $$c = $$el.checked ? true : false;
    if (Array.isArray($$a)) {
      var $$v = null, $$i = _vm._i($$a, $$v);
      if ($$el.checked) {
        $$i < 0 && (_vm.internalValueComputed = $$a.concat([$$v]));
      } else {
        $$i > -1 && (_vm.internalValueComputed = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
      }
    } else {
      _vm.internalValueComputed = $$c;
    }
  } } }) : (_vm.type === "password" ? _vm.internalType : _vm.type) === "radio" ? _c("input", { directives: [{ name: "model", rawName: "v-model", value: _vm.internalValueComputed, expression: "internalValueComputed" }], ref: "input", staticClass: "textbox__control", attrs: { "placeholder": _vm.placeholder, "disabled": _vm.disabled, "name": _vm.name, "required": _vm.required, "autofocus": _vm.autofocus, "maxlength": _vm.maxLength, "autocomplete": false, "type": "radio" }, domProps: { "checked": _vm._q(_vm.internalValueComputed, null) }, on: { "keypress": (event) => _vm.$emit("keypress", event), "focus": _vm.onFocus, "blur": _vm.onBlur, "change": function($event) {
    _vm.internalValueComputed = null;
  } } }) : _c("input", { directives: [{ name: "model", rawName: "v-model", value: _vm.internalValueComputed, expression: "internalValueComputed" }], ref: "input", staticClass: "textbox__control", attrs: { "placeholder": _vm.placeholder, "disabled": _vm.disabled, "name": _vm.name, "required": _vm.required, "autofocus": _vm.autofocus, "maxlength": _vm.maxLength, "autocomplete": false, "type": _vm.type === "password" ? _vm.internalType : _vm.type }, domProps: { "value": _vm.internalValueComputed }, on: { "keypress": (event) => _vm.$emit("keypress", event), "focus": _vm.onFocus, "blur": _vm.onBlur, "input": function($event) {
    if ($event.target.composing)
      return;
    _vm.internalValueComputed = $event.target.value;
  } } }), _vm.clearable && _vm.internalValueComputed ? _c("span", { staticClass: "textbox__clear", on: { "click": () => _vm.internalValueComputed = "" } }, [_c("Icon", { attrs: { "icon": "close" } })], 1) : _vm._e(), _vm.type === "password" && _vm.internalValueComputed ? _c("span", { staticClass: "textbox__password" }, [_c("Icon", { attrs: { "icon": _vm.show ? "eye-hide" : "eye-show" }, on: { "click": () => _vm.show = !_vm.show } })], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
