var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const state = {
  "nps": false,
  "payment_expired": false
};
const actions = {
  toggleStatusBanner({ commit }, { key, value }) {
    let tempStorage = JSON.parse(localStorage.getItem("banners"));
    commit("setStatus", { value, key });
    tempStorage = __spreadProps(__spreadValues({}, tempStorage), { [`${key}`]: value });
    localStorage.setItem("banners", JSON.stringify(tempStorage));
  },
  setBanners({ commit }, features) {
    const key = Object.keys(state);
    let stateObject = {};
    key.forEach((item) => {
      features == null ? void 0 : features.find((f) => {
        if (f.feature.includes(item)) {
          commit("setStatus", { value: f.active, key: item });
          stateObject = __spreadProps(__spreadValues({}, stateObject), { [`${item}`]: f.active });
        }
      });
    });
    localStorage.setItem("banners", JSON.stringify(stateObject));
  },
  setStorage({ commit }, stringBanners) {
    const prevState = JSON.parse(stringBanners);
    const key = Object.keys(state);
    key.forEach((item) => {
      commit("setStatus", { value: prevState[`${item}`], key: item });
    });
  }
};
const mutations = {
  setStatus(state2, { value, key }) {
    state2[`${key}`] = value;
  }
};
const getters = {
  "nps": (state2) => state2.nps,
  "payment_expired": (state2) => state2.payment_expired
};
export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
};
