import Model from "@/models/model";
export default class Notification extends Model {
  static get modelName() {
    return "Notification";
  }
  static apiNamespace() {
    return "api/v1";
  }
  static resourceNamespace() {
    return "new_notifications";
  }
  static default() {
    return "";
  }
  static fields() {
    return [
      "pk",
      "recipient",
      "action_object",
      "target",
      "timestamp",
      "unread",
      "level",
      "sender",
      "data"
    ];
  }
  notifications() {
    return {
      create: () => this.constructor.post(`${this.resourcePath()}test_create/`),
      get: () => this.constructor.get(`${this.resourcePath()}`),
      getCount: () => this.constructor.get(`${this.resourcePath()}count/`),
      update: (data) => this.constructor.post(`${this.resourcePath()}${data.id}/mark_as_read/`)
    };
  }
}
