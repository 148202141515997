var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return !_vm.multiple ? _c("div", { staticClass: "radio", class: ["radio--" + _vm.mode] }, _vm._l(_vm.options, function(option, index) {
    return _c("label", { key: option[_vm.keyField], staticClass: "flex items-center radio__item", class: {
      "radio__item--active": option[_vm.keyField] === _vm.value,
      "radio__item--inline": _vm.inline,
      "disabled": _vm.disabled
    }, attrs: { "for": _vm.internalName + "-" + index } }, [_c("input", { directives: [{ name: "model", rawName: "v-model", value: _vm.internalModelComputed, expression: "internalModelComputed" }], staticClass: "radio__input", attrs: { "id": _vm.internalName + "-" + index, "type": "radio", "name": _vm.internalName, "disabled": _vm.disabled, "required": _vm.required }, domProps: { "value": option[_vm.keyField], "checked": _vm._q(_vm.internalModelComputed, option[_vm.keyField]) }, on: { "change": function($event) {
      _vm.internalModelComputed = option[_vm.keyField];
    } } }), _vm.mode === "dot" ? _c("div", { staticClass: "radio__item-icon" }) : _vm._e(), _c("div", { staticClass: "radio__item-text" }, [_vm._v(" " + _vm._s(option[_vm.textField]) + " ")])]);
  }), 0) : _c("div", { staticClass: "radio radio--multiple" }, _vm._l(_vm.options, function(option) {
    return _c("div", { key: option[_vm.keyField], staticClass: "radio__option radio__option--multiple" }, [_c("Checkbox", { attrs: { "label": option[_vm.textField], "value": (_vm.internalModelComputed || []).includes(option[_vm.keyField]), "no-model": true, "disabled": _vm.disabled }, on: { "click": () => _vm.toggleValue(option[_vm.keyField]) } })], 1);
  }), 0);
};
var staticRenderFns = [];
export { render, staticRenderFns };
