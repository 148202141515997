var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "data-table bg-white shadow-table rounded" }, [_c("table", [_c("thead", { staticClass: "data-table__head" }, [_c("tr", [_vm.selectable && _vm.haveSelectableItems ? _c("td", { staticClass: "data-table__header" }, [_c("span", [_c("input", { attrs: { "type": "checkbox" }, domProps: { "checked": _vm.haveSelectedItems }, on: { "click": _vm.toggleGlobalSelection } })])]) : _vm.selectable && !_vm.haveSelectableItems ? _c("td", { staticClass: "w-20" }) : _vm._e(), _vm._l(_vm.headers, function(header, index) {
    return _c("td", { key: `${header}_${index}`, staticClass: "data-table__header" }, [_vm.checkSortable(header) ? _c("div", [header.sorted !== null ? _c("span", { staticClass: "data-table__header sortable", class: { "sorted": !header.sorted }, on: { "click": function($event) {
      return _vm.sortBy(header);
    } } }, [typeof header.haserror !== "undefined" && header.haserror ? _c("span", { staticClass: "mr-2" }, [_c("Alert-Small")], 1) : _vm._e(), _vm._v(" " + _vm._s(header.title) + " "), header.icon ? _c(header.icon, { directives: [{ name: "tooltip", rawName: "v-tooltip.bottom", value: `${header.icon_tooltip || ""}`, expression: "`${header.icon_tooltip || ''}`", modifiers: { "bottom": true } }], tag: "component", staticClass: "text-grey3" }) : _vm._e(), _c("DownIcon", { staticClass: "mr-2" })], 1) : _c("span", { staticClass: "data-table__header sortable", on: { "click": function($event) {
      return _vm.sortBy(header);
    } } }, [_vm._v(" " + _vm._s(header.title) + " "), typeof header.haserror !== "undefined" && header.haserror ? _c("span", { staticClass: "smallAlert" }, [_c("Alert-Small")], 1) : _vm._e(), header.icon ? _c(header.icon, { directives: [{ name: "tooltip", rawName: "v-tooltip.bottom", value: `${header.icon_tooltip || ""}`, expression: "`${header.icon_tooltip || ''}`", modifiers: { "bottom": true } }], tag: "component", staticClass: "text-grey3" }) : _vm._e(), header.title !== "" ? _c("DownIcon", { staticClass: "mr-2" }) : _vm._e()], 1)]) : _c("span", [_vm._v(" " + _vm._s(header.title) + " ")])]);
  }), _vm.withActions ? _c("td", { staticClass: "data-table__header data-table__actions" }, [_c("span", [_vm._v(" Acciones ")])]) : _vm._e()], 2)]), _c("tbody", { staticClass: "data-table__body" }, _vm._l(_vm.filteredItems, function(item, index) {
    return _c("tr", { key: index, staticClass: "data-table__row", class: { "data-table__row--invalid": item.validate && !item.valid } }, [_vm.selectable && _vm.setIsSelectable(item) ? _c("td", { staticClass: "data-table__item", class: { "data-table__item--narrow": _vm.narrowRows } }, [_c("input", { attrs: { "type": "checkbox" }, domProps: { "checked": _vm.selected.find((_item) => _item.id === item.id) }, on: { "click": function($event) {
      return _vm.toggleSelection(item);
    } } })]) : _vm.selectable && !_vm.setIsSelectable(item) ? _c("td", { staticClass: "data-table__item", class: { "data-table__item--narrow": _vm.narrowRows } }) : _vm._e(), _vm._l(_vm.data.headers, function(header, index2) {
      return _c("td", { key: index2, staticClass: "data-table__item", class: { "data-table__item--narrow": _vm.narrowRows } }, [_vm._t("default", function() {
        return [_vm._v(" " + _vm._s(item[header.field]) + " ")];
      }, { "item": item, "field": header.field })], 2);
    }), _vm.withActions ? _c("td", { staticClass: "data-table__item data-table__actions", class: { "data-table__item--narrow": _vm.narrowRows } }, [_vm._t("actions", null, { "item": item })], 2) : _vm._e()], 2);
  }), 0)]), _vm.filteredItems.length <= 0 && _vm.searchTerms.length === 0 && _vm.loading ? _c("div", { staticClass: "data-table__empty" }, [_c("span", [_vm._v(" Cargando informaci\xF3n... ")])]) : _vm._e(), _vm.filteredItems.length <= 0 && _vm.searchTerms.length === 0 && !_vm.loading ? _c("div", { staticClass: "data-table__empty" }, [_c("span", [_vm._v(" No hay registros que mostrar ")])]) : _vm._e(), _vm.filteredItems.length <= 0 && _vm.searchTerms.length >= 1 ? _c("div", { staticClass: "data-table__empty" }, [_c("span", { staticClass: "flex items-center" }, [_c("SearchIcon", { staticClass: "mr-4" }), _vm._v(" No se han encontrado resultados de tu b\xFAsqueda ")], 1)]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
