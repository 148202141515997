import { DateTime } from "luxon";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
const state = {
  list: [],
  customApprovalFlows: [],
  legacyIncidencesSettings: {},
  customIncidencesSettings: [],
  customIncidencesCategory: [],
  customIncidencesTypes: [],
  incidencesByEmployee: [],
  legacyList: [
    {
      id: "vacations",
      value: false,
      type: "VA",
      label: "Vacaciones"
    },
    {
      id: "home_office",
      value: false,
      type: "HO",
      label: "Home office"
    },
    {
      id: "half_days",
      value: false,
      type: "HD",
      label: "Medio d\xEDa"
    },
    {
      id: "pto",
      value: false,
      type: "PTO",
      label: "D\xEDas personales"
    },
    {
      id: "permissions",
      value: false,
      type: "PER",
      label: "Permisos"
    },
    {
      id: "extra_hours",
      value: false,
      type: "EH",
      label: "Horas extra"
    },
    {
      id: "delayed_departures",
      value: false,
      type: "DD",
      label: "Salidas tard\xEDas"
    },
    {
      id: "early_departures",
      value: false,
      type: "ED",
      label: "Salidas anticipadas"
    },
    {
      id: "double_extra_hours",
      value: false,
      type: "DEH",
      label: "Horas extras dobles"
    },
    {
      id: "triple_extra_hours",
      value: false,
      type: "TEH",
      label: "Horas extras triples"
    },
    {
      id: "bonuses",
      value: false,
      type: "BO",
      label: "Bonos"
    },
    {
      id: "discounts",
      value: false,
      type: "DI",
      label: "Descuentos"
    },
    {
      id: "refunds",
      value: false,
      type: "RE",
      label: "Reembolsos",
      identifier: false
    },
    {
      id: "dominical_prime",
      value: false,
      type: "DP",
      label: "Prima dominical",
      identifier: false
    },
    {
      id: "operated_days",
      value: false,
      type: "OPD",
      label: "D\xEDas operados",
      identifier: false
    },
    {
      id: "worked_holidays",
      value: false,
      type: "WH",
      label: "D\xEDas feriados trabajados",
      identifier: true
    },
    {
      id: "transport_help",
      value: false,
      type: "TH",
      label: "Ayuda de transporte"
    },
    {
      id: "absences",
      value: false,
      type: "AB",
      label: "Faltas"
    },
    {
      id: "inabilities",
      value: false,
      type: "IN",
      label: "Incapacidades"
    },
    {
      id: "delays",
      value: false,
      type: "DE",
      label: "Retrasos"
    },
    {
      id: "administrative_acts",
      value: false,
      type: "ADA",
      label: "Actas administrativas"
    },
    {
      id: "credits",
      value: false,
      type: "CE",
      label: "Cr\xE9ditos"
    },
    {
      id: "vacation_period",
      value: true,
      type: "VAPE",
      label: "Periodo de vacaciones"
    },
    {
      id: "pto_period",
      value: true,
      type: "PTOPE",
      label: "Periodo de d\xEDas personales"
    },
    {
      id: "pto_compensation",
      value: true,
      type: "PTOCOMP",
      label: "Compensaci\xF3n de d\xEDas personales"
    },
    {
      id: "vacation_compensation",
      value: true,
      type: "VACOMP",
      label: "Compensaci\xF3n de vacaciones"
    },
    {
      id: "commissions",
      value: false,
      type: "CO",
      label: "Comisiones"
    }
  ],
  incidencesCount: 0,
  incidencesByEmployeeCount: 0,
  customListRequestable: [],
  myRequestsRequestable: [],
  myUpcomingRequestsList: [],
  myPreviousRequestsList: [],
  myRequestsLesserThan: [],
  incidencesList: [],
  detailMultiselectOptions: [],
  areasInfo: [],
  onboarded: false,
  filterByType: [],
  filterByStatus: [],
  filterByDate: {
    start: DateTime.local().day <= 15 ? DateTime.local().startOf("month").toISODate() : DateTime.local(DateTime.local().year, DateTime.local().month, 16).toISODate(),
    end: DateTime.local().day <= 15 ? DateTime.local(DateTime.local().year, DateTime.local().month, 15).toISODate() : DateTime.local().endOf("month").toISODate()
  },
  gettingStats: false
};
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
