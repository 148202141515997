import { AbilityBuilder, Ability } from "@casl/ability";
import store from "@/store";
export default function defineAbilitiesFor(user, module) {
  const { rules, can } = AbilityBuilder.extract();
  if (store.state.company.permissions.myPermissions !== void 0 && store.state.company.permissions.myPermissions.length > 0) {
    const { myPermissions } = store.state.company.permissions;
    myPermissions.forEach((permission) => can(permission.policy, permission.slug));
  }
  can("read", "Company");
  return new Ability(rules);
}
