const debug = process.env.NODE_ENV === "development";
const development = process.env.NODE_ENV === "development";
const dsn = process.env.VUE_APP_DSN;
const production = process.env.NODE_ENV === "production";
const sentryEnv = process.env.VUE_APP_SENTRY_ENV;
const sentryRelease = process.env.VUE_APP_SENTRY_RELEASE || "unreleased";
export default {
  debug,
  development,
  dsn,
  production,
  sentryEnv,
  sentryRelease
};
