const routes = {
  path: "/vacaciones",
  component: {
    render: (h) => h("router-view")
  },
  children: [
    {
      path: "simulador",
      component: {
        render: (h) => h("router-view")
      },
      redirect: { name: "simulator-start" },
      children: [
        {
          path: "inicio",
          name: "simulator-start",
          component: () => import(
            /* webpackChunkName: "onboarding" */
            "@/views/timeoff/Simulator"
          ),
          meta: {
            module: "Vacaciones",
            requireAuth: true,
            notValidateModule: true
          }
        }
      ]
    }
  ]
};
export default routes;
