var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
import { api } from "@/api";
export default {
  getCompanies(_0) {
    return __async(this, arguments, function* ({ commit }) {
      try {
        const { data } = yield axios.get("api/v1/outsourcers/companies/");
        commit("setCompanies", { companies: data });
        commit("setActualCompany", { company: data[0] });
      } catch (error) {
        this._vm.$notify({
          type: "error",
          text: `\xA1Oh, oh! Algo sali\xF3 mal al obtener las razones sociales.`
        });
      }
    });
  },
  changeCompany(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, companyId) {
      try {
        yield api.outsourcer.loginRS(companyId);
        const newCompany = state.companies.find(({ id }) => id === companyId);
        commit("setActualCompany", { company: newCompany });
      } catch (error) {
        this._vm.$notify({
          type: "error",
          text: `\xA1Oh, oh! Algo sali\xF3 mal al cambiar de raz\xF3n social.`
        });
      }
    });
  },
  setCompanyEmployees({ commit }, employees) {
    commit("setEmployees", employees);
  },
  getEmployee(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, employeeId) {
      const { data } = yield axios.get(`api/v2/employees/public/${employeeId}/`);
      const employees = state.company.employees.list;
      const index = employees.findIndex(({ id }) => id === employeeId);
      employees[index] = data;
      commit("setEmployees", employees);
      return data;
    });
  },
  setCompanyExEmployees({ commit }, employees) {
    commit("setExEmployees", employees);
  }
};
