var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Company from "@/models/company";
import { api } from "@/api";
import { setCookie, cookieExits } from "@/utils/cookies";
export default {
  getCompanyData(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const company = yield Company.retrive(rootState.me.user.company.id);
      commit("setCompanyData", company);
      return company;
    });
  },
  getCompanyDataOutsourcer(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, idCompany) {
      const company = yield Company.retrive(idCompany);
      commit("setCompanyData", company);
      return company;
    });
  },
  updateCompanyData(_0, _1) {
    return __async(this, arguments, function* ({ commit, state, rootGetters }, { data }) {
      const companyData = rootGetters["me/company"];
      const company = new Company(companyData).setFields(data);
      const updatedCompany = yield company.update();
      const newCompany = new Company(updatedCompany);
      commit("me/setCompanyData", { company: newCompany }, { root: true });
      return updatedCompany;
    });
  },
  uploadCompanyLogo(_0, _1) {
    return __async(this, arguments, function* ({ commit, state, rootGetters }, file) {
      const companyData = rootGetters["me/company"];
      const company = new Company(companyData);
      const updatedCompany = yield company.setLogo(file);
      const newCompany = new Company(updatedCompany);
      commit("me/setNewLogo", { logo: newCompany.logo }, { root: true });
      return updatedCompany;
    });
  },
  deletCompanyLogo(_0) {
    return __async(this, arguments, function* ({ commit, state, rootGetters }) {
      const companyData = rootGetters["me/company"];
      const company = new Company(companyData);
      const updatedCompany = yield company.setLogo("");
      const newCompany = new Company(updatedCompany);
      commit("me/setNewLogo", { logo: newCompany.logo }, { root: true });
      return updatedCompany;
    });
  },
  updateChecklist({ state, dispatch, rootGetters }) {
    const companyData = rootGetters["me/company"];
    const company = new Company(companyData).setFields({ checklist: true });
    return dispatch("updateCompanyData", { data: company });
  },
  createEmployee(_0, _1) {
    return __async(this, arguments, function* ({ commit, dispatch }, data) {
      yield api.directory.createEmployeeFromWA(data);
    });
  },
  notifyIncidences(_0) {
    return __async(this, arguments, function* ({ state, rootGetters }) {
      const companyData = rootGetters["me/company"];
      const company = new Company(companyData);
      yield company.incidences().notify();
    });
  },
  sendEmailIncidences(_0, _1) {
    return __async(this, arguments, function* ({ state }, { startDate, endDate, email }) {
      const company = new Company(state.data);
      yield company.incidences().post({ email });
    });
  },
  getRequests(_0) {
    return __async(this, arguments, function* ({ commit, state }, { start, end } = {}) {
      const company = new Company(state.data);
      const requests = yield company.requests().get({ start, end });
      commit("timeOff/setRequests", { data: requests });
      return requests;
    });
  },
  getHolidays(_0) {
    return __async(this, arguments, function* ({ commit, state, rootGetters }) {
      const companyData = rootGetters["me/company"];
      const company = new Company(companyData);
      const holidays = yield company.holidays().get();
      const date = new Date();
      const isCookieExistant = cookieExits("lastHolidaysUpdate");
      !isCookieExistant && setCookie("lastHolidaysUpdate", date.getTime(), 1 / 24);
      commit("holidays/setHolidays", { data: holidays });
    });
  },
  getTimeOffPolicies(_0) {
    return __async(this, arguments, function* ({ commit, state, rootGetters }) {
      const { data } = yield api.timeOff.getCompanyPolicies();
      commit("setTimeOffPolicies", data);
    });
  },
  createTimeOffPolicy(_0, _1) {
    return __async(this, arguments, function* ({ commit }, policy) {
      const { data } = yield api.timeOff.createPolicy(policy);
      commit("setNewTimeOffPolicy", data);
      return data;
    });
  },
  updateTimeOffPolicy(_0, _1) {
    return __async(this, arguments, function* ({ commit }, policy) {
      const { data } = yield api.timeOff.updatePolicy({
        id: policy.id,
        data: policy
      });
      commit("setUpdatedTimeOffPolicy", data);
    });
  },
  deleteTimeOffPolicy(_0, _1) {
    return __async(this, arguments, function* ({ commit }, policy) {
      yield api.timeOff.deletePolicy(policy);
      commit("removeTimeOffPolicy", policy);
    });
  },
  setCover(_0, _1) {
    return __async(this, arguments, function* ({ commit, state, rootGetters }, { file, is_original }) {
      const companyData = rootGetters["me/company"];
      const company = new Company(companyData);
      const updatedCompany = yield company.setCover(file, is_original);
      const newCompany = new Company(__spreadValues(__spreadValues({}, company), updatedCompany));
      commit("me/setCompanyData", { company: newCompany }, { root: true });
      return updatedCompany;
    });
  },
  getProfileSettings(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const { data } = yield api.company(rootState.me.user.company.id).getProfileSettings();
      commit("setProfileSetting", data.public_profile);
      return data;
    });
  },
  getCustomSections(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const { data } = yield api.company(rootState.me.user.company.id).getCustomSections();
      return data;
    });
  },
  setCustomSection(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, payload) {
      const { data } = yield api.company(rootState.me.user.company.id).setCustomSection(payload);
      return data;
    });
  },
  updateCustomSection(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, payload) {
      const { data } = yield api.company(rootState.me.user.company.id).updateCustomSection(payload);
      return data;
    });
  },
  setProfileSettings(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, payload) {
      const { data } = yield api.company(rootState.me.user.company.id).setProfileSettings(payload);
      return data;
    });
  },
  getTeamSettings(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const { data } = yield api.company(rootState.me.user.company.id).getTeamSettings();
      return data;
    });
  },
  setTeamSettings(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, payload) {
      const { data } = yield api.company(rootState.me.user.company.id).setTeamSettings(payload);
      return data;
    });
  }
};
