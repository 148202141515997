export default {
  name: "EllipsisText",
  functional: true,
  props: {
    text: {
      type: String,
      default: ""
    },
    lengthText: {
      type: Number,
      default: 20
    }
  },
  render(createElement, { props }) {
    const ellipsisText = props.text.length > props.lengthText ? `${props.text.substring(0, props.lengthText)}...` : props.text;
    return createElement("span", {}, ellipsisText);
  }
};
