var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { api } from "@/api";
export default {
  getMyCalendarIncidences(_0, _1) {
    return __async(this, arguments, function* ({ commit, getters, rootGetters }, { startDate, endDate, status }) {
      commit("setCalendarRange", { startDate, endDate });
      const employeeId = rootGetters["me/employee"].id;
      const resp = yield api.approvalFlows.getRequestsCards(employeeId);
      commit("company/incidences/setMyRequestsRequestable", resp.data, { root: true });
      const { data } = yield api.calendar.getMyCalendarIncidences({ startDate, endDate, status });
      commit("setMyIncidences", data);
      return resp.data;
    });
  },
  getCalendarIncidences(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { startDate, endDate, status }) {
      commit("setCalendarRange", { startDate, endDate });
      const { data } = yield api.calendar.getCalendarIncidences({ startDate, endDate, status });
      commit("setCalendarIncidences", data);
    });
  },
  getTeamCalendarIncidences(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { startDate, endDate, status }) {
      commit("setCalendarRange", { startDate, endDate });
      const { data } = yield api.calendar.getTeamCalendarIncidences({ startDate, endDate, status });
      commit("setCalendarIncidences", data);
    });
  }
};
