const routes = {
  path: "/backoffice",
  name: "backoffice",
  component: () => import(
    /* webpackChunkName: "b4ck0" */
    "@/views/BackofficeLayout"
  ),
  redirect: {
    name: "backoffice-companies"
  },
  children: [
    {
      path: "companies",
      name: "backoffice-companies",
      component: () => import(
        /* webpackChunkName: "b4ck0" */
        "@/views/BackofficeCompanies"
      ),
      meta: {
        requireAuth: true
      }
    },
    {
      path: "companies/:id/employees",
      name: "backoffice-employees",
      component: () => import(
        /* webpackChunkName: "b4ck0" */
        "@/views/BackofficeEmployees"
      ),
      meta: {
        requireAuth: true
      }
    }
  ]
};
export default routes;
