export default {
  setCompanies(state, { companies }) {
    state.companies = companies;
  },
  setActualCompany(state, { company }) {
    state.actualCompany = company;
  },
  setEmployees(state, employees) {
    state.company = {
      employees: {
        list: employees
      }
    };
  },
  setExEmployees(state, employees) {
    state.company = {
      exemployees: {
        list: employees
      }
    };
  }
};
