const routes = {
  path: "/pre-onboarding",
  component: {
    render: (h) => h("router-view")
  },
  children: [
    {
      path: "inicio",
      name: "preonboarding-welcome",
      component: () => import(
        /* webpackChunkName: "preonboarding" */
        "@/views/preonboarding/PreonboardingWelcome"
      ),
      meta: {
        requireAuth: true,
        module: "preonboarding",
        publicName: "Onboarding para colaboradores futuros"
      }
    },
    {
      path: "preonboarding-config",
      name: "preonboarding-create",
      component: () => import(
        /* webpackChunkName: "preonboarding" */
        "@/views/preonboarding/SetupPreonboarding"
      ),
      meta: {
        requireAuth: true,
        module: "preonboarding",
        publicName: "Nuevo onboarding",
        backTo: "preonboarding-welcome"
      }
    },
    {
      path: "editar/:id",
      name: "edit-preonboarding",
      component: () => import(
        /* webpackChunkName: "preonboarding" */
        "@/views/preonboarding/EditPreonboarding"
      ),
      meta: {
        requireAuth: true,
        module: "preonboarding",
        publicName: "Editar",
        backTo: "preonboarding-welcome"
      },
      props: true
    },
    {
      path: "documentos/:id",
      name: "preonboarding-documents",
      component: () => import(
        /* webpackChunkName: "preonboarding" */
        "@/views/preonboarding/DocumentsEmployee"
      ),
      meta: {
        requireAuth: true,
        module: "preonboarding",
        publicName: "Ver documentos",
        backTo: "preonboarding-welcome"
      },
      props: true
    }
  ]
};
export default routes;
