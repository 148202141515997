import Company from "@/models/company";
import { backoffice } from "@/api";
export default {
  getCompanies({ commit }) {
    return Company.list().then((companies) => {
      commit("setCompanies", { companies });
      console.log(companies);
      return companies;
    });
  },
  getEmployees({ commit }, company) {
    return backoffice.getEmployeesFromCompany(company);
  }
};
