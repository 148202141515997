var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Company from "@/models/company";
import Location from "@/models/location";
export default {
  getLocations(_0) {
    return __async(this, arguments, function* ({ commit, rootState, rootGetters }, query = {}) {
      const companyData = rootGetters["me/company"];
      const company = new Company(companyData);
      const locations = yield company.locations().get(query);
      commit("setLocations", { locations });
      return locations;
    });
  },
  createLocation(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState, rootGetters }, { location }) {
      const companyData = rootGetters["me/company"];
      const company = new Company(companyData);
      const newLocation = yield company.locations().create(location);
      commit("setLocation", { location: newLocation });
      return newLocation;
    });
  },
  updateLocation(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, { location }) {
      const oldLocation = state.list.find((_location) => _location.id === location.id);
      const locationObject = new Location(oldLocation);
      const updatedLocation = yield locationObject.setFields(location).update();
      commit("setLocation", { location: updatedLocation });
      return updatedLocation;
    });
  },
  deleteLocation(_0, _1) {
    return __async(this, arguments, function* ({ commit, state, rootState }, { id }) {
      const oldLocation = state.list.find((_location) => _location.id === id);
      const location = new Location(oldLocation);
      yield location.delete();
      const locations = state.list.filter((_location) => _location.id !== id);
      commit("setLocations", { locations });
      return locations;
    });
  }
};
