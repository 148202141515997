const routes = [
  {
    path: "/login",
    component: () => import(
      /* webpackChunkName: "account" */
      "@/views/v2/login/AccountLoginV2"
    ),
    meta: {
      module: "Cuenta",
      publicName: "Login",
      notValidateModule: true
    }
  },
  {
    path: "/recuperar-contrasena",
    name: "recovery-password",
    component: () => import(
      /* webpackChunkName: "account" */
      "@/views/v2/login/AccountRecoverPasswordV2"
    ),
    meta: {
      module: "Cuenta",
      publicName: "Crear contrase\xF1a",
      notValidateModule: true
    }
  },
  {
    path: "/verificar/:id",
    name: "verify",
    component: () => import(
      /* webpackChunkName: "account" */
      "@/views/v2/login/AccountVerify"
    ),
    meta: {
      module: "Cuenta",
      publicName: "Recuperar contrase\xF1a",
      notValidateModule: true
    }
  },
  {
    path: "/iniciar-sesionV2",
    name: "loginv2",
    component: () => import(
      /* webpackChunkName: "account" */
      "@/views/v2/login/AccountLoginV2"
    ),
    meta: {
      module: "Cuenta",
      publicName: "LoginV2",
      notValidateModule: true
    }
  },
  {
    path: "/iniciar-sesion",
    name: "login",
    component: () => import(
      /* webpackChunkName: "account" */
      "@/views/v2/login/AccountLoginV2"
    ),
    meta: {
      module: "Cuenta",
      publicName: "Login",
      notValidateModule: true
    }
  },
  {
    path: "/reset/:id",
    name: "reset",
    component: () => import(
      /* webpackChunkName: "account" */
      "@/views/v2/login/AccountVerify"
    ),
    meta: {
      module: "Cuenta",
      publicName: "Recuperar contrase\xF1a",
      otValidateModule: true
    }
  },
  {
    path: "/subscription",
    name: "plans",
    component: () => import(
      /* webpackChunkName: "account" */
      "@/views/v2/selfOnboarding/SelfOnboarding"
    ),
    meta: {
      publicName: "Elige un plan",
      module: "Carrito de Compra",
      notValidateModule: true
    }
  },
  {
    path: "/suscripcion",
    name: "plans",
    component: () => import(
      /* webpackChunkName: "account" */
      "@/views/v2/selfOnboarding/SelfOnboarding"
    ),
    meta: {
      publicName: "Elige un plan",
      module: "Carrito de Compra",
      notValidateModule: true
    }
  },
  {
    path: "/correo-proceso-enviado",
    name: "sent-process",
    component: () => import(
      /* webpackChunkName: "account" */
      "@/views/v2/selfOnboarding/SentEmail"
    ),
    meta: {
      publicName: "Correo enviado",
      module: "Carrito de Compra",
      notValidateModule: true
    }
  },
  {
    path: "/renovar-suscripcion/:id",
    name: "renew",
    component: () => import(
      /* webpackChunkName: "account" */
      "@/views/v2/selfOnboarding/RenewSubsc"
    ),
    meta: {
      publicName: "Renovaci\xF3n",
      module: "Carrito de Compra",
      notValidateModule: true
    }
  },
  {
    path: "/checkout/:id",
    name: "checkout",
    component: () => import(
      /* webpackChunkName: "account" */
      "@/views/v2/selfOnboarding/Checkout"
    ),
    meta: {
      publicName: "Checkout",
      module: "Carrito de Compra",
      notValidateModule: true
    }
  },
  {
    path: "/descarga",
    name: "download",
    query: {
      coru: false
    },
    component: () => import(
      /* webpackChunkName: "account" */
      "@/views/DownloadApp"
    ),
    meta: {
      publicName: "Descargar App",
      module: "downloadApp",
      notValidateModule: true
    }
  },
  {
    path: "/worky-lite",
    name: "workylite",
    query: {
      coru: false
    },
    component: () => import(
      /* webpackChunkName: "account" */
      "@/views/DownloadWorkyLite"
    ),
    meta: {
      publicName: "Descargar App Worky-Lite",
      module: "worky-lite",
      notValidateModule: true
    }
  },
  {
    path: "/cerrar-sesion",
    name: "logout",
    component: () => import(
      /* webpackChunkName: "account" */
      "@/views/v2/login/AccountLogout"
    ),
    meta: {
      module: "Cuenta",
      publicName: "Logout",
      notValidateModule: true
    }
  }
];
export default routes;
