var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
var __forAwait = (obj, it, method) => {
  it = obj[Symbol.asyncIterator];
  method = (key, fn) => (fn = obj[key]) && (it[key] = (arg) => new Promise((resolve, reject, done) => {
    arg = fn.call(obj, arg);
    done = arg.done;
    return Promise.resolve(arg.value).then((value) => resolve({ value, done }), reject);
  }));
  return it ? it.call(obj) : (obj = obj[Symbol.iterator](), it = {}, method("next"), method("return"), it);
};
import { api } from "@/api";
export default {
  assignEmployees(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, data) {
      try {
        const { response } = yield api.permissions.assignEmployees(data.permissionGroup, data.payload, data.force);
        const succResp = {
          error: false,
          resp: response
        };
        return succResp;
      } catch (error) {
        const err = error.response.status === 400 ? error.response.data.errors : [];
        const errResp = {
          error: true,
          errors: err
        };
        return errResp;
      }
    });
  },
  getLoggedUserPermissions(_0) {
    return __async(this, arguments, function* ({ commit }) {
      const { data } = yield api.permissions.getAllUserPermissions();
      commit("setPermissions", data);
    });
  },
  getPermissionsGroups(_0, _1) {
    return __async(this, arguments, function* ({ commit }, status) {
      const { data } = yield api.permissions.getGroups(status);
      return data;
    });
  },
  getPermissionsGroup(_0, _1) {
    return __async(this, arguments, function* ({ commit, dispatch, state }, groupId) {
      const { data } = yield api.permissions.getGroup(groupId);
      return data;
    });
  },
  getPermissionsGroupUsers(_0, _1) {
    return __async(this, arguments, function* ({ commit, dispatch, state }, groupId) {
      const { data } = yield api.permissions.getGroupEmployees(groupId);
      return data;
    });
  },
  activateGroup(_0, _1) {
    return __async(this, arguments, function* ({ commit }, id) {
      const { data } = yield api.permissions.activateGroup(id);
      return data;
    });
  },
  deactivateGroup(_0, _1) {
    return __async(this, arguments, function* ({ commit }, id) {
      const { data } = yield api.permissions.deactivateGroup(id);
      return data;
    });
  },
  getAllUserPermissions() {
    return __async(this, null, function* () {
      const { data } = yield api.permissions.getFieldsForModule("directory");
      return data;
    });
  },
  getAvailablePermissions(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const availableModules = rootState.me.user.modules.filter((module) => module.is_active === true);
      const permissions = [];
      try {
        for (var iter = __forAwait(availableModules.entries()), more, temp, error; more = !(temp = yield iter.next()).done; more = false) {
          const [value] = temp.value;
          const { data } = yield api.permissions.getFieldsForModule(value.module);
          permissions[value.module] = data;
        }
      } catch (temp) {
        error = [temp];
      } finally {
        try {
          more && (temp = iter.return) && (yield temp.call(iter));
        } finally {
          if (error)
            throw error[0];
        }
      }
      commit("setAvailablePermissions", permissions);
      return permissions;
    });
  },
  removeEmployee(_0, _1) {
    return __async(this, arguments, function* ({ commit }, data) {
      const { response } = yield api.permissions.removeEmployee(data.permissionGroup, data.payload);
      return response;
    });
  }
};
