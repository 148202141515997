const routes = {
  path: "/reloj-checador",
  name: "time-clock-v2",
  component: () => import(
    /* webpackChunkName: "timeclock" */
    "@/views/timeClockV2/TimeClockLayout"
  ),
  meta: {
    publicName: "Reloj checador"
  },
  redirect: { name: "time-clock-v2-template" },
  children: [
    {
      path: "plantilla",
      name: "time-clock-v2-template",
      component: () => import(
        /* webpackChunkName: "timeclock" */
        "@/views/timeClockV2/ExcelTemplate"
      ),
      meta: {
        requireAuth: true,
        module: "timeclock",
        publicName: "Reloj checador"
      }
    },
    {
      path: "pendientes",
      name: "time-clock-v2-pending",
      component: () => import(
        /* webpackChunkName: "timeclock" */
        "@/views/timeClockV2/TimeClockPendingsView"
      ),
      meta: {
        requireAuth: true,
        module: "timeclock",
        publicName: "Reloj checador"
      }
    },
    {
      path: "horas-laboradas",
      name: "time-clock-v2-hours",
      component: () => import(
        /* webpackChunkName: "timeclock" */
        "@/views/timeClockV2/TimeClockWorkedHoursView"
      ),
      meta: {
        requireAuth: true,
        module: "timeclock",
        publicName: "Reloj checador"
      }
    }
  ]
};
export default routes;
