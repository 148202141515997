import { DateTime } from "luxon";
export default {
  /*
  *  Deprecated to be removed by 01-12-2020
  * */
  employeeByWorkStartDate(state, getters, rootState, rootGetters) {
    const employees = rootGetters["company/employees"];
    const currentYear = DateTime.local().year;
    const currentMonth = DateTime.local().month;
    const twoYearsAgoEmployees = employees.filter(
      (employee) => DateTime.fromISO(employee.work_start_date).year <= currentYear - 2 && employee.work_end_date === null
    ).length;
    const lastEmployees = employees.filter(
      (employee) => DateTime.fromISO(employee.work_start_date).year === currentYear - 1 || DateTime.fromISO(employee.work_end_date).year === currentYear - 1
    );
    const currentEmployees = employees.filter(
      (employee) => DateTime.fromISO(employee.work_start_date).year === currentYear || DateTime.fromISO(employee.work_end_date).year === currentYear
    );
    const newData = [
      ["x", "EN", "FB", "MR", "AB", "MY", "JN", "JL", "AG", "SP", "OC", "NV", "DC"],
      [`${currentYear - 1}`],
      [`${currentYear}`]
    ];
    let contEmployee = twoYearsAgoEmployees;
    for (let i = 1; i <= 12; i += 1) {
      const contEntryMonth = lastEmployees.filter(
        (employee) => DateTime.fromISO(employee.work_start_date).month === i
      ).length;
      const contLaidMonth = lastEmployees.filter(
        (employee) => DateTime.fromISO(employee.work_end_date).month === i
      ).length;
      contEmployee += contEntryMonth - contLaidMonth;
      newData[1][i] = contEmployee;
    }
    for (let i = 1; i <= 12; i += 1) {
      if (currentMonth >= i) {
        const contEntryMonthC = currentEmployees.filter(
          (employee) => DateTime.fromISO(employee.work_start_date).month === i
        ).length;
        const contLaidMonthC = currentEmployees.filter(
          (employee) => DateTime.fromISO(employee.work_end_date).month === i
        ).length;
        contEmployee += contEntryMonthC - contLaidMonthC;
        newData[2][i] = contEmployee;
      } else {
        newData[2][i] = 0;
      }
    }
    return newData;
  },
  /*
  *  End Deprecated
  * */
  employeesByGender(state, getters, rootState, rootGetters) {
    const { men, women } = state.listG;
    const notDeclared = state.listG.not_specified;
    return [
      ["Hombres", men],
      ["Mujeres", women],
      ["Prefiere no declarar", notDeclared]
    ];
  },
  employeesByAge(state, getters, rootState, rootGetters) {
    const ranges = state.listA;
    return [
      ["<20", ranges.age18_19],
      ["20-25", ranges.age20_25],
      ["26-30", ranges.age26_30],
      ["31-40", ranges.age31_40],
      ["41-50", ranges.age41_50],
      ["50+", ranges.age51_100]
    ];
  },
  employeesByScholarship(state, getters, rootState, rootGetters) {
    const currentEmployees = state.listE;
    const elementary = currentEmployees.filter((employee) => employee.nivel_de_estudios === "Primaria");
    const secundary = currentEmployees.filter((employee) => employee.nivel_de_estudios === "Secundaria");
    const highschool = currentEmployees.filter((employee) => employee.nivel_de_estudios === "Bachillerato");
    const bachelor = currentEmployees.filter((employee) => employee.nivel_de_estudios === "Licenciatura");
    const master = currentEmployees.filter((employee) => employee.nivel_de_estudios === "Maestr\xEDa" || employee.nivel_de_estudios === "Maestria");
    const doctor = currentEmployees.filter((employee) => employee.nivel_de_estudios === "Doctorado");
    return [
      ["Primaria", elementary[0] ? elementary[0].count : 0],
      ["Secundaria", secundary[0] ? secundary[0].count : 0],
      ["Bachillerato", highschool[0] ? highschool[0].count : 0],
      ["Licenciatura", bachelor[0] ? bachelor[0].count : 0],
      ["Maestr\xEDa", master[0] ? master[0].count : 0],
      ["Doctorado", doctor[0] ? doctor[0].count : 0]
    ];
  },
  employeesByCivilStatus(state, getters, rootState, rootGetters) {
    const { married, single } = state.listU;
    return [
      ["Casado", married],
      ["Soltero", single]
    ];
  },
  employeeByWorkAntiguedadDate(state, getters, rootState, rootGetters) {
    const employees = rootGetters["company/employees"];
    const currentEmployees = rootGetters["company/employees/currentEmployees"];
    const currentYear = DateTime.local().toLocaleString({ year: "numeric" });
    const lastYear = `${currentYear - 1}`;
    const antiguedadByEmployeeC = [];
    const antiguedadByEmployeeL = [];
    const newData = [
      ["x", lastYear, currentYear],
      ["Antiguedad", 0, 0]
    ];
    const empLaidOff = employees.filter((employee) => DateTime.fromISO(employee.work_end_date).toLocaleString({ year: "numeric" }) === lastYear);
    if (empLaidOff.length !== 0) {
      empLaidOff.forEach((employee) => {
        const numMonth = Math.trunc(DateTime.fromISO(employee.work_end_date).diff(DateTime.fromISO(employee.work_start_date), "months").toObject().months);
        antiguedadByEmployeeL.push(numMonth);
      });
    }
    if (currentEmployees.length !== 0) {
      const empActLaidOff = currentEmployees.filter((employee) => DateTime.fromISO(employee.work_start_date).toLocaleString({ year: "numeric" }) <= lastYear);
      if (empActLaidOff.length !== 0) {
        empActLaidOff.forEach((employee) => {
          const lastDate = `${lastYear}-12-31`;
          const monthCurrentL = Math.trunc(DateTime.fromISO(lastDate).diff(DateTime.fromISO(employee.work_start_date), "months").toObject().months);
          antiguedadByEmployeeL.push(monthCurrentL);
        });
      }
    }
    if (antiguedadByEmployeeL.length > 0) {
      const sumLast = antiguedadByEmployeeL.reduce((vPreL, vCurrentL) => vCurrentL + vPreL);
      const avgAntiguedadByEmployeeL = sumLast / antiguedadByEmployeeL.length;
      newData[1][1] = Math.ceil(avgAntiguedadByEmployeeL);
      const empLaidOffC = employees.filter((employee) => DateTime.fromISO(employee.work_end_date).toLocaleString({ year: "numeric" }) === currentYear);
      if (empLaidOffC.length !== 0) {
        empLaidOffC.forEach((employee) => {
          const numMonthC = Math.trunc(DateTime.fromISO(employee.work_end_date).diff(DateTime.fromISO(employee.work_start_date), "months").toObject().months);
          antiguedadByEmployeeC.push(numMonthC);
        });
      }
    }
    if (currentEmployees.length !== 0) {
      currentEmployees.forEach((employee) => {
        const currentDate = DateTime.local().toISODate();
        const monthCurrentC = Math.trunc(DateTime.fromISO(currentDate).diff(DateTime.fromISO(employee.work_start_date), "months").toObject().months);
        antiguedadByEmployeeC.push(monthCurrentC);
      });
    }
    if (antiguedadByEmployeeC.length > 0) {
      const sumCurrent = antiguedadByEmployeeC.reduce((vPreC, vCurrentC) => vCurrentC + vPreC);
      const avgAntiguedadByEmployeeC = sumCurrent / antiguedadByEmployeeC.length;
      newData[1][2] = Math.ceil(avgAntiguedadByEmployeeC);
    }
    return newData;
  },
  salaryByArea(state) {
    const salaryReport = state.listS;
    const newData = [
      ["x"],
      ["Sueldo ($)"]
    ];
    salaryReport.map((area) => {
      newData[0].push(area.area__name);
      newData[1].push(area.salary_sum);
    });
    return newData;
  },
  absencesByArea(state, getters, rootState, rootGetters) {
    const absences = rootGetters["company/incidences/byEmployeeAbsencesReport"];
    return absences;
  },
  laidOffEmp(state, getters, rootState, rootGetters) {
    const employees = rootGetters["company/employees"];
    const currentYear = DateTime.local().toLocaleString({ year: "numeric" });
    const lastYear = `${currentYear - 1}`;
    const currentEmployees = employees.filter((employee) => DateTime.fromISO(employee.work_end_date).toLocaleString({ year: "numeric" }) === currentYear);
    const lastEmployees = employees.filter((employee) => DateTime.fromISO(employee.work_end_date).toLocaleString({ year: "numeric" }) === lastYear);
    const laidOffEmployee = [
      ["x", "ENE-MAR", "ABR-JUN", "JUL-SEP", "OCT-DIC"],
      [lastYear, 0, 0, 0, 0],
      [currentYear, 0, 0, 0, 0]
    ];
    lastEmployees.forEach((employee) => {
      const monthL = DateTime.fromISO(employee.work_end_date).month;
      if (monthL >= 1 && monthL <= 3) {
        laidOffEmployee[1][1] += 1;
      } else if (monthL >= 4 && monthL <= 6) {
        laidOffEmployee[1][2] += 1;
      } else if (monthL >= 7 && monthL <= 9) {
        laidOffEmployee[1][3] += 1;
      } else if (monthL >= 10 && monthL <= 12) {
        laidOffEmployee[1][4] += 1;
      }
    });
    const lastActEmployee = employees.filter((employee) => employee.work_end_date === null);
    let lastYearMonth = 0;
    let actEmployee = 0;
    if (lastActEmployee.length !== 0) {
      lastYearMonth = DateTime.fromISO(`${lastYear}-03-01`).endOf("month").toISODate();
      actEmployee = lastActEmployee.filter((employee) => employee.work_start_date < lastYearMonth);
      const quantityEneMar = laidOffEmployee[1][1];
      if (actEmployee.length !== 0) {
        laidOffEmployee[1][1] = quantityEneMar / (actEmployee.length + quantityEneMar);
      } else if (quantityEneMar !== 0) {
        laidOffEmployee[1][1] = quantityEneMar / quantityEneMar;
      }
      lastYearMonth = DateTime.fromISO(`${lastYear}-06-01`).endOf("month").toISODate();
      actEmployee = lastActEmployee.filter((employee) => employee.work_start_date < lastYearMonth);
      const quantityAbrJun = laidOffEmployee[1][2];
      if (actEmployee.length !== 0) {
        laidOffEmployee[1][2] = quantityAbrJun / (actEmployee.length + quantityAbrJun);
      } else if (quantityAbrJun !== 0) {
        laidOffEmployee[1][2] = quantityAbrJun / quantityAbrJun;
      }
      lastYearMonth = DateTime.fromISO(`${lastYear}-09-01`).endOf("month").toISODate();
      actEmployee = lastActEmployee.filter((employee) => employee.work_start_date < lastYearMonth);
      const quantityJulSep = laidOffEmployee[1][3];
      if (actEmployee.length !== 0) {
        laidOffEmployee[1][3] = quantityJulSep / (actEmployee.length + quantityJulSep);
      } else if (quantityJulSep !== 0) {
        laidOffEmployee[1][3] = quantityJulSep / quantityJulSep;
      }
      lastYearMonth = DateTime.fromISO(`${lastYear}-12-01`).endOf("month").toISODate();
      actEmployee = lastActEmployee.filter((employee) => employee.work_start_date < lastYearMonth);
      const quantityOctDic = laidOffEmployee[1][4];
      if (actEmployee.length !== 0) {
        laidOffEmployee[1][4] = quantityOctDic / (actEmployee.length + quantityOctDic);
      } else if (quantityOctDic !== 0) {
        laidOffEmployee[1][3] = quantityOctDic / quantityOctDic;
      }
    }
    currentEmployees.forEach((employee) => {
      const monthC = DateTime.fromISO(employee.work_end_date).month;
      if (monthC >= 1 && monthC <= 3) {
        laidOffEmployee[2][1] += 1;
      } else if (monthC >= 4 && monthC <= 6) {
        laidOffEmployee[2][2] += 1;
      } else if (monthC >= 7 && monthC <= 9) {
        laidOffEmployee[2][3] += 1;
      } else if (monthC >= 10 && monthC <= 12) {
        laidOffEmployee[2][4] += 1;
      }
    });
    let currentYearMonth = 0;
    if (lastActEmployee.length !== 0) {
      currentYearMonth = DateTime.fromISO(`${currentYear}-03-01`).endOf("month").toISODate();
      actEmployee = lastActEmployee.filter((employee) => employee.work_start_date < currentYearMonth);
      const quantityEneMarC = laidOffEmployee[2][1];
      if (actEmployee.length !== 0) {
        laidOffEmployee[2][1] = quantityEneMarC / (actEmployee.length + quantityEneMarC);
      } else if (quantityEneMarC !== 0) {
        laidOffEmployee[2][1] = quantityEneMarC / quantityEneMarC;
      }
      currentYearMonth = DateTime.fromISO(`${currentYear}-06-01`).endOf("month").toISODate();
      actEmployee = lastActEmployee.filter((employee) => employee.work_start_date < currentYearMonth);
      const quantityAbrJunC = laidOffEmployee[2][2];
      if (actEmployee.length !== 0) {
        laidOffEmployee[2][2] = quantityAbrJunC / (actEmployee.length + quantityAbrJunC);
      } else if (quantityAbrJunC !== 0) {
        laidOffEmployee[2][2] = quantityAbrJunC / quantityAbrJunC;
      }
      currentYearMonth = DateTime.fromISO(`${currentYear}-09-01`).endOf("month").toISODate();
      actEmployee = lastActEmployee.filter((employee) => employee.work_start_date < currentYearMonth);
      const quantityJulSepC = laidOffEmployee[2][3];
      if (actEmployee.length !== 0) {
        laidOffEmployee[2][3] = quantityJulSepC / (actEmployee.length + quantityJulSepC);
      } else if (quantityJulSepC !== 0) {
        laidOffEmployee[2][3] = quantityJulSepC / quantityJulSepC;
      }
      currentYearMonth = DateTime.fromISO(`${currentYear}-12-01`).endOf("month").toISODate();
      actEmployee = lastActEmployee.filter((employee) => employee.work_start_date < currentYearMonth);
      const quantityOctDicC = laidOffEmployee[2][4];
      if (actEmployee.length !== 0) {
        laidOffEmployee[2][4] = quantityOctDicC / (actEmployee.length + quantityOctDicC);
      } else if (quantityOctDicC !== 0) {
        laidOffEmployee[2][4] = quantityOctDicC / quantityOctDicC;
      }
    }
    return laidOffEmployee;
  },
  employeeStartEndDateCurrent(state, getters, rootState, rootGetters) {
    const employees = rootGetters["company/employees"];
    const exEmployees = rootGetters["company/employees/formerEmployees"];
    const currentYear = DateTime.local().toLocaleString({ year: "numeric" });
    const startDateEmployees = employees.filter((employee) => DateTime.fromISO(employee.work_start_date).toLocaleString({ year: "numeric" }) === currentYear);
    const endDateEmployees = exEmployees.filter((employee) => DateTime.fromISO(employee.work_end_date).toLocaleString({ year: "numeric" }) === currentYear);
    const startEndDateEmployee = [
      ["x", "ENE-MAR", "ABR-JUN", "JUL-SEP", "OCT-DIC"],
      ["Ingreso", 0, 0, 0, 0],
      ["Egreso", 0, 0, 0, 0]
    ];
    startDateEmployees.forEach((employee) => {
      const monthStart = DateTime.fromISO(employee.work_start_date).month;
      if (monthStart >= 1 && monthStart <= 3) {
        startEndDateEmployee[1][1] += 1;
      } else if (monthStart >= 4 && monthStart <= 6) {
        startEndDateEmployee[1][2] += 1;
      } else if (monthStart >= 7 && monthStart <= 9) {
        startEndDateEmployee[1][3] += 1;
      } else if (monthStart >= 10 && monthStart <= 12) {
        startEndDateEmployee[1][4] += 1;
      }
    });
    endDateEmployees.forEach((employee) => {
      const monthEnd = DateTime.fromISO(employee.work_end_date).month;
      if (monthEnd >= 1 && monthEnd <= 3) {
        startEndDateEmployee[2][1] += 1;
      } else if (monthEnd >= 4 && monthEnd <= 6) {
        startEndDateEmployee[2][2] += 1;
      } else if (monthEnd >= 7 && monthEnd <= 9) {
        startEndDateEmployee[2][3] += 1;
      } else if (monthEnd >= 10 && monthEnd <= 12) {
        startEndDateEmployee[2][4] += 1;
      }
    });
    return startEndDateEmployee;
  },
  salaryByAreaTotal(state, getters, rootState, rootGetters) {
    const salaryReport = state.listS;
    const newData = [];
    salaryReport.map((area) => {
      newData.push([area.area__name, area.salary_sum]);
    });
    return newData;
  },
  workingEnvironmentByWeekMonth(state) {
    const sentimentSurveys = state.listSR;
    sentimentSurveys[0][0] = "x";
    return sentimentSurveys;
  },
  workingEnvironmentByMonthYear(state, getters, rootState, rootGetters) {
    const sentimentSurveys = rootState.company.surveys.list;
    const yearCurrent = DateTime.local().year;
    const sentimentSurveysCurrent = sentimentSurveys.filter(
      (sentimentSurvey) => DateTime.fromISO(sentimentSurvey.created).year === yearCurrent
    );
    const newData = [
      ["x", "EN", "FB", "MR", "AB", "MY", "JN", "JL", "AG", "SP", "OC", "NV", "DC"],
      [`${yearCurrent}`]
    ];
    for (let i = 1; i <= 12; i += 1) {
      newData[1].push(sentimentSurveysCurrent.filter(
        (sentiment) => DateTime.fromISO(sentiment.created).month === i
      ).length);
    }
    return newData;
  }
};
