import actions from "./actions";
import mutations from "./mutations";
const state = {
  list: null
};
export default {
  actions,
  mutations,
  state,
  namespaced: true
};
