var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Request from "@/models/request";
export default {
  deleteRequest(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, { data }) {
      const request = new Request(data);
      yield request.delete();
      const requests = state.list.filter((_request) => _request.id !== request.id);
      commit("setRequests", { data: requests });
    });
  },
  approveRequest(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { data }) {
      const request = new Request(data);
      const approved = yield request.approve();
      commit("setRequest", { request: approved });
    });
  },
  rejectRequest(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, { data, note }) {
      const request = new Request(data);
      const rejected = yield request.reject({ note });
      const requests = state.list.filter((_request) => _request.id !== rejected.id);
      commit("setRequests", { data: requests });
    });
  },
  remindRequest(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, { data }) {
      const request = new Request(data);
      const remind = yield request.remind();
      const requests = state.list.filter((_request) => _request.id !== remind.id);
      commit("setRequests", { data: requests });
    });
  },
  updateRequest(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, { data }) {
      const request = new Request(data);
      const update = yield request.update(data);
      const requests = state.list.filter((_request) => _request.id !== update.id);
      commit("setRequests", { data: requests });
      return update;
    });
  },
  addFileRequest(_0, _1) {
    return __async(this, arguments, function* ({ state }, { data }) {
      const request = new Request(data);
      yield request.addFile(data);
    });
  },
  deleteFileRequest(_0, _1) {
    return __async(this, arguments, function* ({ state }, { data }) {
      const request = new Request(data);
      yield request.deleteFile(data.idFile);
    });
  }
};
