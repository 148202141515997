var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import TimeOffPolicy from "@/models/timeOffPolicy";
import TimeOffPeriod from "@/models/timeOffPeriod";
export default {
  // getTimeOffPolicies ({ commit, rootState }) {
  //   return TimeOffPolicy.list()
  //     .then((policies) => {
  //       const promises = []
  //       policies.forEach((policy) => {
  //         promises.push(policy.getPeriods())
  //       })
  //       Promise.all(promises)
  //         .then(() => {
  //           commit('setTimeOffPolicies', { policies })
  //         })
  //       return policies
  //     })
  // },
  updateTimeOffPolicy({ commit }, { data }) {
    return new TimeOffPolicy(data).update().then((policy) => {
      data.periods.forEach((period) => {
        new TimeOffPeriod(period).update();
      });
      return policy;
    }).then((policy) => {
      commit("setTimeOffPolicy", { data: policy });
      return policy;
    });
  },
  deleteTimeOffPolicy(_0, _1) {
    return __async(this, arguments, function* ({ commit, state, rootState }, { id }) {
      yield new TimeOffPolicy({ id }).delete();
      commit("removeTimeOffPolicy", { id });
    });
  }
};
