var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";
import { api } from "@/api";
import Candidate from "@/models/candidate";
import { DataError } from "@/exceptions";
TimeAgo.addLocale(es);
const statusConfig = [
  {
    value: 0,
    display_name: "Borrador",
    color: "-grey",
    button: {
      text: "PUBLICAR VACANTE",
      style: "--success",
      action: 1
    }
  },
  {
    value: 1,
    display_name: "Activa",
    color: "-blue7",
    button: {
      text: "PAUSAR VACANTE",
      style: "--alert",
      action: 2
    }
  },
  {
    value: 2,
    display_name: "Pausada",
    color: "-yellow2",
    button: {
      text: "CERRAR VACANTE",
      style: "--danger",
      action: 3
    }
  },
  {
    value: 3,
    display_name: "Cerrado",
    color: "-red2"
  }
];
export default {
  getVacancies(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState, rootGetters }, { filters, id }) {
      try {
        const { data } = yield api.company(id).vacancies.getList(filters);
        const areas = rootGetters["company/areas/companyAreas"];
        const vacancies = data.map((vacancy) => {
          const timeAgo = new TimeAgo("es");
          const newArea = areas.find((area) => area.id === vacancy.area);
          const newStatus = statusConfig.find((item) => item.value === vacancy.status);
          return __spreadProps(__spreadValues({}, vacancy), {
            totalCandidates: vacancy.candidates.length,
            areaName: newArea && newArea.name || "Sin \xE1rea",
            status: newStatus,
            upon: timeAgo.format(new Date(vacancy.created))
          });
        });
        return vacancies;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "No se pudieron cargar las vacantes"
        });
        return null;
      }
    });
  },
  setTotalVacanciesNumber({ commit, rootState }, { vacanciesNumber }) {
    try {
      commit("setTotalVacancies", vacanciesNumber);
    } catch (error) {
      this.$notify({
        type: "error",
        text: "No se pudieron cargar las vacantes"
      });
    }
  },
  getVacancy(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { id }) {
      const { data } = yield api.company().vacancies.getOne(id);
      return data;
    });
  },
  getVacancyPage({ commit }) {
    return api.company().vacancies.getPublicPage().then((response) => {
      const { data } = response;
      return data;
    });
  },
  getVacancyPublic({ commit }, { id }) {
    return api.company().vacancies.getOnePublic(id).then((response) => {
      const { data } = response;
      return data;
    });
  },
  createVacancy({ commit, rootState }, { data }) {
    return api.company(rootState.me.user.company.id).vacancies.create(data).then((response) => {
      commit("setTotalVacancies", rootState.company.vacancies.totalVacancies + 1);
      return response.data;
    });
  },
  updateVacancy({ commit }, { data }) {
    return api.company().vacancies.update(data).then((response) => response.data);
  },
  updateVacancyStatus({ commit }, { data }) {
    return api.company().vacancies.updateStatus(data).then((response) => response.data);
  },
  updateVacancyVisibility({ commit }, { data }) {
    return api.company().vacancies.updateVisibility(data).then((response) => response.data);
  },
  deleteVacancy({ commit }, { id }) {
    return api.company().vacancies.delete(id).then(() => id);
  },
  getGeneralReport({ commit }) {
    return api.company().vacancies.getGeneralReport();
  },
  getVacancyReport({ commit }, { vacancyId }) {
    return api.company().vacancies.getVacancyReport(vacancyId);
  },
  getCandidatesReport({ commit }, { vacancyId }) {
    return api.company().vacancies.getCandidatesReport(vacancyId);
  },
  getRounds({ commit }, { id }) {
    return api.company().vacancies.getRounds(id).then((response) => {
      const { data } = response;
      const formattedStatuses = data.map((status) => __spreadProps(__spreadValues({}, status), {
        isEditing: false,
        showInstructions: false,
        instructionCopy: "Presiona <i>enter</i> para guardar el nombre",
        actions: []
      }));
      return formattedStatuses.sort((a, b) => a.position - b.position);
    });
  },
  createRound({ commit }, { data }) {
    return api.company().vacancies.createRound(data).then((response) => response.data);
  },
  updateRound({ commit }, { data }) {
    return api.company().vacancies.updateRound(data).then((response) => response.data);
  },
  deleteRound({ commit }, { data }) {
    return api.company().vacancies.deleteRound(data.id).then((response) => response.data);
  },
  createCandidate({ commit }, { data }) {
    return api.candidates.create(data);
  },
  updateCandidate({ commit }, { data }) {
    return api.candidates.update(data).then((response) => response.data);
  },
  getAllCandidates({ commit }) {
    return api.candidates.getAll().then((response) => {
      const { data } = response;
      return data;
    });
  },
  getCandidatesByVacancy({ commit, rootGetters }, { id }) {
    return api.candidates.getList(id).then((response) => {
      const { data } = response;
      const employees = rootGetters["company/employees/currentEmployees"];
      const formerEmployees = rootGetters["company/employees/formerEmployees"];
      const formattedCandidates = data.map((candidate) => {
        let likesCount = 0;
        let dislikesCount = 0;
        const newCandidate = __spreadValues({}, candidate);
        if (candidate.round === null) {
          if (candidate.hired) {
            newCandidate.round = "hired";
          } else if (candidate.rejected) {
            newCandidate.round = "rejected";
          } else {
            newCandidate.round = "candidate";
          }
        }
        const candidateComments = candidate.comments.map((comment) => {
          if (comment.reaction === "like") {
            likesCount += 1;
          }
          if (comment.reaction === "dislike") {
            dislikesCount += 1;
          }
          const recruiter = employees.concat(formerEmployees).find((employee) => employee.id === comment.recruiter);
          return __spreadProps(__spreadValues({}, comment), {
            recruiter,
            upon: new TimeAgo("es").format(new Date(comment.date))
          });
        });
        return __spreadProps(__spreadValues({}, newCandidate), {
          comments: candidateComments,
          likes: likesCount,
          dislikes: dislikesCount
        });
      });
      return formattedCandidates;
    });
  },
  getCandidate({ commit, rootGetters }, { id }) {
    return api.candidates.getOne(id).then((response) => {
      const candidate = response.data;
      const timeAgo = new TimeAgo("es");
      const employees = rootGetters["company/employees/currentEmployees"];
      const formerEmployees = rootGetters["company/employees/formerEmployees"];
      let comments;
      if (candidate.comments) {
        comments = candidate.comments.map((comment) => {
          const recruiter = employees.concat(formerEmployees).find((employee) => employee.id === comment.recruiter);
          return __spreadProps(__spreadValues({}, comment), {
            recruiter,
            upon: timeAgo.format(new Date(comment.date))
          });
        });
      }
      return __spreadProps(__spreadValues({}, candidate), {
        comments
      });
    });
  },
  updateCandidateRound({ commit }, { data }) {
    return api.candidates.updateRound(data);
  },
  getComments({ commit }, { id }) {
    return api.comments.getList(id).then((response) => {
      const { data } = response;
      return data;
    });
  },
  createComments({ commit }, { data, id }) {
    return api.comments.create(data, id).then((response) => response.data);
  },
  setRecruiter({ commit }, { vacancy, employee }) {
    return api.company().vacancies.setRecruiter(vacancy, { employee });
  },
  /* eslint-disable camelcase */
  updateRecruiters({ commit }, { vacancy, employee_ids }) {
    return api.company().vacancies.updateRecruiters(vacancy, { employee_ids });
  },
  /* eslint-enable camelcase */
  deleteRecruiter({ commit }, { vacancy, employee }) {
    return api.company().vacancies.deleteRecruiter(vacancy, { employee });
  },
  deleteCandidate(_0) {
    return __async(this, arguments, function* ({ commit, state }, { id } = {}) {
      if (!id) {
        throw new DataError("The candidate is not defined");
      }
      return api.candidates.delete(id);
    });
  },
  notifyCandidate(_0) {
    return __async(this, arguments, function* ({ commit, state }, { data } = {}) {
      if (!data.id) {
        throw new DataError("The candidate is not defined");
      }
      const candidateCurrent = data;
      const candidate = new Candidate(candidateCurrent);
      yield candidate.notifyCandidate(data);
      return candidate;
    });
  }
};
