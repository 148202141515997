import Model from "@/models/v1/model";
export default class Incidence extends Model {
  static get modelName() {
    return "Incidence";
  }
  static apiNamespace() {
    return "api/v2";
  }
  static resourceNamespace() {
    return "incidences";
  }
  static fields() {
    return [
      "id",
      "employee",
      "comments",
      "start_date",
      "end_date",
      "type",
      "quantity",
      "with_salary",
      "status",
      "note",
      "reviewer",
      "reviewer_at",
      "file"
    ];
  }
  static defaults() {
    return {
      note: "",
      file: []
    };
  }
  addFile(data) {
    const formData = new FormData();
    formData.append("file", data.file);
    return this.constructor.post(`${this.resourcePath()}/files/`, formData, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  }
  deleteFile(idFile) {
    return this.constructor.delete(
      `${this.resourcePath()}/files/${idFile}/`
    );
  }
}
