var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;
const me = {
  get() {
    return axios.get("/api/v1/me/");
  }
};
function user(id) {
  return {
    get() {
      return axios.get(`/api/v1/users/${id}/`);
    }
  };
}
function verifyAccount(key) {
  return {
    getData() {
      const uninterceptedAxiosInstance = axios.create();
      return uninterceptedAxiosInstance.get(`/api/v1/verify_account/${key}/`, { withCredentials: false });
    },
    setPassword(data) {
      const uninterceptedAxiosInstance = axios.create();
      return uninterceptedAxiosInstance.post(`/api/v1/verify_account/${key}/`, __spreadProps(__spreadValues({}, data), { withCredentials: false, activate_user: data.activateAccount }));
    }
  };
}
const api = {
  me,
  user,
  verifyAccount
};
export default api;
