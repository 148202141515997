var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "phone-box", class: {
    "phone-box--has-focus": _vm.isFocus,
    "phone-box--has-error": _vm.hasError || _vm.error
  } }, [_vm.isOpen ? _c("div", { staticClass: "phone-box__list", class: {
    "phone-box__list--in-the-box": _vm.isInTheBox,
    "pointer-events-none": _vm.disabledPrefix
  }, style: __spreadValues({}, _vm.isInTheBox ? {
    top: `${_vm.positionTop}px !important`,
    left: `${_vm.positionLeft}px !important`
  } : {}) }, [_c("div", { staticClass: "phone-box__list-search" }, [_c("Textbox", { attrs: { "icon": "search", "placeholder": "Buscar pa\xEDs...", "size": "small", "name": "search", "autofocus": true }, model: { value: _vm.search, callback: function($$v) {
    _vm.search = $$v;
  }, expression: "search" } })], 1), _c("div", { staticClass: "phone-box__list-countries" }, _vm._l(_vm.countries, function(country) {
    return _c("div", { key: country.iso, staticClass: "phone-box__list-item", on: { "click": () => _vm.onSelectCountry(country) } }, [_c("div", { staticClass: "vti__flag", class: country.iso.toLowerCase() }), _c("div", { staticClass: "phone-box__country-name" }, [_vm._v(" " + _vm._s(country.name) + " ")])]);
  }), 0)]) : _vm._e(), _c("div", { staticClass: "phone-box__country", class: {
    "pointer-events-none": _vm.disabledPrefix
  }, on: { "click": _vm.toggleBox } }, [_c("div", { staticClass: "vti__flag", class: _vm.countrySelected.toLowerCase() }), _c("div", { staticClass: "phone-box__country-prefix" }, [_vm._v(" +" + _vm._s(_vm.countryPrefix) + " ")]), !_vm.disabledPrefix ? _c("div", { staticClass: "phone-box__country-icon" }, [_vm._v(" " + _vm._s(_vm.isOpen ? "\u25B2" : "\u25BC") + " ")]) : _vm._e()]), _c("input", { directives: [{ name: "model", rawName: "v-model", value: _vm.phoneComputed, expression: "phoneComputed" }], staticClass: "phone-box__control", attrs: { "placeholder": _vm.placeholder, "required": _vm.required, "type": "text" }, domProps: { "value": _vm.phoneComputed }, on: { "focus": _vm.onFocus, "blur": _vm.onBlur, "keypress": _vm.onKeyPress, "input": function($event) {
    if ($event.target.composing)
      return;
    _vm.phoneComputed = $event.target.value;
  } } })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
