var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c(_vm.whichComponent, _vm._b({ tag: "component", staticClass: "button inline-flex items-center whitespace-nowrap relative justify-center break-normal", class: _vm.classes, attrs: { "disabled": _vm.disabledButton, "type": _vm.buttonType, "role": "button" }, on: { "click": _vm.onClick } }, "component", __spreadValues(__spreadValues(__spreadValues({}, _vm.to ? { to: _vm.to } : {}), _vm.href ? { href: _vm.href } : {}), _vm.$attrs), false), [_vm._t("start-icon"), _vm.loading ? _c("BLoading", { attrs: { "theme": _vm.theme } }) : _vm._e(), _vm.iconStartActive ? _c("span", [_vm._t("icon-btn"), _vm._t("default")], 2) : _vm._e(), _vm._v(" " + _vm._s(_vm.capitalize ? _vm.label.toUpperCase() : _vm.label) + " "), _vm._t("default"), _vm._t("end-icon")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };
