var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { DateTime } from "luxon";
import { workingDays } from "@/utils/date";
function capitalizeWords(s) {
  const arr = s.split(" ");
  return arr.map((item) => {
    const first = item[0];
    return item.replace(first, first.toUpperCase());
  }).join(" ");
}
export default {
  formatedTimeOff(state, getters, rootState, rootGetters) {
    const employees = rootGetters["company/employees/currentEmployees"];
    const employeeCurrent = rootState.me;
    return state.list.filter((request) => request.employee && employees.find((_employee) => _employee.id === request.employee)).map((incidencia) => {
      let employee = employees.find((_employee) => _employee.id === incidencia.employee);
      if (employee.id === employeeCurrent.id) {
        employee = employeeCurrent;
      }
      let type = "";
      if (incidencia.type === "VA") {
        type = "Vacaciones";
      } else if (incidencia.type === "PTO") {
        type = "D\xEDas personales";
      } else if (incidencia.type === "AB") {
        type = "Falta";
      } else if (incidencia.type === "HO") {
        type = "Home office";
      } else if (incidencia.type === "DE") {
        type = "Retraso";
      } else if (incidencia.type === "PER") {
        type = "Permiso";
      } else if (incidencia.type === "OPD") {
        type = "D\xEDa operado";
      }
      const start = capitalizeWords(DateTime.fromISO(incidencia.start_date).setLocale("es").toFormat("dd MMM yy"));
      const end = capitalizeWords(DateTime.fromISO(incidencia.end_date).setLocale("es").toFormat("dd MMM yy"));
      const { fromISO } = DateTime;
      const periodInterval = start === end ? `${start}` : `${start} al ${end}`;
      const validInterval = fromISO(incidencia.start_date) <= fromISO(incidencia.end_date);
      const typeText = type;
      const statusList = {
        APR: "Aprobado",
        REJ: "Rechazado",
        PEN: "Pendiente"
      };
      const reviewer = employees.find((_employee) => _employee.id === incidencia.reviewer);
      const status = statusList[incidencia.status];
      const statusText = status;
      const approbedBy = reviewer && reviewer.full_name || "No disponible";
      const holidays = rootState.company.holidays.list;
      const days = workingDays(incidencia.start_date, incidencia.end_date, holidays, employee);
      const total = Math.max(0, days);
      const employeeName = employee.full_name;
      const employeeArea = employee.areaName || "No asignado";
      const employeePosition = employee.work_puesto || "No asignado";
      return Object.assign(__spreadProps(__spreadValues({}, incidencia), {
        employee,
        employeeName,
        employeeArea,
        employeePosition,
        approbedBy,
        end,
        period: validInterval ? periodInterval : `Per\xEDodo invalido (${periodInterval})`,
        type: incidencia.type,
        typeText,
        start,
        status,
        statusText,
        total,
        quantity: ["BO", "DP", "TH", "DI", "DEH", "TEH"].indexOf(incidencia.type) >= 0 ? incidencia.quantity : null,
        files: incidencia.file,
        reviewer,
        reviewerName: reviewer && reviewer.full_name,
        reviewerEmail: reviewer && reviewer.username
      }));
    });
  }
};
