var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { api } from "@/api";
export default {
  namespaced: true,
  state: {
    pendings: [],
    workedHours: [],
    companyConfig: null
  },
  getters: {
    pendingsList: (state) => state.pendings,
    workedHoursList: (state) => state.workedHours,
    workedHoursListV3: (state) => state.workedHoursV3,
    companyConfig: (state) => state.companyConfig
  },
  mutations: {
    setPendings(state, payload) {
      state.pendings = payload;
    },
    setWorkedHours(state, payload) {
      state.workedHours = payload;
    },
    setCompanyConfig(state, payload) {
      state.companyConfig = payload;
    }
  },
  actions: {
    getPendingData(_0, _1) {
      return __async(this, arguments, function* ({ commit }, {
        companyId,
        startDate,
        endDate,
        status,
        area_in,
        location_in,
        incidence_type_in,
        owner_full_name
      }) {
        const { data } = yield api.timeClock.pendings({
          companyId,
          startDate,
          endDate,
          status,
          area_in,
          location_in,
          incidence_type_in,
          owner_full_name
        });
        commit("setPendings", data);
      });
    },
    getWorkedHoursData(_0, _1) {
      return __async(this, arguments, function* ({ commit }, { companyId, startDate, endDate }) {
        const { data } = yield api.timeClock.workedHours({ companyId, startDate, endDate });
        commit("setWorkedHours", data);
      });
    },
    downloadWorkedHoursData(_0, _1) {
      return __async(this, arguments, function* ({ commit }, { companyId, startDate, endDate }) {
        const { data } = yield api.timeClock.downloadWorkedHours({ companyId, startDate, endDate });
        return data;
      });
    },
    getCompanyConfig(_0) {
      return __async(this, arguments, function* ({ commit }) {
        const { data } = yield api.timeClock.getCompanyConfig();
        commit("setCompanyConfig", data);
      });
    }
  }
};
