export default {
  setTimeOffPolicies(state, { data }) {
    state.timeOffList = data;
  },
  setTimeOffPolicy(state, { data }) {
    const { id } = data;
    const list = [...state.timeOffList];
    const index = list.findIndex((timeOffPolicy) => timeOffPolicy.id === id);
    if (index >= 0) {
      list[index] = data;
    } else {
      list.push(data);
    }
    state.timeOffList = list;
  },
  removeTimeOffPolicy(state, { id }) {
    state.timeOffList = state.timeOffList.filter((policy) => policy.id !== id);
  }
};
