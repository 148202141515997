export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const GET_TRAININGS = "GET_TRAININGS";
export const CREATE_TRAINING = "CREATE_TRAINING";
export const EDIT_TRAINING = "EDIT_TRAINING";
export const DELETE_TRAINING = "DELETE_TRAINING";
export const ARCHIVE_TRAINING = "ARCHIVE_TRAINING";
export const UNARCHIVE_TRAINING = "UNARCHIVE_TRAINING";
export const CLONE_TRAINING = "CLONE_TRAINING";
export const SET_GUESTS_IN_PROCESS = "SET_GUESTS_IN_PROCESS";
export const REMOVE_GUESTS_IN_PROCESS = "REMOVE_GUESTS_IN_PROCESS";
export const SET_GUESTS_FINISHED = "SET_GUESTS_FINISHED";
export const CLEAN_SELECTED_TRAINING = "CLEAN_SELECTED_TRAINING";
export const SET_TEAM_TRAININGS = "SET_TEAM_TRAININGS";
export const EDIT_TEAM_TRAINING = "EDIT_TEAM_TRAINING";
export const SET_MY_TRAININGS = "SET_MY_TRAININGS";
export const SET_MY_COMPLETED_TRAININGS = "SET_MY_COMPLETED_TRAININGS";
export const UPDATE_MY_TRAINING = "UPDATE_MY_TRAINING";
export const MARK_TASK_AS_DONE = "MARK_TASK_AS_DONE";
export const MARK_TASK_AS_UNDONE = "MARK_TASK_AS_UNDONE";
