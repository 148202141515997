import Model from "./model";
export default class Holiday extends Model {
  static get modelName() {
    return "Holiday";
  }
  static apiNamespace() {
    return "api/v2";
  }
  static resourceNamespace() {
    return "holidays";
  }
  static fields() {
    return [
      "company",
      "date"
    ];
  }
}
