var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import store from "@/store";
import { api } from "@/api";
const validateLevel = (route) => {
  var _a, _b, _c, _d;
  const { url_username } = ((_c = (_b = (_a = store) == null ? void 0 : _a.state) == null ? void 0 : _b.me) == null ? void 0 : _c.user) || { url_username: null };
  if (url_username === ((_d = route == null ? void 0 : route.params) == null ? void 0 : _d.url)) {
    return "employee";
  }
  return "boss";
};
const routes = {
  path: "/empleado/:url",
  name: "employee-profile",
  component: () => import(
    /* webpackChunkName: "profile" */
    "@/views/profileV2/ProfileLayout"
  ),
  beforeEnter: (to, from, next) => __async(void 0, null, function* () {
    let profileId = "";
    if (to.params.id) {
      localStorage.setItem("profile_id", to.params.id);
      localStorage.setItem("profile_url", to.params.url);
      profileId = to.params.id;
    } else if (to.params.url === from.params.url && to.params.url === localStorage.getItem("profile_url")) {
      profileId = localStorage.getItem("profile_id");
    } else {
      const data = yield api.profile.getIdFromUrl(to.params.url);
      profileId = data;
      localStorage.setItem("profile_id", data);
      localStorage.setItem("profile_url", to.params.url);
    }
    if (store.state.me.user.role !== "HRA" && store.state.me.user.role !== "OSR" && !store.state.company.publicProfile && !store.state.me.firstSubordinates.includes(profileId) && profileId !== store.state.me.user.employee.id) {
      next({ name: "not-found" });
    } else if (store.state.me.user.role === "OSR" && profileId === store.state.me.user.id) {
      next({ name: "osr-profile" });
    } else
      next();
  }),
  redirect: { name: "profile-personal-info" },
  children: [
    {
      path: "personal",
      name: "profile-personal-info",
      component: () => import(
        /* webpackChunkName: "profile" */
        "@/views/profileV2/PublicProfile"
      ),
      meta: {
        requireAuth: true,
        module: "directory",
        publicName: "Perfil de colaborador",
        backTo: "directory",
        backToName: "Lista de colaboradores"
      }
    },
    {
      path: "privado",
      name: "profile-private-info",
      component: () => import(
        /* webpackChunkName: "profile" */
        "@/views/profileV2/PrivateProfileV2"
      ),
      meta: {
        requireAuth: true,
        module: "directory",
        publicName: "Perfil de colaborador",
        backTo: "back",
        validateTab: {
          key: "tab_private_data",
          subMenu: "employee_info",
          level: validateLevel
        }
      }
    },
    {
      path: "privado2",
      name: "profile-private-info-2",
      component: () => import(
        /* webpackChunkName: "profile" */
        "@/views/profileV2/PrivateProfile"
      ),
      meta: {
        requireAuth: true,
        module: "directory",
        publicName: "Perfil de colaborador",
        backTo: "back"
      }
    },
    {
      path: "documentos",
      name: "profile-docs-info",
      component: () => import(
        /* webpackChunkName: "profile" */
        "@/views/profileV2/DocsProfile"
      ),
      meta: {
        requireAuth: true,
        module: "directory",
        publicName: "Perfil de colaborador",
        backTo: "back"
      }
    },
    {
      path: "evaluaciones",
      name: "profile-evaluation-info",
      component: () => import(
        /* webpackChunkName: "profile" */
        "@/views/profileV2/EvaluationProfile"
      ),
      meta: {
        requireAuth: true,
        module: "directory",
        publicName: "Perfil de colaborador",
        backTo: "back",
        validateTab: {
          key: "tab_evaluations",
          subMenu: "employee_info",
          level: validateLevel
        }
      }
    },
    {
      path: "capacitaciones",
      name: "profile-training-info",
      component: () => import(
        /* webpackChunkName: "profile" */
        "@/views/profileV2/TrainingsProfile"
      ),
      meta: {
        requireAuth: true,
        module: "directory",
        publicName: "Perfil de colaborador",
        backTo: "back",
        validateTab: {
          key: "tab_trainings",
          subMenu: "employee_info",
          level: validateLevel
        }
      }
    },
    {
      path: "permisos",
      name: "profile-permission-info",
      component: () => import(
        /* webpackChunkName: "profile" */
        "@/views/profileV2/PermissionProfile"
      ),
      meta: {
        requireAuth: true,
        module: "directory",
        publicName: "Perfil de colaborador",
        backTo: "back"
      }
    },
    {
      path: "nomina",
      name: "profile-payroll-info",
      component: () => import(
        /* webpackChunkName: "profile" */
        "@/views/profileV2/PayrollProfile"
      ),
      meta: {
        requireAuth: true,
        module: "directory",
        publicName: "Perfil de colaborador",
        backTo: "back"
      }
    },
    {
      path: "descripcion-de-puesto",
      name: "profile-jobprofile-info",
      component: () => import(
        /* webpackChunkName: "profile" */
        "@/views/profileV2/ProfileJobDescription"
      ),
      meta: {
        requireAuth: true,
        module: "directory",
        publicName: "Descripci\xF3n de Puesto",
        backTo: "back"
      }
    },
    {
      path: "historial",
      name: "profile-history-info",
      component: () => import(
        /* webpackChunkName: "profile" */
        "@/views/profileV2/PrivateHistory"
      ),
      meta: {
        requireAuth: true,
        module: "directory",
        publicName: "Perfil de colaborador",
        backTo: "back",
        validateTab: {
          key: "tab_history",
          subMenu: "employee_info",
          level: validateLevel
        }
      }
    },
    {
      path: "actualizacion-expediente",
      name: "profile-update-info",
      component: () => import(
        /* webpackChunkName: "profile" */
        "@/views/profileV2/UpdateProfile"
      ),
      meta: {
        requireAuth: true,
        module: "directory",
        publicName: "Perfil de colaborador",
        backTo: "back"
      }
    }
  ]
};
export default routes;
