const isArray = new RegExp("^\\[.*\\]$", "gm");
const removeBrackets = new RegExp("^\\[|\\]$", "gm");
const isArrayKey = new RegExp("^.+\\[.*\\]$", "gm");
const deepField = (object, path) => {
  const paths = path.split(".");
  let current = object;
  let i;
  for (i = 0; i < paths.length; i += 1) {
    if (isArray.test(paths[i])) {
      current = current[paths[i].replaceAll(removeBrackets, "")];
    } else if (isArrayKey.test(paths[i])) {
      const elementSplited = paths[i].split("[").map((key) => key.replaceAll("]", ""));
      current = deepField(current[elementSplited[0]], elementSplited[1]);
    } else if (current === null || current[paths[i]] === void 0) {
      return void 0;
    } else {
      current = current[paths[i]];
    }
  }
  return current;
};
export default deepField;
