const payroll = {
  path: "nomina",
  name: "payrollView",
  component: () => import("@/views/payroll/PayrollLayout"),
  meta: {
    requireAuth: true,
    module: "zentric",
    publicName: "N\xF3mina",
    permission: true,
    role: ["HRA"]
  },
  redirect: { name: "payrollDashboard" },
  children: [
    {
      path: "activar",
      name: "payrollActivateForm",
      component: () => import("@/views/payroll/PayrollActivationForm"),
      meta: {
        requireAuth: true,
        module: "zentric",
        publicName: "N\xF3mina",
        permission: true,
        role: ["HRA"]
      }
    },
    {
      path: "dashboard",
      name: "payrollDashboard",
      component: () => import("@/views/payroll/PayrollHome"),
      meta: {
        requireAuth: true,
        module: "zentric",
        publicName: "N\xF3mina",
        permission: true,
        role: ["HRA"]
      }
    },
    {
      path: "nominas",
      name: "payrollsList",
      component: () => import("@/views/payroll/payrolls/PayrollsLayout"),
      meta: {
        requireAuth: true,
        module: "zentric",
        publicName: "N\xF3minas",
        backTo: "payrollDashboard",
        permission: true,
        role: ["HRA"]
      },
      redirect: { name: "payrollsListActive" },
      children: [
        {
          path: "activas",
          name: "payrollsListActive",
          component: () => import("@/views/payroll/payrolls/PayrollsActive"),
          meta: {
            requireAuth: true,
            publicName: "N\xF3minas",
            module: "zentric",
            backTo: "payrollDashboard",
            permission: true,
            role: ["HRA"]
          },
          children: [
            {
              path: "detalle/nomina-:id-:year-:period",
              alias: "detalle/nomina-:id-:year",
              name: "payrollsListActiveDetail",
              component: () => import("@/views/payroll/payrolls/PayrollDetail/PayrollDetail"),
              meta: {
                requireAuth: true,
                publicName: "N\xF3minas",
                backTo: "payrollsListActive",
                module: "zentric",
                permission: true,
                role: ["HRA"]
              },
              children: [
                {
                  path: "colaborador-:employeeId",
                  name: "payrollsListActiveDetailEmployee",
                  component: () => import("@/views/payroll/payrolls/PayrollDetail/PayrollDetailEmployee"),
                  meta: {
                    requireAuth: true,
                    publicName: "N\xF3minas",
                    backTo: "payrollsListActiveDetail",
                    module: "zentric",
                    permission: true,
                    role: ["HRA"]
                  }
                }
              ]
            }
          ]
        },
        {
          path: "normales",
          name: "payrollsListNormal",
          component: () => import("@/views/payroll/payrolls/Payrolls"),
          meta: {
            requireAuth: true,
            publicName: "N\xF3minas",
            backTo: "payrollDashboard",
            module: "zentric",
            permission: true,
            role: ["HRA"]
          }
        },
        {
          path: "especiales",
          name: "payrollsListSpecial",
          component: () => import("@/views/payroll/payrolls/PayrollsSpecial"),
          meta: {
            requireAuth: true,
            publicName: "N\xF3minas",
            backTo: "payrollDashboard",
            module: "zentric",
            permission: true,
            role: ["HRA"]
          }
        }
      ]
    },
    // {
    //   path: 'Recibos de nómina',
    //   name: 'payrollReceipts'
    // }
    {
      path: "incidencias",
      name: "payrollIncidences",
      component: () => import("@/views/payroll/payrollIncidences/PayrollIncidencesInPayroll"),
      meta: {
        requireAuth: true,
        module: "zentric",
        publicName: "Incidencias",
        backTo: "payrollDashboard",
        permission: true,
        role: ["HRA"]
      },
      children: [
        {
          path: "configurar/:id",
          name: "payrollIncidencesConfigurationEdit",
          component: () => import("@/views/payroll/payrollIncidences/PayrollIncidencesConfiguration"),
          meta: {
            requireAuth: true,
            publicName: "Configuraci\xF3n de incidencias para n\xF3mina",
            backTo: "back",
            module: "zentric",
            permission: true,
            role: ["HRA"]
          }
        }
      ]
    },
    {
      path: "colaboradores",
      name: "payrollEmployees",
      component: () => import("@/views/payroll/payrollEmployees/PayrollEmployeesLayout"),
      meta: {
        requireAuth: true,
        module: "zentric",
        publicName: "Colaboradores",
        backTo: "payrollDashboard",
        permission: true,
        role: ["HRA"]
      },
      redirect: { name: "PayrollEmployeesStatus" },
      children: [
        {
          path: "estado",
          name: "PayrollEmployeesStatus",
          component: () => import("@/views/payroll/payrollEmployees/PayrollEmployees"),
          meta: {
            requireAuth: true,
            module: "zentric",
            publicName: "Colaboradores",
            backTo: "payrollDashboard",
            permission: true,
            role: ["HRA"]
          }
        },
        {
          path: "altas",
          name: "PayrollEmployeesUps",
          component: () => import("@/views/payroll/payrollEmployees/PayrollEmployeesUps"),
          meta: {
            requireAuth: true,
            module: "zentric",
            publicName: "Altas de colaboradores",
            backTo: "payrollDashboard",
            permission: true,
            role: ["HRA"]
          }
        },
        {
          path: "bajas",
          name: "PayrollEmployeesLeaves",
          component: () => import("@/views/payroll/payrollEmployees/PayrollEmployeesLeaves"),
          meta: {
            requireAuth: true,
            module: "zentric",
            publicName: "Bajas de colaboradores",
            backTo: "payrollDashboard",
            permission: true,
            role: ["HRA"]
          }
        },
        {
          path: "historial-de-cambios",
          name: "PayrollEmployeesHistoryChanges",
          component: () => import("@/views/payroll/payrollEmployees/PayrollEmployeesHistory"),
          meta: {
            requireAuth: true,
            module: "zentric",
            publicName: "Historial de cambios de colaboradores",
            backTo: "payrollDashboard",
            permission: true,
            role: ["HRA"]
          }
        }
      ]
    },
    {
      path: "reportes",
      name: "payrollReports",
      component: () => import("@/views/payroll/payrollReports/PayrollReports"),
      meta: {
        requireAuth: true,
        publicName: "Reportes",
        backTo: "back",
        permission: true,
        role: ["HRA"]
      }
    }
  ]
};
export default payroll;
