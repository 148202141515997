var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "tag", class: [
    { "tag--is-removable": _vm.removable },
    `tag--${_vm.status}`,
    `tag--size-${_vm.size}`
  ] }, [_vm.icon ? _c("span", { staticClass: "tag__icon" }, [_c("Icon", { attrs: { "icon": _vm.icon } })], 1) : _vm._e(), _c("span", [_vm._t("default")], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
