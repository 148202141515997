var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { zentric } from "@/api";
import { DateTime } from "luxon";
const state = {
  isActive: false,
  employeeInfo: null,
  payrolls: [],
  payrollSelected: null,
  calendarInPayrollSelected: [],
  dashboard: {},
  hasPayrolls: false,
  organigrams: [],
  calendars: [],
  loadings: {
    calendars: false,
    organigrams: false,
    dashboard: false,
    payrolls: false,
    calendarsInPayrolls: false
  },
  payrollEmpty: false,
  isEmptyCalendars: false,
  // Accumulated Info
  // Status Get: Pending, Success, Error || null
  accumulatedGetStatus: null,
  accumulatedDataByMonth: [],
  accumulatedCheckDate: null
};
const actions = {
  getIntegrationStatus(_0) {
    return __async(this, arguments, function* ({ commit }) {
      let isActive = false;
      try {
        const zentricStatus = yield zentric.getStatus().then(({ data }) => data);
        const { status } = zentricStatus;
        isActive = status === "active";
      } catch (_) {
        console.error(_);
      }
      commit("setIsActive", isActive);
      return isActive;
    });
  },
  getEmployeeInfo(_0, _1) {
    return __async(this, arguments, function* ({ commit }, employeeSlug) {
      let employeeInfo = null;
      try {
        employeeInfo = yield zentric.getEmployeeByURL(employeeSlug).then(({ data }) => data);
      } catch (_) {
        employeeInfo = null;
      }
      commit("setEmployeeInfo", employeeInfo);
    });
  },
  getPayrolls(_0) {
    return __async(this, arguments, function* ({ commit, dispatch }) {
      commit("setLoading", ["payrolls", true]);
      commit("setLoading", ["calendarsInPayrolls", true]);
      const date = new Date();
      const year = date.getFullYear();
      let payrolls = yield zentric.getPayrolls(1, year);
      if (!Array.isArray(payrolls)) {
        payrolls = [];
      }
      if (payrolls.length > 0) {
        dispatch("setPayrollSelectedNGetDetails", payrolls[0]);
        commit("setPayrollEmpty", false);
      } else {
        dispatch("setPayrollSelectedNGetDetails", null);
        commit("setPayrollEmpty", true);
      }
      commit("setPayrolls", payrolls);
      commit("setLoading", ["payrolls", false]);
    });
  },
  setPayrollSelectedNGetDetails(_0, _1) {
    return __async(this, arguments, function* ({ commit }, payroll) {
      commit("setPayrollSelected", payroll);
      commit("setLoading", ["calendarsInPayrolls", true]);
      if (!payroll) {
        commit("setCalendar", null);
        commit("setLoading", ["calendarsInPayrolls", false]);
        return;
      }
      let calendarDetail = [];
      try {
        calendarDetail = yield zentric.getCalendarDetails(payroll.calendar);
      } catch (_) {
        console.error(_);
      }
      commit("setCalendar", calendarDetail);
      commit("setLoading", ["calendarsInPayrolls", false]);
    });
  },
  getDashboard(_0) {
    return __async(this, arguments, function* ({ commit }) {
      commit("setLoading", ["dashboard", true]);
      let dashboard = {};
      try {
        dashboard = yield zentric.getDashboard();
      } catch (_) {
        console.error(_);
      }
      commit("setDashboard", dashboard);
      commit("setLoading", ["dashboard", false]);
    });
  },
  getOrganigrams(_0) {
    return __async(this, arguments, function* ({ commit }) {
      commit("setLoading", ["organigrams", true]);
      let organigrams = [];
      try {
        organigrams = yield zentric.getZOrganigrams();
        if (!Array.isArray(organigrams)) {
          organigrams = [];
        }
      } catch (_) {
        console.error(_);
      }
      commit("setOrganigrams", organigrams);
      commit("setLoading", ["organigrams", false]);
    });
  },
  getCalendars(_0) {
    return __async(this, arguments, function* ({ commit }) {
      commit("setLoading", ["calendars", true]);
      let calendars = [];
      try {
        calendars = yield zentric.getCalendars();
        if (!Array.isArray(calendars)) {
          calendars = [];
        }
      } catch (_) {
        console.error(_);
      }
      commit("setIsEmptyCalendars", calendars.length === 0);
      commit("setCalendars", calendars);
      commit("setLoading", ["calendars", false]);
    });
  },
  setNextPayroll({ dispatch, state: state2 }) {
    const { payrolls, payrollSelected } = state2;
    const payrollSelectedIndex = payrolls.findIndex((payroll) => payroll.id === payrollSelected.id);
    if (payrollSelectedIndex >= 0 && payrolls.length > payrollSelectedIndex + 1) {
      dispatch("setPayrollSelectedNGetDetails", payrolls[payrollSelectedIndex + 1]);
    }
  },
  setPrevPayroll({ dispatch, state: state2 }) {
    const { payrolls, payrollSelected } = state2;
    const payrollSelectedIndex = payrolls.findIndex((payroll) => payroll.id === payrollSelected.id);
    if (payrollSelectedIndex > 0) {
      dispatch("setPayrollSelectedNGetDetails", payrolls[payrollSelectedIndex - 1]);
    }
  },
  setFalseIsActiveWorkyPlus({ commit }) {
    commit("setIsActive", false);
  },
  getAccumulatedData(_0) {
    return __async(this, arguments, function* ({ commit, state: state2, dispatch }) {
      if (state2.accumulatedGetStatus === "pending") {
        if (state2.accumulatedCheckDate && state2.accumulatedCheckDate instanceof Date) {
          const oldDate = state2.accumulatedCheckDate;
          const newDate = new Date();
          if (oldDate.getTime() + 1e6 <= newDate.getTime()) {
            commit("setGetAccumulatedStatus", null);
            dispatch("getAccumulatedData");
          }
        } else if (!(state2.accumulatedCheckDate instanceof Date)) {
          commit("setGetAccumulatedStatus", null);
          dispatch("getAccumulatedData");
        }
        return;
      }
      commit("setAccumulatedCheckDate", new Date());
      commit("setGetAccumulatedStatus", "pending");
      let payrolls = yield zentric.getPayrolls();
      payrolls = payrolls.filter((p) => p.attribute === 1);
      if (payrolls.length > 0) {
        const filter = {
          payrolls: payrolls.map((p) => p.id),
          years: payrolls.map((p) => p.year)
        };
        const { data } = yield zentric.getAccumulatedReport(filter);
        if (data.data.length > 0) {
          const accumulated = data.data;
          dispatch("processAccumulatedDataToSet", accumulated);
        }
      }
      commit("setGetAccumulatedStatus", "success");
    });
  },
  processAccumulatedDataToSet({ commit }, accumulated) {
    const netPaymentData = accumulated.reduce((acc, item) => {
      const exists = acc.find((p) => p.month === item.month && p.year === item.year);
      if (!exists) {
        const accumulatedByMonth = accumulated.filter((p) => p.month === item.month && p.year === item.year);
        const perc = accumulatedByMonth.filter((p) => p.type === "Percepciones").map((p) => p.amount).reduce((sum, amount) => sum + amount, 0);
        const other = accumulatedByMonth.filter((p) => p.type === "Otros pagos").map((p) => p.amount).reduce((sum, amount) => sum + amount, 0);
        const deduc = accumulatedByMonth.filter((p) => p.type === "Deducciones").map((p) => p.amount).reduce((sum, amount) => sum + amount, 0);
        const provisions = accumulatedByMonth.filter((p) => p.type === "Provisiones").map((p) => p.amount).reduce((sum, amount) => sum + amount, 0);
        const informatives = accumulatedByMonth.filter((p) => p.type === "Informativos").map((p) => p.amount).reduce((sum, amount) => sum + amount, 0);
        const net = accumulatedByMonth.filter((p) => p.concept_key === "I20").map((p) => p.amount).reduce((sum, amount) => sum + amount, 0);
        acc.push({
          month: item.month,
          year: item.year,
          net,
          gross: perc + other,
          perc,
          other,
          deduc,
          provisions,
          informatives
        });
      }
      return acc;
    }, []);
    commit("setAcumulatedDataByMonth", netPaymentData);
  }
};
const mutations = {
  setIsActive(state2, status) {
    state2.isActive = status;
  },
  setEmployeeInfo(state2, employeeInfo) {
    state2.employeeInfo = employeeInfo;
  },
  setPayrolls(state2, payrolls) {
    state2.payrolls = payrolls;
  },
  setPayrollSelected(state2, payrollSelected) {
    state2.payrollSelected = payrollSelected;
  },
  setCalendar(state2, calendar) {
    state2.calendarInPayrollSelected = calendar;
  },
  setDashboard(state2, dashboard) {
    state2.dashboard = dashboard;
  },
  setOrganigrams(state2, organigrams) {
    state2.organigrams = organigrams;
  },
  setCalendars(state2, calendars) {
    state2.calendars = calendars;
  },
  setLoading(state2, [loadingName, status]) {
    state2.loadings[loadingName] = status;
  },
  setPayrollEmpty(state2, payrollEmpty) {
    state2.payrollEmpty = payrollEmpty;
  },
  setIsEmptyCalendars(state2, isEmptyCalendars) {
    state2.isEmptyCalendars = isEmptyCalendars;
  },
  setGetAccumulatedStatus(state2, getAccumulatedStatus) {
    state2.accumulatedGetStatus = getAccumulatedStatus;
  },
  setAcumulatedDataByMonth(state2, accumulatedDataByMonth) {
    state2.accumulatedDataByMonth = accumulatedDataByMonth;
  },
  setAccumulatedCheckDate(state2, accumulatedCheckDate) {
    state2.accumulatedCheckDate = accumulatedCheckDate;
  }
};
const getters = {
  employeeIsIntegrated: (state2) => {
    var _a;
    return (_a = state2.employeeInfo) == null ? void 0 : _a.is_integrated;
  },
  payrollSelectedIndex: (state2) => (state2.payrolls || []).findIndex(
    (payroll) => {
      var _a;
      return payroll.id === ((_a = state2.payrollSelected) == null ? void 0 : _a.id);
    }
  ) + 1,
  currentPeriod: (state2) => {
    const { payrollSelected, calendarInPayrollSelected } = state2;
    if (!payrollSelected)
      return null;
    if (!calendarInPayrollSelected)
      return null;
    const today = DateTime.now();
    const currentPeriod = calendarInPayrollSelected.find((period) => {
      const { start_date: startDate, end_date: endDate } = period;
      const startDateObj = DateTime.fromISO(startDate);
      const endDateObj = DateTime.fromISO(endDate);
      return startDateObj <= today && today <= endDateObj || period.period === payrollSelected.period;
    });
    if (!currentPeriod) {
      const lastPeriod = calendarInPayrollSelected[calendarInPayrollSelected.length - 1];
      if (!lastPeriod) {
        return {};
      }
      const { end_date: endDate } = lastPeriod;
      const endDateObj = DateTime.fromISO(endDate);
      if (endDateObj < today) {
        return lastPeriod;
      }
    }
    if (currentPeriod.period < payrollSelected.period) {
      return calendarInPayrollSelected.find((period) => period.period === payrollSelected.period);
    }
    return currentPeriod;
  },
  getLoading: (state2) => (loadingName) => state2.loadings[loadingName]
};
export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
};
