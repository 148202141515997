import store from "@/store";
function verifyPermisions(to, from, next) {
  if (to.params && to.params.calendarType !== "empresa") {
    return next();
  }
  if (!store.state.company.permissions.myPermissions.find(
    (permission) => permission.field_or_action === "calendar"
  )) {
    return next();
  }
  if (store.state.company.permissions.myPermissions.filter(
    (permission) => permission.slug.includes("calendar") && permission.policy === "disallow"
  ).length > 0) {
    return next({ name: "not-found" });
  }
  return next();
}
const routes = {
  path: "/calendario/:calendarType",
  component: () => import(
    /* webpackChunkName: "calendar" */
    "@/views/CalendarCompany"
  ),
  beforeEnter: verifyPermisions,
  name: "calendar",
  meta: {
    requireAuth: true,
    module: "calendar",
    publicName: "Calendario de empresa"
  }
};
export default routes;
