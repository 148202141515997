var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c(!_vm.noModel ? "label" : "div", { tag: "component", staticClass: "checkbox", class: { "checkbox--disabled": _vm.disabled, "checkbox--is-checked": _vm.checked } }, [_c("input", { directives: [{ name: "model", rawName: "v-model", value: _vm.checked, expression: "checked" }], ref: "checkbox", staticClass: "checkbox__input", attrs: { "disabled": _vm.disabled, "type": "checkbox", "required": _vm.required }, domProps: { "checked": Array.isArray(_vm.checked) ? _vm._i(_vm.checked, null) > -1 : _vm.checked }, on: { "change": function($event) {
    var $$a = _vm.checked, $$el = $event.target, $$c = $$el.checked ? true : false;
    if (Array.isArray($$a)) {
      var $$v = null, $$i = _vm._i($$a, $$v);
      if ($$el.checked) {
        $$i < 0 && (_vm.checked = $$a.concat([$$v]));
      } else {
        $$i > -1 && (_vm.checked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
      }
    } else {
      _vm.checked = $$c;
    }
  } } }), _c("span", { staticClass: "checkbox__icon", class: [`checkbox__icon--${_vm.theme}`, { "mr-2": _vm.label || _vm.$slots.default }], on: { "click": _vm.onClick } }), _c("span", { staticClass: "checkbox__text select-none", on: { "click": _vm.onClick } }, [_vm.label ? [_vm._v(" " + _vm._s(_vm.label) + " ")] : _vm._t("default")], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
