import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = false;
const digitalSign = {
  verifyOwnership(options) {
    return axios.post("/api/v1/document_editor/assigned_document/verify_email/", options);
  },
  getDocumentDetail(options) {
    return axios.post(`/api/v1/document_editor/assigned_document/assignation_detail/`, options).then(({ data }) => data);
  },
  sendSign(options) {
    const formData = new FormData();
    Object.keys(options).forEach((key) => {
      formData.append(key, options[key]);
    });
    return axios.post(`/api/v1/document_editor/assigned_document/sign_with_inactive_user/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(({ data }) => data);
  }
};
export default digitalSign;
