import { api } from "@/api";
export default {
  getAnswers({ commit }, { id }) {
    return api.encuestas.getAnswers(id).then((response) => {
      commit("setAnswers", response.data);
      return response;
    });
  },
  setAnswer({ commit }, { id, response }) {
    const { data } = api.encuestas.setAnswer(id, response);
    commit("me/setSurveyAnswered", true, { root: true });
    return data;
  }
};
