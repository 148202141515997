import Model from "@/models/v1/model";
import { DateTime } from "luxon";
export default class Incidence extends Model {
  static get modelName() {
    return "Incidence";
  }
  static apiNamespace() {
    return "api/v2";
  }
  static resourceNamespace() {
    return "incidences";
  }
  static fields() {
    return [
      "id",
      "reviewer",
      "owner",
      "created_by",
      "edited_by",
      "start_date",
      "end_date",
      "status",
      "reviewed_at",
      "incidence_type",
      "detail",
      "comment",
      "days_off",
      "total_days",
      "with_salary"
    ];
  }
  static default() {
    return "";
  }
  get() {
    return this.constructor.get(`${this.resourcePath()}`);
  }
  getCompanyIncidencesCount() {
    return this.constructor.get("api/v2/incidences/company_count/");
  }
  getCompanyIncidences({
    idCompany,
    status,
    legacyTypes,
    customTypes,
    startDate,
    endDate,
    timeClock = null,
    userRole
  }) {
    let statusSearch = null;
    let legacySearch = null;
    let customSearch = null;
    let startDateSearch = null;
    let endDateSearch = null;
    let timeClockSearch = null;
    if (timeClock !== null) {
      timeClockSearch = `time_clock=${timeClock}`;
    }
    if (legacyTypes && legacyTypes.length > 0) {
      if (status) {
        statusSearch = status.reduce((stringCompleted, statusEl, index) => index === 0 ? `status_in=${statusEl},` : `${stringCompleted}${statusEl},`, "");
      } else {
        statusSearch = `status_in=APR,REJ`;
      }
      if (startDate) {
        startDateSearch = `end_date_gte=${startDate}`;
      }
      if (endDate) {
        if (!startDate) {
          const mockTodayStart = DateTime.local().toISODate();
          endDateSearch = `end_date_gte=${mockTodayStart}&start_date_lte=${endDate}`;
        } else {
          endDateSearch = `start_date_lte=${endDate}`;
        }
      }
      legacySearch = legacyTypes.reduce((stringCompleted, typeEl, idx) => idx === 0 ? `incidence_type_in=${typeEl},` : `${stringCompleted}${typeEl},`, "");
      const searches2 = [
        statusSearch,
        startDateSearch,
        endDateSearch,
        legacySearch,
        timeClockSearch
      ];
      const notNullSearchesUrl2 = searches2.filter((search) => search).reduce((url, search, idx) => idx === 0 ? `api/v2/companies/${idCompany}/incidences/?${search}&` : `${url}${search}&`, "");
      return this.constructor.get(`${notNullSearchesUrl2}`);
    }
    if (customTypes && customTypes.length > 0) {
      if (status) {
        statusSearch = status.reduce((stringCompleted, statusEl, index) => index === 0 ? `status_in=${statusEl},` : `${stringCompleted}${statusEl},`, "");
      } else {
        statusSearch = `status_in=APR,REJ`;
      }
      if (startDate) {
        startDateSearch = `end_date_gte=${startDate}`;
      }
      if (endDate) {
        if (!startDate) {
          const mockTodayStart = DateTime.local().toISODate();
          endDateSearch = `end_date_gte=${mockTodayStart}&start_date_lte=${endDate}`;
        } else {
          endDateSearch = `start_date_lte=${endDate}`;
        }
      }
      customSearch = customTypes.reduce((stringCompleted, typeEl, idx) => idx === 0 ? `template_in=${typeEl},` : `${stringCompleted}${typeEl},`, "");
      const searches2 = [
        statusSearch,
        startDateSearch,
        endDateSearch,
        customSearch,
        timeClockSearch
      ];
      const notNullSearchesUrl2 = searches2.filter((search) => search).reduce((url, search, idx) => idx === 0 ? `api/v2/companies/${idCompany}/incidences/?${search}&` : `${url}${search}&`, "");
      return this.constructor.get(`${notNullSearchesUrl2}`);
    }
    if (status) {
      statusSearch = status.reduce((stringCompleted, statusEl, index) => index === 0 ? `status_in=${statusEl},` : `${stringCompleted}${statusEl},`, "");
    } else {
      statusSearch = `status_in=APR,REJ`;
    }
    if (startDate) {
      startDateSearch = `end_date_gte=${startDate}`;
    }
    if (endDate) {
      if (!startDate) {
        const mockTodayStart = DateTime.local().toISODate();
        endDateSearch = `end_date_gte=${mockTodayStart}&start_date_lte=${endDate}`;
      } else {
        endDateSearch = `start_date_lte=${endDate}`;
      }
    }
    if (timeClock) {
      timeClockSearch = `time_clock=${timeClock}`;
    } else {
      timeClockSearch = `time_clock=false`;
    }
    const searches = [
      statusSearch,
      startDateSearch,
      endDateSearch,
      timeClockSearch
    ];
    const notNullSearchesUrl = searches.filter((search) => search).reduce((url, search, idx) => idx === 0 ? `api/v2/companies/${idCompany}/incidences/?${search}&` : `${url}${search}&`, "");
    return this.constructor.get(`${notNullSearchesUrl}`);
  }
  create(incident) {
    const module = incident.new_module;
    const type = incident.incidence_type;
    if (module && (type === "VA" || type === "PTO" || type === "VACOMP" || type === "PTOCOMP")) {
      return this.constructor.post("api/v1/time_off/incidences/", incident);
    }
    return this.constructor.post("api/v2/incidences/", incident);
  }
  getTeamIncidencesCount() {
    return this.constructor.get("api/v2/team/incidences/as-boss/count/");
  }
  getTeamIncidences({
    status,
    legacyTypes,
    customTypes,
    startDate,
    endDate
  }) {
    let statusSearch = null;
    let legacySearch = null;
    let customSearch = null;
    let startDateSearch = null;
    let endDateSearch = null;
    if (legacyTypes && legacyTypes.length > 0) {
      if (status) {
        statusSearch = status.reduce((stringCompleted, statusEl, index) => index === 0 ? `status_in=${statusEl},` : `${stringCompleted}${statusEl},`, "");
      } else {
        statusSearch = `status_in=APR,REJ`;
      }
      if (startDate) {
        startDateSearch = `end_date_gte=${startDate}`;
      }
      if (endDate) {
        if (!startDate) {
          const mockTodayStart = DateTime.local().toISODate();
          endDateSearch = `end_date_gte=${mockTodayStart}&start_date_lte=${endDate}`;
        } else {
          endDateSearch = `start_date_lte=${endDate}`;
        }
      }
      legacySearch = legacyTypes.reduce((stringCompleted, typeEl, idx) => idx === 0 ? `incidence_type_in=${typeEl},` : `${stringCompleted}${typeEl},`, "");
      const searches2 = [
        statusSearch,
        startDateSearch,
        endDateSearch,
        legacySearch
      ];
      const notNullSearchesUrl2 = searches2.filter((search) => search).reduce((url, search, idx) => idx === 0 ? `api/v2/team/incidences/?time_clock=false&${search}&` : `${url}${search}&`, "");
      return this.constructor.get(`${notNullSearchesUrl2}`);
    }
    if (customTypes && customTypes.length > 0) {
      if (status) {
        statusSearch = status.reduce((stringCompleted, statusEl, index) => index === 0 ? `status_in=${statusEl},` : `${stringCompleted}${statusEl},`, "");
      } else {
        statusSearch = `status_in=APR,REJ`;
      }
      if (startDate) {
        startDateSearch = `end_date_gte=${startDate}`;
      }
      if (endDate) {
        if (!startDate) {
          const mockTodayStart = DateTime.local().toISODate();
          endDateSearch = `end_date_gte=${mockTodayStart}&start_date_lte=${endDate}`;
        } else {
          endDateSearch = `start_date_lte=${endDate}`;
        }
      }
      customSearch = customTypes.reduce((stringCompleted, typeEl, idx) => idx === 0 ? `template_in=${typeEl},` : `${stringCompleted}${typeEl},`, "");
      const searches2 = [
        statusSearch,
        startDateSearch,
        endDateSearch,
        customSearch
      ];
      const notNullSearchesUrl2 = searches2.filter((search) => search).reduce((url, search, idx) => idx === 0 ? `api/v2/team/incidences/?time_clock=false&${search}&` : `${url}${search}&`, "");
      return this.constructor.get(`${notNullSearchesUrl2}`);
    }
    if (status) {
      statusSearch = status.reduce((stringCompleted, statusEl, index) => index === 0 ? `status_in=${statusEl},` : `${stringCompleted}${statusEl},`, "");
    } else {
      statusSearch = `status_in=APR,REJ`;
    }
    if (startDate) {
      startDateSearch = `end_date_gte=${startDate}`;
    }
    if (endDate) {
      if (!startDate) {
        const mockTodayStart = DateTime.local().toISODate();
        endDateSearch = `end_date_gte=${mockTodayStart}&start_date_lte=${endDate}`;
      } else {
        endDateSearch = `start_date_lte=${endDate}`;
      }
    }
    const searches = [
      statusSearch,
      startDateSearch,
      endDateSearch
    ];
    const notNullSearchesUrl = searches.filter((search) => search).reduce((url, search, idx) => idx === 0 ? `api/v2/team/incidences/?time_clock=false&${search}&` : `${url}${search}&`, "");
    return this.constructor.get(`${notNullSearchesUrl}`);
  }
  getCompanyIncidencesByEmployee(idCompany, startDate, endDate, timeClock = null) {
    let startDateSearch = null;
    let endDateSearch = null;
    let timeClockSearch = null;
    if (startDate) {
      startDateSearch = `start_date_gte=${startDate}`;
    }
    if (endDate) {
      if (!startDate) {
        const mockTodayStart = DateTime.local().toISODate();
        endDateSearch = `start_date_gte=${mockTodayStart}&end_date_lte=${endDate}`;
      } else {
        endDateSearch = `end_date_lte=${endDate}`;
      }
    }
    if (timeClock !== null) {
      if (timeClock) {
        timeClockSearch = `time_clock=${timeClock}`;
      } else {
        timeClockSearch = `time_clock=false`;
      }
    }
    const searches = [
      timeClockSearch,
      startDateSearch,
      endDateSearch,
      timeClockSearch
    ];
    const notNullSearchesUrl = searches.filter((search) => search).reduce((url, search) => `${url}&${search}`, "");
    const basicUrl = `api/v2/companies/${idCompany}/incidences_by_employee/?status=APR`;
    return this.constructor.get(`${basicUrl}${notNullSearchesUrl}`);
  }
  getTeamIncidencesByEmployee(startDate, endDate, timeClock = null) {
    let startDateSearch = null;
    let endDateSearch = null;
    let timeClockSearch = null;
    if (startDate) {
      startDateSearch = `start_date_gte=${startDate}`;
    }
    if (endDate) {
      if (!startDate) {
        const mockTodayStart = DateTime.local().toISODate();
        endDateSearch = `start_date_gte=${mockTodayStart}&end_date_lte=${endDate}`;
      } else {
        endDateSearch = `end_date_lte=${endDate}`;
      }
    }
    if (timeClock) {
      timeClockSearch = `time_clock=${timeClock}`;
    } else {
      timeClockSearch = `time_clock=false`;
    }
    if (timeClock !== null) {
      timeClockSearch = `time_clock=${timeClock}`;
    }
    const searches = [
      timeClockSearch,
      startDateSearch,
      endDateSearch,
      timeClockSearch
    ];
    const notNullSearchesUrl = searches.filter((search) => search).reduce((url, search) => `${url}&${search}`, "");
    const basicUrl = `api/v2/team/incidences/by_employee?status=APR`;
    return this.constructor.get(`${basicUrl}&${notNullSearchesUrl}`);
  }
  getTeamIncidencesForOptions() {
    return this.constructor.get("api/v2/team/incidences?time_clock=false&status_in=REJ,APR");
  }
  getCompanyIncidencesForOptions(idCompany) {
    return this.constructor.get(`api/v2/companies/${idCompany}/incidences?time_clock=false&status_in=REJ,APR`);
  }
  getTeamPendingRequests({
    areas,
    legacyTypes,
    customTypes,
    startDate,
    endDate,
    page
  }) {
    let areasSearch = null;
    let legacySearch = null;
    let customSearch = null;
    let startDateSearch = null;
    let endDateSearch = null;
    if (legacyTypes && legacyTypes.length > 0) {
      if (areas) {
        areasSearch = `area_in=${areas}`;
      }
      if (startDate) {
        startDateSearch = `start_date_gte=${startDate}`;
      }
      if (endDate) {
        if (!startDate) {
          const mockTodayStart = DateTime.local().toISODate();
          endDateSearch = `start_date_gte=${mockTodayStart}&end_date_lte=${endDate}`;
        } else {
          endDateSearch = `end_date_lte=${endDate}`;
        }
      }
      legacySearch = legacyTypes.reduce((stringCompleted, typeEl, idx) => idx === 0 ? `&incidence_type_in=${typeEl},` : `${stringCompleted}${typeEl},`, "");
      const searches2 = [
        areasSearch,
        startDateSearch,
        endDateSearch,
        legacySearch
      ];
      const notNullSearchesUrl2 = searches2.filter((search) => search).reduce((url, search, idx) => idx === 0 ? `api/v2/team/incidences/?time_clock=false&status=PEN&${search}&paginate=true&page=${page}` : `${url}${search}&paginate=true&page=${page}`, "");
      return this.constructor.get(`${notNullSearchesUrl2}`);
    }
    if (customTypes && customTypes.length > 0) {
      if (areas) {
        areasSearch = `area_in=${areas}`;
      }
      if (startDate) {
        startDateSearch = `start_date_gte=${startDate}`;
      }
      if (endDate) {
        if (!startDate) {
          const mockTodayStart = DateTime.local().toISODate();
          endDateSearch = `start_date_gte=${mockTodayStart}&end_date_lte=${endDate}`;
        } else {
          endDateSearch = `end_date_lte=${endDate}`;
        }
      }
      customSearch = customTypes.reduce((stringCompleted, typeEl, idx) => idx === 0 ? `template_in=${typeEl},` : `${stringCompleted}${typeEl},`, "");
      const searches2 = [
        areasSearch,
        startDateSearch,
        endDateSearch,
        customSearch
      ];
      const notNullSearchesUrl2 = searches2.filter((search) => search).reduce((url, search, idx) => idx === 0 ? `api/v2/team/incidences/?time_clock=false&status=PEN&${search}&paginate=true&page=${page}` : `${url}${search}&paginate=true&page=${page}`, "");
      return this.constructor.get(`${notNullSearchesUrl2}`);
    }
    if (areas) {
      areasSearch = `area_in=${areas}`;
    }
    if (startDate) {
      startDateSearch = `start_date_gte=${startDate}`;
    }
    if (endDate) {
      if (!startDate) {
        const mockTodayStart = DateTime.local().toISODate();
        endDateSearch = `start_date_gte=${mockTodayStart}&end_date_lte=${endDate}`;
      } else {
        endDateSearch = `end_date_lte=${endDate}`;
      }
    }
    const searches = [
      areasSearch,
      startDateSearch,
      endDateSearch
    ];
    const notNullSearchesUrl = searches.filter((search) => search).reduce((url, search, idx) => idx === 0 ? `api/v2/team/incidences/?time_clock=false&status=PEN&${search}&paginate=true&page=${page}` : `${url}${search}&paginate=true&page=${page}`, "");
    if (notNullSearchesUrl === "") {
      return this.constructor.get(`api/v2/team/incidences/?time_clock=false&status=PEN&paginate=true&page=${page}`);
    }
    return this.constructor.get(`${notNullSearchesUrl}`);
  }
  getCompanyPendingRequests({
    idCompany,
    areas,
    legacyTypes,
    customTypes,
    startDate,
    endDate,
    searchTerm,
    page
  }) {
    let areasSearch = null;
    let incidenceSearch = null;
    let startDateSearch = null;
    let endDateSearch = null;
    let searchT = null;
    let pagination = "";
    const searches = [];
    const incidenceTypes = legacyTypes.concat(customTypes);
    if (page !== null) {
      pagination = `&paginate=true&page=${page}`;
    }
    if (searchTerm) {
      searchT = `&owner_full_name=${searchTerm}`;
      searches.push(searchT);
    }
    if (areas.length > 0) {
      areasSearch = `&area_in=${areas}`;
      searches.push(areasSearch);
    }
    if (startDate) {
      startDateSearch = `&start_date_gte=${startDate}`;
      searches.push(startDateSearch);
    }
    if (endDate) {
      if (!startDate) {
        const mockTodayStart = DateTime.local().toISODate();
        endDateSearch = `&start_date_gte=${mockTodayStart}&end_date_lte=${endDate}`;
      } else {
        endDateSearch = `&end_date_lte=${endDate}`;
      }
      searches.push(endDateSearch);
    }
    if (incidenceTypes && incidenceTypes.length > 0) {
      incidenceSearch = incidenceTypes.reduce((stringCompleted, typeEl, idx) => idx === 0 ? `&incidence_or_template=${typeEl},` : `${stringCompleted}${typeEl},`, "").slice(0, -1);
      searches.push(incidenceSearch);
    }
    const notNullSearchesUrl = searches.filter((search) => search).reduce((url, search, idx) => idx === 0 ? `api/v2/companies/${idCompany}/incidences/?time_clock=false&status=PEN${search}${pagination}` : `${url}${search}`, "");
    if (notNullSearchesUrl === "") {
      return this.constructor.get(`api/v2/companies/${idCompany}/incidences/?time_clock=false&status=PEN${pagination}`);
    }
    return this.constructor.get(`${notNullSearchesUrl}`);
  }
  getAreasInfo(companyId) {
    return this.constructor.get(`/api/v2/companies/${companyId}/areas/`);
  }
  getCompanyPendingRequestsForOptions(idCompany) {
    return this.constructor.get(`api/v2/companies/${idCompany}/incidences/?time_clock=false&status=PEN`);
  }
  getTeamPendingRequestsByPeriod(startDate, endDate) {
    return this.constructor.get(`api/v2/team/incidences/?time_clock=false&status=PEN&start_date=${startDate}&end_date=${endDate}`);
  }
  getCompanyPendingRequestsByPeriod(idCompany, startDate, endDate) {
    return this.constructor.get(`api/v2/companies/${idCompany}/incidences?status=PEN&start_date=${startDate}&end_date=${endDate}`);
  }
  getEqualGreaterIncidences(date) {
    return this.constructor.get(`api/v2/incidences?start_date_gte=${date}`);
  }
  getLessIncidences(date) {
    return this.constructor.get(`api/v2/incidences?start_date_lt=${date}`);
  }
  // getCompanyIncidences (idCompany, startDate, endDate) {
  //   return this.constructor.get(`api/v2/companies/
  // ${idCompany}/incidences?start_date_gte=${startDate}&end_date_lte=${endDate}`)
  // }
  // getTeamIncidences () {
  //   return this.constructor.get('/api/v2/team/incidences/by_employee/')
  // }
  remind() {
    return this.constructor.post(`${this.resourcePath()}/remind/`);
  }
  delete(requestId) {
    return this.constructor.delete(`api/v2/incidences/${requestId}`);
  }
  addFile(file) {
    const formData = new FormData();
    formData.append("file", file);
    return this.constructor.post(`${this.resourcePath()}/files/`, formData, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  }
  deleteFile(idFile) {
    return this.constructor.delete(
      `${this.resourcePath()}/files/${idFile}/`
    );
  }
}
