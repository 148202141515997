import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;
export const documentEditor = {
  getDocumentsTemplates(options = {}) {
    return axios.get("api/v1/document_editor/document_template/", { params: options });
  },
  getDocumentsVariables() {
    return axios.get("api/v1/document_editor/document_variable/");
  },
  getDocumentsCategories() {
    return axios.get("api/v1/document_editor/document_category/");
  },
  getDocumentsCategoriesWithDocuments(options = {}) {
    return axios.get("/api/v1/document_editor/document_category/with_documents/", { params: options });
  },
  getDocumentsTemplateDetails({ id }) {
    return axios.get(`api/v1/document_editor/document_template/${id}/`);
  },
  createDocumentsTemplate({ payload }) {
    return axios.post("api/v1/document_editor/document_template/", payload);
  },
  createDocumentsCategory({ payload }) {
    return axios.post("api/v1/document_editor/document_category/", payload);
  },
  deleteDocumentsTemplate({ id }) {
    return axios.delete(`api/v1/document_editor/document_template/${id}/`);
  },
  editDocumentsCategory({ id, payload }) {
    return axios.patch(`api/v1/document_editor/document_category/${id}/`, payload);
  },
  editDocumentsTemplate({ id, payload }) {
    return axios.patch(`api/v1/document_editor/document_template/${id}/`, payload);
  },
  previewDocument({ payload, config = { responseType: "blob" } }) {
    return axios.post("api/v1/document_editor/document_template/preview/", payload, config);
  },
  assignDocument({ payload }) {
    return axios.post("api/v1/document_editor/assigned_document/", payload);
  },
  downloadDocumentByEmployees({ payload, config = { responseType: "blob" } }) {
    return axios.post(`/api/v1/document_editor/document_template/${payload.document_template}/download_zip/`, payload, config);
  },
  getPDF({ id }) {
    return axios.get(`/api/v1/document_editor/assigned_document/${id}/pdf/`, { responseType: "arraybuffer" });
  },
  getDocumentDetail({ id }) {
    return axios.get(`api/v1/document_editor/assigned_document/${id}/`).then(({ data }) => data);
  },
  sendReminder(options) {
    return axios.post("/api/v1/document_editor/assigned_document/send_reminder/", null, { params: options });
  },
  downloadReport(options) {
    return axios.post("/api/v1/reports/download/", options, { responseType: "blob" });
  },
  downloadZip(options) {
    return axios.post("/api/v1/document_editor/assigned_document/download_zip/", {}, { responseType: "blob", params: options });
  },
  cancelDocument({ id }) {
    return axios.delete(`api/v1/document_editor/assigned_document/${id}/`);
  },
  restoreDocument({ id }) {
    return axios.post(`/api/v1/document_editor/document_template/${id}/restore/`);
  }
};
export default documentEditor;
