import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
const state = {
  listA: [],
  listG: [],
  listU: [],
  listE: [],
  listS: [],
  listSR: [],
  uploadedFile: {}
};
export default {
  getters,
  actions,
  state,
  mutations,
  namespaced: true
};
