export default {
  setPayrollReceipts(state, { data }) {
    state.list = data;
  },
  setUnAssignedPayrollReceipts(state, { data }) {
    state.listU = data;
  },
  uploadFile(state, { data }) {
    state.uploadedFile = data;
  }
};
