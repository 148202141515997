var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { DateTime } from "luxon";
import { api } from "@/api";
import CustomIncidenceTemplate from "@/models/customIncidenceTemplate";
import CustomReloadedIncidence from "@/models/customReloadedIncidence";
import CustomReloadedRequest from "@/models/customReloadedRequest";
import Employee from "@/models/employee";
import CustomIncidenceNameTemplate from "@/models/customIncidenceNameTemplate";
import CustomIncidenceToogleTemplate from "@/models/customIncidenceToogleTemplate";
import CustomIncidenceNewTemplate from "@/models/customIncidenceNewTemplate";
export default {
  /**************************************************************/
  /**************************************************************/
  // SETTINGS LEGACY INCIDENCES
  /* Get & update Legacy Incidences Settings */
  getLegacyIncidencesSettings(_0) {
    return __async(this, arguments, function* ({ commit }) {
      try {
        const { data } = yield api.incidencias.getSettings();
        commit("setLegacyIncidencesSettings", data);
        return data;
      } catch (error) {
        this._vm.$notify({
          type: "error",
          text: "\xA1Oh, oh!, ocurri\xF3 un error al descargar las incidencias de Worky."
        });
        throw error;
      }
    });
  },
  updateLegacyIncidencesSettings(_0, _1) {
    return __async(this, arguments, function* ({ dispatch, commit }, updatedData) {
      try {
        const { data } = yield api.incidencias.updateLegacyIncidencesSettings(updatedData);
        commit("setLegacyIncidencesSettings", data);
        dispatch("getCustomIncidencesSettings");
      } catch (error) {
        this._vm.$notify({
          type: "error",
          text: "Lo sentimos, ocurri\xF3 un error al guardar la configuraci\xF3n."
        });
      }
    });
  },
  /**************************************************************/
  /**************************************************************/
  // SETTINGS CUSTOM INCIDENCES
  /* Get, create, update, on/off, && delete Custom Incidences Settings */
  getCustomIncidencesSettings(_0) {
    return __async(this, arguments, function* ({ commit }) {
      const customIncidenceList = new CustomIncidenceTemplate();
      const getCustomIncidence = yield customIncidenceList.get();
      if (this._vm.$feature("incidences.complete_view.new_version")) {
        commit("setCustomIncidencesSettings", getCustomIncidence);
      } else {
        const incidenceFiltered = getCustomIncidence.filter((inc) => inc.is_custom);
        commit("setCustomIncidencesSettings", incidenceFiltered);
      }
      return customIncidenceList;
    });
  },
  getCustomIncidencesCategory(_0) {
    return __async(this, arguments, function* ({ commit }) {
      const getCustomCategory = yield api.customIncidences.getIncidencesCategory();
      commit("setCustomIncidencesCategory", getCustomCategory);
    });
  },
  getCustomIncidencesTypes(_0) {
    return __async(this, arguments, function* ({ commit }) {
      const getCustomTypes = yield api.customIncidences.getIncidencesType();
      commit("setCustomIncidencesTypes", getCustomTypes);
    });
  },
  createCustomIncidenceSettings(_0, _1) {
    return __async(this, arguments, function* ({ dispatch, rootState }, customIncidence) {
      let createData = {
        active: false,
        company: rootState.me.user.company.id,
        name: customIncidence.name,
        amount: [{
          has_amount: customIncidence.amount === 1
        }],
        date_range: [{
          has_date_range: customIncidence.date_range === 1
        }],
        duration: [{
          has_duration: customIncidence.duration === 1
        }],
        identifier: [{
          has_identifier: customIncidence.identifier === 1
        }],
        quantity: [{
          has_quantity: customIncidence.quantity === 1
        }],
        requestable: [{
          is_requestable: customIncidence.is_requestable === 1
        }],
        temporality: [{
          past: customIncidence.temporality === 2 || customIncidence.temporality === 0,
          future: customIncidence.temporality === 2 || customIncidence.temporality === 1
        }],
        areas: customIncidence.areas,
        apply_to: customIncidence.apply_to,
        use_vacation_balance: customIncidence.use_vacation_balance
      };
      let customIncidenceSetting = new CustomIncidenceTemplate();
      if (this._vm.$feature("incidences.complete_view.new_version")) {
        createData = __spreadProps(__spreadValues({}, createData), {
          active: true,
          description: customIncidence.description,
          incidence_category: customIncidence.incidence_category.id,
          legacy_incidence_type: customIncidence.legacy_incidence_type.type ? customIncidence.legacy_incidence_type.type : customIncidence.legacy_incidence_type,
          incidence_file: [{
            allow_upload_file: customIncidence.incidence_file.allow_upload_file,
            is_required: customIncidence.incidence_file.is_required,
            comments: customIncidence.incidence_file.comments
          }],
          is_duplicated: customIncidence.is_duplicated,
          template_base: customIncidence.is_duplicated ? customIncidence.template_base : null,
          areas: customIncidence.areas,
          locations: customIncidence.locations,
          apply_to: customIncidence.apply_to
        });
        customIncidenceSetting = new CustomIncidenceNewTemplate();
      }
      yield customIncidenceSetting.setFields(createData).create();
      dispatch("getCustomIncidencesSettings");
    });
  },
  updateCustomIncidenceSettings(_0, _1) {
    return __async(this, arguments, function* ({ dispatch }, { updatedInfo }) {
      console.log(updatedInfo);
      let updatedInfoSetting = new CustomIncidenceTemplate();
      if (this._vm.$feature("incidences.complete_view.new_version")) {
        updatedInfoSetting = new CustomIncidenceNewTemplate();
      }
      yield updatedInfoSetting.setFields(updatedInfo).update();
      dispatch("getCustomIncidencesSettings");
    });
  },
  updateCustomIncidenceNameSettings(_0, _1) {
    return __async(this, arguments, function* ({ dispatch }, { updatedInfo }) {
      const updatedInfoSetting = new CustomIncidenceNameTemplate();
      yield updatedInfoSetting.setFields(updatedInfo).update();
      dispatch("getCustomIncidencesSettings");
    });
  },
  toogleCustomIncidenceSettings(_0, _1) {
    return __async(this, arguments, function* ({ dispatch }, { updatedInfo }) {
      const toogleSetting = new CustomIncidenceToogleTemplate();
      yield toogleSetting.setFields(updatedInfo).toogle();
      dispatch("getCustomIncidencesSettings");
    });
  },
  deleteCustomIncidenceSetting(_0, _1) {
    return __async(this, arguments, function* ({ dispatch }, id) {
      const customIncidenceSetting = new CustomIncidenceTemplate();
      yield customIncidenceSetting.delete(id);
      dispatch("getCustomIncidencesSettings");
    });
  },
  /********************************************************************/
  /********************************************************************/
  // EMPLOYEE REQUESTS
  /* Get my requestable requests list */
  // Get requestable requests for current employee
  getMyRequestsRequestable(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { id, is_request, options = {} }) {
      const currentEmployee = new Employee({ id });
      let requestsList = [];
      if (this._vm.$feature("incidences.complete_view.new_version")) {
        requestsList = yield currentEmployee.getMyRequestableRequests_V2(id, is_request, options);
      } else {
        requestsList = yield currentEmployee.getMyRequestableRequests(id);
      }
      commit("setMyRequestsRequestable", requestsList);
    });
  },
  /** ************************************************************************ */
  /** ************************************************************************ */
  // EMPLOYEE REQUESTS WITH CUSTOM INCIDENCES
  /* Create, edit request from myRequests, Add & delete files for request */
  createNewRequest(_0, _1) {
    return __async(this, arguments, function* ({ dispatch }, request) {
      const requestObj = new CustomReloadedRequest(request);
      const resp = yield requestObj.create(request);
      return resp;
    });
  },
  updatePendingRequest(_0, _1) {
    return __async(this, arguments, function* ({ dispatch }, request) {
      const requestObj = new CustomReloadedRequest(request);
      yield requestObj.update();
      dispatch("getMyUpcomingRequestsList", DateTime.local().toISODate());
    });
  },
  addRequestFile(request, file) {
    return __async(this, null, function* () {
      const requestObj = new CustomReloadedRequest(request);
      yield requestObj.addFile(file);
    });
  },
  /******************************************************************/
  /******************************************************************/
  // EMPLOYEE REQUESTS WITH CUSTOM INCIDENCES EMPLOYEE TABLES
  getMyUpcomingRequestsList(_0, _1) {
    return __async(this, arguments, function* ({ commit }, date) {
      const requestObj = new CustomReloadedRequest();
      const resp = yield requestObj.getMyUpcomingRequests(date);
      commit("setMyUpcomingRequestsList", resp);
    });
  },
  getMyPreviousRequestsList(_0, _1) {
    return __async(this, arguments, function* ({ commit }, date) {
      const requestObj = new CustomReloadedRequest();
      const resp = yield requestObj.getMyPreviousRequests(date);
      commit("setMyPreviousRequestsList", resp);
    });
  },
  /******************************************************************/
  /******************************************************************/
  /* PENDING REQUESTS AS HRA For total count */
  getPendingRequestsForOptions(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const idCompany = rootState.me.user.company.id;
      const incidenceObj = new CustomReloadedIncidence();
      const customIncidences = yield incidenceObj.getCompanyPendingRequestsForOptions(idCompany);
      const areasData = yield incidenceObj.getAreasInfo(idCompany);
      commit("setAreasInfo", areasData);
      commit("setPendingRequestsList", customIncidences);
    });
  },
  /* PENDING REQUESTS AS BOSS */
  getTeamPendingRequestsForOptions(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const idCompany = rootState.me.user.company.id;
      const incidentObj = new CustomReloadedIncidence();
      const customIncidences = yield incidentObj.getTeamPendingRequestsForOptions();
      const areasData = yield incidentObj.getAreasInfo(idCompany);
      commit("setAreasInfo", areasData);
      commit("setPendingRequestsList", customIncidences);
    });
  },
  /* PENDING REQUESTS AS HRA with filters from tables applied */
  getPendingRequests(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, {
      areas,
      legacyTypes,
      customTypes,
      startDate,
      endDate
    }) {
      const idCompany = rootState.me.user.company.id;
      const incidenceObj = new CustomReloadedIncidence();
      if ((!legacyTypes || legacyTypes.length === 0) && (!customTypes || customTypes.length === 0)) {
        const result = yield incidenceObj.getCompanyPendingRequests({
          idCompany,
          areas,
          legacTypes: null,
          customTypes: null,
          startDate,
          endDate
        });
        commit("setPendingRequestsList", result);
      } else {
        let legacyList = [];
        let customList = [];
        if (legacyTypes) {
          legacyList = yield incidenceObj.getCompanyPendingRequests({
            idCompany,
            areas,
            legacyTypes,
            customTypes: null,
            startDate,
            endDate
          });
        }
        if (customTypes) {
          customList = yield incidenceObj.getCompanyPendingRequests({
            idCompany,
            areas,
            legacyTypes: null,
            customTypes,
            startDate,
            endDate
          });
        }
        const result = legacyList.concat(customList);
        commit("setPendingRequestsList", result);
      }
    });
  },
  /* PENDING REQUESTS AS BOSS with filters from tables applied */
  getTeamPendingRequests(_0, _1) {
    return __async(this, arguments, function* ({ commit }, {
      areas,
      legacyTypes,
      customTypes,
      startDate,
      endDate
    }) {
      const incidenceObj = new CustomReloadedIncidence();
      if ((!legacyTypes || legacyTypes.length === 0) && (!customTypes || customTypes.length === 0)) {
        const result = yield incidenceObj.getTeamPendingRequests({
          areas,
          legacTypes: null,
          customTypes: null,
          startDate,
          endDate
        });
        commit("setPendingRequestsList", result);
      } else {
        let legacyList = [];
        let customList = [];
        if (legacyTypes) {
          legacyList = yield incidenceObj.getTeamPendingRequests({
            areas,
            legacyTypes,
            customTypes: null,
            startDate,
            endDate
          });
        }
        if (customTypes) {
          customList = yield incidenceObj.getTeamPendingRequests({
            areas,
            legacyTypes: null,
            customTypes,
            startDate,
            endDate
          });
        }
        const result = legacyList.concat(customList);
        commit("setPendingRequestsList", result);
      }
    });
  },
  approveRequest(request) {
    return __async(this, null, function* () {
      const customReloadedIncidencesObject = new CustomReloadedIncidence(request);
      yield customReloadedIncidencesObject.approve(request.id);
    });
  },
  rejectRequest(request) {
    return __async(this, null, function* () {
      const customReloadedIncidencesObject = new CustomReloadedIncidence(request);
      yield customReloadedIncidencesObject.reject(request.id);
    });
  },
  /***********************************************************************/
  /*********************************************************************/
  // INCIDENCES AS HRA with filters applied
  getIncidences(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, {
      status,
      legacyTypes,
      customTypes,
      startDate,
      endDate,
      timeClock
    }) {
      let idCompany;
      if (rootState.me.user.company) {
        idCompany = rootState.me.user.company.id;
      } else {
        idCompany = rootState.outsourcer.actualCompany.owner.id;
      }
      const incidenceObj = new CustomReloadedIncidence();
      if ((!legacyTypes || legacyTypes.length === 0) && (!customTypes || customTypes.length === 0)) {
        const result = yield incidenceObj.getCompanyIncidences({
          idCompany,
          status,
          legacyTypes: null,
          customTypes: null,
          startDate,
          endDate,
          userRole: rootState.me.role,
          timeClock
        });
        commit("setIncidencesList", result);
      } else {
        let legacyList = [];
        let customList = [];
        if (legacyTypes) {
          legacyList = yield incidenceObj.getCompanyIncidences({
            idCompany,
            status,
            legacyTypes,
            customTypes: null,
            startDate,
            endDate,
            timeClock
          });
        }
        if (customTypes) {
          customList = yield incidenceObj.getCompanyIncidences({
            idCompany,
            status,
            legacyTypes: null,
            customTypes,
            startDate,
            endDate,
            timeClock
          });
        }
        const result = legacyList.concat(customList);
        commit("setIncidencesList", result);
      }
    });
  },
  // INCIDENCES for reports view
  getIncidencesForReport(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, {
      startDate,
      endDate
    }) {
      const idCompany = rootState.me.user.company.id;
      const list = yield api.editRequest.getIncidencesForReport({
        idCompany,
        startDate,
        endDate
      });
      return list;
    });
  },
  // INCIDENCES AS BOSS with filters applied
  getTeamIncidences(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, {
      status,
      legacyTypes,
      customTypes,
      startDate,
      endDate,
      timeClock
    }) {
      const incidenceObj = new CustomReloadedIncidence();
      if ((!legacyTypes || legacyTypes.length === 0) && (!customTypes || customTypes.length === 0)) {
        const result = yield incidenceObj.getTeamIncidences({
          status,
          legacyTypes: null,
          customTypes: null,
          startDate,
          endDate,
          timeClock
        });
        return result;
      }
      let legacyList = [];
      let customList = [];
      if (legacyTypes) {
        legacyList = yield incidenceObj.getTeamIncidences({
          status,
          legacyTypes,
          customTypes: null,
          startDate,
          endDate,
          timeClock
        });
      }
      if (customTypes) {
        customList = yield incidenceObj.getTeamIncidences({
          status,
          legacyTypes: null,
          customTypes,
          startDate,
          endDate,
          timeClock
        });
      }
      return legacyList.concat(customList);
    });
  },
  getIncidencesCount(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const incidenceObj = new CustomReloadedIncidence();
      const { count } = yield incidenceObj.getCompanyIncidencesCount();
      return count;
    });
  },
  getOsrCompanyIncidencesCount() {
    return __async(this, null, function* () {
      const { count } = yield api.outsourcer.getIncidencesCount();
      return count;
    });
  },
  getTeamIncidencesCount(_0) {
    return __async(this, arguments, function* ({ commit }) {
      const incidenceObj = new CustomReloadedIncidence();
      const { count } = yield incidenceObj.getTeamIncidencesCount();
      return count;
    });
  },
  getIncidencesByEmployee(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, {
      startDate,
      endDate,
      timeClock
    }) {
      const idCompany = rootState.me.user.company ? rootState.me.user.company.id : rootState.outsourcer.actualCompany.owner.id;
      const incidenceObj = new CustomReloadedIncidence();
      const incidencesResp = yield incidenceObj.getCompanyIncidencesByEmployee(idCompany, startDate, endDate, timeClock);
      commit("setIncidencesByEmployeeCount", incidencesResp.length);
      return incidencesResp;
    });
  },
  getTeamIncidencesByEmployee(_0, _1) {
    return __async(this, arguments, function* ({ commit }, {
      startDate,
      endDate,
      timeClock
    }) {
      const incidentObj = new CustomReloadedIncidence();
      const incidencesResp = yield incidentObj.getTeamIncidencesByEmployee(startDate, endDate, timeClock);
      commit("setIncidencesByEmployeeCount", incidencesResp.length);
      return incidencesResp;
    });
  },
  getIncidencesByEmployeeCount(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const idCompany = rootState.me.user.company ? rootState.me.user.company.id : rootState.outsourcer.actualCompany.owner.id;
      const incidenceObj = new CustomReloadedIncidence();
      const incidencesResp = yield incidenceObj.getCompanyIncidencesByEmployee(idCompany, null, null);
      commit("setIncidencesByEmployeeCount", incidencesResp.length);
    });
  },
  getTeamIncidencesByEmployeeCount(_0) {
    return __async(this, arguments, function* ({ commit }) {
      const incidentObj = new CustomReloadedIncidence();
      const incidencesResp = yield incidentObj.getTeamIncidencesByEmployee(null, null);
      commit("setIncidencesByEmployeeCount", incidencesResp.length);
    });
  },
  createNewIncident(_0, _1) {
    return __async(this, arguments, function* ({ dispatch }, request) {
      const requestObj = new CustomReloadedIncidence(__spreadValues({}, request));
      const resp = yield requestObj.create(request);
      return resp;
    });
  },
  createMassiveIncidences(_0, _1) {
    return __async(this, arguments, function* ({ commit }, incidences) {
      return api.massiveIncidences.createMassiveIncidences(incidences);
    });
  }
};
