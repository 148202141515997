import axios from "axios";
import { buildURLParams } from "@/utils/params";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;
export const getCategories = () => axios.get(`/api/v2/fields_configuration/categories/`);
export const getFields = () => axios.get(`/api/v2/fields_configuration/`);
export const saveConfig = (fields) => axios.put(`/api/v2/fields_configuration/bulk_update/`, fields);
export const getFieldsByFilter = (params) => {
  const urlParams = buildURLParams(params);
  return axios.get(`/api/v2/fields_configuration/`, {
    params: urlParams
  });
};
