import Model from "@/models/model";
export default class Benefit extends Model {
  static get modelName() {
    return "Benefit";
  }
  static apiNamespace() {
    return "api/v2";
  }
  static resourceNamespace() {
    return "benefits";
  }
  static default() {
    return "";
  }
  static fields() {
    return [
      "id",
      "name",
      "company",
      "long_description",
      "employees"
    ];
  }
}
