const routes = {
  path: "/documentos-firma-digital",
  name: "documents-digital-sign",
  component: () => import(
    /* webpackChunkName: "documentsAndDigitalSign" */
    "@/views/v2/documents/DocumentsAndDigitalSignListView"
  ),
  meta: {
    requireAuth: true,
    module: "document_editor",
    publicName: "Documentos y firma digital",
    redirect: {
      name: "created-documents-list"
    }
  },
  children: [
    {
      path: "documento/:id?",
      name: "form-document",
      component: () => import(
        /* webpackChunkName: "documentsAndDigitalSign" */
        "@/views/v2/documents/DocumentEditorForm"
      ),
      meta: {
        requireAuth: true,
        module: "document_editor",
        publicName: "Agregar documento",
        backTo: "created-documents-list"
      }
    },
    {
      path: "documentos-creados",
      name: "created-documents-list",
      component: () => import(
        /* webpackChunkName: "documentsAndDigitalSign" */
        "@/views/v2/documents/ListDocuments"
      ),
      meta: {
        requireAuth: true,
        module: "document_editor",
        publicName: "Listado de documentos creados"
      }
    },
    {
      path: "listado-categorias-documentos",
      name: "list-documents-categories",
      component: () => import(
        /* webpackChunkName: "documentsAndDigitalSign" */
        "@/views/v2/documents/ListDocumentsCategories"
      ),
      meta: {
        requireAuth: true,
        module: "document_editor",
        publicName: "Categor\xEDas de documentos",
        backTo: "created-documents-list"
      }
    },
    {
      path: "generar-firmar-documentos",
      name: "generate-sign-documents",
      component: () => import(
        /* webpackChunkName: "documentsAndDigitalSign" */
        "@/views/v2/documents/GenerateSignDocuments"
      ),
      meta: {
        requireAuth: true,
        module: "document_editor",
        publicName: "Generar documento y/o firmar",
        backTo: "created-documents-list"
      }
    },
    {
      path: "enviados",
      name: "sent-documents-list",
      component: () => import(
        /* webpackChunkName: "documentsAndDigitalSign" */
        "@/views/v2/documents/SentDocuments"
      ),
      meta: {
        requireAuth: true,
        module: "document_editor",
        publicName: "Documentos y firma digital"
      }
    },
    {
      path: "sin-enviar",
      name: "without-send-documents-list",
      component: () => import(
        /* webpackChunkName: "documentsAndDigitalSign" */
        "@/views/v2/documents/NotSentDocuments"
      ),
      meta: {
        requireAuth: true,
        module: "document_editor",
        publicName: "Documentos y firma digital"
      }
    },
    {
      path: "eliminados",
      name: "deleted-documents-list",
      component: () => import(
        /* webpackChunkName: "documentsAndDigitalSign" */
        "@/views/v2/documents/ListDeletedDocuments"
      ),
      meta: {
        requireAuth: true,
        module: "document_editor",
        publicName: "Documentos y firma digital"
      }
    }
  ]
};
export default routes;
