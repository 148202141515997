var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { api } from "@/api";
function employeeWithFormatedData(rootState, employee) {
  let areaName = "No disponible";
  if (employee.area && typeof employee.area === "object") {
    areaName = employee.area.name;
  } else {
    const foundArea = rootState.company.areas.list.find((_area) => _area.id === employee.area);
    areaName = foundArea ? foundArea.name : "No disponible";
  }
  let bossName = "No disponible";
  if (employee.boss && typeof employee.boss === "object") {
    bossName = employee.boss.full_name;
  } else {
    const foundBoss = rootState.company.employees.list.find((_employee) => _employee.id === employee.boss);
    bossName = foundBoss ? `${foundBoss.first_name} ${foundBoss.last_name}` : "No disponible";
  }
  let locationAddress = "No disponible";
  if (rootState.company.locations.list) {
    const foundLocation = rootState.company.locations.list.find((_location) => _location.id === employee.location);
    if (foundLocation) {
      locationAddress = foundLocation.address;
    }
  }
  const fullName = employee.full_name ? employee.full_name : `${employee.first_name} ${employee.last_name} ${employee.last_name2}`;
  return Object.assign(
    {},
    employee,
    {
      areaName,
      bossName,
      locationAddress,
      full_name: fullName
    }
  );
}
export default {
  /* eslint-disable camelcase */
  formatedEmployees(state, getters, rootState) {
    return state.list.map((employee) => employeeWithFormatedData(rootState, employee));
  },
  formatedLaidOffEmployees(state, getters, rootState) {
    return state.former.map((employee) => employeeWithFormatedData(rootState, employee));
  },
  currentEmployees: (state, getters) => getters.formatedEmployees.filter((employee) => !employee.laid_off).sort((a, b) => {
    if (a.first_name) {
      const aValue = a.first_name.toUpperCase();
      const bValue = b.first_name.toUpperCase();
      return aValue.localeCompare(bValue);
    }
    return a.full_name.toUpperCase().localeCompare(b.full_name.toUpperCase());
  }),
  currentEmployeesWithPolicies: (state, getters) => state.listWithPolicies.results.map((emp) => {
    var _a, _b;
    return __spreadProps(__spreadValues({}, emp), {
      areaName: (_a = emp == null ? void 0 : emp.area) == null ? void 0 : _a.name,
      work_puesto: (_b = emp == null ? void 0 : emp.work_position) == null ? void 0 : _b.name
    });
  }).sort((a, b) => {
    if (a.first_name) {
      const aValue = a.first_name.toUpperCase();
      const bValue = b.first_name.toUpperCase();
      return aValue.localeCompare(bValue);
    }
    return a.full_name.toUpperCase().localeCompare(b.full_name.toUpperCase());
  }),
  formerEmployees: (state, getters) => getters.formatedLaidOffEmployees.filter((employee) => employee.laid_off),
  onboardedEmployees: (state, getters) => getters.currentEmployees.filter((employee) => employee.is_onboarded),
  recruiters: (state, getters) => getters.currentEmployees.filter((employee) => employee.is_recruiter),
  employee: (state, getters) => (id) => getters.formatedEmployees.find((employee) => employee.id === id) || null,
  employeeByUser: (state, getters) => (userId) => getters.formatedEmployees.find((employee) => employee.user === userId) || null,
  employeesByLegalName: (state, getters) => (legalNameId) => getters.currentEmployees.filter((emp) => emp.razon_social === legalNameId),
  team: (state, getters, rootState) => {
    const currentEmployee = rootState.me.user;
    if (currentEmployee.is_boss) {
      return getters.currentEmployees.filter((emp) => emp.boss && (typeof emp.boss === "object" && emp.boss.id === currentEmployee.employee.id || typeof emp.boss === "string" && emp.boss === currentEmployee.employee.id));
    }
    return [];
  },
  teamTwoLeaves: (state, getters, rootState) => {
    const currentEmployee = rootState.me.user;
    let firstLeaves = [];
    if (currentEmployee.is_boss) {
      firstLeaves = getters.currentEmployees.filter((emp) => emp.boss && (typeof emp.boss === "object" && emp.boss.id === currentEmployee.employee.id || typeof emp.boss === "string" && emp.boss === currentEmployee.employee.id));
    }
    const secondLeaves = [];
    firstLeaves.forEach((upperLevelEmp, index) => {
      const temp = getters.currentEmployees.filter((emp) => emp.boss && (typeof emp.boss === "object" && emp.boss.id === upperLevelEmp.id || typeof emp.boss === "string" && emp.boss === upperLevelEmp.id));
      secondLeaves.push(...temp);
    });
    const team = [...firstLeaves, ...secondLeaves];
    const emptyValues = team.filter((item) => item.full_name === "" || item.full_name === null);
    const dataValues = team.filter((item) => item.full_name !== "" && item.full_name !== null);
    return dataValues.sort((a, b) => a.full_name.toUpperCase() > b.full_name.toUpperCase() ? 1 : -1).concat(emptyValues);
  },
  visitors: (state, getters) => getters.currentEmployees.filter((employee) => employee.is_analyzer),
  evaluators: (state, getters) => getters.currentEmployees.filter((employee) => employee.is_evaluator),
  hasIntegration: (state) => {
    var _a, _b, _c;
    return !!((_b = (_a = state.profile) == null ? void 0 : _a.integration) == null ? void 0 : _b.id) && ((_c = state == null ? void 0 : state.profile) == null ? void 0 : _c.is_integrated);
  },
  integrationId: (state, getters) => getters.hasIntegration ? state.profile.integration.internal_id : null,
  firstHRA(state, getters, rootState) {
    return __async(this, null, function* () {
      var _a, _b;
      if ((_a = rootState.company.companyData) == null ? void 0 : _a.id) {
        const { data } = yield api.company((_b = rootState.company.companyData) == null ? void 0 : _b.id).getCompanyHras();
        if (data.length > 0) {
          return data[0];
        }
      }
      return null;
    });
  }
};
