var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { directory } from "@/api";
import { getFields } from "@/api/configureData";
const state = {
  stats: {},
  fieldConfiguration: [],
  loading: false
};
const actions = {
  getDirectoryStats(_0) {
    return __async(this, arguments, function* ({ commit }) {
      commit("setLoading", true);
      try {
        const stats = yield directory.getDirectoryStats();
        commit("setStats", stats);
        commit("setLoading", false);
      } catch (e) {
      }
    });
  },
  getFieldConfiguration(_0) {
    return __async(this, arguments, function* ({ commit }) {
      const fields = yield getFields().then(({ data }) => data);
      const fieldsMapped = fields.map((field) => ({
        name: field.is_custom_field ? `${field.category}-${field.field_name}` : field.field_name,
        can_view_boss: field.can_view_boss,
        can_view_employee: field.can_view_employee,
        active: field.active
      }));
      commit("setFieldConfiguration", fieldsMapped);
    });
  }
};
const mutations = {
  setStats(state2, stats) {
    state2.stats = stats;
  },
  setLoading(state2, st) {
    state2.loading = st;
  },
  setFieldConfiguration(state2, fieldConfiguration) {
    state2.fieldConfiguration = fieldConfiguration;
  }
};
export default {
  state,
  actions,
  mutations,
  namespaced: true
};
