var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("CardWrapper", [_vm.emptyText ? _c("div", { staticClass: "max-w-full p-4 rounded truncate bg-white text-grey3 font-medium text-center", class: {
    "shadow-mo": _vm.$mq === "sm",
    "border border-grey2": ["md", "de", "lg"].includes(_vm.$mq)
  } }, [_vm._v(" " + _vm._s(_vm.emptyText) + " ")]) : _c("div", _vm._g({ staticClass: "max-w-full flex-grow flex flex-col items-center rounded whitespace-nowrap h-full justify-center" }, _vm.$listeners), [_c("div", { staticClass: "flex items-center justify-start px-6 py-5 w-full font-montserrat" }, [_c("p", { staticClass: "mr-4 headline-4 !text-main-900" }, [_vm._v(" " + _vm._s(_vm.realCount) + " ")]), _c("p", { staticClass: "line-clamp-2 !whitespace-normal leading-[1.125rem] headline-7", staticStyle: { "word-break": "break-word !important" } }, [_vm._v(" " + _vm._s(_vm.text) + " ")])]), _vm.linkTo ? _c("div", { staticClass: "flex flex-grow items-center w-full px-6 py-[1.375rem] bg-brand-body" }, [_c("span", { staticClass: "cursor-pointer flex items-center link-small", on: { "click": function($event) {
    _vm.$router.push({
      name: _vm.linkTo,
      params: __spreadValues({}, _vm.paramsTo)
    }).catch(() => {
    });
  } } }, [_vm._v(" " + _vm._s(_vm.linkText) + " "), _c("Icon", { staticClass: "ml-1 font-bold text-fs-20", attrs: { "icon": "arrow-next" } })], 1)]) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
