import store from "@/store";
import defineAbilitiesFor from "@/acl";
function showModule(module) {
  if (typeof store.state.company.permissions.myPermissions === "undefined" || !store.state.company.permissions.myPermissions.find(
    (permission) => permission.field_or_action === module
  )) {
    return true;
  }
  const abilities = defineAbilitiesFor(store.state.me.user);
  return abilities.can("allow", `${module}-x-${module}`);
}
const routes = {
  path: "/configuracion-de-permisos",
  name: "permissions-settings",
  component: () => import(
    /* webpackChunkName: "permissions" */
    "@/views/settingsPermissions/PermissionsLayout"
  ),
  beforeEnter: (to, from, next) => {
    const permissionModule = showModule("settings");
    if (!permissionModule && store.state.me.user.role === "HRA") {
      next({ name: "not-found" });
    } else
      next();
  },
  redirect: {
    name: "hra-permissions"
  },
  children: [
    {
      path: "administradores",
      name: "hra-permissions",
      component: () => import(
        /* webpackChunkName: "permissions" */
        "@/views/settingsPermissions/PermissionsLayout"
      ),
      beforeEnter: (to, from, next) => {
        const permiso = store.state.me.user.is_superhra;
        if (!permiso) {
          next({ name: "general-permissions" });
        } else
          next();
      },
      redirect: {
        name: "assigned-permissions"
      },
      children: [
        {
          path: "asignados",
          name: "assigned-permissions",
          component: () => import(
            /* webpackChunkName: "permissions" */
            "@/views/settingsPermissions/PermissionsIndex"
          ),
          beforeEnter: (to, from, next) => {
            const permiso = store.state.me.user.is_superhra;
            if (!permiso) {
              next({ name: "not-found" });
            } else
              next();
          },
          meta: {
            requireAuth: true,
            module: "permissions",
            publicName: "Configuraci\xF3n de permisos",
            description: "Protege la confidencialidad de tus colaboradores. Crea diferentes perfiles de acceso y as\xEDgnalos"
          }
        },
        {
          path: "noasignados",
          name: "unassigned-permissions",
          component: () => import(
            /* webpackChunkName: "permissions" */
            "@/views/settingsPermissions/PermissionsNonActive"
          ),
          beforeEnter: (to, from, next) => {
            const permiso = store.state.me.user.is_superhra;
            if (!permiso) {
              next({ name: "not-found" });
            } else
              next();
          },
          meta: {
            requireAuth: true,
            module: "permissions",
            publicName: "Configuraci\xF3n de permisos",
            description: "Protege la confidencialidad de tus colaboradores. Crea diferentes perfiles de acceso y as\xEDgnalos"
          }
        },
        {
          path: "crear-grupo",
          name: "create-permissions-group",
          component: () => import(
            /* webpackChunkName: "permissions" */
            "@/views/settingsPermissions/PermissionsGroupCreate"
          ),
          beforeEnter: (to, from, next) => {
            const permiso = store.state.me.user.is_superhra;
            if (!permiso) {
              next({ name: "not-found" });
            } else
              next();
          },
          meta: {
            requireAuth: true,
            module: "permissions",
            publicName: "Configuraci\xF3n de permisos",
            backTo: "hra-permissions"
          }
        },
        {
          path: "editar-grupo/:id",
          name: "edit-permissions-group",
          component: () => import(
            /* webpackChunkName: "permissions" */
            "@/views/settingsPermissions/PermissionsGroupCreate"
          ),
          beforeEnter: (to, from, next) => {
            const permiso = store.state.me.user.is_superhra;
            if (!permiso) {
              next({ name: "not-found" });
            } else
              next();
          },
          meta: {
            requireAuth: true,
            module: "permissions",
            publicName: "Configuraci\xF3n de permisos",
            backTo: "hra-permissions"
          }
        }
      ]
    },
    {
      path: "generales",
      name: "general-permissions",
      component: () => import(
        /* webpackChunkName: "permissions" */
        "@/views/settings/delegatePermissions/DelegatePermissions"
      ),
      beforeEnter: (to, from, next) => {
        const permiso = store.state.me.user.role === "HRA";
        if (!permiso) {
          next({ name: "not-found" });
        } else
          next();
      },
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de permisos",
        module: "permissions"
      }
    }
  ]
};
export default routes;
