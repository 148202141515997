import firebase from "firebase/app";
import "firebase/auth";
import "firebase/remote-config";
const firebaseConfig = {
  apiKey: "AIzaSyAnywQLE1Olz5pDMuxD9vRqF4dOCgjN4HY",
  authDomain: "api-worky.firebaseapp.com",
  databaseURL: "https://api-worky.firebaseio.com",
  projectId: "api-worky",
  storageBucket: "api-worky.appspot.com",
  messagingSenderId: "1019671724264",
  appId: "1:1019671724264:web:07ed50c7323cbc3978095d",
  measurementId: "G-BVDV3S92N8"
};
const firebaseAppName = "workyFirebaseAuthApp";
firebase.initializeApp(firebaseConfig, firebaseAppName);
const auth = firebase.app(firebaseAppName).auth();
const remoteConfig = firebase.app(firebaseAppName).remoteConfig();
if (process.env.VUE_APP_MAINTENANCE_CAPABLE === "true") {
  remoteConfig.settings.minimumFetchIntervalMillis = 3e5;
}
export { remoteConfig };
export default auth;
