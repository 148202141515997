import store from "@/store";
import defineAbilitiesFor from "@/acl";
function havePermission(...permissions) {
  return store.state.me.user.access_permissions.map((permission) => permission.field_or_action).some((permission) => permissions.includes(permission));
}
function showModule(module) {
  if (typeof store.state.company.permissions.myPermissions === "undefined" || !store.state.company.permissions.myPermissions.find(
    (permission) => permission.field_or_action === module
  )) {
    return true;
  }
  const abilities = defineAbilitiesFor(store.state.me.user);
  return abilities.can("allow", `${module}-x-${module}`);
}
function getRedirect() {
  const isHRA = store.state.me.user.role === "HRA";
  if (store.state.me.user.is_prepayroll_admin && !isHRA)
    return "incidence-prepayroll";
  if (havePermission("custom_incidences", "worky_incidences") && !isHRA)
    return "incidence-settings";
  if (havePermission("review_policies") && !isHRA)
    return "incidence-approval-flows";
  return "company-settings";
}
const routes = {
  path: "/configuracion",
  name: "settings",
  component: () => import(
    /* webpackChunkName: "settings" */
    "@/views/settings/SettingsLayout"
  ),
  beforeEnter: (to, from, next) => {
    const permission = havePermission("custom_incidences", "worky_incidences", "review_policies", "admin_geolocation_timework", "admin_policies_timework", "admin_personal_settings");
    const permissionModule = showModule("settings");
    if (!permissionModule && store.state.me.user.role === "HRA" || store.state.me.user.role !== "HRA" && !store.state.me.user.is_prepayroll_admin && !permission && !permissionModule) {
      next({ name: "not-found" });
    } else
      next();
  },
  redirect: {
    name: getRedirect()
  },
  children: [
    {
      path: "empresa",
      name: "company-settings",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/company/CompanyLayout"
      ),
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de empresa"
      },
      children: [
        {
          path: "general",
          name: "company-settings",
          component: () => import(
            /* webpackChunkName: "settings" */
            "@/views/settings/SettingsCompanyGeneral"
          ),
          meta: {
            requireAuth: true,
            publicName: "Configuraci\xF3n de empresa"
          }
        },
        {
          path: "organizacion",
          name: "company-organization",
          component: () => import(
            /* webpackChunkName: "settings" */
            "@/views/settings/SettingsCompanyOrganization"
          ),
          meta: {
            requireAuth: true,
            publicName: "Organizaci\xF3n"
          }
        },
        {
          path: "razones-sociales",
          name: "company-razones",
          component: () => import(
            /* webpackChunkName: "settings" */
            "@/views/settings/SettingsCompanyRazonesSociales"
          ),
          meta: {
            requireAuth: true,
            publicName: "Configuraci\xF3n de razones sociales"
          }
        },
        {
          path: "registros-patronales",
          name: "company-employer-registrations",
          component: () => import(
            /* webpackChunkName: "settings" */
            "@/views/settings/employerRegistrations/EmployerRegistrations"
          ),
          meta: {
            requireAuth: true,
            publicName: "Configuraci\xF3n de registros patronales"
          }
        },
        {
          path: "encuesta-pulso",
          name: "company-pulse-poll",
          component: () => import(
            /* webpackChunkName: "settings" */
            "@/views/settings/SettingsPulsePoll"
          ),
          meta: {
            requireAuth: true,
            publicName: "Clima Laboral"
          }
        }
      ]
    },
    {
      path: "expediente",
      name: "directory-settings",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/directory/DirectoryLayout"
      ),
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de documentos personalizados"
      },
      children: [
        {
          path: "configuracion-de-datos",
          name: "directory-settings-configuration",
          component: () => import(
            /* webpackChunkName: "settings" */
            "@/views/directory/DirectoryConfigureData"
          ),
          meta: {
            requireAuth: true,
            publicName: "Configuraci\xF3n de expediente"
          }
        },
        {
          path: "documentos-personalizados",
          name: "directory-settings-documents",
          component: () => import(
            /* webpackChunkName: "settings" */
            "@/views/settings/SettingsCompanyCustomDocuments"
          ),
          meta: {
            requireAuth: true,
            publicName: "Configuraci\xF3n de documentos personalizados"
          }
        },
        {
          path: "descripcion-de-puestos",
          name: "directory-settings-job-description",
          component: () => import(
            /* webpackChunkName: "settings" */
            "@/views/settings/SettingsJobProfileDescriptions"
          ),
          meta: {
            requireAuth: true,
            publicName: "Descripci\xF3n de puesto"
          }
        },
        {
          path: "crear-descripcion-de-puesto",
          name: "directory-settings-job-description-create",
          component: () => import(
            /* webpackChunkName: "settings" */
            "@/views/settings/SettingsJobProfileCreate"
          ),
          meta: {
            requireAuth: true,
            publicName: "Nueva Plantilla",
            backTo: "directory-settings-job-description"
          }
        },
        {
          path: "perfiles-de-colaboradores",
          name: "directory-settings-profile",
          component: () => import(
            /* webpackChunkName: "settings" */
            "@/views/settings/SettingsCompanyProfile"
          ),
          meta: {
            requireAuth: true,
            publicName: "Perfil de colaboradores"
          }
        },
        {
          path: "datos-personalizados",
          name: "directory-settings-customized-data",
          component: () => import(
            /* webpackChunkName: "settings" */
            "@/views/settings/SettingsCustomizedData"
          ),
          meta: {
            requireAuth: true,
            publicName: "Datos personalizados"
          }
        }
      ]
    },
    {
      path: "reloj-checador",
      name: "time-clock",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/SettingsTimeClockEmployees"
      ),
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de reloj checador",
        module: "timework"
      }
    },
    {
      path: "reloj-checador/general",
      alias: "reloj-checador-beta/general",
      name: "time-v3-clock-general",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/timeClockV3/GeneralTimeClock"
      ),
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de reloj checador",
        module: "timework"
      }
    },
    {
      path: "reloj-checador/politicas",
      alias: "reloj-checador-beta/politicas",
      name: "time-v3-clock-policies",
      beforeEnter: (to, from, next) => {
        const permission = havePermission("admin_policies_timework");
        const permissionModule = showModule("settings");
        if (!permissionModule && store.state.me.user.role === "HRA" || store.state.me.user.role !== "HRA" && !store.state.me.user.is_prepayroll_admin && (!permission || !permissionModule)) {
          next({ name: "not-found" });
        } else
          next();
      },
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/timeClockV3/SettingsTimeClockPolicies"
      ),
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de reloj checador",
        module: "timework"
      }
    },
    {
      path: "reloj-checador/incidencias",
      name: "time-v3-clock-inc-config",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/timeClockV3/GeneralTimeClockIncConfig"
      ),
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de reloj checador",
        module: "timework"
      }
    },
    {
      path: "reloj-checador/geolocalizacion",
      name: "time-v3-clock-geo",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/timeClockV3/GeneralTimeClockGeo"
      ),
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de reloj checador",
        module: "timework"
      }
    },
    {
      path: "reloj-checador/geolocalizacion/configurar/:id",
      name: "time-v3-clock-geo-configuration",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/timeClockV3/GeneralTimeClockGeoConfiguration"
      ),
      meta: {
        requireAuth: true,
        publicName: "Configurar geolocalizaci\xF3n",
        backTo: "time-v3-clock-geo",
        module: "timework"
      }
    },
    {
      path: "reloj-checador/politicas/configurar/:id?",
      name: "create-timeemployees-policy",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/timeClockV3/CreateTimeEmployeesPolicy"
      ),
      meta: {
        requireAuth: true,
        publicName: "Creaci\xF3n de Pol\xEDtica",
        backTo: "time-v3-clock-policies",
        module: "timework"
      }
    },
    {
      path: "permisos-reloj",
      name: "time-v3-clock-permissions",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/SettingsTimeClockPermissions"
      ),
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de reloj checador",
        module: "timework"
      }
    },
    {
      path: "permisos-jefes-y-colaboradores",
      name: "modules-employees-permissions",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/Permissions/PermissionForEmployeesNBoss"
      ),
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de permisos",
        module: "permissions"
      }
    },
    {
      path: "integraciones",
      name: "integrations",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/integrations/SettingsIntegrationsView"
      ),
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de Integraciones"
      }
    },
    {
      path: "incidencia",
      name: "incidence-settings",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/incidences/SettingsCustomIncidences"
      ),
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de incidencias",
        module: "time_off"
      }
    },
    {
      path: "flujos-aprobacion",
      name: "incidence-approval-flows",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/ApprovalFlowsSettings"
      ),
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de incidencias",
        module: "review_policies"
      }
    },
    {
      path: "nuevo-flujo-aprobacion",
      name: "new-incidence-approval-flow",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/NewApprovalFlow"
      ),
      meta: {
        requireAuth: true,
        publicName: "Nuevo flujo de aprobaci\xF3n",
        backTo: "incidence-approval-flows",
        module: "review_policies"
      }
    },
    {
      path: "dias-feriados",
      name: "incidence-holidays",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/SettingsHolidays"
      ),
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de incidencias"
      }
    },
    {
      path: "dias-de-descanso",
      name: "incidence-daysoff",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/SettingsDaysOff"
      ),
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de incidencias"
      }
    },
    {
      path: "dias-de-trabajo-remoto",
      name: "incidence-remote-workdays",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/SettingsRemoteWorkDays"
      ),
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de incidencias"
      }
    },
    {
      path: "prenomina",
      name: "incidence-prepayroll",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/SettingsPrepayroll"
      ),
      beforeEnter: (to, from, next) => {
        const permiso = store.state.me.user.modules.some(
          (mod) => mod.module === "prepayroll" && mod.is_active
        );
        if (!permiso) {
          next({ name: "not-found" });
        } else
          next();
      },
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de incidencias",
        module: "prepayroll"
      }
    },
    {
      path: "prenomina-layouts",
      name: "incidence-prepayroll-layouts",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/SettingsPrepayrollLayouts"
      ),
      beforeEnter: (to, from, next) => {
        const permiso = store.state.me.user.modules.some(
          (mod) => mod.module === "prepayroll" && mod.is_active
        );
        if (!permiso) {
          next({ name: "not-found" });
        } else
          next();
      },
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de layouts pren\xF3mina",
        backTo: "incidence-prepayroll",
        backToName: "Regresar a pren\xF3mina",
        module: "prepayroll"
      }
    },
    {
      path: "politicas-de-vacaciones",
      name: "vacation-policies",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/SettingsVacation"
      ),
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de vacaciones",
        module: "time_off"
      }
    },
    {
      path: "beneficios-de-la-empresa",
      name: "benefits-business",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/modules/Benefits/views/settings/BenefitConfigurationList"
      ),
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de beneficios",
        module: "benefits"
      }
    },
    {
      path: "beneficios-de-la-empresa/crear-beneficio",
      name: "benefit-business-form-create",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/modules/Benefits/views/settings/BenefitConfigurationForm"
      ),
      meta: {
        requireAuth: true,
        publicName: "Nuevo beneficio",
        module: "benefits",
        backTo: "benefits-business",
        backToName: "Regresar"
      }
    },
    {
      path: "beneficios-de-la-empresa/editar-beneficio/:id",
      name: "benefit-business-form-edit",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/modules/Benefits/views/settings/BenefitConfigurationForm"
      ),
      meta: {
        requireAuth: true,
        publicName: "Editar beneficio",
        module: "benefits",
        backTo: "benefits-business",
        backToName: "Regresar"
      }
    },
    {
      path: "beneficios-de-los-colaboradores",
      name: "benefits-detail",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/modules/Benefits/views/settings/BenefitConfigurationAssigned"
      ),
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de beneficios",
        module: "benefits"
      }
    },
    {
      path: "reclutadores",
      name: "recruitment-policies",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/SettingsRecruiters"
      ),
      meta: {
        requireAuth: true,
        publicName: "Listado de reclutadores",
        module: "recruitment"
      }
    },
    {
      path: "visitantes",
      name: "report-visitors",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/SettingsReportVisitors"
      ),
      meta: {
        requireAuth: true,
        publicName: "Listado de visitantes",
        module: "reports"
      }
    },
    {
      path: "gestores-de-evaluaciones",
      name: "evaluation-managers",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/SettingsEvaluationManagers"
      ),
      meta: {
        requireAuth: true,
        publicName: "Configuraci\xF3n de Evaluaciones",
        module: "evaluations"
      }
    },
    {
      path: "procesos-internos",
      name: "internal-process-settings",
      component: () => import(
        /* webpackChunkName: "settings" */
        "@/views/settings/internalProcess/SettingsInternalProcess"
      ),
      beforeEnter: (to, from, next) => {
        const permiso = store.state.me.user.modules.some(
          (mod) => mod.module === "internal_processes" && mod.is_active
        );
        if (!permiso) {
          next({ name: "not-found" });
        } else
          next();
      },
      redirect: {
        name: "internal-process-all"
      },
      meta: {
        requireAuth: true,
        publicName: "Procesos Internos"
      },
      children: [
        {
          path: "",
          name: "internal-process-all",
          component: () => import(
            /* webpackChunkName: "settings" */
            "@/views/settings/internalProcess/SettingsInternalProcessAll"
          ),
          meta: {
            requireAuth: true,
            publicName: "Procesos Internos",
            module: "internal_processes"
          }
        },
        {
          path: "plantillas",
          name: "internal-process-templates",
          component: () => import(
            /* webpackChunkName: "settings" */
            "@/views/settings/internalProcess/SettingsInternalProcessTemplates"
          ),
          meta: {
            requireAuth: true,
            publicName: "Procesos Internos",
            module: "internal_processes"
          }
        }
      ]
    }
  ]
};
export default routes;
