var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { DateTime } from "luxon";
const individualIncidencesAvailable = [
  {
    type: "VA",
    id: "vacations",
    name: "Vacaciones"
  },
  {
    type: "HO",
    id: "home_office",
    name: "Home office"
  },
  {
    type: "PTO",
    id: "pto",
    name: "D\xEDa personal"
  }
];
const incidenceTypeFormat = [
  {
    id: "half_days",
    value: false,
    type: "HD",
    label: "Medio d\xEDa"
  },
  {
    id: "permissions",
    value: false,
    type: "PER",
    label: "Permiso"
  },
  {
    id: "custom",
    value: false,
    type: "CUST",
    label: "Personalizada"
  },
  {
    id: "extra_hours",
    value: false,
    type: "EH",
    label: "Horas extra"
  },
  {
    id: "delayed_departures",
    value: false,
    type: "DD",
    label: "Salidas tard\xEDas"
  },
  {
    id: "early_departures",
    value: false,
    type: "ED",
    label: "Salida anticipada"
  },
  {
    id: "double_extra_hours",
    value: false,
    type: "DEH",
    label: "Horas extras dobles"
  },
  {
    id: "triple_extra_hours",
    value: false,
    type: "TEH",
    label: "Horas extras triples"
  },
  {
    id: "bonuses",
    value: false,
    type: "BO",
    label: "Bono"
  },
  {
    id: "discounts",
    value: false,
    type: "DI",
    label: "Descuento"
  },
  {
    id: "refunds",
    value: false,
    type: "RE",
    label: "Reembolso",
    identifier: false
  },
  {
    id: "dominical_prime",
    value: false,
    type: "DP",
    label: "Prima dominical",
    identifier: false
  },
  {
    id: "operated_days",
    value: false,
    type: "OPD",
    label: "D\xEDas operados",
    identifier: false
  },
  {
    id: "worked_holidays",
    value: false,
    type: "WH",
    label: "D\xEDas feriados trabajados",
    identifier: true
  },
  {
    id: "transport_help",
    value: false,
    type: "TH",
    label: "Ayuda de transporte"
  },
  {
    id: "absences",
    value: false,
    type: "AB",
    label: "Falta"
  },
  {
    id: "inabilities",
    value: false,
    type: "IN",
    label: "Incapacidad"
  },
  {
    id: "delays",
    value: false,
    type: "DE",
    label: "Retraso"
  },
  {
    id: "administrative_acts",
    value: false,
    type: "ADA",
    label: "Acta administrativa"
  },
  {
    id: "credits",
    value: false,
    type: "CE",
    label: "Cr\xE9dito"
  },
  {
    id: "pto_period",
    value: true,
    type: "PTOPE",
    label: "Periodo de d\xEDas personales"
  },
  {
    id: "pto_compensation",
    value: true,
    type: "PTOCOMP",
    label: "Compensaci\xF3n de d\xEDas personales"
  },
  {
    id: "vacation_compensation",
    value: true,
    type: "VACOMP",
    label: "Compensaci\xF3n de vacaciones"
  },
  {
    id: "vacation_period",
    value: true,
    type: "VAPE",
    label: "Compensaci\xF3n de vacaciones"
  },
  {
    id: "commissions",
    value: false,
    type: "CO",
    label: "Comisi\xF3n"
  }
];
function groupIncidences(incidences, settings, areas) {
  const individualIncidences = incidences.filter((inc) => individualIncidencesAvailable.map((ind) => ind.type).includes(inc.incidence_type));
  const individualIncidencesWithEmployee = setEmployeeToIndividualIncidences(
    individualIncidences,
    areas
  );
  const groupedIncidences = getGroupedIncidences(incidences, settings);
  const groupedIncidencesWithEmployee = setEmployeeToGroupedIncidences(groupedIncidences, areas);
  return __spreadProps(__spreadValues({}, individualIncidencesWithEmployee), {
    incidences: groupedIncidencesWithEmployee
  });
}
function getIndividualIncidences(incidences) {
  return incidences.filter((incidence) => individualIncidencesAvailable.find((incidenceType) => incidenceType.type === incidence.incidence_type));
}
function setEmployeeToIndividualIncidences(incidences, areas) {
  const incidencesByType = {};
  individualIncidencesAvailable.forEach((incidenceTypeAvailable) => {
    incidencesByType[`${incidenceTypeAvailable.id}`] = incidences.filter((currentInc) => currentInc.incidence_type === incidenceTypeAvailable.type).map((incidence) => {
      const employeeFullName = `${incidence.first_name} ${incidence.last_name} ${incidence.last_name2}`;
      const findArea = areas.find((ar) => ar.id === incidence.area);
      const areaName = findArea ? findArea.name : "Sin asignar";
      return __spreadValues(__spreadValues({}, incidence), { areaName, employeeFullName });
    });
  });
  return incidencesByType;
}
function getGroupedIncidences(incidences, settings) {
  return incidences.filter((incidence) => {
    const isNotIndividual = !individualIncidencesAvailable.find((incidenceTypes) => incidenceTypes.type === incidence.incidence_type);
    const isActive = settings.find((setting) => setting.type === incidence.incidence_type);
    return isNotIndividual && isActive;
  });
}
function setEmployeeToGroupedIncidences(groupedIncidences, areas) {
  return groupedIncidences.map((incidence) => {
    const employeeFullName = `${incidence.first_name} ${incidence.last_name} ${incidence.last_name2}`;
    const findArea = areas.find((ar) => ar.id === incidence.area);
    const type = incidenceTypeFormat.find((incidenceType) => incidenceType.type === incidence.incidence_type);
    const incidenceName = type ? type.name : "";
    const areaName = findArea ? findArea.name : "Sin asignar";
    return __spreadValues(__spreadValues({}, incidence), { employeeFullName, areaName, incidenceName });
  });
}
function groupIncidencesByDate(incidences) {
  const incidencesGroups = {};
  Object.entries(incidences).forEach(([label, groups]) => {
    const singleIncidenceBlocks = groups.map((incidence) => {
      const initialDate = DateTime.fromISO(incidence.start_date);
      return groupEachIncidenceByDate(incidence, initialDate);
    });
    incidencesGroups[label] = [];
    singleIncidenceBlocks.forEach((block) => incidencesGroups[label].push(block));
  });
  return incidencesGroups;
}
function groupEachIncidenceByDate(incidence, initialDate) {
  const endDate = DateTime.fromISO(incidence.end_date);
  const totalDays = new Date(endDate) === new Date(DateTime.fromISO(incidence.start_date)) ? 1 : endDate.diff(initialDate).as("days") + 1;
  const groupedDates = {};
  const initialDay = `${initialDate.month}-${initialDate.day}`;
  let currentBlockDay = initialDay;
  groupedDates[`${currentBlockDay}`] = [];
  for (let day = 0; day < totalDays; day += 1) {
    const currentDate = initialDate.plus({ days: day });
    groupedDates[`${currentBlockDay}`].push(incidence);
    if (lastDayOfWeek(currentDate) && hasPendingDays(day)) {
      const nextDate = currentDate.plus({ days: 1 });
      currentBlockDay = `${nextDate.month}-${nextDate.day}`;
      groupedDates[`${currentBlockDay}`] = [];
    }
  }
  function lastDayOfWeek(date) {
    return date.plus({ days: 1 }).weekday < date.weekday;
  }
  function hasPendingDays(currentDay) {
    return currentDay + 1 < totalDays;
  }
  return groupedDates;
}
function getBirthdateOfEmployees(birthdates, start) {
  return birthdates.filter((emp) => {
    const startMonth = DateTime.fromISO(start).month;
    const empBirthdate = emp.birthdate ? DateTime.fromISO(emp.birthdate) : null;
    return empBirthdate && startMonth === empBirthdate.month;
  }).map((bday) => {
    const employeeFullName = `${bday.full_name}`;
    const date = bday.birthdate;
    const birthdate = DateTime.fromISO(date);
    const birthday = `${birthdate.month}-${birthdate.day}`;
    const birthdays = {};
    birthdays[birthday] = [{ employeeFullName, date }];
    return birthdays;
  });
}
function getAniversaryOfEmployees(anniv, start) {
  const aniversaryEmployee = anniv.filter((emp) => {
    const startMonth = DateTime.fromISO(start).month;
    const annivdate = emp.work_start_date ? DateTime.fromISO(emp.work_start_date) : null;
    return annivdate && startMonth === annivdate.month;
  }).map((anniversary) => {
    const employeeFullName = `${anniversary.full_name}`;
    const date = anniversary.work_start_date;
    const currentYear = new Date().getFullYear();
    const aniversary = DateTime.fromISO(date);
    const aniversaryDay = `${aniversary.month}-${aniversary.day}`;
    const aniversaries = {};
    if (aniversary.year !== currentYear) {
      aniversaries[aniversaryDay] = [{ employeeFullName, date }];
    }
    return aniversaries;
  });
  return aniversaryEmployee;
}
function getPeriod(startDate, endDate) {
  const start = DateTime.fromISO(startDate);
  const end = DateTime.fromISO(endDate);
  if (startDate === endDate) {
    return start.toFormat("dd LLL yy", { locale: "es" });
  }
  return `De ${start.toFormat("dd LLL yy", { locale: "es" })} a ${end.toFormat("dd LLL yy", { locale: "es" })}`;
}
export {
  getAniversaryOfEmployees,
  getBirthdateOfEmployees,
  groupEachIncidenceByDate,
  groupIncidencesByDate,
  setEmployeeToGroupedIncidences,
  setEmployeeToIndividualIncidences,
  getGroupedIncidences,
  getIndividualIncidences
};
export default {
  myIncidencesList: (state) => state.myIncidences.map((inc) => {
    const period = getPeriod(inc.start_date, inc.end_date);
    return __spreadProps(__spreadValues({}, inc), {
      period
    });
  }),
  myCalendar(state, getters, rootState, rootGetters) {
    const legacySettingsIncidences = rootGetters["company/incidences/formatedActiveLegacyIncidencesSettings"];
    const settingsIncidences = legacySettingsIncidences;
    const currentIncidences = getters.myIncidencesList;
    const companyAreas = rootGetters["company/areas/companyAreas"];
    const incidences = groupIncidences(
      currentIncidences,
      settingsIncidences,
      companyAreas
    );
    const incidencesGroupedByDate = groupIncidencesByDate(incidences);
    const birthdate = getBirthdateOfEmployees(
      rootGetters["company/employees/currentEmployees"],
      state.startRange,
      state.endRange
    );
    Object.assign(
      incidencesGroupedByDate,
      { birthdate, aniversary: [] }
    );
    return incidencesGroupedByDate;
  },
  myTeamCalendar(state, getters, rootState, rootGetters) {
    const legacySettingsIncidences = rootGetters["company/incidences/formatedActiveLegacyIncidencesSettings"];
    const customSettingsIncidences = rootGetters["company/incidences/formatedCustomIncidencesSettings"].map((set) => __spreadProps(__spreadValues({}, set), { type: "CUST" }));
    const settingsIncidences = legacySettingsIncidences.concat(customSettingsIncidences);
    const currentIncidences = getters.calendarIncidencesList;
    const companyAreas = rootGetters["company/areas/companyAreas"];
    const incidences = groupIncidences(
      currentIncidences,
      settingsIncidences,
      companyAreas
    );
    const incidencesGroupedByDate = groupIncidencesByDate(incidences);
    const birthdate = getBirthdateOfEmployees(
      rootGetters["company/employees/currentEmployees"],
      state.startRange,
      state.endRange
    );
    const aniversary = getAniversaryOfEmployees(
      rootGetters["company/employees/currentEmployees"],
      state.startRange,
      state.endRange
    );
    Object.assign(
      incidencesGroupedByDate,
      { birthdate, aniversary }
    );
    return incidencesGroupedByDate;
  },
  calendarIncidencesList: (state) => state.calendarIncidences.map((inc) => {
    const period = getPeriod(inc.start_date, inc.end_date);
    return __spreadProps(__spreadValues({}, inc), {
      period
    });
  }),
  companyCalendar(state, getters, rootState, rootGetters) {
    const legacySettingsIncidences = rootGetters["company/incidences/formatedActiveLegacyIncidencesSettings"];
    const customSettingsIncidences = rootGetters["company/incidences/formatedCustomIncidencesSettings"].map((set) => __spreadProps(__spreadValues({}, set), { type: "CUST" }));
    const settingsIncidences = legacySettingsIncidences.concat(customSettingsIncidences);
    const companyAreas = rootGetters["company/areas/companyAreas"];
    const currentIncidences = getters.calendarIncidencesList;
    const incidences = groupIncidences(
      currentIncidences,
      settingsIncidences,
      companyAreas
    );
    const incidencesGroupedByDate = groupIncidencesByDate(incidences);
    const birthdate = getBirthdateOfEmployees(
      rootGetters["company/employees/currentEmployees"],
      state.startRange
    );
    const aniversary = getAniversaryOfEmployees(
      rootGetters["company/employees/currentEmployees"],
      state.startRange
    );
    Object.assign(
      incidencesGroupedByDate,
      { birthdate, aniversary }
    );
    return incidencesGroupedByDate;
  }
};
