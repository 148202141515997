var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "datepicker", class: {
    "datepicker--disabled": _vm.disabled,
    [`datepicker--direction-${_vm.openDirection}`]: true,
    [`datepicker--${_vm.size}`]: _vm.size !== "normal",
    "datepicker--focused": _vm.isFocus
  } }, [_c("VDatepicker", _vm._b({ ref: "datepicker", staticClass: "datepicker__control", class: { "datepicker__control--has-error": _vm.hasError }, attrs: { "language": _vm.language, "format": _vm.displayFormat, "disabled": _vm.disabledDates, "calendar-button-icon": "worky-icon worky-icon-calendar", "clear-button-icon": "worky-icon worky-icon-close", "calendar-button": "", "disabled-picker": _vm.disabled, "clear-button": _vm.disabled ? false : _vm.clearable, "calendar-class": _vm.IsOnBox() ? "datepicker--in-box" : null }, model: { value: _vm.internalModelComputed, callback: function($$v) {
    _vm.internalModelComputed = $$v;
  }, expression: "internalModelComputed" } }, "VDatepicker", _vm.$attrs, false))], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
