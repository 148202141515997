var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Area from "@/models/area";
import Company from "@/models/company";
import { DataError } from "@/exceptions";
import { api } from "@/api";
export default {
  getAreas(_0) {
    return __async(this, arguments, function* ({ commit }) {
      const areas = yield api.areas.getAreas();
      commit("setAreas", { areas });
      return areas;
    });
  },
  createArea(_0) {
    return __async(this, arguments, function* ({ commit, rootGetters }, { area } = {}) {
      if (!area) {
        throw new DataError("The area data is not defined");
      }
      const companyObj = rootGetters["me/company"];
      const company = new Company(companyObj);
      const newArea = yield company.areas().create(area);
      commit("setArea", { area: newArea });
      return area;
    });
  },
  updateArea(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, { area }) {
      const oldArea = state.list.find((_area) => _area.id === area.id);
      const areaObject = new Area(oldArea);
      const updatedArea = yield areaObject.setFields(area).update();
      commit("setArea", { area: updatedArea });
      return updatedArea;
    });
  },
  deleteArea(_0, _1) {
    return __async(this, arguments, function* ({ commit, dispatch, state }, { id }) {
      const oldArea = state.list.find((_area) => _area.id === id);
      const area = new Area(oldArea);
      yield area.delete();
      const areas = state.list.filter((_area) => _area.id !== id);
      commit("setAreas", { areas });
      return areas;
    });
  }
};
