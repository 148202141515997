import store from "@/store";
function verifyPermissions(to, from, next) {
  if (typeof store.state.company.permissions.myPermissions === "undefined" || !store.state.company.permissions.myPermissions.find(
    (permission) => permission.field_or_action === "trainings"
  )) {
    return next();
  }
  if (typeof store.state.company.permissions.myPermissions === "undefined" || store.state.company.permissions.myPermissions.filter(
    (permission) => permission.slug.includes("trainings") && permission.policy === "disallow"
  ).length > 0) {
    return next({ name: "not-found" });
  }
  return next();
}
const routes = {
  path: "/capacitaciones",
  name: "trainings",
  component: () => import(
    /* webpackChunkName: "trainings" */
    "@/views/trainings/TrainingsLayout"
  ),
  meta: { publicName: "Capacitaciones" },
  redirect: { name: "trainings-company-list" },
  children: [
    {
      path: "empresa",
      name: "trainings-company",
      component: () => import(
        /* webpackChunkName: "trainings" */
        "@/views/trainings/TrainingsCompanyLayout"
      ),
      redirect: { name: "trainings-company-list" },
      children: [
        {
          path: "todas",
          name: "trainings-company-list",
          component: () => import(
            /* webpackChunkName: "trainings" */
            "@/views/trainings/hra/TrainingsListView"
          ),
          meta: {
            requireAuth: true,
            module: "trainings",
            publicName: "Capacitaciones"
          }
        },
        {
          path: "detalle/:id",
          name: "training-company-detail",
          component: () => import(
            /* webpackChunkName: "trainings" */
            "@/views/trainings/hra/TrainingDetailView"
          ),
          redirect: { name: "training-detail-employees-in-process" },
          meta: {
            requireAuth: true,
            module: "trainings",
            publicName: "Detalle"
          },
          children: [
            {
              path: "en-proceso",
              name: "training-detail-employees-in-process",
              component: () => import(
                /* webpackChunkName: "trainings" */
                "@/views/trainings/hra/TrainingEmployeesInProcessView"
              ),
              meta: {
                requireAuth: true,
                module: "trainings",
                publicName: "En proceso",
                backTo: "trainings-company-list"
              }
            },
            {
              path: "terminadas",
              name: "training-detail-employees-finished",
              component: () => import(
                /* webpackChunkName: "trainings" */
                "@/views/trainings/hra/TrainingEmployeesFinishedView"
              ),
              props: true,
              meta: {
                requireAuth: true,
                module: "trainings",
                publicName: "Terminadas",
                backTo: "trainings-company-list"
              }
            },
            {
              path: "avance/:guestId",
              name: "training-detail-progress-by-employee",
              component: () => import(
                /* webpackChunkName: "trainings" */
                "@/views/trainings/hra/TrainingEmployeeProgressView"
              ),
              meta: {
                requireAuth: true,
                module: "trainings",
                publicName: "En proceso",
                backTo: "training-detail-employees-in-process"
              }
            },
            {
              path: "completada/:guestId",
              name: "training-detail-completed-by-employee",
              component: () => import(
                /* webpackChunkName: "trainings" */
                "@/views/trainings/hra/TrainingEmployeeCompletedView"
              ),
              meta: {
                requireAuth: true,
                module: "trainings",
                publicName: "Completada",
                backTo: "training-detail-employees-finished"
              }
            }
          ]
        },
        {
          path: "expedientes",
          name: "trainings-company-history",
          component: () => import(
            /* webpackChunkName: "trainings" */
            "@/views/trainings/hra/TrainingsHistoryView"
          ),
          meta: {
            requireAuth: true,
            module: "trainings",
            publicName: "Expedientes"
          }
        },
        {
          path: "expedientes/:id",
          name: "trainings-company-history-detail",
          component: () => import(
            /* webpackChunkName: "trainings" */
            "@/views/trainings/hra/TrainingsHistoryDetailView"
          ),
          meta: {
            requireAuth: true,
            module: "trainings",
            publicName: "Expediente",
            backTo: "trainings-company-history"
          }
        },
        {
          path: "crear",
          name: "trainings-company-create",
          beforeEnter: verifyPermissions,
          component: () => import(
            /* webpackChunkName: "trainings" */
            "@/views/trainings/hra/CreateTrainingView"
          ),
          meta: {
            requireAuth: true,
            module: "trainings",
            publicName: "Nueva capacitaci\xF3n",
            backTo: "trainings-company-list"
          }
        },
        {
          path: "editar/:id",
          name: "trainings-company-edit",
          component: () => import(
            /* webpackChunkName: "trainings" */
            "@/views/trainings/hra/EditTrainingView"
          ),
          meta: {
            requireAuth: true,
            module: "trainings",
            publicName: "Editar capacitaci\xF3n",
            backTo: "trainings-company-list"
          }
        }
      ]
    },
    {
      path: "equipo",
      name: "trainings-team",
      component: () => import(
        /* webpackChunkName: "trainings" */
        "@/views/trainings/TrainingsTeamLayout"
      ),
      redirect: { name: "trainings-team-list" },
      children: [
        {
          path: "todas",
          name: "trainings-team-list",
          component: () => import(
            /* webpackChunkName: "trainings" */
            "@/views/trainings/boss/TrainingsTeamListView"
          ),
          meta: {
            requireAuth: true,
            module: "trainings",
            publicName: "Capacitaciones",
            onlyMyCompany: true
          }
        },
        {
          path: "detalle/:id",
          name: "training-team-detail",
          component: () => import(
            /* webpackChunkName: "trainings" */
            "@/views/trainings/boss/TrainingTeamDetailView"
          ),
          redirect: { name: "training-detail-employees-in-process" },
          meta: {
            requireAuth: true,
            module: "trainings",
            publicName: "Detalle",
            onlyMyCompany: true
          },
          children: [
            {
              path: "en-proceso",
              name: "training-team-detail-employees-in-process",
              component: () => import(
                /* webpackChunkName: "trainings" */
                "@/views/trainings/boss/TrainingTeamEmployeesInProcessView"
              ),
              meta: {
                requireAuth: true,
                module: "trainings",
                publicName: "En proceso",
                backTo: "trainings-team-list",
                onlyMyCompany: true
              }
            },
            {
              path: "terminadas",
              name: "training-team-detail-employees-finished",
              component: () => import(
                /* webpackChunkName: "trainings" */
                "@/views/trainings/boss/TrainingTeamEmployeesFinishedView"
              ),
              props: true,
              meta: {
                requireAuth: true,
                module: "trainings",
                publicName: "Terminadas",
                backTo: "trainings-team-list",
                onlyMyCompany: true
              }
            }
          ]
        },
        {
          path: "expedientes",
          name: "trainings-team-history",
          component: () => import(
            /* webpackChunkName: "trainings" */
            "@/views/trainings/boss/TrainingsTeamHistoryView"
          ),
          meta: {
            requireAuth: true,
            module: "trainings",
            publicName: "Expedientes",
            onlyMyCompany: true
          }
        },
        {
          path: "expedientes/:id",
          name: "trainings-team-history-detail",
          component: () => import(
            /* webpackChunkName: "trainings" */
            "@/views/trainings/hra/TrainingsHistoryDetailView"
          ),
          props: {
            isBoss: true
          },
          meta: {
            requireAuth: true,
            module: "trainings",
            publicName: "Expediente",
            backTo: "trainings-team-history",
            onlyMyCompany: true
          }
        }
      ]
    },
    {
      path: "colaborador",
      name: "trainings-employee",
      component: () => import(
        /* webpackChunkName: "trainings" */
        "@/views/trainings/em/TrainingsEmployeeLayout"
      ),
      redirect: { name: "trainings-employee-list" },
      children: [
        {
          path: "todas",
          name: "trainings-employee-list",
          component: () => import(
            /* webpackChunkName: "trainings" */
            "@/views/trainings/em/TrainingsEmployeeListView"
          ),
          meta: {
            requireAuth: true,
            module: "trainings",
            publicName: "Capacitaciones",
            onlyMyCompany: true
          }
        },
        {
          path: "terminadas",
          name: "trainings-employee-list-completed",
          component: () => import(
            /* webpackChunkName: "trainings" */
            "@/views/trainings/em/TrainingsEmployeeListCompletedView"
          ),
          meta: {
            requireAuth: true,
            module: "trainings",
            publicName: "Capacitaciones",
            onlyMyCompany: true
          }
        },
        {
          path: "detalle/:id",
          name: "training-employee-detail",
          component: () => import(
            /* webpackChunkName: "trainings" */
            "@/views/trainings/em/TrainingEmployeeDetailView"
          ),
          meta: {
            requireAuth: true,
            module: "trainings",
            publicName: "Detalle de la capacitaci\xF3n",
            backTo: "trainings-employee-list",
            onlyMyCompany: true
          }
        },
        {
          path: "detalle/:id/completada",
          name: "training-employee-detail-completed",
          component: () => import(
            /* webpackChunkName: "trainings" */
            "@/views/trainings/em/TrainingEmployeeDetailCompletedView"
          ),
          meta: {
            requireAuth: true,
            module: "trainings",
            publicName: "Capacitaci\xF3n completada",
            backTo: "trainings-employee-list-completed",
            onlyMyCompany: true
          }
        }
      ]
    }
  ]
};
export default routes;
