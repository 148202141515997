function checkFeature(store) {
  return function check(key) {
    var _a, _b, _c;
    const { features = [] } = ((_b = (_a = store == null ? void 0 : store.state) == null ? void 0 : _a.me) == null ? void 0 : _b.user) || {};
    if (features.length === 0)
      return false;
    const feature = features.find((feat) => feat.feature === key) || {};
    return (_c = feature == null ? void 0 : feature.active) != null ? _c : false;
  };
}
export default function plugin(Vue, store) {
  if (plugin.installed)
    return;
  plugin.installed = true;
  Vue.prototype.$feature = checkFeature(store);
}
