import Model from "@/models/model";
import Area from "@/models/area";
import Benefit from "@/models/benefit";
import Employee from "@/models/employee";
import Holiday from "@/models/holiday";
import Location from "@/models/location";
import Incidence from "@/models/incidence";
import RazonSocial from "@/models/razonSocial";
import Request from "@/models/request";
import Template from "@/models/template";
export default class Company extends Model {
  static get modelName() {
    return "Company";
  }
  static apiNamespace() {
    return "api/v2";
  }
  static resourceNamespace() {
    return "companies";
  }
  static default() {
    return "";
  }
  static defaults() {
    return {
      vacations_onboarded: false,
      company_onboarded: false,
      incidencias_onboarded: false,
      checklist: false,
      require_signature_payrolls: false
    };
  }
  static fields() {
    return [
      "id",
      "name",
      "description",
      "cover",
      "cover_bg_color",
      "cover_original",
      "logo",
      "active",
      "checklist",
      "company_onboarded",
      "incidencias_onboarded",
      "vacations_onboarded",
      "admin_name",
      "admin_email",
      "admin_phone",
      "complaintbox_email",
      "social_facebook",
      "social_twitter",
      "social_linkedin",
      "social_instagram",
      "webpage",
      "outsourcer_company",
      "outsourcer_name",
      "outsourcer_email",
      "outsourcer_phone",
      "accountant_name",
      "accountant_email",
      "accountant_phone",
      "active_employees",
      "count_employees",
      "require_signature_payrolls",
      "is_checklisted",
      "is_onboarded",
      "is_self_onboarded",
      "payroll_receipt_dispersion",
      "organization_name"
    ];
  }
  setLogo(file) {
    const formData = new FormData();
    file !== "" ? formData.append("file", file, file.name) : formData.append("file", "");
    return this.constructor.post(`${this.resourcePath()}/upload_logo/`, formData, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  }
  setCover(file, is_original) {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("is_original", is_original);
    return this.constructor.post(`${this.resourcePath()}/upload_cover/`, formData, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  }
  areas() {
    return {
      get: () => this.constructor.get(`api/v2/areas/`).then((areas) => areas.map((area) => new Area(area))),
      create: (area) => this.constructor.post(`api/v2/areas/`, area).then((newArea) => new Area(newArea))
    };
  }
  locations() {
    return {
      get: (params = {}) => this.constructor.get(`api/v2/locations/`, { params }).then((locations) => locations.map((location) => new Location(location))),
      create: (location) => this.constructor.post(`api/v2/locations/`, location).then((newLocation) => new Location(newLocation))
    };
  }
  razonesSociales() {
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/razones_sociales/`).then((razonesSociales) => razonesSociales.map((razonSocial) => new RazonSocial(razonSocial))),
      create: (razonSocial) => this.constructor.post(`${this.resourcePath()}/razones_sociales/`, razonSocial).then((newRazonSocial) => new RazonSocial(newRazonSocial)),
      remove: (razonSocial) => this.constructor.post(`${this.resourcePath()}/remove_razon_social/`, razonSocial)
    };
  }
  employees() {
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/employees/`).then((employees) => employees.map((employee) => new Employee(employee))),
      create: (employee) => this.constructor.post(`${this.resourcePath()}/employees/`, employee).then((newEmployee) => new Employee(newEmployee))
    };
  }
  calendarEmployees() {
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/calendar_employees/`).then((employees) => employees.map((employee) => new Employee(employee))),
      create: (employee) => this.constructor.post(`${this.resourcePath()}/employees/`, employee).then((newEmployee) => new Employee(newEmployee))
    };
  }
  incidences() {
    return {
      get: (params) => this.constructor.get(`${this.resourcePath()}/incidences/`, { params }).then((incidences) => incidences.map((incidence) => new Incidence(incidence))),
      create: (incidence) => this.constructor.post(`${this.resourcePath()}/incidences/`, incidence).then((newIncidence) => new Incidence(newIncidence)),
      notify: () => this.constructor.post(`${this.resourcePath()}/notify_incidences/`),
      post: (email) => this.constructor.post(`${this.resourcePath()}/download_incidencias/`, email)
    };
  }
  requests() {
    return {
      get: (params) => this.constructor.get(`${this.resourcePath()}/requests/`, { params }).then((requests) => requests.map((request) => new Request(request))),
      create: (request) => this.constructor.post(`${this.resourcePath()}/requests/`, request).then((newRequest) => new Request(newRequest))
    };
  }
  holidays() {
    return {
      get: () => this.constructor.get(`api/v2/holidays/`).then((holidays) => holidays.map((holiday) => new Holiday(holiday))),
      create: (holiday) => this.constructor.post(`api/v2/holidays/`, holiday).then((newHoliday) => new Holiday(newHoliday))
    };
  }
  templates() {
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/templates/`).then((templates) => templates.map((template) => new Template(template)))
    };
  }
  evaluations() {
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/evaluations/`)
    };
  }
  benefits() {
    return {
      create: (benefit) => this.constructor.post(`api/v2/benefits/`, benefit).then((newBenefit) => new Benefit(newBenefit)),
      get: (benefit) => this.constructor.get(`${this.resourcePath()}/benefits/`).then((newBenefit) => new Benefit(newBenefit))
    };
  }
  customDocumentsSection() {
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/custom_documents_section/`),
      create: (title) => this.constructor.post(
        `${this.resourcePath()}/custom_documents_section/`,
        title
      ),
      remove: (id) => this.constructor.delete(
        `${this.resourcePath()}/custom_documents_section/`,
        id
      )
    };
  }
  customDocuments() {
    return {
      create: (name) => this.constructor.post(`${this.resourcePath()}/custom_documents/`, name),
      update: (data) => this.constructor.put(`${this.resourcePath()}/custom_documents/`, data),
      remove: (id) => this.constructor.delete(`${this.resourcePath()}/custom_documents/`, id)
    };
  }
  payrollReceipts() {
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/payroll_receipts/?has_receiver=true`)
    };
  }
  payrollReceiptsUnassigned() {
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/payroll_receipts/?has_receiver=false`)
    };
  }
  laidOffEmployees() {
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/employees/?laid_off=true`)
    };
  }
  reportAbscensesByArea() {
    return {
      get: () => this.constructor.get(`${this.reportsResourcePath()}/report_abscenses_by_area/`)
    };
  }
  reportEmploymentDate() {
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/report_date_of_employment/`)
    };
  }
  reportCivilStatus() {
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/report_civil_status/`)
    };
  }
  reportEducationlevel() {
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/report_education_level/`)
    };
  }
  reportEmployeesByYear() {
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/report_employees_by_month/`)
    };
  }
  reportIncidences() {
    return {
      get: () => this.constructor.get(`${this.reportsResourcePath()}/incidences/`)
    };
  }
  reportSalary() {
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/report_salary/`)
    };
  }
  reportSentimentByWeek(filters) {
    let filterParams = "";
    if (filters) {
      filterParams = Object.keys(filters).map((filterParam) => `${filterParam}=${filters[filterParam]}`).join("&");
    }
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/report_workenv_by_week/?${filterParams}`)
    };
  }
  reportSentimentByArea(filters) {
    let filterParams = "";
    if (filters) {
      filterParams = Object.keys(filters).map((filterParam) => `${filterParam}=${filters[filterParam]}`).join("&");
    }
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/report_workenv_by_area/?${filterParams}`)
    };
  }
  reportSentimentByMonthYear(filters) {
    let filterParams = "";
    if (filters) {
      filterParams = Object.keys(filters).map((filterParam) => `${filterParam}=${filters[filterParam]}`).join("&");
    }
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/report_workenv_by_month_year/?${filterParams}`)
    };
  }
  reportSurveys() {
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/`)
    };
  }
  reportGender() {
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/report_gender/`)
    };
  }
  reportAge() {
    return {
      get: () => this.constructor.get(`${this.reportsResourcePath()}/report_age/`)
    };
  }
  reportTrimesterRotation() {
    return {
      get: () => this.constructor.get(`${this.resourcePath()}/report_trimester_rotation/`)
    };
  }
}
