export const setCookie = (cookieKey, cookieValue, expirationDays) => {
  let expiryDate = "";
  if (expirationDays) {
    const date = new Date();
    date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1e3);
    expiryDate = `; expires=" ${date.toUTCString()}`;
  }
  document.cookie = `${cookieKey}=${cookieValue || ""}${expiryDate}; path=/`;
};
export const getCookie = (cookieKey) => {
  let finalCookie;
  const cookieName = `${cookieKey}=`;
  const cookieArray = document.cookie.split(";");
  cookieArray.forEach((cookie) => {
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(cookieName) === 0) {
      finalCookie = cookie.substring(cookieName.length, cookie.length);
    }
  });
  return finalCookie;
};
export const cookieExits = (cookieName) => {
  if (document.cookie.split(";").some((item) => item.trim().startsWith(`${cookieName}=`))) {
    return true;
  }
  return false;
};
