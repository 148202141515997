export default {
  setAgeReport(state, { data }) {
    state.listA = data;
  },
  setGenderReport(state, { data }) {
    state.listG = data;
  },
  setCivilStatusReport(state, { data }) {
    state.listU = data;
  },
  setEscolarityReport(state, { data }) {
    state.listE = data;
  },
  setSalaryReport(state, { data }) {
    state.listS = data;
  },
  setSentimentbyWeekReport(state, { data }) {
    state.listSR = data;
  },
  uploadFile(state, { data }) {
    state.uploadedFile = data;
  }
};
