const state = {
  rebranding: true
};
const actions = {
  changeRebranding({ commit }, status) {
    commit("setRebranding", status);
  }
};
const mutations = {
  setRebranding(state2, payload) {
    state2.rebranding = payload;
  }
};
export default {
  state,
  actions,
  mutations,
  namespaced: true
};
