var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "modal-whats-new" }, [_c("Modal", { attrs: { "width": "476px", "closeable": false }, on: { "clickClose": () => _vm.$emit("close") } }, [_c("div", { staticClass: "modal-whats-new__cover" }, _vm._l(_vm.slides, function(slide, slideIndex) {
    return _c("div", { key: slideIndex, staticClass: "modal-whats-new__slide", style: {
      backgroundImage: `url(${slide.cover})`,
      transform: `translateX(${100 * (slideIndex - _vm.currentSlide)}%)`
    } });
  }), 0), _c("div", [_c("div", { staticClass: "modal-whats-new__head" }, [_vm._v(" " + _vm._s(_vm.slideActive.head ? _vm.slideActive.head : _vm.head) + " ")]), _c("div", { staticClass: "modal-whats-new__title" }, [_vm._v(" " + _vm._s(_vm.slideActive.title) + " ")]), _c("div", [_c("p", { staticClass: "modal-whats-new__text", domProps: { "innerHTML": _vm._s(_vm.slideActive.description) } })]), _c("div", { staticClass: "flex mt-6" }, [_c("div", { staticClass: "flex-auto modal-whats-new__dots" }, _vm._l(_vm.slides, function(slide, index) {
    return _c("div", { key: index, staticClass: "modal-whats-new__dot cursor-pointer", class: { "modal-whats-new__dot--active": index === _vm.currentSlide }, on: { "click": function($event) {
      return _vm.goToSlide(index);
    } } });
  }), 0), _vm.slides.length > 1 ? _c("div", [_vm.currentSlide < _vm.slides.length - 1 ? _c("Button", { staticClass: "mr-4", attrs: { "theme": "ghost" }, on: { "click": () => _vm.$emit("close") } }, [_vm._v(" Cerrar ")]) : _vm._e(), _vm.currentSlide < _vm.slides.length - 1 ? _c("Button", { on: { "click": _vm.nextSlide } }, [_vm._v(" Siguiente ")]) : _vm._e(), _vm.currentSlide === _vm.slides.length - 1 ? _c("Button", { on: { "click": () => _vm.$emit("close", "end") } }, [_vm._v(" Finalizar ")]) : _vm._e()], 1) : _vm.slides.length === 1 ? _c("div", { staticClass: "flex gap-4 justify-end" }, [_c("Button", { attrs: { "theme": "ghost" }, on: { "click": () => _vm.$emit("close") } }, [_vm._v(" Cerrar ")]), _c("Button", { on: { "click": () => _vm.$emit("close", "end") } }, [_vm._v(" Entendido ")])], 1) : _vm._e()])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
