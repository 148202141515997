import Model from "@/models/model";
export default class Level extends Model {
  static get modelName() {
    return "Level";
  }
  static apiNamespace() {
    return "api/v1";
  }
  static resourceNamespace() {
    return "levels";
  }
  static default() {
    return "";
  }
  static fields() {
    return [
      "id",
      "name",
      "company",
      "description"
    ];
  }
  employees() {
    return {
      set: (ids) => this.constructor.put(`${this.resourcePath()}/employees/`, {
        employee_ids: ids
      })
    };
  }
}
