import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
const state = {
  list: []
};
export default {
  actions,
  getters,
  mutations,
  state,
  namespaced: true
};
