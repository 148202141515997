export default {
  setVacancies(state, data) {
    state.list = data;
  },
  setTotalVacancies(state, data) {
    state.totalVacancies = data;
  },
  setVacancy(state, { data }) {
    const { id } = data;
    const index = state.list.findIndex((vacancy) => vacancy.id === id);
    state.vacancy = data;
    if (index >= 0) {
      state.list[index] = data;
    } else {
      state.list.push(data);
    }
  },
  removeVacancy(state, { id }) {
    state.list = state.list.filter((vacancy) => vacancy.id !== id);
  },
  setRounds(state, { data }) {
    state.rounds = data;
  },
  setRound(state, { data }) {
    const index = state.rounds.findIndex((round) => round.id === data.id);
    if (index >= 0) {
      state.rounds[index] = data;
    } else {
      state.rounds.push(data);
    }
  },
  removeRound(state, { data }) {
    state.rounds = state.rounds.filter((round) => round.id !== data.id);
  },
  setCandidates(state, { data }) {
    state.candidates = data;
  },
  setCandidate(state, { data }) {
    const index = state.candidates.findIndex((candidate) => candidate.id === data.id);
    state.candidate = data;
    const updatedCandidates = [...state.candidates];
    if (index >= 0) {
      updatedCandidates[index] = data;
    } else {
      updatedCandidates.push(data);
    }
    state.candidates = updatedCandidates;
  },
  setComments(state, { data }) {
    const updatedComments = [...state.comments];
    data.forEach((comment) => {
      const index = updatedComments.findIndex((_comment) => _comment.id === comment.id);
      if (index >= 0) {
        updatedComments[index] = comment;
      } else {
        updatedComments.push(comment);
      }
    });
    const ids = [];
    const uniqueComments = updatedComments.filter((comment) => {
      if (ids.includes(comment.id)) {
        return false;
      }
      ids.push(comment.id);
      return true;
    });
    state.comments = uniqueComments;
  },
  setComment(state, { data }) {
    const updatedComments = [...state.comments];
    const index = updatedComments.findIndex((_comment) => _comment.id === data.id);
    if (index >= 0) {
      updatedComments[index] = data;
    } else {
      updatedComments.push(data);
    }
    state.comments = updatedComments;
  }
};
