var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Company from "@/models/company";
import Holiday from "@/models/holiday";
import { DataError } from "@/exceptions";
export default {
  createHoliday(_0) {
    return __async(this, arguments, function* ({ commit, state, rootState }, { date, companyId } = {}) {
      const company = new Company({ id: companyId });
      const data = {
        date,
        company: companyId
      };
      yield company.holidays().create(data);
    });
  },
  deleteHoliday(_0) {
    return __async(this, arguments, function* ({ commit, state }, { date } = {}) {
      if (!date) {
        throw new DataError("The holiday date is not defined");
      }
      const holiday = state.list.find((_holiday) => _holiday.date === date);
      if (holiday) {
        yield new Holiday(holiday).delete();
        const holidays = state.list.filter((_holiday) => _holiday.date !== date);
        commit("setHolidays", { data: holidays });
      }
    });
  }
};
