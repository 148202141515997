var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("nav", { ref: "sidebar", staticClass: "sidebar transition-width duration-500 ease-out", class: {
    "w-[300px]": _vm.isOpenMenu,
    "w-20": !_vm.isOpenMenu,
    "with-admin": _vm.hasAdminBar
  }, on: { "mouseleave": () => _vm.openMenu(false), "mouseenter": () => _vm.openMenu(true) } }, [_c("RouterLink", { staticClass: "self-center", attrs: { "to": { name: "home" } } }, [_c("img", { staticClass: "client-logo", attrs: { "src": _vm.companyLogo, "alt": (_vm.company || {}).name } })]), _vm.$is("mycompany") && !_vm.isEM && _vm.myInfoModule.submodules.length ? _c("section", { staticClass: "mb-5" }, [_c("ItemSidebar", _vm._b({ attrs: { "is-open-menu": _vm.isOpenMenu, "bg-color": "light" } }, "ItemSidebar", _vm.myInfoModule, false))], 1) : _vm._e(), _c("hr", { staticClass: "bg-white opacity-30 mb-2" }), _c("section", { staticClass: "items-list flex-grow overflow-hidden hover:overflow-y-auto flex flex-col justify-between gap-10 pl-[4px] hover:pl-0 pb-4" }, [_c("div", { staticClass: "flex-grow" }, _vm._l(_vm.activeModules, function(module) {
    return _c("ItemSidebar", _vm._b({ key: module.title, staticClass: "overflow-hidden", attrs: { "is-open-menu": _vm.isOpenMenu, "submodules": module.submodules || [], "parent-paddings": {
      default: "pl-[8px] pr-3",
      submodules: "pl-[8px]"
    }, "has-rounded": "" } }, "ItemSidebar", module, false));
  }), 1), _c("div", { staticClass: "px-6 text-fs-12 text-white flex items-center gap-1 transition-all duration-500" }, [_vm.isOpenMenu ? _c("span", { staticClass: "opacity-70 whitespace-nowrap" }, [_vm._v("Creado con")]) : _vm._e(), _c("img", { staticClass: "opacity-50 flex-shrink-0", attrs: { "src": "/images/worky-logo-letters.svg", "alt": "Worky" } })])]), _c("hr", { staticClass: "bg-white opacity-30" }), _c("section", [_vm.configurationsModule.submodules.length ? _c("ItemSidebar", _vm._b({ attrs: { "is-open-menu": _vm.isOpenMenu, "submenu-y-position": "bottom", "bg-color": "dark" } }, "ItemSidebar", _vm.configurationsModule, false)) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
