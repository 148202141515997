var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { DateTime } from "luxon";
export default {
  formatedLegacyIncidencesSettings(state) {
    return Object.entries(state.legacyIncidencesSettings).reduce((setupList, entry) => {
      const isLegacy = state.legacyList.find((incLeg) => incLeg.id === entry[0]);
      return !isLegacy ? setupList : [...setupList, {
        id: isLegacy.id,
        value: entry[1],
        label: isLegacy.label,
        type: isLegacy.type
      }];
    }, []);
  },
  formatedLegacySettings(state, getters, rootState) {
    const completeList = __spreadProps(__spreadValues({}, state.legacyIncidencesSettings), {
      pto_compensation: true,
      vacation_compensation: true
    });
    return Object.entries(completeList).reduce((setupList, entry) => {
      const isLegacy = state.legacyList.find((incLeg) => incLeg.id === entry[0]);
      return !isLegacy ? setupList : [...setupList, {
        id: isLegacy.id,
        value: entry[1],
        label: isLegacy.label,
        type: isLegacy.type
      }];
    }, []).map((set) => {
      const dateRangeValid = [
        "VA",
        "HO",
        "IN",
        "EH",
        "DEH",
        "TEH",
        "TH",
        "HD",
        "PER",
        "PTO",
        "OPD",
        "WH"
      ];
      const amountValid = ["CO", "BO", "DP", "TH", "DI", "RE", "CE"];
      const quantityValid = [
        "EH",
        "DEH",
        "TEH",
        "PE",
        "VAPE",
        "VACOMP",
        "PTOPE",
        "PTOCOMP"
      ];
      const validPastHra = [
        "VA",
        "DE",
        "AB",
        "IN",
        "BO",
        "CO",
        "DD",
        "ED",
        "EH",
        "TEH",
        "DEH",
        "DP",
        "TH",
        "HO",
        "HD",
        "DI",
        "PER",
        "OPD",
        "PTO",
        "ADA",
        "RE",
        "WH",
        "VACOMP",
        "PTOCOMP",
        "CE"
      ];
      const validFutureHra = [
        "VA",
        "DE",
        "AB",
        "IN",
        "EH",
        "TEH",
        "DEH",
        "HO",
        "TH",
        "HD",
        "PER",
        "OPD",
        "PTO",
        "ADA",
        "RE",
        "WH",
        "VACOMP",
        "PTOCOMP",
        "CE",
        "DI",
        "BO",
        "CO"
      ];
      const validPast = [
        "VA",
        "DE",
        "AB",
        "IN",
        "BO",
        "CO",
        "DD",
        "ED",
        "EH",
        "TEH",
        "DEH",
        "DP",
        "TH",
        "HO",
        "HD",
        "DI",
        "PER",
        "OPD",
        "PTO",
        "ADA",
        "RE",
        "WH",
        "VACOMP",
        "PTOCOMP",
        "CE"
      ];
      const validFuture = [
        "VA",
        "DE",
        "AB",
        "HO",
        "PER",
        "OPD",
        "PTO",
        "WH"
      ];
      const setFormated = {
        date_range: dateRangeValid.includes(set.type) || set.type === "HO" || set.type === "PER",
        name: set.label,
        type: set.type,
        identifier: set.type === "WH",
        withSalary: set.type === "AB",
        duration: set.type === "DD" || set.type === "ED" || set.type === "DE" || set.type === "EH" || set.type === "DEH" || set.type === "TEH",
        amount: amountValid.includes(set.type),
        quantity: quantityValid.includes(set.type)
      };
      if (rootState.me.user.role === "HRA" || rootState.me.user.is_boss) {
        setFormated.temporality = {
          past: validPastHra.includes(set.type),
          future: validFutureHra.includes(set.type)
        };
      } else {
        setFormated.temporality = {
          past: validPast.includes(set.type),
          future: validFuture.includes(set.type)
        };
      }
      return setFormated;
    });
  },
  formatedActiveLegacyIncidencesSettings(state, getters, rootState) {
    const completeList = __spreadProps(__spreadValues({}, state.legacyIncidencesSettings), {
      pto_compensation: true,
      vacation_compensation: true
    });
    const [feature] = rootState.me.user.features.filter((feat) => feat.feature === "incidences.complete_view.new_version");
    if (feature == null ? void 0 : feature.active) {
      if (Array.isArray(state.legacyIncidencesSettings)) {
        const list = state.legacyIncidencesSettings.map((set) => {
          const setFormated = __spreadProps(__spreadValues({}, set), {
            type: set.legacy_incidence_type,
            withSalary: set.legacy_incidence_type === "AB"
          });
          return setFormated;
        });
        return list;
      }
      return [];
    }
    return Object.entries(completeList).reduce((setupList, entry) => {
      const isLegacy = state.legacyList.find((incLeg) => incLeg.id === entry[0]);
      return !isLegacy ? setupList : [...setupList, {
        id: isLegacy.id,
        value: entry[1],
        label: isLegacy.label,
        type: isLegacy.type,
        review_policy: isLegacy.review_policy
      }];
    }, []).filter((inc) => inc.value).map((set) => {
      const dateRangeValid = [
        "VA",
        "HO",
        "IN",
        "EH",
        "DEH",
        "TEH",
        "TH",
        "HD",
        "PER",
        "PTO",
        "OPD",
        "WH"
      ];
      const amountValid = ["CO", "BO", "DP", "TH", "DI", "RE", "CE"];
      const quantityValid = [
        "EH",
        "DEH",
        "TEH",
        "PE",
        "VAPE",
        "VACOMP",
        "PTOPE",
        "PTOCOMP"
      ];
      const validPastHra = [
        "VA",
        "DE",
        "AB",
        "IN",
        "BO",
        "CO",
        "DD",
        "ED",
        "EH",
        "TEH",
        "DEH",
        "DP",
        "TH",
        "HO",
        "HD",
        "DI",
        "PER",
        "OPD",
        "PTO",
        "ADA",
        "RE",
        "WH",
        "VACOMP",
        "PTOCOMP",
        "CE"
      ];
      const validFutureHra = [
        "VA",
        "DE",
        "AB",
        "IN",
        "EH",
        "TEH",
        "DEH",
        "HO",
        "TH",
        "HD",
        "PER",
        "OPD",
        "PTO",
        "ADA",
        "RE",
        "WH",
        "VACOMP",
        "PTOCOMP",
        "CE",
        "DI",
        "BO",
        "CO"
      ];
      const validPast = [
        "VA",
        "DE",
        "AB",
        "IN",
        "BO",
        "CO",
        "DD",
        "ED",
        "EH",
        "TEH",
        "DEH",
        "DP",
        "TH",
        "HO",
        "HD",
        "DI",
        "PER",
        "OPD",
        "PTO",
        "ADA",
        "RE",
        "WH",
        "VACOMP",
        "PTOCOMP",
        "CE"
      ];
      const validFuture = [
        "VA",
        "DE",
        "AB",
        "HO",
        "PER",
        "OPD",
        "PTO",
        "WH"
      ];
      const setFormated = {
        date_range: dateRangeValid.includes(set.type) || set.type === "HO" || set.type === "PER",
        name: set.label,
        type: set.type,
        identifier: set.type === "WH",
        withSalary: set.type === "AB",
        duration: set.type === "DD" || set.type === "ED" || set.type === "DE" || set.type === "EH" || set.type === "DEH" || set.type === "TEH",
        amount: amountValid.includes(set.type),
        quantity: quantityValid.includes(set.type)
      };
      if (rootState.me.user.role === "HRA" || rootState.me.user.is_boss) {
        setFormated.temporality = {
          past: validPastHra.includes(set.type),
          future: validFutureHra.includes(set.type)
        };
      } else {
        setFormated.temporality = {
          past: validPast.includes(set.type),
          future: validFuture.includes(set.type)
        };
      }
      return setFormated;
    });
  },
  formatedCustomSettings(state, getters, rootState) {
    const [feature] = rootState.me.user.features.filter((feat) => feat.feature === "incidences.complete_view.new_version");
    if (feature == null ? void 0 : feature.active) {
      return state.customIncidencesSettings;
    }
    return state.customIncidencesSettings.map((custInc) => {
      const formatCustomIncSettings = __spreadProps(__spreadValues({}, custInc), {
        type: "CUST"
      });
      return formatCustomIncSettings;
    });
  },
  customApprovalFlowsList: (state) => state.customApprovalFlows,
  formatedCustomIncidencesSettings(state, getters, rootState) {
    const [feature] = rootState.me.user.features.filter((feat) => feat.feature === "incidences.complete_view.new_version");
    if (feature == null ? void 0 : feature.active) {
      return state.customIncidencesSettings;
    }
    return state.customIncidencesSettings.map((custInc) => {
      const formatCustomIncSettings = __spreadProps(__spreadValues({}, custInc), {
        type: "CUST"
      });
      return formatCustomIncSettings;
    });
  },
  myRequestableRequestsList: (state) => {
    const amountValid = ["CO", "BO", "DP", "TH", "DI", "RE", "CE"];
    const quantity = ["EH", "DEH", "TEH", "PE", "VACOMP", "PTOCOMP"];
    const dateRangeValid = [
      "VA",
      "HO",
      "IN",
      "EH",
      "DEH",
      "TEH",
      "TH",
      "HD",
      "PER",
      "PTO",
      "OPD",
      "WH"
    ];
    const durationValid = ["DD", "ED", "DE", "EH", "DEH", "TEH"];
    const keysToDelete = ["CUST", "VAPE", "PTOPE", "PTOCOMP", "VACOMP"];
    const myRequestList = Object.entries(state.myRequestsRequestable.requestable).filter((request) => !keysToDelete.includes(request[0])).reduce((list, request) => {
      const requestType = request[0];
      const requestContent = request[1];
      return __spreadProps(__spreadValues({}, list), {
        [requestType]: __spreadProps(__spreadValues({}, requestContent), {
          duration: durationValid.includes(requestType),
          amount: amountValid.includes(requestType),
          quantity: quantity.includes(requestType),
          date_range: dateRangeValid.includes(requestType),
          identifier: requestType === "WH",
          withSalary: requestType === "AB"
        })
      });
    }, {});
    return __spreadProps(__spreadValues({}, state.myRequestsRequestable), {
      requestable: myRequestList
    });
  },
  myCustomRequestableRequests: (state) => {
    if (state.customListRequestable.length > 0) {
      return state.customListRequestable.map((req) => __spreadProps(__spreadValues({}, req), { type: "CUST" }));
    }
    return [];
  },
  myLegacyRequestableRequests: (state, getters, rootGetters) => {
    const legacyList = state.myRequestsRequestable.requestable;
    delete legacyList.CUST;
    if (legacyList.VAPE) {
      delete legacyList.VAPE;
    }
    if (legacyList.PTOPE) {
      delete legacyList.PTOPE;
    }
    if (legacyList.PTOCOMP) {
      delete legacyList.PTOCOMP;
    }
    if (legacyList.VACOMP) {
      delete legacyList.VACOMP;
    }
    return Object.entries(legacyList).reduce((list, entry) => {
      const requestType = entry[0];
      const requestVals = entry[1];
      return [...list, __spreadProps(__spreadValues({}, requestVals), { type: requestType })];
    }, []).map((set) => {
      const dateRangeValid = [
        "VA",
        "HO",
        "IN",
        "EH",
        "DEH",
        "TEH",
        "TH",
        "HD",
        "PER",
        "PTO",
        "OPD",
        "WH"
      ];
      const amountValid = ["CO", "BO", "DP", "TH", "DI", "RE", "CE"];
      const quantityValid = ["EH", "DEH", "TEH", "PE", "VACOMP", "PTOCOMP"];
      const validPast = [
        "VA",
        "DE",
        "AB",
        "IN",
        "BO",
        "CO",
        "DD",
        "ED",
        "EH",
        "TEH",
        "DEH",
        "DP",
        "TH",
        "HO",
        "HD",
        "DI",
        "PER",
        "OPD",
        "PTO",
        "ADA",
        "RE",
        "WH",
        "VACOMP",
        "PTOCOMP",
        "CE"
      ];
      const validFuture = [
        "VA",
        "DE",
        "AB",
        "HO",
        "PER",
        "OPD",
        "PTO",
        "WH",
        "VACOMP",
        "PTOCOMP"
      ];
      const setFormated = {
        date_range: dateRangeValid.includes(set.type) || set.type === "HO" || set.type === "PER",
        name: set.name,
        type: set.type,
        identifier: set.type === "WH",
        withSalary: set.type === "AB",
        duration: set.type === "DD" || set.type === "ED" || set.type === "DE" || set.type === "EH" || set.type === "DEH" || set.type === "TEH",
        amount: amountValid.includes(set.type),
        quantity: quantityValid.includes(set.type),
        incidence_type: set.type,
        date: set.date,
        total_taken: set.total_taken,
        total_remaining: set.total_remaining
      };
      if (set.type === "VA" || set.type === "PTO") {
        setFormated.total = set.total;
      }
      setFormated.temporality = {
        past: validPast.includes(set.type),
        future: validFuture.includes(set.type)
      };
      return setFormated;
    });
  },
  pendingRequestsCount: (state) => state.incidencesCount,
  incidencesCalendarList: (state) => state.incidencesList.map((inc) => __spreadProps(__spreadValues({}, inc), {
    employee: inc.owner.id,
    type: inc.incidence_type,
    comments: inc.comment,
    quantity: inc.detail.quantity,
    with_salary: inc.incidence_type === "AB" ? inc.detail.withSalary : false
  })),
  byEmployeeAbsencesReport: (state, rootGetters) => state.incidencesByEmployee.reduce((list, emp) => {
    const employees = rootGetters["company/employees/currentEmployees"];
    const dateLast = DateTime.local().plus({ days: -30 }).toISODate();
    return emp.AB && emp.AB.total > 0 ? [...list, emp.AB.items.filter((inc) => new Date(inc.start_date) >= dateLast && new Date(inc.start_date) <= DateTime.local().toISODate()).map((employeeItem) => {
      const findOwner = employees.find((e) => e.id === employeeItem.owner);
      return findOwner && findOwner.area ? findOwner.areaName : "Sin asignar";
    })] : list;
  }, []),
  incidencesByEmployeeList: (state) => state.incidencesByEmployee.map((employee) => {
    const { owner } = employee;
    const custTypes = employee.CUST.items.reduce((types, li) => types.includes(li.detail.template.id) ? types : [...types, li.detail.template.id], []).map((templateId) => {
      const templateList = employee.CUST.items.filter((it) => it.detail.template.id === templateId);
      return {
        id: templateId,
        items: templateList,
        total: templateList.length
      };
    });
    const extraTypes = employee.CUST.extra.reduce((types, entry) => {
      const include = custTypes.map((e) => e.id === entry.template_id).some((i) => i);
      return include ? types : [...types, {
        id: entry.template_id,
        items: [],
        total: entry.total
      }];
    }, []).concat(custTypes);
    const nocustEmp = __spreadValues({}, employee);
    delete nocustEmp.CUST;
    delete nocustEmp.owner;
    const legacyList = Object.entries(nocustEmp).reduce((legacyItems, inc) => [...legacyItems, {
      id: inc[0],
      total: inc[1].total,
      name: inc[1].name,
      items: inc[1].items
    }], []);
    return {
      owner,
      list: extraTypes.concat(legacyList)
    };
  }),
  typeDetailOptions: (state, getters, rootState) => {
    const completeList = __spreadProps(__spreadValues({}, state.legacyIncidencesSettings), {
      pto_compensation: true,
      vacation_compensation: true
    });
    const [feature] = rootState.me.user.features.filter((feat) => feat.feature === "incidences.complete_view.new_version");
    if (feature == null ? void 0 : feature.active) {
      const hasCustRequests2 = state.customIncidencesSettings.map((custInc) => ({
        id: custInc.id || custInc.legacy_incidence_type,
        template: custInc.id || custInc.legacy_incidence_type,
        name: custInc.name,
        legacy_type: custInc.legacy_incidence_type
      })).map((custInc) => ({
        name: custInc.name,
        value: custInc.id || custInc.active,
        type: custInc.template,
        legacy_type: custInc.legacy_type
      }));
      return [
        {
          groupLabel: "Todas",
          list: []
        },
        {
          groupLabel: "Incidencias Worky",
          list: hasCustRequests2
        }
      ];
    }
    const legacyRequests = Object.entries(completeList).reduce((setupList, entry) => {
      const isLegacy = state.legacyList.find((incLeg) => incLeg.id === entry[0]);
      return !isLegacy ? setupList : [...setupList, {
        id: isLegacy.id,
        value: entry[1],
        name: isLegacy.label,
        type: isLegacy.type
      }];
    }, []).filter((inc) => inc.value);
    const hasCustRequests = state.customIncidencesSettings.map((custInc) => ({
      id: custInc.id,
      // change of new_version
      template: custInc.id,
      name: custInc.name
    })).map((custInc) => ({
      name: custInc.name,
      value: custInc.id,
      type: custInc.template
    }));
    return [
      {
        groupLabel: "Todas",
        list: []
      },
      {
        groupLabel: "Incidencias Worky",
        list: legacyRequests
      },
      {
        groupLabel: "Incidencias Personalizadas",
        list: hasCustRequests
      }
    ];
  },
  statusOptions: () => {
    const statusList = [
      { value: "APR", name: "Aprobada" },
      { value: "REJ", name: "Rechazada" }
    ];
    return [
      {
        groupLabel: "Todas",
        list: statusList
      }
    ];
  },
  myIncidences(state, getters, rootState, rootGetters) {
    return rootGetters["company/calendar/myIncidencesList"].filter((inc) => {
      const thisYear = DateTime.local().year;
      const thisMonth = DateTime.local().month;
      const startYear = DateTime.fromISO(inc.start_date).year;
      const startMonth = DateTime.fromISO(inc.start_date).month;
      return thisYear === startYear && thisMonth === startMonth;
    }).sort((a, b) => {
      const startA = new Date(DateTime.fromISO(a.start_date));
      const startB = new Date(DateTime.fromISO(b.start_date));
      return startA > startB ? 1 : -1;
    }).slice(0.4).map((inc) => {
      let incName = inc.name;
      if (inc.incidence_type === "CUST") {
        const findName = getters.formatedCustomSettings.find((incident) => incident.template === inc.detail.template.id);
        if (findName) {
          incName = findName.name;
        }
      }
      const incWithName = __spreadValues({}, inc);
      delete incWithName.name;
      incWithName.name = incName;
      return __spreadProps(__spreadValues({}, incWithName), { value: incWithName.incidence_type });
    });
  },
  incidencesCategory(state) {
    const { data } = state.customIncidencesCategory;
    return data.results;
  },
  incidencesTypes(state) {
    const { data } = state.customIncidencesTypes;
    return data;
  },
  getGettingStats: (state) => state.gettingStats
};
