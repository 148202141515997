import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;
export const internalProcess = {
  getInternalProcessConfig({ page, filters, searchT }) {
    const pg = page ? `page=${page}&` : "";
    const status = filters ? `status=${filters}&` : "";
    const search = searchT ? `name=${searchT}&` : "";
    return axios.get(`api/v1/internal-process-configuration/?${search}${status}${pg}`);
  },
  getRequestableTemplates({ page, searchT, paginate = true }) {
    const pg = page ? `page=${page}&` : "";
    const search = searchT ? `title=${searchT}&` : "";
    const paginated = !paginate ? `paginate=${paginate}` : "";
    return axios.get(`/api/v1/requestable-templates/?${search}${pg}${paginated}`);
  },
  getFlowTemplates({ page, filters }) {
    const pg = page ? `page=${page}&` : "";
    return axios.get(`/api/v1/flow-templates/?${pg}`);
  },
  getInternalProcess({ page }) {
    const pg = page ? `page=${page}&` : "";
    return axios.get(`/api/v1/internal-process/?${pg}`);
  },
  getRequestInternalProcess({ page, filters, searchT }) {
    const pg = page ? `page=${page}&` : "";
    const resolution = filters ? `resolution=${filters}&` : "";
    const search = searchT ? `name=${searchT}&` : "";
    return axios.get(`/api/v1/request-internal-process/?${resolution}${search}${pg}`);
  },
  getPendingInternalProcess({ page, searchT }) {
    const pg = page ? `page=${page}&` : "";
    const search = searchT ? `name=${searchT}&` : "";
    return axios.get(`/api/v1/pending-approve-internal-process/?${search}${pg}`);
  },
  getPendingInternalProcessDetail({ id }) {
    return axios.get(`/api/v1/pending-approve-internal-process/${id}/`);
  },
  getInternalProcessDetail({ id }) {
    return axios.get(`/api/v1/request-internal-process/${id}/`);
  },
  getVoucher({ id }) {
    return axios.get(`/api/v1/request-internal-process/${id}/download_voucher/`, { responseType: "arraybuffer" });
  },
  cancelInternalProcessDetail({ id }) {
    return axios.post(`/api/v1/request-internal-process/${id}/cancel/`);
  },
  remindInternalProcessDetail({ id }) {
    return axios.post(`/api/v1/request-internal-process/${id}/remind/`);
  },
  getIdReviewer({ id }) {
    return axios.post(`/api/v1/pending-approve-internal-process/${id}/get_my_reviewer_id/`);
  },
  commentProcess({ id, payload }) {
    return axios.post(`/api/v1/resolve-internal-process/${id}/comment/`, payload);
  },
  resolveProcess({ id, status }) {
    return axios.post(`/api/v1/resolve-internal-process/${id}/${status}/`);
  },
  createInternalProcess(payload) {
    return axios.post("api/v1/internal-process-configuration/", payload);
  },
  createInternalProcessWhitFlow(payload) {
    return axios.post("api/v1/internal-process-configuration/create_with_flow_template/", payload);
  },
  createRequestableTemplate(payload) {
    return axios.post("/api/v1/requestable-templates/", payload);
  },
  requestInternalProcess(payload) {
    return axios.post("/api/v1/request-internal-process/", payload);
  },
  switchInternalProcess({ id, status }) {
    return axios.post(`/api/v1/internal-process-configuration/${id}/${status}/`);
  },
  isApproverProcess(payload) {
    return axios.post(`/api/v1/internal-process/check_employee_is_reviewer/`, payload);
  },
  updateApproverProcess(payload) {
    return axios.post(`/api/v1/internal-process/update_reviewer/`, payload);
  },
  updateRequestableTemplate(id, payload) {
    return axios.patch(`/api/v1/requestable-templates/${id}/`, payload);
  },
  updateInternalProcess(id, payload) {
    return axios.patch(`/api/v1/internal-process-configuration/${id}/`, payload);
  },
  updateInternalProcessWhitFlow(id, payload) {
    return axios.patch(`/api/v1/internal-process-configuration/${id}/update_with_flow_template/`, payload);
  },
  deleteRequestableTemplate(id) {
    return axios.delete(`/api/v1/requestable-templates/${id}/`);
  },
  deleteFlowTemplate(id) {
    return axios.delete(`/api/v1/flow-templates/${id}/`);
  },
  deleteInternalProcess(id) {
    return axios.delete(`/api/v1/internal-process-configuration/${id}/`);
  }
};
export default internalProcess;
