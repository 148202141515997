var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Company from "@/models/company";
import Employee from "@/models/employee";
import { DataError } from "@/exceptions";
import { api } from "@/api";
export default {
  getSectionCustomDocuments(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const company = new Company(rootState.me.user.company);
      const customDocument = yield company.customDocumentsSection().get();
      return customDocument;
    });
  },
  createSectionCustomDocuments(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, { titleSection }) {
      const company = new Company(rootState.me.user.company);
      const customDocument = yield company.customDocumentsSection().create({ title: titleSection });
      return customDocument;
    });
  },
  deleteSectionCustomDocuments(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, { idSection }) {
      const company = new Company(rootState.me.user.company);
      const data = { company_custom_document_section: idSection };
      const customDocument = yield company.customDocumentsSection().remove({ data });
      return customDocument;
    });
  },
  createCustomDocument(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, { data }) {
      const company = new Company(rootState.me.user.company);
      const customDocument = yield company.customDocuments().create(data);
      return customDocument;
    });
  },
  updateCustomDocument(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, { data }) {
      const company = new Company(rootState.me.user.company);
      const customDocument = yield company.customDocuments().update(data);
      return customDocument;
    });
  },
  deleteCustomDocument(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, { data }) {
      const company = new Company(rootState.me.user.company);
      const customDocument = yield company.customDocuments().remove({ data });
      return customDocument;
    });
  },
  getEmployeeCustomDocument(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, { id }) {
      if (!id) {
        throw new DataError("The employee is not defined");
      }
      const { data } = yield api.directory.getProfile(id);
      const employee = new Employee(data);
      const customDocument = yield employee.customDocuments().get();
      return customDocument;
    });
  },
  uploadArchive(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, { data }) {
      if (!data.idEmployee) {
        throw new DataError("The employee is not defined");
      }
      const employeeData = yield api.directory.getProfile(data.idEmployee);
      const employee = new Employee(employeeData.data);
      const customDocument = yield employee.customDocuments().upload(
        data.company_custom_document,
        data.fileArchive
      );
      return customDocument;
    });
  },
  deleteArchive(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, { data }) {
      if (!data.idEmployee) {
        throw new DataError("The employee is not defined");
      }
      const employeeData = yield api.directory.getProfile(data.idEmployee);
      const employee = new Employee(employeeData.data);
      const customDocument = yield employee.customDocuments().removeArchive({ data });
      return customDocument;
    });
  }
};
