import actions from "./actions";
import mutations from "./mutations";
const state = {
  timeOffList: []
};
export default {
  state,
  mutations,
  actions,
  namespaced: true
};
