var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "bg-white rounded-t-md fixed bottom-0 px-4 py-3 w-104 notify-download" }, [_c("div", { staticClass: "flex items-center", class: {
    "mb-2": !_vm.collapse
  } }, [_c("div", { staticClass: "flex items-center w-full cursor-pointer", on: { "click": function($event) {
    _vm.collapse = !_vm.collapse;
  } } }, [_c("p", { staticClass: "font-medium text-l" }, [_vm._v(" " + _vm._s(_vm.titleStatus) + " ")]), _c("Icon", { staticClass: "ml-auto mr-2 cursor-pointer", attrs: { "icon": _vm.collapse ? "chevron-up" : "chevron-down" } })], 1), _c("Icon", { staticClass: "cursor-pointer ml-auto", attrs: { "icon": "close" }, on: { "click": () => _vm.$emit("close") } })], 1), _c("div", { directives: [{ name: "show", rawName: "v-show", value: !_vm.collapse, expression: "!collapse" }], staticClass: "flex items-center" }, [_vm.loading ? _c("CircularLoader") : _c("Icon", { attrs: { "icon": "circle-check text-fs-24 text-wgreen-600" } }), _c("p", { staticClass: "leading-[18px] ml-2 w-9/10" }, [_vm._v(" " + _vm._s(_vm.descriptionStatus) + " ")])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
