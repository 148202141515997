import { DateTime } from "luxon";
export default {
  name: "DateParser",
  functional: true,
  props: {
    date: {
      type: [String, Date],
      default: ""
    },
    format: {
      type: String,
      default: "yyyy-MM-dd"
    },
    toFormat: {
      type: String,
      default: "dd MMM yyyy"
    },
    setZone: {
      type: Boolean,
      default: false
    }
  },
  render(createElement, { props }) {
    const date = props.format === "ISO" ? DateTime.fromISO(props.date, { setZone: props.setZone }) : DateTime.fromFormat(props.date, props.format);
    return createElement("span", {}, date.toFormat(props.toFormat, { locale: "es" }));
  }
};
