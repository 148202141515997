var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "pagination" }, [_c("div", { directives: [{ name: "tooltip", rawName: "v-tooltip", value: _vm.value !== 1 ? "Ir al inicio" : null, expression: "value !== 1 ? 'Ir al inicio' : null" }], staticClass: "pagination__item-icon", class: { "pagination__item-icon--disabled": _vm.value === 1 }, on: { "click": () => _vm.onSetPage(1) } }, [_c("Icon", { attrs: { "icon": "double-chevron-left" } })], 1), _c("div", { staticClass: "pagination__item pagination__item--prev", class: { "pagination__item--disabled": _vm.value === 1 }, on: { "click": _vm.onSetPrevPage } }, [_vm._v(" Anterior ")]), _vm._l(_vm.pagesNumber > 10 ? 4 : _vm.pagesNumber, function(page) {
    return _c("div", { key: page, staticClass: "pagination__number", class: {
      "pagination__number--active": page === _vm.value,
      "pagination__number--last": page === _vm.pagesNumber && _vm.pagesNumber < 10
    }, on: { "click": () => _vm.onSetPage(page) } }, [_vm._v(" " + _vm._s(page) + " ")]);
  }), _vm.pagesNumber > 10 ? _c("div", { staticClass: "pagination__number" }, [_vm._v(" ... ")]) : _vm._e(), _vm._l(_vm.pagesNumber > 10 ? 4 : 0, function(page) {
    return _c("div", { key: _vm.pagesNumber - 4 + page, staticClass: "pagination__number", class: {
      "pagination__number--active": _vm.pagesNumber - 4 + page === _vm.value,
      "pagination__number--last": page === 4
    }, on: { "click": () => _vm.onSetPage(_vm.pagesNumber - 4 + page) } }, [_vm._v(" " + _vm._s(_vm.pagesNumber - 4 + page) + " ")]);
  }), _c("div", { staticClass: "pagination__item pagination__item--next", class: { "pagination__item--disabled": _vm.value === _vm.pagesNumber }, on: { "click": _vm.onSetNextPage } }, [_vm._v(" Siguiente ")]), _c("div", { directives: [{ name: "tooltip", rawName: "v-tooltip", value: _vm.value !== _vm.pagesNumber ? "Ir al final" : null, expression: "value !== pagesNumber ? 'Ir al final' : null" }], staticClass: "pagination__item-icon", class: { "pagination__item-icon--disabled": _vm.value === _vm.pagesNumber }, on: { "click": () => _vm.onSetPage(_vm.pagesNumber) } }, [_c("Icon", { attrs: { "icon": "double-chevron-right" } })], 1)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };
