export default {
  setLevels(state, { data }) {
    data.sort((a, b) => a.name.localeCompare(b.name));
    state.list = data;
  },
  setLevel(state, { level }) {
    if (state.list.find((lev) => lev.id === level.id)) {
      state.list = state.list.filter((lev) => lev.id !== level.id);
    } else {
      state.list.push(level);
    }
  }
};
