import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = false;
export default {
  getEmployeesConfiguration() {
    return axios.get("/api/v2/employee_days_off").then(({ data }) => data);
  },
  setEmployeesConfiguration(body = { employees_ids: [], days_off: { sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false } }) {
    return axios.put(`api/v2/employee_days_off/bulk_update_days/`, body);
  },
  setSingleEmployeeConfiguration(employees = []) {
    return axios.put("api/v2/employee_days_off/bulk_update/", employees);
  }
};
