var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { api } from "@/api";
import Level from "@/models/level";
import { DataError } from "@/exceptions";
export default {
  getLevels(_0) {
    return __async(this, arguments, function* ({ commit }) {
      const { data } = yield api.levels.getLevels();
      commit("setLevels", { data });
      return data;
    });
  },
  createLevel(_0, _1) {
    return __async(this, arguments, function* ({ commit }, payload) {
      if (!payload) {
        throw new DataError("The level data is not defined");
      }
      const newLevelResponse = yield api.levels.createNewLevel(payload);
      const newLevel = newLevelResponse.data;
      commit("setLevel", { level: newLevel });
      return new Promise((resolve) => resolve(newLevel));
    });
  },
  updateLevel(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, { level }) {
      const oldLevel = state.list.find((_level) => _level.id === level.id);
      const levelObject = new Level(level);
      const updatedLevel = yield api.levels.editLevel(oldLevel.id, levelObject);
      commit("setLevel", { level: updatedLevel.data });
      return updatedLevel;
    });
  },
  deleteLevel(_0, _1) {
    return __async(this, arguments, function* ({ commit, dispatch, state }, { id }) {
      yield api.levels.deleteLevel(id);
      commit("setLevel", { level: { id } });
    });
  }
};
