export default {
  setAvailablePermissions(state, payload) {
    state.availablePermissions = payload;
  },
  setPermissions(state, payload) {
    state.myPermissions = payload;
  },
  setCalendarRange(state, { startDate, endDate }) {
    state.startRange = startDate;
    state.endRange = endDate;
  }
};
