import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
const state = {
  loading: 0,
  // hra
  trainings: [],
  selectedTraining: {
    guestsInProcess: {
      results: []
    },
    guestsFinished: {
      results: []
    }
  },
  // boss
  teamTrainings: {
    results: []
  },
  // em
  myTrainings: {},
  myCompletedTrainings: {}
};
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
};
