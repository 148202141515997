export default {
  setCompanies(state, { companies }) {
    state.companies = companies;
  },
  setUsers(state, users) {
    state.users = users;
  },
  setEmployees(state, { company, employees }) {
    const index = state.companies.findIndex((_company) => _company.id === company.id);
    const newCompanies = [...state.companies];
    newCompanies[index].employees = employees || [];
    state.companies = newCompanies;
    state.employees = employees;
  }
};
