import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
const state = {
  data: null,
  employee: {},
  path: void 0,
  /* New implementation. Dashboard. */
  user: {}
};
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
};
