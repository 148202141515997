export default {
  setBenefits(state, benefits) {
    state.list = benefits.sort((a, b) => a.name.localeCompare(b.name));
  },
  unsetBenefit(state, benefit) {
    const newList = [...state.list];
    const deletedItem = newList.filter((item) => item.id !== benefit.id);
    state.list = deletedItem;
  },
  setBenefit(state, { benefit }) {
    const { id } = benefit;
    const newList = [...state.list];
    const index = newList.findIndex((_benefit) => _benefit.id === id);
    if (index >= 0) {
      newList[index] = benefit;
    } else {
      newList.push(benefit);
    }
    state.list = newList;
  }
};
