var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Model from "@/models/model";
import TimeOffPeriod from "@/models/timeOffPeriod";
export default class TimeOffPolicy extends Model {
  static get modelName() {
    return "TimeOffPolicy";
  }
  static apiNamespace() {
    return "api/v2";
  }
  static resourceNamespace() {
    return "time-off-policies";
  }
  static fields() {
    return [
      "id",
      "name",
      "policy_kind",
      "period_type",
      "negative_balance",
      "cumulative",
      "max_cumulative",
      "max_periods",
      "company"
    ];
  }
  getPeriods() {
    return __async(this, null, function* () {
      const periods = yield this.constructor.get(`${this.resourcePath()}/time_off_periods/`);
      this.periods = periods.sort((a, b) => a.start_year > b.start_year).map((period) => new TimeOffPeriod(period));
      return this.periods;
    });
  }
}
