const accentsMap = {
  "\xE1\xE0\xE4\xE2\xE3a": "a",
  "\xE9\xE8\xEB\xEAe": "e",
  "\xED\xEC\xEF\xEEi": "i",
  "\xF3\xF2\xF6\xF4\xF5o": "o",
  "\xFA\xF9\xFC\xFBu": "u",
  "\xFFy": "y"
};
const cleanAccents = (s) => {
  let term = s;
  Object.entries(accentsMap).forEach(([modes, letter]) => {
    const re = new RegExp(`[${modes.split("").join("|")}]`, "gi");
    term = term.replace(re, letter);
  });
  return term;
};
export default {
  cleanAccents
};
export {
  cleanAccents
};
