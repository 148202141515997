var render = function render2() {
  var _a;
  var _vm = this, _c = _vm._self._c;
  return _c("div", _vm._g({ staticClass: "avatar" }, _vm.$listeners), [(_vm.$is("hra") || _vm.$is("osr")) && !_vm.isActive ? _c("span", { directives: [{ name: "tooltip", rawName: "v-tooltip", value: "Colaborador inactivo", expression: "'Colaborador inactivo'" }], staticClass: "avatar__state" }) : _vm._e(), _c("div", { staticClass: "avatar__picture", class: _vm.styles, on: { "mouseover": function($event) {
    _vm.hover = true;
  }, "mouseleave": function($event) {
    _vm.hover = false;
  } } }, [_c("div", { staticClass: "avatar__image" }, [_vm.allowDelete ? _c("div", { staticClass: "trashCan absolute bg-red w-full cursor-pointer content-center rounded-full h-full bg-opacity-80", on: { "click": function($event) {
    return _vm.deleteThis(_vm.employee.id);
  } } }, [_c("TrashCan", { staticClass: "object-center mx-auto my-2" })], 1) : _vm._e(), ((_a = _vm.employee) == null ? void 0 : _a.avatar) ? [_c("img", { attrs: { "src": _vm.employee.avatar, "alt": _vm.employee.full_name } })] : [_c("div", [_vm._v(" " + _vm._s(_vm.abbreviationName || "NA") + " ")])]], 2)]), !!_vm.$slots.status ? _c("div", { staticClass: "avatar__status" }, [_vm._t("status")], 2) : _vm._e(), _vm.tooltip ? _c("AppAvatarTooltip", { staticClass: "avatar__tooltip", attrs: { "employee": _vm.employee }, scopedSlots: _vm._u([{ key: "image", fn: function({ employee }) {
    return [_c("div", { staticClass: "avatar__picture", class: _vm.$mq === "md" && !_vm.smallv2 ? "avatar--medium" : "avatar--small" }, [_c("div", { staticClass: "avatar__image" }, [(employee == null ? void 0 : employee.avatar) ? [_c("img", { attrs: { "src": employee.avatar, "alt": employee.full_name } })] : [_c("div", { staticClass: "bg-[var(--brand-primary)] rounded-full h-10 w-10 flex items-center justify-center" }, [_vm._v(" " + _vm._s(_vm.abbreviationName || "NA") + " ")])]], 2)])];
  } }, { key: "tooltip", fn: function({ employee }) {
    return [_vm._t("tooltip", function() {
      return [_c("span", [_vm._v(_vm._s(employee.full_name))])];
    }, { "employee": employee })];
  } }], null, true) }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
