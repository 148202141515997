import Model from "@/models/model";
export default class CustomIncidenceNameTemplate extends Model {
  static get modelName() {
    return "ustomIncidenceNameTemplate";
  }
  static apiNamespace() {
    return "api/v2";
  }
  static resourceNamespace() {
    return "custom_incidences_template";
  }
  static fields() {
    return [
      "id",
      "name"
    ];
  }
  static default() {
    return "";
  }
}
