import axios from "axios";
const options = {
  withCredentials: false
};
export const plans = {
  getPlans() {
    return axios.get(`/api/v1/payments/company_plan/`, options);
  },
  registerCompany(data) {
    return axios.post("/api/v1/payments/company_plan/create_company/", data, options);
  },
  getPaymentResume(data) {
    return axios.post("/api/v1/payments/company_plan/choose_company_plan/", data, options);
  },
  sendCheckout(data) {
    return axios.post("/api/v1/payments/send_quotation/", data, options);
  },
  getTokens() {
    return axios.get("/api/v1/payments/generate_token/", options);
  },
  getSpeiInfo(planid) {
    return axios.get(`/api/v1/payments/company_plan/${planid}/create_company_to_conekta/`, options);
  },
  createPaymentOrder(data) {
    return axios.post(`/api/v1/payments/company_plan/create_payment_order/`, data, options);
  },
  // Por si se refresca la página en el checkout
  getCompanyPlanInfo(planid) {
    return axios.get(`/api/v1/payments/company_plan/${planid}/company_plan_info/`, options);
  },
  getSubscriptionInfo() {
    return axios.get(`/api/v1/payments/subscription_info/`);
  },
  getBillingInfo() {
    return axios.get(`/api/v1/payments/company_billing/`);
  },
  updateBillingInfo(data) {
    return axios.post(`/api/v1/payments/company_billing/`, data);
  },
  getSubscBills(start, end, pag) {
    return axios.get(`/api/v1/payments/company_payments/?page=${pag}&created__gte=${start}&created__lte=${end}`);
  },
  renewSubscription(data) {
    return axios.post(`/api/v1/payments/renew_subscription/`, data);
  },
  downloadBills() {
    return axios.get(`/api/v1/payments/company_payments/download_zip/`, { responseType: "arraybuffer" });
  },
  getCheckoutInfo(id) {
    return axios.get(`/api/v1/payments/company_plan/${id}/company_plan_info`);
  },
  printInfo(id) {
    return axios.get(`/api/v1/payments/company_plan/${id}/print_company_plan/`, { responseType: "arraybuffer" });
  },
  sendInfo(id) {
    return axios.get(`/api/v1/payments/company_plan/${id}/send_mail_company_plan/`);
  },
  getBillZip(paymentid) {
    return axios.get(`/api/v1/payments/company_payments/${paymentid}/download_invoice/`, { responseType: "arraybuffer" });
  }
};
export default plans;
