export default {
  setCalendarIncidences(state, payload) {
    state.calendarIncidences = payload;
  },
  setMyIncidences(state, payload) {
    state.myIncidences = payload;
  },
  setCalendarRange(state, { startDate, endDate }) {
    state.startRange = startDate;
    state.endRange = endDate;
  }
};
