var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export default {
  namespaced: true,
  state: {
    loading: false,
    preonboardingsList: [],
    documents: [],
    documentsInvited: [],
    candidateTemplate: [],
    candidateInfo: []
  },
  mutations: {
    SET_LOADING_STATUS(state, payload) {
      state.loading = payload;
    },
    SET_PREONBOARDINGS(state, payload) {
      state.preonboardingsList = payload;
    },
    ADD_PREONBOARDING(state, preonboarding) {
      state.preonboardingsList.push(preonboarding);
    },
    SET_DOCUMENTS(state, docs) {
      state.documents = docs;
    },
    REMOVE_PREONBOARDING(state, preonboardingId) {
      state.preonboardingsList = state.preonboardingsList.filter((item) => item.id !== preonboardingId);
    },
    UPDATE_PREONBOARDING_LIST(state, data) {
      state.preonboardingsList = state.preonboardingsList.map((preonb) => {
        if (preonb.id === data.id) {
          preonb = data;
        }
        return preonb;
      });
    },
    ADD_INVITE(state, preonboardingId) {
      state.preonboardingsList = state.preonboardingsList.map((preonb) => {
        if (preonb.id === preonboardingId) {
          preonb.candidates_in_process += 1;
        }
        return preonb;
      });
    },
    SET_DOCUMENTS_CANDIDATE(state, data) {
      state.documentsInvited = data;
    },
    SET_CANDIDATE_TEMPLATE(state, data) {
      state.candidateTemplate = data.data;
    },
    SET_CANDIDATE_INFO(state, data) {
      state.candidateInfo = data.data;
    }
  },
  actions: {
    // View: 'SetupPreonboarding'
    getDocuments(_0) {
      return __async(this, arguments, function* ({ commit }) {
        commit("SET_LOADING_STATUS", true);
        const { data } = yield axios.get("/api/v1/preonboardings/documents/by_section/");
        commit("SET_DOCUMENTS", data);
        commit("SET_LOADING_STATUS", false);
      });
    },
    createPreonboarding(_0, _1) {
      return __async(this, arguments, function* ({ commit }, preonboarding) {
        commit("SET_LOADING_STATUS", true);
        const { data } = yield axios.post("/api/v1/preonboardings/", preonboarding);
        commit("ADD_PREONBOARDING", data);
        commit("SET_LOADING_STATUS", false);
      });
    },
    // View: 'PreonboardingWelcome'
    getPreonboardingsList(_0) {
      return __async(this, arguments, function* ({ commit }) {
        commit("SET_LOADING_STATUS", true);
        const { data } = yield axios.get("/api/v1/preonboardings/");
        commit("SET_PREONBOARDINGS", data);
        commit("SET_LOADING_STATUS", false);
      });
    },
    deletePreonboarding(_0, _1) {
      return __async(this, arguments, function* ({ commit }, preonboardingId) {
        commit("SET_LOADING_STATUS", true);
        yield axios.delete(`/api/v1/preonboardings/${preonboardingId}/`);
        commit("REMOVE_PREONBOARDING", preonboardingId);
        commit("SET_LOADING_STATUS", false);
      });
    },
    duplicatePreonboarding(_0, _1) {
      return __async(this, arguments, function* ({ commit }, preonboardingId) {
        commit("SET_LOADING_STATUS", true);
        const { data } = yield axios.post(`/api/v1/preonboardings/${preonboardingId}/duplicate/`);
        commit("ADD_PREONBOARDING", data);
        commit("SET_LOADING_STATUS", false);
      });
    },
    getCandidatesById(_0, _1) {
      return __async(this, arguments, function* ({ commit }, id) {
        commit("SET_LOADING_STATUS", false);
        return axios.get(`/api/v1/preonboardings/${id}/candidates/`);
      });
    },
    inviteCandidate(_0, _1) {
      return __async(this, arguments, function* ({ commit }, { id, candidateInvitation }) {
        commit("SET_LOADING_STATUS", true);
        yield axios.post(`/api/v1/preonboardings/${id}/invite/`, candidateInvitation);
        commit("ADD_INVITE", id);
        commit("SET_LOADING_STATUS", false);
      });
    },
    reinviteCandidate(_0, _1) {
      return __async(this, arguments, function* ({ commit }, { id, candidateInvitation }) {
        commit("SET_LOADING_STATUS", true);
        yield axios.post(`/api/v1/preonboardings/${id}/invite/`, candidateInvitation);
        commit("SET_LOADING_STATUS", false);
      });
    },
    // View: 'EditPreonboarding'
    getPreonboardingById(_0, _1) {
      return __async(this, arguments, function* ({ commit }, preonboardingId) {
        commit("SET_LOADING_STATUS", true);
        return axios.get(`/api/v1/preonboardings/${preonboardingId}/`);
      });
    },
    updatePreonboarding(_0, _1) {
      return __async(this, arguments, function* ({ commit }, { id, template }) {
        commit("SET_LOADING_STATUS", true);
        const { data } = yield axios.put(`/api/v1/preonboardings/${id}/`, template);
        commit("UPDATE_PREONBOARDING_LIST", data);
        commit("SET_LOADING_STATUS", false);
      });
    },
    // View: 'DocumentsEmployee'
    getDocsCandidate(_0, _1) {
      return __async(this, arguments, function* ({ commit }, candidateId) {
        const { data } = yield axios.get(`/api/v1/preonboardings/candidate/${candidateId}/document/`);
        commit("SET_DOCUMENTS_CANDIDATE", data.data);
      });
    },
    getCandidateDetail(_0, _1) {
      return __async(this, arguments, function* ({ commit }, candidateId) {
        commit("SET_LOADING_STATUS", true);
        const { data } = yield axios.get(`/api/v1/preonboardings/candidate/${candidateId}/`);
        commit("SET_CANDIDATE_INFO", data);
        commit("SET_LOADING_STATUS", false);
      });
    },
    createCollaborator(_0, _1) {
      return __async(this, arguments, function* ({ commit, dispatch }, { candidateId, employee, activate }) {
        const newEmployee = yield axios.post(`/api/v1/preonboardings/candidate/${candidateId}/create_employee/`, employee);
        commit("employees/setEmployee", { data: newEmployee }, { root: true });
        if (activate) {
          dispatch("employees/inviteEmployee", { id: newEmployee.id });
        }
        return newEmployee;
      });
    },
    // View: 'PreonboardingInvited'
    getCandidateTemplate(_0, _1) {
      return __async(this, arguments, function* ({ commit }, candidateId) {
        commit("SET_LOADING_STATUS", true);
        const { data } = yield axios.get(`/api/v1/preonboardings/candidate/${candidateId}/document/`);
        commit("SET_CANDIDATE_TEMPLATE", data);
        commit("SET_LOADING_STATUS", false);
      });
    },
    uploadAttachement({ commit }, { file, candidateId }) {
      commit("SET_LOADING_STATUS", true);
      const formData = new FormData();
      formData.append("document", file.document);
      formData.append("preonboarding_document", file.preonboarding_document);
      formData.append("candidate", file.candidate);
      return axios.post(
        `/api/v1/preonboardings/candidate/${candidateId}/document/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
    }
  },
  getters: {
    companyPreonboardingList: (state) => state.preonboardingsList,
    preonboardingDocuments: (state) => state.documents,
    getPreonboardingById: (state) => (id) => state.preonboardingsList.find((preonboarding) => preonboarding.id === id),
    invitedTemplate: (state) => state.candidateTemplate
  }
};
