var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { zentric } from "@/api";
import Vue from "vue";
const state = {
  payrolls: [],
  fetching: false,
  incidencesSummary: [],
  isLoadingSummary: false,
  isSummaryEmpty: false,
  emptyPayrolls: false,
  payrollSelectedIndex: null
};
const getters = {
  payrollSelected(state2) {
    if (state2.payrollSelectedIndex > 0) {
      return state2.payrolls[state2.payrollSelectedIndex];
    }
    if (state2.payrolls.length > 0) {
      return state2.payrolls[0];
    }
    return null;
  }
};
let calendarYearMock = null;
const actions = {
  getPayrolls(_0) {
    return __async(this, arguments, function* ({ commit, dispatch, state: state2 }) {
      if (state2.fetching && calendarYearMock != null) {
        return;
      }
      commit("setFetching", true);
      const calendarYears = yield zentric.getCalendarYears();
      calendarYearMock = calendarYears;
      let currentYear = new Date().getFullYear();
      if (!calendarYears.includes(currentYear)) {
        [currentYear] = calendarYears.sort((a, b) => b - a);
      }
      const payrolls = yield zentric.getPayrollsInYear(currentYear);
      commit("setEmptyPayrolls", payrolls.length === 0);
      commit("setFetching", false);
      const payrollInStateNotInResponse = state2.payrolls.filter((payroll) => !payrolls.map(({ id }) => id).includes(payroll == null ? void 0 : payroll.id));
      if (payrollInStateNotInResponse.length > 0) {
        commit("removePayrolls", payrollInStateNotInResponse);
      }
      payrolls.forEach((payroll) => {
        dispatch("generatePayrollContext", payroll);
      });
      if (state2.payrollSelectedIndex === null && payrolls.length > 0) {
        dispatch("onChangePayrollSelected", 0);
      }
      if (state2.payrollSelectedIndex >= 0) {
        dispatch("refreshPayrollSelectedData");
      }
    });
  },
  generatePayrollContext(_0, _1) {
    return __async(this, arguments, function* ({ commit, dispatch, state: state2 }, payroll) {
      var _a;
      const payrollInState = state2.payrolls.find(({ id: id2 }) => id2 === (payroll == null ? void 0 : payroll.id));
      if ((_a = payrollInState == null ? void 0 : payrollInState.currentPeriod) == null ? void 0 : _a.fetching) {
        return;
      }
      const {
        id,
        calendar,
        period,
        name,
        year,
        attribute_name
      } = payroll;
      commit("addPayroll", {
        id,
        calendar,
        period,
        name,
        year,
        attribute_name,
        currentPeriod: {
          fetching: false,
          lastFetch: null
        },
        incidencesStats: {
          fetching: false,
          lastFetch: null
        },
        periodSummary: null,
        periodIncidences: [],
        periodClockIncidences: [],
        isSummaryLoading: false,
        isIncidencesLoading: false
      });
      commit("setFetchingField", { payrollId: id, field: "currentPeriod", status: true });
      let calendarToGetDetail = calendar;
      const calendars = yield zentric.getCalendars({ father_calendar: calendar });
      if (calendars.length > 1) {
        const calendarByYear = calendars.find((cal) => cal.year === year) || calendars.sort((a, b) => b - a)[0];
        calendarToGetDetail = calendarByYear.id;
      }
      const periods = yield zentric.getPeriodStart(calendarToGetDetail);
      const currentPeriod = periods.find((per) => per.period === period);
      commit("setCurrentPeriod", {
        payrollId: id,
        current: {
          start_date: currentPeriod.start_date,
          end_date: currentPeriod.end_date,
          payment_date: currentPeriod.payment_date
        }
      });
      commit("setFetchingField", { payrollId: id, field: "currentPeriod", status: false });
      dispatch("getIncidencesSummary", {
        id,
        start_date: currentPeriod.start_date,
        end_date: currentPeriod.end_date
      });
    });
  },
  getIncidencesSummary(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { id: payroll, start_date, end_date }) {
      commit("setFetchingField", { payrollId: payroll, field: "incidencesStats", status: true });
      const incidencesStats = yield zentric.getSummaryIncidences({
        payroll_zentric_id: payroll,
        end_date_gte: start_date,
        start_date_lte: end_date
      });
      commit("setIncidencesStats", { payrollId: payroll, incidencesStats });
      commit("setFetchingField", { payrollId: payroll, field: "incidencesStats", status: false });
    });
  },
  getTotalIncidences(_0, _1) {
    return __async(this, arguments, function* ({ commit }, dates) {
      commit("setIsLoadingSummary", true);
      try {
        const incidences = yield zentric.getSummaryTotalIncidences(dates);
        commit("setIncidencesSummary", incidences);
        commit("setIsSummaryEmpty", incidences.length === 0);
      } catch (_) {
      }
      commit("setIsLoadingSummary", false);
    });
  },
  onChangePayrollSelected({ commit, state: state2 }, payrolllIndex) {
    commit("setPayrollSelectedIndex", payrolllIndex);
  },
  refreshPayrollSelectedData(_0, _1) {
    return __async(this, arguments, function* ({ commit, state: state2, dispatch }, payrollSelectedIndex) {
      let payroll = null;
      if (payrollSelectedIndex >= 0) {
        payroll = state2.payrolls[payrollSelectedIndex];
      } else {
        payroll = state2.payrolls[state2.payrollSelectedIndex];
      }
      const {
        id,
        currentPeriod
      } = payroll != null ? payroll : {};
      const { start_date, end_date } = currentPeriod || {};
      commit("setIsIncidencesLoading", { payroll: id, status: true });
      if (payroll) {
        dispatch("getPeriodSummary", payroll);
        Promise.all([
          zentric.getClockIncidencesPending({
            payroll_zentric_id: id,
            end_date_gte: start_date,
            start_date_lte: end_date
          }).then((pendingIncidences) => {
            commit("setPeriodClockIncidences", { pending: pendingIncidences, payroll: id });
            return pendingIncidences;
          }),
          zentric.getIncidencesPending({
            payroll_zentric_id: id,
            end_date_gte: start_date,
            start_date_lte: end_date
          }).then((pendingIncidences) => {
            commit("setPeriodIncidences", { pending: pendingIncidences, payroll: id });
            return pendingIncidences;
          })
        ]).then(() => {
          commit("setIsIncidencesLoading", { payroll: id, status: false });
        });
      }
    });
  },
  getPeriodSummary({ commit }, { id, period, year }) {
    commit("setIsSummaryLoading", { payroll: id, status: true });
    zentric.getPeriodSummary({ payroll: id, period, year }).then((payrollSummary) => {
      commit("setPeriodSummary", payrollSummary);
      commit("setIsSummaryLoading", { payroll: id, status: false });
    });
  },
  setPrevPayroll({ dispatch, state: state2 }) {
    if (state2.payrollSelectedIndex === 0)
      return;
    const payrollSelectedIndex = state2.payrollSelectedIndex - 1;
    dispatch("onChangePayrollSelected", payrollSelectedIndex);
    dispatch("refreshPayrollSelectedData", payrollSelectedIndex);
  },
  setNextPayroll({ dispatch, state: state2 }) {
    if (state2.payrollSelectedIndex === state2.payrolls.length - 1)
      return;
    const payrollSelectedIndex = state2.payrollSelectedIndex + 1;
    dispatch("onChangePayrollSelected", payrollSelectedIndex);
    dispatch("refreshPayrollSelectedData", payrollSelectedIndex);
  }
};
const mutations = {
  addPayroll(state2, payroll) {
    const payrollFound = state2.payrolls.find(({ id }) => id === (payroll == null ? void 0 : payroll.id));
    if (!payrollFound) {
      state2.payrolls.push(payroll);
    } else {
      [
        "calendar",
        "period",
        "name",
        "year",
        "attribute_name",
        "periodSummary",
        "periodIncidences",
        "periodClockIncidences",
        "isSummaryLoading",
        "isIncidencesLoading"
      ].forEach((key) => {
        if (!payroll[key] && payrollFound[key]) {
          return;
        }
        Vue.set(payrollFound, key, payroll[key]);
      });
    }
  },
  setCurrentPeriod(state2, { payrollId, current }) {
    const payroll = state2.payrolls.find((pr) => pr.id === payrollId);
    payroll.currentPeriod = __spreadValues(__spreadValues({}, payroll.currentPeriod), current);
  },
  setIncidencesStats(state2, { payrollId, incidencesStats }) {
    const payroll = state2.payrolls.find((pr) => pr.id === payrollId);
    payroll.incidencesStats = __spreadValues(__spreadValues({}, payroll.incidencesStats), incidencesStats);
  },
  setSummary(state2, { payrollId, summary }) {
    const payroll = state2.payrolls.find((pr) => pr.id === payrollId);
    payroll.summary = __spreadValues(__spreadValues({}, payroll.summary), summary);
  },
  setFetching(state2, fetchingState) {
    state2.fetching = fetchingState;
  },
  setFetchingField(state2, { payrollId, field, status }) {
    const payroll = state2.payrolls.find((pr) => pr.id === payrollId);
    payroll[field].fetching = status;
  },
  setIncidencesSummary(state2, incidencesSummary) {
    state2.incidencesSummary = incidencesSummary;
  },
  setIsLoadingSummary(state2, status) {
    state2.isLoadingSummary = status;
  },
  setIsSummaryEmpty(state2, status) {
    state2.isSummaryEmpty = status;
  },
  removePayrolls(state2, payrollsToRemove) {
    state2.payrolls = state2.payrolls.filter((payroll) => !payrollsToRemove.map(({ id }) => id).includes(payroll == null ? void 0 : payroll.id));
  },
  setEmptyPayrolls(state2, status) {
    state2.emptyPayrolls = status;
  },
  setPayrollSelectedIndex(state2, index) {
    state2.payrollSelectedIndex = index;
  },
  setPeriodSummary(state2, periodSummary) {
    const payroll = state2.payrolls.find((pr) => pr.id === Number(periodSummary.payroll));
    if (payroll) {
      Vue.set(payroll, "periodSummary", periodSummary);
    }
  },
  setPeriodClockIncidences(state2, { pending, payroll: payrollId }) {
    const payroll = state2.payrolls.find((pr) => pr.id === Number(payrollId));
    if (payroll) {
      Vue.set(payroll, "periodClockIncidences", pending);
    }
  },
  setPeriodIncidences(state2, { pending, payroll: payrollId }) {
    const payroll = state2.payrolls.find((pr) => pr.id === Number(payrollId));
    if (payroll) {
      Vue.set(payroll, "periodIncidences", pending);
    }
  },
  setIsSummaryLoading(state2, { payroll: payrollId, status }) {
    const payroll = state2.payrolls.find((pr) => pr.id === Number(payrollId));
    if (payroll) {
      Vue.set(payroll, "isSummaryLoading", status);
    }
  },
  setIsIncidencesLoading(state2, { payroll: payrollId, status }) {
    const payroll = state2.payrolls.find((pr) => pr.id === Number(payrollId));
    if (payroll) {
      Vue.set(payroll, "isIncidencesLoading", status);
    }
  }
};
export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
};
