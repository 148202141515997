import { getInitialsCapitalized } from "@/utils/formatText";
export default {
  computed: {
    /*
      Returns the initials of a text with capital letters only if the word is not in the array
      toDiscard (see utils/formatText.js). If the name is not defined, returns an empty string.
      Uses role to determine if the employee is an OSR or not.
    */
    abbreviationName() {
      const { first_name: firstName = "", last_name: lastName = "", full_name: fullName = "" } = this.employee || {};
      const { role = "" } = this.user || {};
      const toDiscard = ["del", "de", "la", "las", "los"];
      const isOSRRole = role === "OSR";
      const concatFullName = `${firstName || ""}${lastName ? ` ${lastName}` : ""}`;
      const name = isOSRRole ? concatFullName : fullName;
      if (!name) {
        return "";
      }
      return getInitialsCapitalized(name, toDiscard);
    }
  }
};
