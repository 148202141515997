class CustomError extends Error {
  constructor(...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }
    this.name = "CustomError";
  }
}
class DataError extends CustomError {
  constructor(...params) {
    super(...params);
    this.name = "DataError";
  }
}
class NotFoundError extends CustomError {
  constructor(...params) {
    super(...params);
    this.name = "NotFoundError";
  }
}
export { DataError, NotFoundError };
