var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Company from "@/models/company";
export default {
  getAbscensesByArea(_0) {
    return __async(this, arguments, function* ({ rootState }) {
      const company = new Company(rootState.me.user.company);
      const abscensesByArea = yield company.reportAbscensesByArea().get();
      return abscensesByArea;
    });
  },
  getGenderReport(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const company = new Company(rootState.me.user.company);
      const currentGenders = yield company.reportGender().get();
      commit("setGenderReport", { data: currentGenders });
      return currentGenders;
    });
  },
  getAgeReport(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const company = new Company(rootState.me.user.company);
      const currentAges = yield company.reportAge().get();
      commit("setAgeReport", { data: currentAges });
      return currentAges;
    });
  },
  getCivilStatusReport(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const company = new Company(rootState.me.user.company);
      const civilStatus = yield company.reportCivilStatus().get();
      commit("setCivilStatusReport", { data: civilStatus });
      return civilStatus;
    });
  },
  getEscolarityReport(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const company = new Company(rootState.me.user.company);
      const educationLevel = yield company.reportEducationlevel().get();
      commit("setEscolarityReport", { data: educationLevel });
      return educationLevel;
    });
  },
  getIncidencesReport(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const company = new Company(rootState.me.user.company);
      const incidencesReport = yield company.reportIncidences().get();
      return incidencesReport;
    });
  },
  getSalaryReport(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const company = new Company(rootState.me.user.company);
      const salaryByArea = yield company.reportSalary().get();
      commit("setSalaryReport", { data: salaryByArea });
      return salaryByArea;
    });
  },
  getSentimentByWeekReport(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, filters) {
      const company = new Company(rootState.me.user.company);
      const reportSentiment = yield company.reportSentimentByWeek(filters).get();
      commit("setSentimentbyWeekReport", { data: reportSentiment });
      return reportSentiment;
    });
  },
  getSentimentByArea(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, filters) {
      const company = new Company(rootState.me.user.company);
      const reportSentiment = yield company.reportSentimentByArea(filters).get();
      return reportSentiment;
    });
  },
  getSentimentbyMonthYear(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, filters) {
      const company = new Company(rootState.me.user.company);
      const reportSentiment = yield company.reportSentimentByMonthYear(filters).get();
      return reportSentiment;
    });
  },
  getEmployeesByYear(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const company = new Company(rootState.me.user.company);
      const employeesByYear = yield company.reportEmployeesByYear().get();
      return employeesByYear;
    });
  },
  getEmploymentDate(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const company = new Company(rootState.me.user.company);
      const employmentDate = yield company.reportEmploymentDate().get();
      return employmentDate;
    });
  },
  getTrimesterRotation(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const company = new Company(rootState.me.user.company);
      const reportTrimesterRotation = yield company.reportTrimesterRotation().get();
      commit("setTrimesterRotation", { data: reportTrimesterRotation });
      return reportTrimesterRotation;
    });
  },
  updateLocation(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, { location }) {
      const oldLocation = state.list.find((_location) => _location.id === location.id);
      const locationObject = new Location(oldLocation);
      const updatedLocation = yield locationObject.setFields(location).update();
      commit("setLocation", { location: updatedLocation });
      return updatedLocation;
    });
  },
  deleteLocation(_0, _1) {
    return __async(this, arguments, function* ({ commit, state, rootState }, { id }) {
      const oldLocation = state.list.find((_location) => _location.id === id);
      const location = new Location(oldLocation);
      yield location.delete();
      const locations = state.list.filter((_location) => _location.id !== id);
      commit("setLocations", { locations });
      return locations;
    });
  }
};
