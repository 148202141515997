var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "textarea", class: { "textarea--is-focus": _vm.isFocus } }, [_c("textarea", { directives: [{ name: "model", rawName: "v-model", value: _vm.internalComputed, expression: "internalComputed" }], staticClass: "textarea__control", attrs: { "placeholder": _vm.placeholder, "required": _vm.required, "name": _vm.name, "maxlength": _vm.maxLength }, domProps: { "value": _vm.internalComputed }, on: { "focus": _vm.onFocus, "blur": _vm.onBlur, "input": function($event) {
    if ($event.target.composing)
      return;
    _vm.internalComputed = $event.target.value;
  } } }), _vm._v(" "), _c("div", { staticClass: "textarea__corner textarea__corner--left-down" }, [_vm._t("default")], 2), _vm.maxLength ? _c("div", { staticClass: "textarea__counter" }, [_vm._v(" " + _vm._s(_vm.maxLengthText) + " ")]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
