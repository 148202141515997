var _a;
import store from "@/store";
const routes = {
  path: "mi-suscripcion",
  name: "suscripcion",
  component: () => import(
    /* webpackChunkName: "subscription" */
    "@/views/v2/selfOnboarding/Subscription"
  ),
  redirect: { name: "suscripcion-info" },
  children: [
    {
      path: "",
      name: "suscripcion-info",
      component: () => import(
        /* webpackChunkName: "subscription" */
        "@/views/v2/selfOnboarding/SubscriptionInfo"
      ),
      meta: {
        module: "Carrito de Compra",
        notValidateModule: true
      }
    },
    {
      path: "facturacion",
      name: "facturacion-info",
      component: () => import(
        /* webpackChunkName: "subscription" */
        "@/views/v2/selfOnboarding/BillingInfo"
      ),
      meta: {
        module: "Carrito de Compra",
        notValidateModule: true
      }
    },
    {
      path: "facturas",
      name: "facturas",
      component: () => import(
        /* webpackChunkName: "subscription" */
        "@/views/v2/selfOnboarding/Bills"
      ),
      meta: {
        module: "Carrito de Compra",
        notValidateModule: true
      }
    },
    {
      path: "mejorar",
      name: "up-sell",
      component: {
        render: (h) => h("router-view")
      },
      redirect: { name: "up-sell-info" },
      beforeEnter: (to, from, next) => {
        var _a2, _b;
        const { user = {} } = ((_b = (_a2 = store) == null ? void 0 : _a2.state) == null ? void 0 : _b.me) || {};
        const { features = [] } = user || {};
        const isHRA = (user == null ? void 0 : user.role) === "HRA";
        const feature = features.find((e) => (e == null ? void 0 : e.feature) === "payments.subscription.upsell") || { active: false };
        if (!isHRA || !(feature == null ? void 0 : feature.active)) {
          next({ name: "not-found" });
        } else {
          next();
        }
      },
      children: [
        {
          path: "",
          name: "up-sell-info",
          component: () => import(
            /* webpackChunkName: "subscription" */
            "@/views/v2/selfOnboarding/UpSellView"
          ),
          meta: {
            requireAuth: true,
            module: "Mejorar suscripci\xF3n",
            publicName: ((_a = store.state.me.user.company) == null ? void 0 : _a.is_self_onboarded) ? "Mejora tu plan" : "Asciende tu suscripci\xF3n",
            backTo: "back",
            notValidateModule: true
          }
        },
        {
          path: "detalle/:moduleName?",
          name: "up-sell-detail",
          component: () => import(
            /* webpackChunkName: "subscription" */
            "@/views/v2/selfOnboarding/UpSellViewDetail"
          ),
          beforeEnter: (to, from, next) => {
            if (!to.query.id) {
              next({ name: "up-sell-info" });
            } else
              next();
          },
          meta: {
            module: "Mejorar suscripci\xF3n",
            backTo: "back",
            notValidateModule: true
          }
        }
      ]
    }
  ]
};
export default routes;
