var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import RazonSocial from "@/models/razonSocial";
export default {
  getRazonesSociales(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const razonesSocialesObject = new RazonSocial();
      const razonesSociales = yield razonesSocialesObject.get();
      commit("setRazonesSociales", { razonesSociales });
      return razonesSociales;
    });
  },
  createRazonSocial(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, { razonSocial }) {
      const razonesSocialesObject = new RazonSocial();
      const razonesSociales = yield razonesSocialesObject.create(razonSocial);
      commit("setRazonSocial", { razonSocial: razonesSociales });
      return razonesSociales;
    });
  },
  updateRazonSocial(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, { razonSocial }) {
      const oldRazonSocial = state.list.find((_razonSocial) => _razonSocial.id === razonSocial.id);
      const razonSocialObject = new RazonSocial(oldRazonSocial);
      const updatedRazonSocial = yield razonSocialObject.update(razonSocial);
      commit("setRazonSocial", { razonSocial: updatedRazonSocial });
      return updatedRazonSocial;
    });
  },
  deleteRazonSocial(_0, _1) {
    return __async(this, arguments, function* ({ commit, state, rootState }, { razonSocial }) {
      const razonesSocialesObject = new RazonSocial();
      yield razonesSocialesObject.delete(razonSocial.id);
      commit("unsetRazonSocial", razonSocial);
    });
  }
};
