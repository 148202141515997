var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Company from "@/models/company";
import Employee from "@/models/employee";
import EmployeeV2 from "@/models/v2/employee";
import { DataError, NotFoundError } from "@/exceptions";
import { api } from "@/api";
import axios from "axios";
export default {
  getEmployees(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      try {
        const company = new Company(rootState.me.user.company);
        const list = yield company.employees().get();
        const employees = list.map((employee) => new Employee(employee)).map((emp) => {
          const formatEmp = __spreadValues({}, emp);
          formatEmp.bossName = "No disponible";
          if (formatEmp.boss) {
            const findBoss = list.find((boss) => boss.id === formatEmp.boss);
            if (findBoss && !findBoss.laid_off) {
              formatEmp.bossName = `${findBoss.first_name} ${findBoss.last_name} ${findBoss.last_name2}`;
            }
          }
          return formatEmp;
        });
        commit("setEmployees", { data: employees });
        return employees;
      } catch (e) {
        commit("setEmployees", { data: [] });
        return [];
      }
    });
  },
  getEmployeesMinInfo(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const { data } = yield api.company(rootState.me.user.company.id).getEmployeesMinimumInfo();
      commit("setEmployees", { data });
      return data;
    });
  },
  getEmployeesWithPolicies(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      const { data } = yield api.vacationsV3.getEmployeesWithPolicies();
      commit("setEmployeesWithPolicies", { data });
      return data;
    });
  },
  getCalendarEmployees(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      try {
        const company = new Company(rootState.me.user.company);
        const list = yield company.calendarEmployees().get();
        const employees = list.map((employee) => new Employee(employee)).map((emp) => {
          const formatEmp = __spreadValues({}, emp);
          formatEmp.bossName = "No disponible";
          if (formatEmp.boss) {
            const findBoss = list.find((boss) => boss.id === formatEmp.boss);
            if (findBoss && !findBoss.laid_off) {
              formatEmp.bossName = findBoss.full_name;
            }
          }
          return formatEmp;
        });
        commit("setEmployees", { data: employees });
        return employees;
      } catch (e) {
        commit("setEmployees", { data: [] });
        return [];
      }
    });
  },
  getEmployeesTimeClockSettings(_0) {
    return __async(this, arguments, function* ({ commit, rootState, rootGetters }) {
      const company = new Company(rootState.me.user.company);
      const companyAreas = rootGetters["company/areas/companyAreas"];
      const { data } = yield axios.get(`/api/v2/companies/${company.id}/employees/`);
      const employees = data.map((emp) => {
        const formatEmp = __spreadValues({}, emp);
        formatEmp.areaName = "Sin Asignar";
        if (formatEmp.area) {
          const findArea = companyAreas.find((ar) => ar.id === formatEmp.area);
          formatEmp.areaName = findArea ? findArea.name : "Sin Asignar";
        }
        formatEmp.bossName = "No disponible";
        if (formatEmp.boss) {
          const findBoss = data.find((boss) => boss.id === formatEmp.boss);
          if (findBoss && !findBoss.laid_off) {
            formatEmp.bossName = findBoss.full_name;
          }
        }
        return formatEmp;
      });
      commit("setEmployees", { data: employees });
      return employees;
    });
  },
  getDirectory(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      try {
        const { data } = yield api.directory.getDirectory();
        const employees = data.map((employee) => new Employee(employee)).map((emp) => {
          const formatEmp = __spreadValues({}, emp);
          formatEmp.bossName = "No disponible";
          if (formatEmp.boss) {
            formatEmp.bossName = `${formatEmp.boss.first_name}
            ${formatEmp.boss.last_name} ${formatEmp.boss.last_name2}`;
          }
          return formatEmp;
        });
        commit("setEmployees", { data: employees });
        return employees;
      } catch (e) {
        commit("setEmployees", { data: [] });
        throw e;
      }
    });
  },
  getDirectoryEmployees(_0) {
    return __async(this, arguments, function* ({ commit, rootState }) {
      try {
        const { data } = yield api.directory.getDirectoryEm();
        const employees = data.map((employee) => new Employee(employee)).map((emp) => {
          const formatEmp = __spreadValues({}, emp);
          formatEmp.bossName = "No disponible";
          if (formatEmp.boss) {
            formatEmp.bossName = formatEmp.boss.full_name;
          }
          return formatEmp;
        });
        commit("setEmployees", { data: employees });
        return employees;
      } catch (e) {
        commit("setEmployees", { data: [] });
        throw e;
      }
    });
  },
  getPublicProfileComplete(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, employeeId) {
      const { data } = yield api.profile.getPublicProfileComplete(employeeId);
      return data;
    });
  },
  getPrivateProfileComplete(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, employeeId) {
      const { data } = yield api.profile.getPrivateProfileComplete(employeeId);
      return data;
    });
  },
  getProfile(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState, state }, employeeId) {
      var _a;
      if (((_a = state == null ? void 0 : state.profile) == null ? void 0 : _a.id) !== employeeId) {
        commit("setGettingState", "NewProfile");
      } else {
        commit("setGettingState", "ReloadProfile");
      }
      const { data } = yield api.directory.getProfile(employeeId);
      commit("setProfile", data);
      commit("setGettingState", "OK");
      return data;
    });
  },
  reloadProfile({ dispatch, state }) {
    var _a;
    if ((_a = state == null ? void 0 : state.profile) == null ? void 0 : _a.id) {
      dispatch("getProfile", state.profile.id);
    }
  },
  getProfileEditing(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, employeeId) {
      const { data } = yield api.directory.getProfile(employeeId);
      return new EmployeeV2(data);
    });
  },
  getPublicProfile(_0, _1) {
    return __async(this, arguments, function* ({ commit, rootState }, employeeId) {
      const { data } = yield api.profile.getPublicProfile(employeeId);
      return data;
    });
  },
  setLaboralInfo(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { employeeId, payload }) {
      const { data } = yield api.profile.setLaboralInfo(employeeId, payload);
      return data;
    });
  },
  setWorkInfo(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { employeeId, payload }) {
      const { data } = yield api.profile.setWorkInfo(employeeId, payload);
      return data;
    });
  },
  getMyTeam(_0, _1) {
    return __async(this, arguments, function* ({ commit }, employeeId) {
      const { data } = yield api.profile.getMyTeam(employeeId);
      return data;
    });
  },
  getMyFirstSubordinates(_0, _1) {
    return __async(this, arguments, function* ({ commit }, employeeId) {
      const { data } = yield api.profile.getMyTeam(employeeId);
      if (data.subordinates_1) {
        commit("me/setSubordinates", data.subordinates_1, { root: true });
      } else {
        commit("me/setSubordinates", [], { root: true });
      }
      return data.subordinates_1 ? data.subordinates_1 : [];
    });
  },
  getSubstitute(_0, _1) {
    return __async(this, arguments, function* ({ commit }, employeeId) {
      const { data } = yield api.profile.getSubstitute(employeeId);
      return data;
    });
  },
  setNewSubstitute(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { employeeId, payload }) {
      const data = yield api.profile.setSubstitute(employeeId, payload);
      return data;
    });
  },
  getHobbies(_0, _1) {
    return __async(this, arguments, function* ({ commit }, employeeId) {
      const { data } = yield api.profile.getHobbies(employeeId);
      return data;
    });
  },
  setHobbies(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { employeeId, payload }) {
      const data = yield api.profile.setHobbies(employeeId, payload);
      return data;
    });
  },
  getCustomResponse(_0, _1) {
    return __async(this, arguments, function* ({ commit }, employeeId) {
      const { data } = yield api.profile.getCustomResponse(employeeId);
      return data;
    });
  },
  setCustomResponse(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { employeeId, payload }) {
      const data = yield api.profile.setCustomResponse(employeeId, payload);
      return data;
    });
  },
  getExpertise(_0, _1) {
    return __async(this, arguments, function* ({ commit }, employeeId) {
      const { data } = yield api.profile.getExpertise(employeeId);
      return data;
    });
  },
  setExpertise(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { employeeId, payload }) {
      const data = yield api.profile.setExpertise(employeeId, payload);
      return data;
    });
  },
  getExpertiseDocument(_0, _1) {
    return __async(this, arguments, function* ({ commit }, documentId) {
      const { data } = yield api.profile.getExpDocs(documentId);
      return data;
    });
  },
  askFeedback(_0, _1) {
    return __async(this, arguments, function* ({ commit }, payload) {
      const data = yield api.profile.askFeedback(payload);
      return data;
    });
  },
  getPayrollInfo(_0, _1) {
    return __async(this, arguments, function* ({ commit }, employeeId) {
      const { data } = yield api.profile.getPayrollInfo(employeeId);
      return data;
    });
  },
  setPayrollInfo(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { employeeId, payload }) {
      const { data } = yield api.profile.setPayrollInfo(employeeId, payload);
      return data;
    });
  },
  askSalaryRaise(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { payload }) {
      const { data } = yield api.profile.salaryRaise(payload);
      return data;
    });
  },
  getPersonalInfo(_0, _1) {
    return __async(this, arguments, function* ({ commit }, employeeId) {
      const { data } = yield api.profile.getPersonalInfo(employeeId);
      return data;
    });
  },
  setPersonalInfo(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { employeeId, payload }) {
      const { data } = yield api.profile.setPersonalInfo(employeeId, payload);
      return data;
    });
  },
  getCustomInfo(_0, _1) {
    return __async(this, arguments, function* ({ commit }, employeeId) {
      const { data } = yield api.profile.getCustomInfo(employeeId);
      return data;
    });
  },
  getDocumentsInfo(_0, _1) {
    return __async(this, arguments, function* ({ commit }, employeeId) {
      const { data } = yield api.profile.getDocumentsInfo(employeeId);
      return data;
    });
  },
  getAnnivCongrats(_0, _1) {
    return __async(this, arguments, function* ({ commit }, employeeId) {
      const { data } = yield api.profile.getAnnivCongrats(employeeId);
      return data;
    });
  },
  getBirthCongrats(_0, _1) {
    return __async(this, arguments, function* ({ commit }, employeeId) {
      const { data } = yield api.profile.getBirthCongrats(employeeId);
      return data;
    });
  },
  getIncidencesHistory(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { id, page }) {
      const { data } = yield api.profile.getIncidencesHistory(id, page);
      return data;
    });
  },
  // async getVacationsMovements ({ commit }, { id, page }) {
  getVacationsMovements(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { id, page }) {
      const { data } = yield api.vacations.getRecentsMovements(id, "VA", page);
      return data;
    });
  },
  getPTOMovements(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { id, page }) {
      const { data } = yield api.vacations.getRecentsMovements(id, "PTO", page);
      return data;
    });
  },
  congrat(_0, _1) {
    return __async(this, arguments, function* ({ commit }, payload) {
      const { data } = yield api.profile.setCongrats(payload);
      return data;
    });
  },
  createEmployee(_0) {
    return __async(this, arguments, function* ({ commit, dispatch }, { employee, activate } = {}) {
      const { data } = yield api.directory.createEmployee(employee);
      const newEmployee = new Employee(data);
      commit("setEmployee", { data: newEmployee });
      if (activate) {
        const { id } = newEmployee;
        dispatch("inviteEmployee", { id });
      }
      return newEmployee;
    });
  },
  getEmployee(_0) {
    return __async(this, arguments, function* ({ commit }, { id } = {}) {
      if (!id) {
        throw new DataError("The id is not defined");
      }
      try {
        const employee = yield Employee.retrive(id);
        commit("setEmployee", { data: employee });
        return employee;
      } catch (e) {
        if (e.response && e.response.status === 404) {
          throw new NotFoundError("Employee not found");
        }
        throw e;
      }
    });
  },
  updateEmployeeProfile(_0, _1) {
    return __async(this, arguments, function* ({
      state,
      getters,
      commit,
      dispatch,
      rootGetters
    }, { id, data }) {
      if (!data) {
        throw new DataError("The data is not defined");
      }
      const update = yield api.employees.updateProfile({ id, data });
      if (update.data.boss && typeof update.data.boss === "string") {
        const findBoss = getters.currentEmployees.find((emp) => emp.id === update.data.boss);
        if (findBoss) {
          update.data.boss = findBoss;
          update.data.bossName = findBoss.full_name;
        }
      } else if (update.data.boss && update.data.boss === "object") {
        update.data.bossName = update.data.boss.full_name;
      }
      const updatedEmployee = new Employee(update.data);
      commit("setEmployee", { data: updatedEmployee });
      const isMe = rootGetters["me/employee"];
      if (id === isMe.id) {
        const meUpdated = yield axios.get(`/api/v1/me/`);
        yield commit("me/setMeData", meUpdated.data, { root: true });
      }
      return update.data;
    });
  },
  updateEmployeeBoss(_0, _1) {
    return __async(this, arguments, function* ({
      state,
      getters,
      commit,
      dispatch,
      rootGetters
    }, { data, id }) {
      if (!data) {
        throw new DataError("The data is not defined");
      }
      const update = yield api.employees.updateBoss({ data });
      update.data.forEach((single) => __async(this, null, function* () {
        const isMe = rootGetters["me/employee"];
        if (id === isMe.id) {
          const meUpdated = yield axios.get(`/api/v1/me/`);
          yield commit("me/setMeData", meUpdated.data, { root: true });
        }
      }));
      return update;
    });
  },
  updateEmployeePolicy({
    state,
    getters,
    commit,
    dispatch,
    rootGetters
  }, { data }) {
    if (!data) {
      throw new DataError("The data is not defined");
    }
    commit("setEmployeeWithPolicies", { data });
  },
  updateEmployee(_0) {
    return __async(this, arguments, function* ({
      state,
      commit,
      dispatch,
      rootState
    }, { data } = {}) {
      if (!data) {
        throw new DataError("The data is not defined");
      }
      const employeeData = state.list.find((emp) => emp.id === data.id);
      const employeeObj = new Employee(employeeData);
      const update = yield employeeObj.setFields(data).updatePatch(data);
      commit("setEmployee", { data: update });
      if (data.id === rootState.me.user.employee.id) {
        dispatch("me/getMe", { root: true });
      }
      return update;
    });
  },
  updateEmployeeDaysOff(_0) {
    return __async(this, arguments, function* ({
      state,
      commit,
      dispatch,
      rootState
    }, { data } = {}) {
      if (!data) {
        throw new DataError("The data is not defined");
      }
      const employeeData = state.list.find((emp) => emp.id === data.id);
      const employeeObj = new Employee(employeeData);
      commit("setEmployee", { data: employeeObj });
      if (data.id === rootState.me.user.employee.id) {
        dispatch("me/getMe", { root: true });
      }
    });
  },
  deleteEmployee(_0) {
    return __async(this, arguments, function* ({ commit, state }, { employee } = {}) {
      if (!employee) {
        throw new DataError("The employee is not defined");
      }
      const oldEmployee = new Employee(employee);
      yield oldEmployee.delete();
      const employees = state.list.filter((_employee) => _employee.id !== employee.id);
      commit("setEmployees", { data: employees });
      return employees;
    });
  },
  inviteEmployee(_0, _1) {
    return __async(this, arguments, function* ({ commit }, { id }) {
      const inviteEmp = yield axios.post(`/api/v2/employees/${id}/invite/`);
      const updatedEmployee = new Employee(inviteEmp);
      commit("setEmployee", { data: updatedEmployee });
    });
  },
  assignLevel(_0) {
    return __async(this, arguments, function* ({ dispatch }, { employee, level } = {}) {
      if (employee === void 0) {
        throw new DataError("The employee is not defined");
      }
      if (level === void 0) {
        throw new DataError("The area is not defined");
      }
      return dispatch("updateEmployee", { data: { level: level.id } });
    });
  },
  laidOffEmployee(_0) {
    return __async(this, arguments, function* ({ state, commit }, {
      employeeId,
      workEndDate,
      laidOffReason,
      laidOffComment
    } = {}) {
      if (!employeeId) {
        throw new DataError("The employee is not defined");
      }
      yield api.directory.laidOffEmployee({
        employeeId,
        work_end_date: workEndDate,
        laid_off_reason: laidOffReason,
        laid_off_comments: laidOffComment
      });
    });
  },
  setRecruiter(_0) {
    return __async(this, arguments, function* ({ state, commit }, { data } = {}) {
      if (!data) {
        throw new DataError("The employee is not defined");
      }
      const employeeData = state.list.find((emp) => emp.id === data.id);
      const employeeObj = new Employee(employeeData);
      const update = yield employeeObj.setRecruiter({
        is_recruiter: true
      });
      update.full_name = employeeObj.full_name;
      return commit("setEmployee", { data: update });
    });
  },
  removeRecruiter(_0) {
    return __async(this, arguments, function* ({ state, commit }, { data } = {}) {
      if (!data) {
        throw new DataError("The employee is not defined");
      }
      const employeeData = state.list.find((emp) => emp.id === data.id);
      const employeeObj = new Employee(employeeData);
      const update = yield employeeObj.setRecruiter({
        is_recruiter: false
      });
      update.full_name = employeeObj.full_name;
      return commit("setEmployee", { data: update });
    });
  },
  setAvatar(_0, _1) {
    return __async(this, arguments, function* ({ state, commit, rootState }, { oldEmployee, file }) {
      const employee = new EmployeeV2(oldEmployee);
      const updatedAvatar = yield employee.setAvatar(file);
      const newEmployee = __spreadValues({}, oldEmployee);
      newEmployee.avatar = updatedAvatar.avatar;
      if (rootState.me.user.employee.id === oldEmployee.id) {
        commit("me/setEmployee", newEmployee, { root: true });
      }
    });
  },
  removeAvatar(_0, _1) {
    return __async(this, arguments, function* ({ state, dispatch }, { id }) {
      const oldEmployee = state.list.find((employee2) => employee2.id === id);
      const employee = new Employee(oldEmployee);
      const updatedEmployee = yield employee.setFields({ avatar: null }).update();
      return dispatch("updateEmployee", { data: updatedEmployee });
    });
  },
  getVacancies(_0, _1) {
    return __async(this, arguments, function* ({ state, commit }, { id }) {
      const employee = new Employee({ id });
      const vacancies = yield employee.getVacancies();
      commit("company/vacancies/setVacancies", { data: vacancies }, { root: true });
      return vacancies;
    });
  },
  removeVisitor(_0, _1) {
    return __async(this, arguments, function* ({ dispatch }, { data }) {
      if (!data) {
        throw new Error("The employee is not defined");
      }
      const oldEmployee = new Employee(data);
      const employee = yield oldEmployee.setFields({ is_analyzer: false }).update();
      return dispatch("updateEmployee", { data: employee });
    });
  },
  removeEvaluator(_0, _1) {
    return __async(this, arguments, function* ({ dispatch }, { data }) {
      if (!(data == null ? void 0 : data.id)) {
        throw new Error("The employee is not defined");
      }
      return dispatch("updateEmployee", { data: { id: data.id, is_evaluator: false } });
    });
  },
  acceptTerms(_0) {
    return __async(this, arguments, function* ({ state, dispatch }, { id } = {}) {
      if (!id) {
        throw new DataError("The employee is not defined");
      }
      const employee = new Employee(state.list.find((_employee) => _employee.id === id));
      yield employee.acceptTerms();
      dispatch("user/getUserEmployee", {}, { root: true });
    });
  },
  getAvailableRequest(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, { id }) {
      if (!id) {
        throw new DataError("The employee is not defined");
      }
      const employee = new Employee(state.list.find((_employee) => _employee.id === id));
      const availableRequests = yield employee.getAvailableRequests();
      return availableRequests;
    });
  },
  getLaidOffEmployees(_0) {
    return __async(this, arguments, function* ({ state, commit, rootState }) {
      try {
        const company = new Company(rootState.me.user.company);
        const laidOffEmployees = yield company.laidOffEmployees().get();
        const list = laidOffEmployees.map((employee) => new Employee(employee)).map((emp) => {
          const formatEmp = __spreadValues({}, emp);
          formatEmp.bossName = "No disponible";
          if (formatEmp.boss) {
            const findBoss = state.list.find((boss) => boss.id === formatEmp.boss);
            if (findBoss && !findBoss.laid_off) {
              formatEmp.bossName = findBoss.full_name;
            }
          }
          return formatEmp;
        });
        commit("setLaidOffEmployees", { data: list });
        return laidOffEmployees;
      } catch (e) {
        commit("setLaidOffEmployees", { data: [] });
        throw e;
      }
    });
  },
  getAvailableRequestCustom(_0, _1) {
    return __async(this, arguments, function* ({ commit, state }, idEmployee) {
      if (!idEmployee) {
        throw new DataError("The employee is not defined");
      }
      const employee = new Employee(state.list.find((_employee) => _employee.id === idEmployee));
      const availableRequests = yield employee.getMyRequestableRequests(idEmployee);
      return availableRequests;
    });
  }
};
