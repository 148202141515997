import Vue from "vue";
import CardRefresh from "./CardRefresh";
import "@/styles/css/tailwind.css";
export default function generateRefreshCard() {
  const cardWrapper = document.createElement("div");
  document.body.appendChild(cardWrapper);
  new Vue({
    el: cardWrapper,
    render: (h) => h(CardRefresh)
  });
}
