import { computed, getCurrentInstance, inject, watch } from "vue";
import getDeepFieldFromObject from "@/components/v2/utils/getDeepFieldFromObject";
export default function useInput() {
  const instance = getCurrentInstance();
  const reloadParent = () => {
    const { $parent } = instance.proxy;
    if ($parent && $parent.isField) {
      $parent.checkChildrenProperties();
    }
  };
  watch(() => instance.proxy.$props.required, () => {
    reloadParent();
  });
  watch(() => instance.proxy.$props.name, () => {
    reloadParent();
  });
  const $v = inject("$v");
  const hasValidationInjected = computed(() => !!$v);
  const validationNameField = computed(() => {
    if (!hasValidationInjected.value) {
      return "";
    }
    const dataIndex = instance.proxy.dataIndex;
    const dataGroup = instance.proxy.dataGroup;
    const name = instance.proxy.name;
    if (dataIndex !== null) {
      return `${dataGroup}.$each[${dataIndex}].${name}`;
    }
    return [dataGroup, name].filter((item) => item).join(".");
  });
  const validationElement = computed(() => {
    if (!hasValidationInjected.value) {
      return null;
    }
    return getDeepFieldFromObject($v, validationNameField.value);
  });
  const hasError = computed(() => {
    if (!validationElement.value) {
      return false;
    }
    return validationElement.value.$error;
  });
  return {
    hasValidationInjected,
    validationNameField,
    validationElement,
    hasError
  };
}
