var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import * as types from "./mutations-types";
export default {
  [types.GET_TRAININGS](state, payload) {
    state.trainings = payload;
  },
  [types.CREATE_TRAINING](state, payload) {
    state.trainings = __spreadProps(__spreadValues({}, state.trainings), {
      count: state.trainings.count + 1,
      results: [
        ...state.trainings.results,
        payload
      ]
    });
  },
  [types.EDIT_TRAINING](state, training) {
    const itemIndex = state.trainings.results.findIndex((t) => t.id === training.id);
    state.trainings.results.splice(itemIndex, 1, training);
  },
  [types.CLONE_TRAINING](state, payload) {
    state.trainings = __spreadProps(__spreadValues({}, state.trainings), {
      count: state.trainings.count + 1,
      results: [
        ...state.trainings.results,
        payload
      ]
    });
  },
  [types.ARCHIVE_TRAINING](state, { index, training }) {
    state.trainings.results.splice(index, 1, training);
  },
  [types.UNARCHIVE_TRAINING](state, { index, training }) {
    state.trainings.results.splice(index, 1, training);
  },
  [types.DELETE_TRAINING](state, payload) {
    state.trainings = __spreadProps(__spreadValues({}, state.trainings), {
      count: state.trainings.count - 1,
      results: state.trainings.results.filter((training) => training.id !== payload.id)
    });
  },
  [types.SET_GUESTS_IN_PROCESS](state, payload) {
    state.selectedTraining.guestsInProcess = payload;
  },
  [types.REMOVE_GUESTS_IN_PROCESS](state, guestsIds) {
    const { results } = state.selectedTraining.guestsInProcess;
    state.selectedTraining.guestsInProcess.results = results.filter((employee) => !guestsIds.includes(employee.id));
  },
  [types.SET_GUESTS_FINISHED](state, payload) {
    state.selectedTraining.guestsFinished = payload;
  },
  [types.CLEAN_SELECTED_TRAINING](state) {
    state.selectedTraining = {
      guestsInProcess: {
        results: []
      },
      guestsFinished: {
        results: []
      }
    };
  },
  // BOSS
  [types.SET_TEAM_TRAININGS](state, payload) {
    state.teamTrainings = payload;
  },
  [types.EDIT_TEAM_TRAINING](state, training) {
    const itemIndex = state.teamTrainings.results.findIndex((t) => t.id === training.id);
    state.teamTrainings.results.splice(itemIndex, 1, training);
  },
  // EMPLOYEE
  [types.SET_MY_TRAININGS](state, payload) {
    state.myTrainings = payload;
  },
  [types.SET_MY_COMPLETED_TRAININGS](state, payload) {
    state.myCompletedTrainings = payload;
  },
  [types.UPDATE_MY_TRAINING](state, training) {
    const trainingIdx = state.myTrainings.results.findIndex((t) => t.id === training.id);
    state.myTrainings.results.splice(trainingIdx, 1, training);
  },
  [types.START_LOADING](state) {
    state.loading += 1;
  },
  [types.STOP_LOADING](state) {
    state.loading -= 1;
  }
};
