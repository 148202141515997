function capitalize(word) {
  let newWord = word;
  if (word && typeof word === "string") {
    newWord = word[0].toUpperCase() + word.slice(1).toLowerCase();
  }
  return newWord;
}
function capitalizeFirstLetter(word) {
  let newWord = word;
  if (word && typeof word === "string") {
    newWord = word[0].toUpperCase() + word.slice(1);
  }
  return newWord;
}
export function camelCaseTransform(object) {
  const transformedObject = {};
  Object.keys(object).forEach((key) => {
    const words = key.split("_");
    if (words.length > 1) {
      const firstWord = words.shift();
      const nextWords = [];
      words.forEach((word) => {
        nextWords.push(capitalize(word));
      });
      const newKey = [firstWord.toLowerCase(), ...nextWords].join("");
      transformedObject[newKey] = object[key];
    } else {
      transformedObject[key] = object[key];
    }
  });
  return transformedObject;
}
export { capitalize, capitalizeFirstLetter };
